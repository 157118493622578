import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

import { AppError } from '../errors/app-error';
import { UnsubscribeTarget } from '../resources/config';

export const dispatchAppError = createAction('[Core] Dispatch App Error', props<{ source: string; error: AppError }>());
export const dispatchInfoMessage = createAction('[Core] Dispatch Info Message', props<{ message: string }>());

export const setBrowserTitle = createAction('[Core] Set Browser Title', props<{ subTitle?: string; problemDetailFlag?: boolean }>());
export const setTitle = createAction('[Core] Set Title', props<{ title: string }>());

export const navigate = createAction('[Core] Navigate', props<{ url: string; extras?: NavigationExtras }>());
export const openWindow = createAction('[Core] Open Window', props<{ url: string; target?: string; extras?: NavigationExtras }>());
export const openPrintDialog = createAction('[Core] Open Print Dialog');
export const redirectPage = createAction('[Core] Redirect Page', props<{ url: string }>());

export const unsubscribeWatchedProps = createAction('[Core] Unsubscribe Watched Props', props<{ target: UnsubscribeTarget }>());
