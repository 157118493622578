<!-- 入力画面 -->
<div class="root-container" *ngIf="mode == 'input'">
  <div class="mat-title">メールアドレス変更</div>

  <div class="form-container">
    <form class="input-form" [formGroup]="emailForm">
      <div>ご登録のメールアドレスを変更できます。</div>
      <div class="email">現在のメールアドレス：{{ email }}</div>

      <div>ご本人確認のため、現在のパスワードを入力してください。</div>
      <!-- 現在のパスワード -->
      <mat-form-field appearance="outline">
        <mat-label>現在のパスワード</mat-label>
        <input matInput formControlName="password" [type]="'password'" />
        <mat-error *ngIf="emailForm.get('password').hasError('required')">入力してください</mat-error>
      </mat-form-field>

      <div>変更後のメールアドレスを入力してください。</div>
      <!-- 変更後のメールアドレス -->
      <mat-form-field class="mail-form-field" appearance="outline">
        <mat-label>変更後のメールアドレス</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="emailForm.get('email').hasError('required')">入力してください</mat-error>
        <mat-error *ngIf="emailForm.get('email').hasError('email')">メールアドレス形式で入力してください</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="buttons-container">
    <div class="mat-caption error-message" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()">キャンセル</button>
    <button mat-flat-button color="primary" (click)="confirm()">確認</button>
  </div>
</div>

<!-- 確認画面 -->
<div class="root-container" *ngIf="mode == 'confirm'">
  <div class="mat-title">メールアドレス変更</div>

  <div class="form-container">
    <div>ご登録のメールアドレスを変更します。よろしいですか？</div>
    <div class="email">変更後のメールアドレス：{{ emailForm.get('email').value }}</div>

    <div class="attention">
      よろしければ、「決定」ボタンを押してください。<br />
      「決定」押下後、自動的にサインアウトしますので、変更後のメールアドレスでサインインできるかをご確認ください。<br /><br />
      サインインできない場合は、旺文社の担当までご連絡ください。
    </div>
  </div>

  <div class="buttons-container">
    <div class="mat-caption error-message" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="returnInput()" [disabled]="emailUpdating">キャンセル</button>
    <button mat-flat-button color="primary" (click)="editEmail()" [disabled]="emailUpdating">決定</button>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="emailUpdating"></mat-spinner>
  </div>
</div>
