import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

// Redux
import { navigate, setTitle } from '../../../../actions/core.actions';
import * as CommonIdAnsweredProblemActions from 'src/app/actions/common-id/common-id-answered-problem.actions';
import * as CommonIdBookmarkAndAnsweredPaperAcions from 'src/app/actions/common-id/common-id-bookmark-and-answered-paper.actions';
import * as CommonIdFindPlaylistOriginalProblemIdsActions from 'src/app/actions/common-id/common-id-find-playlist-original-problems.action';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { CommonIdFindPlaylistOriginalProblemIdsResult } from 'src/app/reducers/common-id/common-id-find-playlist-original-problem.reducer';
import { RootState } from '../../../../reducers';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { getSubject } from 'src/app/selectors/static-data.selectors';
import * as CommonIdFindPlaylistOriginalProblemsSelectors from 'src/app/selectors/common-id/common-id-find-playlist-original-problem.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import * as CommonIdAnsweredProblemSelectors from 'src/app/selectors/common-id/common-id-answered-problem.selectors';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';

// models
import { Subject } from 'src/app/models/common-data';
import {
  CommonIdAnsweredProblem,
  CommonIdAnsweredProblems,
  CommonIdMyListAnsweredProblem
} from 'src/app/models/common-id/common-id-answered-problem';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { University } from 'src/app/models/common-data';
import { StaticCommonData } from 'src/app/models/static-common-data';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { CommonIdFindPlaylistOriginalProblemIdsResponse } from 'src/app/models/common-id/common-id-playlist-original-problem';

// utils
import { GAUtil } from '../../../../utils/ga-util';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { Dates } from 'src/app/utils/dates';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';

// config
import {
  COMMON_ID_FREE_YEARS,
  COMMON_ID_SEARCH_YEARS,
  DESCRIPTION_PREMIUM_DIALOG_WITDH,
  FROM_ANSWERED_PROBLEMS
} from 'src/app/resources/common-id-config';
import { enter } from 'src/app/resources/animations';
import { DIALOG_ZERO_PADDING_PANEL_CLASS, NO_DISPLAY_UNIVERSITY_IDS, ORIGINAL_UNIVERSITY_IDS, SubjectId } from 'src/app/resources/config';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, GA_EVENT_LABELS } from '../../../../resources/common-id/ga';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

// components
import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';

// others
import * as moment from 'moment';

@Component({
  selector: 'app-common-id-mylist-answer-problems',
  templateUrl: './mylist-answer-problems.component.html',
  styleUrls: ['./mylist-answer-problems.component.scss'],
  animations: [enter]
})
export class CommonIdMylistAnswerProblemsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger) universityNameAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('univNameInput') universityNameInput: ElementRef<HTMLInputElement>;

  private LOG_SOURCE = this.constructor.name;
  private title = 'マイリスト - 問題管理';
  private initializedMylistAnsweredProblemSubject = new BehaviorSubject(false);
  initializedMylistPaper$ = this.initializedMylistAnsweredProblemSubject.asObservable();

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  user: CommonIdUser;
  isPremiumUser: boolean;

  staticCommonData$: Observable<StaticCommonData>;
  subjects$: Observable<Subject[]>;
  answeredProblems$: Observable<CommonIdAnsweredProblems>;
  filteredUniversities$: Observable<University[]>;
  private selectableUniversities$: BehaviorSubject<University[]>;

  targetProblems: CommonIdAnsweredProblem[] = [];
  showingProblems = [];
  problemsCount = 0;
  engilishProblemsCount = 0;
  mathProblemsCount = 0;
  japaneseProblemsCount = 0;
  physicsProblemsCount = 0;
  chemistryProblemsCount = 0;
  biologyProblemsCount = 0;
  japaneseHistoryProblemsCount = 0;
  worldHistoryProblemsCount = 0;
  geographyProblemsCount = 0;
  politicalEconomyProblemsCount = 0;

  subjects: Subject[];
  selectedSubjectId: string;
  years: string[];
  selectedYear: string;
  selectableMonths: any[] = [];
  selectedMonth: any;
  selectedSort = 'DESC';
  selectedUniversityNamesForSummary: string;
  allUniversities: University = { id: null, name: 'すべて' };
  selectedUniversity: University = this.allUniversities;
  universityNamesPlaceholder = '入力してください';
  universityNameFormControl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  universitiesInTarget: string[] = [];
  playlistOriginalProblems: CommonIdFindPlaylistOriginalProblemIdsResponse[];

  currentDateTime$: Observable<CurrentDateTime>;

  canDisplay = true;

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {}

  menuClickHandler(key) {
    switch (key) {
      case 'mylist-univ':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistUniversities() }));
        break;
      case 'mylist-problem':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistProblems() }));
        break;
      case 'mylist-playlist':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistPlaylists() }));
        break;
    }
  }

  setUp() {
    // set up subscriptions
    this.answeredProblems$ = this.store
      .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
      .pipe(filter<CommonIdAnsweredProblems>(it => it !== null));
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.subjects$ = this.store.select(getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    // dispatch actions
    this.store
      .select(CommonIdFindPlaylistOriginalProblemsSelectors.getFindCommonIdFindPlaylistOriginalProblemIdsResult)
      .subscribe(playlistOriginalProblemIds => {
        if (!playlistOriginalProblemIds) {
          this.store.dispatch(CommonIdFindPlaylistOriginalProblemIdsActions.initializeCommonIdFindPlaylistOriginalProblemIdsState());
          this.store.dispatch(CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIds());
        }
      });

    // get basic information
    combineLatest([
      this.currentDateTime$,
      this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none')),
      this.subjects$,
      this.store
        .select(CommonIdFindPlaylistOriginalProblemsSelectors.getFindCommonIdFindPlaylistOriginalProblemIdsResult)
        .pipe(filter<CommonIdFindPlaylistOriginalProblemIdsResult>(it => it !== null))
    ])
      .pipe(take(1))
      .subscribe(([currentDateTime, _signedInUser, subjects, playlistOriginalProblemIds]) => {
        // signed in user
        this.user = _signedInUser;
        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(currentDateTime, _signedInUser);

        // subjects
        const _subjects = subjects.concat();
        this.subjects = _subjects;
        _subjects.unshift({ id: '00', name: 'すべて' });
        this.subjects$ = of(this.subjects);
        this.selectedSubjectId = '00';

        // years
        const _years = COMMON_ID_SEARCH_YEARS.concat();
        _years.unshift('すべて');
        this.years = _years;
        this.selectedYear = 'すべて';
        this.getDatas();

        // playlist original problems
        this.playlistOriginalProblems = playlistOriginalProblemIds.response;
      });
  }

  getDatas() {
    this.initializedMylistAnsweredProblemSubject.next(false);

    this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: this.user.id }));
    // 解答済みの問題を取得
    combineLatest([this.answeredProblems$])
      .pipe(take(1))
      .subscribe(([answeredProblems]) => {
        if (answeredProblems.problems) {
          this.targetProblems = answeredProblems.problems;
          // 期間のフィルタを作成
          const _selectableMonths = this.targetProblems
            .map(problem => {
              const date = new Date(problem.cAt);
              return {
                date: `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(-2)}`,
                value: `${date.getFullYear()}年${date.getMonth() + 1}月`
              };
            })
            .filter((v, i, a) => a.findIndex(t => t.date === v.date) === i)
            .sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));
          this.selectableMonths = _selectableMonths;
        }
        this.selectableMonths.unshift({ date: 'all', value: 'すべて' });
        this.selectedMonth = this.selectableMonths[0];
        this.problemsFilter();
        this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
      });
  }

  problemsFilter() {
    this.initializedMylistAnsweredProblemSubject.next(false);
    this.universitiesInTarget = [];
    // フィルタ
    const yearRegExp = this.selectedYear === 'すべて' ? '^\\d{2}' : this.selectedYear.slice(2, 4);
    const unvRegExp = this.selectedUniversity.id === null ? '\\d{4}' : this.selectedUniversity.id.slice(1, 5);
    const subjectRegExp = this.selectedSubjectId === '00' ? '\\d{2}' : this.selectedSubjectId;
    const filterRegExp = new RegExp(yearRegExp + unvRegExp + subjectRegExp + '\\d{4}', 'g');
    let _problems = this.targetProblems.filter(problem => problem.pId.match(filterRegExp));
    if (this.selectedMonth.date !== 'all') {
      const selectedMonthDate = new Date(
        parseInt(this.selectedMonth.date.slice(0, 4), 10),
        parseInt(this.selectedMonth.date.slice(5, 7), 10) - 1
      );
      _problems = _problems.filter(problem => {
        const problemDate = new Date(problem.cAt);
        return problemDate.getFullYear() === selectedMonthDate.getFullYear() && problemDate.getMonth() === selectedMonthDate.getMonth();
      });
    }

    if (_problems.length > 0) {
      const _showingProblems = [];

      combineLatest([this.staticCommonData$, this.subjects$])
        .pipe(take(1))
        .subscribe(([staticCommonData, subjects]) => {
          const universities = staticCommonData.universities;
          _problems.forEach(problem => {
            const _problem = this.optimizeProblem(problem, universities, subjects);
            if (!this.universitiesInTarget.includes(_problem.universityId)) {
              this.universitiesInTarget.push(_problem.universityId);
            }
            _showingProblems.push(_problem);
          });
          this.countProblems(_showingProblems);

          const groupedProblems = [];
          _showingProblems.forEach(problem => {
            const dateMoment = Dates.momentDateFromIso(problem.cAt);
            const dataStr = Dates.simple4YmdStringFromIso(problem.cAt);
            const index = groupedProblems.findIndex(it => it.date === dataStr);

            if (index === -1) {
              const todayMoment = moment(Dates.now()).startOf('day');
              const problemDate = dateMoment.startOf('day');
              const diff = todayMoment.diff(problemDate, 'days');

              let label = null;
              if (diff === 0) {
                label = '今日';
              } else if (diff === 1) {
                label = '昨日';
              } else if (diff < 7) {
                label = dateMoment.toDate().toLocaleDateString('ja-JP', { weekday: 'short' }) + '曜日';
              }

              groupedProblems.push({
                date: dataStr,
                problems: [problem],
                label
              });
            } else {
              groupedProblems[index].problems.push(problem);
            }
          });
          this.showingProblems = groupedProblems;
          if (this.selectedSort === 'DESC') {
            this.showingProblems.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));
            this.showingProblems.forEach(groupedProblem => {
              groupedProblem.problems.sort((a, b) => (a.cAt > b.cAt ? -1 : b.cAt > a.cAt ? 1 : 0));
            });
          } else {
            this.showingProblems.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
            this.showingProblems.forEach(groupedProblem => {
              groupedProblem.problems.sort((a, b) => (a.cAt > b.cAt ? 1 : b.cAt > a.cAt ? -1 : 0));
            });
          }
          this.setUpSelectableUniversities();
          this.canDisplay = true;

          this.store.dispatch(CommonIdBookmarkAndAnsweredPaperAcions.initializeCommonIdFindBookmarkAndAnsweredPapers());
          this.initializedMylistAnsweredProblemSubject.next(true);
        });
    } else {
      this.countProblems([]);
      this.showingProblems = [];
      this.universitiesInTarget = [];
      this.setUpSelectableUniversities();
      this.canDisplay = true;
      this.initializedMylistAnsweredProblemSubject.next(true);
    }
  }

  // html用に加工
  optimizeProblem(problem: CommonIdAnsweredProblem, universities: University[], subjects: Subject[]) {
    let _problem: CommonIdMyListAnsweredProblem;
    const _university = universities.find(univ => univ.id === `D${problem.pId.slice(2, 6)}`);
    const year = '20' + problem.pId.slice(0, 2);
    const _subject = subjects.find(subject => subject.id === problem.pId.slice(6, 8));
    const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(_subject.id);
    const daimonNumber = parseInt(problem.pId.slice(10, 12), 0);
    const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(year) ? false : true;
    _problem = {
      ...problem,
      universityId: _university.id,
      universityName: _university.name,
      subjectId: _subject.id,
      subjectName: _subject.name,
      subjectLabelStyleName,
      year: NO_DISPLAY_UNIVERSITY_IDS.includes(_university.id) ? '' : year, // オリジナル問題に年度は不要なため
      daimonNumber,
      isPremiumMemberOnly
    };
    return _problem;
  }

  setUpFilters() {}

  countProblems(probelms: any[]) {
    this.problemsCount = 0;
    this.engilishProblemsCount = 0;
    this.mathProblemsCount = 0;
    this.japaneseProblemsCount = 0;
    this.physicsProblemsCount = 0;
    this.chemistryProblemsCount = 0;
    this.biologyProblemsCount = 0;
    this.japaneseHistoryProblemsCount = 0;
    this.worldHistoryProblemsCount = 0;
    this.geographyProblemsCount = 0;
    this.politicalEconomyProblemsCount = 0;
    probelms.forEach(problem => {
      switch (problem.subjectId) {
        case SubjectId.ENGLISH:
          this.engilishProblemsCount++;
          break;
        case SubjectId.MATH:
          this.mathProblemsCount++;
          break;
        case SubjectId.NATIONAL_LANGUAGE:
          this.japaneseProblemsCount++;
          break;
        case SubjectId.PHYSICS:
          this.physicsProblemsCount++;
          break;
        case SubjectId.CHEMISTRY:
          this.chemistryProblemsCount++;
          break;
        case SubjectId.BIOLOGY:
          this.biologyProblemsCount++;
          break;
        case SubjectId.JAPANESE_HISTORY:
          this.japaneseHistoryProblemsCount++;
          break;
        case SubjectId.WORLD_HISTORY:
          this.worldHistoryProblemsCount++;
          break;
        case SubjectId.GEOGRAPHY:
          this.geographyProblemsCount++;
          break;
        case SubjectId.POLITICAL_ECONOMY:
          this.politicalEconomyProblemsCount++;
          break;
      }
      this.problemsCount++;
    });
  }

  openUniversityNamesAutocomplete() {
    this.universityNameAutocompleteTrigger.openPanel();
  }

  universitySelected(event: MatAutocompleteSelectedEvent) {
    const univ: University = event.option.value;
    this.selectedUniversity = univ;

    this.updateSelectableUniversities();

    this.problemsFilter();
  }

  private filterSelectableUniversities(univName: string | null): Observable<University[]> {
    if (!univName) return this.selectableUniversities$;
    return this.selectableUniversities$.pipe(
      map(currentSelectableUnivs => currentSelectableUnivs.filter(univ => univ.name.indexOf(univName) !== -1))
    );
  }

  univForAutoComplete(univ: University) {
    if (univ) {
      return univ.name;
    } else {
      return '';
    }
  }

  private setUpSelectableUniversities() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        this.selectableUniversities$ = new BehaviorSubject(
          // 大学IDの昇順に並び替え（学習履歴では「大学入学共通テスト予想問題」を表示する）
          [...staticCommonData.universities.filter(it => this.universitiesInTarget.includes(it.id))].sort((a, b) =>
            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
          )
        );
        this.filteredUniversities$ = this.universityNameFormControl.valueChanges.pipe(
          startWith(null as string),
          switchMap(univName => this.filterSelectableUniversities(univName))
        );
      });
  }

  private updateSelectableUniversities() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const filtered = [...staticCommonData.universities].filter(univOrg => univOrg !== this.selectedUniversity);
        this.selectableUniversities$.next(filtered);
      });
  }

  goSearchUniv() {
    const url = RoutingPathResolver.resolveCommonIdSearchUniv();
    this.store.dispatch(navigate({ url }));
  }

  clickResult(event: { problemId: string; isPremiumMemberOnly: boolean }) {
    if (event.isPremiumMemberOnly && !this.isPremiumUser) {
      this.openDialog();
    } else {
      this.goProblemDetail(event.problemId);
    }
  }

  private openDialog() {
    const config: MatDialogConfig = {
      width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      disableClose: true
    };
    this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
  }

  goProblemDetail(problemId: string) {
    this.store.dispatch(CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIds());
    const univId = `D${problemId.slice(2, 6)}`;
    if (ORIGINAL_UNIVERSITY_IDS.includes(univId)) {
      const themeId = this.playlistOriginalProblems.find(it => it.problemIds.includes(problemId))?.themeId;
      const playlistId = themeId.slice(0, 7);
      this.store.dispatch(
        navigate({
          url: RoutingPathResolver.resolveCommonIdPlaylistProblemDetail(playlistId, themeId, problemId),
          extras: { queryParams: { from: FROM_ANSWERED_PROBLEMS } }
        })
      );
    } else {
      const subjectId = problemId.slice(6, 8);
      this.store.dispatch(
        navigate({
          url: RoutingPathResolver.resolveCommonIdSearchByCategoriesProblemDetail(problemId),
          extras: { queryParams: { subjectId, hasExternalData: true, sortType: 'D_CODE', from: FROM_ANSWERED_PROBLEMS } }
        })
      );
    }
  }

  bannerClick() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.NYUSHI_PREMIUM_BANNER,
      'event_label': GA_EVENT_LABELS.MYLIST_PROBLEMS,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop(), extras: { queryParams: { to: 'premium' } } }));
  }
}
