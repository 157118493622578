import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import * as CommonIdAnsweredProblemActions from 'src/app/actions/common-id/common-id-answered-problem.actions';
import * as CommonIdPaperBookmarkActions from 'src/app/actions/common-id/common-id-paper-bookmark.actions';
import { Subject } from 'src/app/models/common-data';
import { CommonIdAnsweredProblems } from 'src/app/models/common-id/common-id-answered-problem';
import {
  CommonIdAddPaperBookmarkData,
  CommonIdDeletePaperBookmarkData,
  CommonIdPaperBookmark
} from 'src/app/models/common-id/common-id-paper-bookmark';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { COMMON_ID_SEARCH_YEARS } from 'src/app/resources/common-id-config';
import * as CommonIdAnsweredProblemSelectors from 'src/app/selectors/common-id/common-id-answered-problem.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import * as CommonIdPaperBookmarkSelectors from 'src/app/selectors/common-id/common-id-paper-bookmark.selectors';
import { getSubject } from 'src/app/selectors/static-data.selectors';
import { dispatchInfoMessage, navigate, setTitle } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { RootState } from '../../../../reducers';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as CommonIdBookmarkAndAnsweredPaperAcions from 'src/app/actions/common-id/common-id-bookmark-and-answered-paper.actions';
import { University } from 'src/app/models/common-data';
import {
  CommonIdMylistPaper,
  ComonIdFindBookmarkAndAnsweredPaperResponse
} from 'src/app/models/common-id/common-id-bookmark-and-answered-paper';
import { StaticCommonData } from 'src/app/models/static-common-data';
import { CommonIdBookmarkAndAnsweredPapersResult } from 'src/app/reducers/common-id/common-id-bookmark-and-answered-paper.reducer';
import { enter } from 'src/app/resources/animations';
import { NO_DISPLAY_UNIVERSITY_IDS } from 'src/app/resources/config';
import * as CommonIdBookmarkAndAnsweredPaperSelectors from 'src/app/selectors/common-id/common-id-bookmark-and-answered-paper.selectors';
import { Log } from 'src/app/utils/log';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, GA_EVENT_LABELS } from '../../../../resources/common-id/ga';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { GAUtil } from '../../../../utils/ga-util';

@Component({
  selector: 'app-common-id-mylist-problems',
  templateUrl: './mylist-problems.component.html',
  styleUrls: ['./mylist-problems.component.scss'],
  animations: [enter]
})
export class CommonIdMylistProblemsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger) universityNameAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('univNameInput') universityNameInput: ElementRef<HTMLInputElement>;

  private LOG_SOURCE = this.constructor.name;
  private title = 'マイリスト - 問題管理';
  private initializedMylistPaperSubject = new BehaviorSubject(false);
  initializedMylistPaper$ = this.initializedMylistPaperSubject.asObservable();

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute) {}

  user: CommonIdUser;

  staticCommonData$: Observable<StaticCommonData>;
  subjects$: Observable<Subject[]>;
  answeredProblems$: Observable<CommonIdAnsweredProblems>;
  bookmarkedPapers$: Observable<CommonIdPaperBookmark>;
  filteredUniversities$: Observable<University[]>;
  private selectableUniversities$: BehaviorSubject<University[]>;

  targtPapers: string[] = [];
  answeredProblemIds: string[];
  bookmarkedPaperIds: string[];
  showingPapers: CommonIdMylistPaper[] = [];
  subjects: Subject[];
  selectedSubjectId: string;
  years: string[];
  selectedYear: string;
  selectedUniversities: University[];
  selectedUniversityNamesForSummary: string;
  allUniversities: University = { id: null, name: 'すべて' };
  selectedUniversity: University = this.allUniversities;
  universityNamesPlaceholder = '入力してください';
  universityNameFormControl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  universitiesInTarget: string[];

  onlyNotAnsweredPapers = false;
  canDisplay = true;

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {}

  menuClickHandler(key) {
    switch (key) {
      case 'mylist-univ':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistUniversities() }));
        break;
      case 'mylist-playlist':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistPlaylists() }));
        break;
      case 'mylist-answer-problem':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistAnswerProblems() }));
        break;
    }
  }

  setUp() {
    // set up subscriptions
    this.answeredProblems$ = this.store
      .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
      .pipe(filter<CommonIdAnsweredProblems>(it => it !== null));
    this.bookmarkedPapers$ = this.store
      .select(CommonIdPaperBookmarkSelectors.getCommonIdPaperBookmark)
      .pipe(filter<CommonIdPaperBookmark>(it => it !== null));
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.subjects$ = this.store.select(getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    // get basic information
    combineLatest([
      this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none')),
      this.subjects$
    ])
      .pipe(take(1))
      .subscribe(([_signedInUser, subjects]) => {
        // signed in user
        this.user = _signedInUser;

        // subjects
        const _subjects = subjects.concat();
        _subjects.unshift({ id: '00', name: 'すべて' });
        this.subjects = _subjects;
        this.subjects$ = of(this.subjects);
        this.selectedSubjectId = '00';

        // years
        const _years = COMMON_ID_SEARCH_YEARS.concat();
        _years.unshift('すべて');
        this.years = _years;
        this.selectedYear = 'すべて';

        this.getDatas();
      });
  }

  getDatas() {
    this.initializedMylistPaperSubject.next(false);

    this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: this.user.id }));
    this.store.dispatch(CommonIdPaperBookmarkActions.commonIdFindPaperBookmark({ userId: this.user.id }));
    // 解答済みの問題を取得
    combineLatest([this.answeredProblems$, this.bookmarkedPapers$])
      .pipe(take(1))
      .subscribe(([answeredProblems, bookmarkedPapers]) => {
        this.answeredProblemIds = answeredProblems.problems ? answeredProblems.problems.map(problem => problem.pId) : [];
        this.bookmarkedPaperIds = bookmarkedPapers.papers ? bookmarkedPapers.papers.map(paper => paper.pId) : [];
        this.targtPapers = this.bookmarkedPaperIds;
        this.papersFilter();
        this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
        this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdFindPaperBookmarkState());
      });
  }

  // 絞り込みメイン処理
  papersFilter() {
    this.initializedMylistPaperSubject.next(false);
    const yearRegExp = this.selectedYear === 'すべて' ? '^\\d{2}' : this.selectedYear.slice(2, 4);
    const unvRegExp = this.selectedUniversity.id === null ? '\\d{4}' : this.selectedUniversity.id.slice(1, 5);
    const subjectRegExp = this.selectedSubjectId === '00' ? '\\d{2}' : this.selectedSubjectId;
    const filterRegExp = new RegExp(yearRegExp + unvRegExp + subjectRegExp + '\\d{2}', 'g');
    const paperIds = this.targtPapers.filter(paper => paper.match(filterRegExp));
    if (paperIds.length > 0) {
      this.store.dispatch(CommonIdBookmarkAndAnsweredPaperAcions.initializeCommonIdFindBookmarkAndAnsweredPapers());

      this.store.dispatch(CommonIdBookmarkAndAnsweredPaperAcions.commonIdFindBookmarkAndAnsweredPapers({ paperIds }));

      this.store
        .select(CommonIdBookmarkAndAnsweredPaperSelectors.getFindCommonIdBookmarkAndAnsweredPaperResult)
        .pipe(
          filter<CommonIdBookmarkAndAnsweredPapersResult>(it => it !== null),
          take(1)
        )
        .subscribe(response => {
          let _optimizedPapers = response.response.map(paper => this.optimizePaper(paper));
          if (this.onlyNotAnsweredPapers) {
            _optimizedPapers = _optimizedPapers.filter(paper => !paper.fulllAswered);
          }
          this.showingPapers = _optimizedPapers;
          this.universitiesInTarget = this.showingPapers.map(paper => paper.universityId);
          this.setUpSelectableUniversities();
          this.canDisplay = true;

          this.store.dispatch(CommonIdBookmarkAndAnsweredPaperAcions.initializeCommonIdFindBookmarkAndAnsweredPapers());
          this.initializedMylistPaperSubject.next(true);
        });
    } else {
      this.showingPapers = [];
      this.universitiesInTarget = [];
      this.setUpSelectableUniversities();
      this.canDisplay = true;
      this.initializedMylistPaperSubject.next(true);
    }
  }

  // html用に加工
  optimizePaper(paper: ComonIdFindBookmarkAndAnsweredPaperResponse) {
    let mylistPaper: CommonIdMylistPaper;
    const _bookmarked = this.bookmarkedPaperIds.includes(paper.paperId);
    const _answeredProblemCount = this.answeredProblemIds.filter(pId => paper.problemIds.includes(pId)).length;
    const subjectName = this.subjects.find(subject => subject.id === paper.subjectId).name;
    mylistPaper = {
      ...paper,
      bookmarked: _bookmarked,
      answeredProblemCount: _answeredProblemCount,
      fulllAswered: paper.problemIds.length === _answeredProblemCount,
      subjectName
    };
    return mylistPaper;
  }

  // 未解答 で絞り込み
  notAnsweredPaperFilter(flg: boolean) {
    this.onlyNotAnsweredPapers = flg;
    this.papersFilter();
  }

  openUniversityNamesAutocomplete() {
    this.universityNameAutocompleteTrigger.openPanel();
  }

  universitySelected(event: MatAutocompleteSelectedEvent) {
    const univ: University = event.option.value;
    this.selectedUniversity = univ;

    this.updateSelectableUniversities();

    this.papersFilter();
  }

  private filterSelectableUniversities(univName: string | null): Observable<University[]> {
    if (!univName) return this.selectableUniversities$;
    return this.selectableUniversities$.pipe(
      map(currentSelectableUnivs => currentSelectableUnivs.filter(univ => univ.name.indexOf(univName) !== -1))
    );
  }

  univForAutoComplete(univ: University) {
    if (univ) {
      return univ.name;
    } else {
      return '';
    }
  }

  private setUpSelectableUniversities() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        this.selectableUniversities$ = new BehaviorSubject(
          // 大学IDの昇順に並び替え
          [
            ...staticCommonData.universities.filter(
              it => !NO_DISPLAY_UNIVERSITY_IDS.includes(it.id) && this.universitiesInTarget.includes(it.id)
            )
          ].sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
        );
        this.filteredUniversities$ = this.universityNameFormControl.valueChanges.pipe(
          startWith(null as string),
          switchMap(univName => this.filterSelectableUniversities(univName))
        );
      });
  }

  private updateSelectableUniversities() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const filtered = [...staticCommonData.universities].filter(univOrg => univOrg !== this.selectedUniversity);
        this.selectableUniversities$.next(filtered);
      });
  }

  addPaperBookmark(paperId: string) {
    this.canDisplay = false;
    Log.debug(this.LOG_SOURCE, 'あとで解くに登録します');
    this.store.dispatch(
      dispatchInfoMessage({
        message: `あとで解くに登録しています`
      })
    );

    const request: CommonIdAddPaperBookmarkData = {
      userId: this.user.id,
      paperId
    };
    Log.debug(this.LOG_SOURCE, 'add paper bookmark', request);
    this.store.dispatch(CommonIdPaperBookmarkActions.commonIdAddPaperBookmark(request));
    this.store
      // TODO: あとで解く登録の結果をセレクト
      .select(CommonIdPaperBookmarkSelectors.getCommonIdAddPaperBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `add paper bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `あとで解くに登録しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `add paper bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdAddPaperBookmarkState());
        this.getDatas();
      });
  }

  deletePaperBookmark(paperId: string) {
    this.canDisplay = false;
    Log.debug(this.LOG_SOURCE, 'あとで解くを解除します');
    this.store.dispatch(
      dispatchInfoMessage({
        message: `あとで解くを解除しています`
      })
    );

    const request: CommonIdDeletePaperBookmarkData = {
      userId: this.user.id,
      paperId
    };

    Log.debug(this.LOG_SOURCE, 'delete bookmark', request);
    this.store.dispatch(CommonIdPaperBookmarkActions.commonIdDeletePaperBookmark(request));

    this.store
      .select(CommonIdPaperBookmarkSelectors.getCommonIdDeletePaperBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `delete bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `あとで解くを解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdDeletePaperBookmarkState());
        this.getDatas();
      });
  }

  goSearchUniv() {
    const url = RoutingPathResolver.resolveCommonIdSearchUniv();
    this.store.dispatch(navigate({ url }));
  }

  goProblemdetail(paperId: string) {
    const url = RoutingPathResolver.resolveCommonIdPaperDetail(paperId);
    this.store.dispatch(navigate({ url }));
  }

  bannerClick() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.NYUSHI_PREMIUM_BANNER,
      'event_label': GA_EVENT_LABELS.MYLIST_PROBLEMS,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop(), extras: { queryParams: { to: 'premium' } } }));
  }
}
