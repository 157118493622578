import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdAnsweredProblemActions from '../../actions/common-id/common-id-answered-problem.actions';
import { AppError } from '../../errors/app-error';
import {
  CommonIdAnsweredProblems,
  CommonIdSaveAnsweredProblemsResponse,
  CommonIdSaveAnsweredProblemsRequest
} from '../../models/common-id/common-id-answered-problem';

export const commonIdAnsweredProblemFeatureKey = 'commonIdAnsweredProblem';

export interface CommonIdSaveAnsweredProblemResult {
  success: boolean;
  response: CommonIdSaveAnsweredProblemsResponse | null;
  error: AppError | null;
}

export interface State {
  answeredProblem: CommonIdAnsweredProblems | null;
  saveAnsweredProblemResult: CommonIdSaveAnsweredProblemResult | null;
}

export const initialState: State = {
  answeredProblem: null,
  saveAnsweredProblemResult: null
};

const CommonIdAnsweredProblemsReducer = createReducer(
  initialState,

  // Find Bookmarks -------------------------------------------------------
  on(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblemsSuccess, (state, { answeredProblem }) => ({
    ...state,
    answeredProblem
  })),
  on(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems, state => {
    const next: State = { ...state, answeredProblem: null };
    return next;
  }),

  // save Answered Problem ----------------------------------------------------------------
  on(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblemsSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      saveAnsweredProblemResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblemsFailure, (state, { error }) => {
    const next: State = {
      ...state,
      saveAnsweredProblemResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems, state => {
    const next: State = { ...state, saveAnsweredProblemResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return CommonIdAnsweredProblemsReducer(state, action);
}
