<app-common-id-search-by-categories-base
  [isPremiumUser]="isPremiumUser"
  [isSearchSummaryDisplayed]="isSearchSummaryDisplayed"
  [matchedProblemCount$]="matchedProblemCount$"
  [previousCondition]="previousCondition"
  [searchButtonDisabled$]="searchButtonDisabled$"
  [problemCountSearching$]="problemCountSearching$"
  [staticCommonData$]="staticCommonData$"
  [staticEnglishData$]="staticEnglishData$"
  [staticMathData$]="staticMathData$"
  [staticNationalLanguageData$]="staticNationalLanguageData$"
  [staticPhysicsData$]="staticPhysicsData$"
  [staticChemistryData$]="staticChemistryData$"
  [staticBiologyData$]="staticBiologyData$"
  [staticJapaneseHistoryData$]="staticJapaneseHistoryData$"
  [staticWorldHistoryData$]="staticWorldHistoryData$"
  [staticGeographyData$]="staticGeographyData$"
  [staticPoliticalEconomyData$]="staticPoliticalEconomyData$"
  (resetClick)="reset($event)"
  (changeSearchCondition)="onChangeValues($event)"
  (showSearchResultViewClick)="showSearchResultView($event)"
></app-common-id-search-by-categories-base>

<div *ngIf="isSearchResultsShown">
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-content-row>
      <app-card-content [showSeparator]="false">
        <app-wrapper size="fluid">
          <app-content-row>
            <div class="-mb-6">
              <mat-form-field appearance="outline">
                <mat-label>並び替え</mat-label>
                <mat-select [(value)]="selectedSortType" (selectionChange)="onChangeSortType()">
                  <mat-option *ngFor="let sortType of selectableSortTypes" [value]="sortType">{{ sortType.displayName }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </app-content-row>

          <div #searchByCategoriesResult></div>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>
</div>

<div *ngIf="isShowMoreButtonShown$ | async">
  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <div class="pb-8">
        <app-button-more canSpinner="true" [pageButton]="this.pageButton" (buttonClick)="showMoreResults()"></app-button-more>
      </div>
    </app-wrapper>
  </app-content-row>
</div>

<div class="progress-48-search-result-loading py-6 md:py-8" *ngIf="(isProblemsSearching$ | async) && this.currentPage === 1">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
