import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { getWatchedUsers } from '../../../selectors/user.selectors';
import { initializeWatchedUsers, watchUsers } from '../../../actions/user.actions';
import { User } from '../../../models/user';
import { Plan } from '../../../models/plan';
import { getPlans } from '../../../selectors/plan.selectors';
import { findPlans, initializeFindPlansState } from '../../../actions/plan.actions';
import { navigate, setBrowserTitle, setTitle, unsubscribeWatchedProps } from '../../../actions/core.actions';
import { enter } from '../../../resources/animations';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import {
  ADD_JUKU_DIALOG_HEIGHT,
  ADD_JUKU_DIALOG_WIDTH,
  DELETE_JUKU_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  EDIT_JUKU_DIALOG_HEIGHT,
  EDIT_JUKU_DIALOG_WIDTH,
  JUKU_ORGANIZATION_ID,
  OTHER_JUKU_CODE,
  PlanStatuses,
  UnsubscribeTarget
} from '../../../resources/config';
import { CurrentDateTime } from '../../../models/current-date-time';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../../actions/current-date-time.actions';
import { PlanUtil, PriorityRecord } from '../../../utils/plan-util';
import { Juku, UpdateJukuData } from 'src/app/models/juku';
import { getJukus } from 'src/app/selectors/juku.selectors';
import { findJukus, initializeFindJukusState } from 'src/app/actions/juku.actions';
import { DeleteJukuDialogComponent, DeleteJukuDialogData } from '../delete-juku-dialog/delete-juku-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Log } from 'src/app/utils/log';
import { AddJukuDialogComponent } from '../add-juku-dialog/add-juku-dialog.component';
import { EditJukuDialogComponent, EditJukuDialogData } from '../edit-juku-dialog/edit-juku-dialog.component';
import { JukuUtil } from '../../../utils/juku-util';

interface JukuData {
  id: string;
  jukuCode: string;
  jukuName: string;
  jukuSubName: string;
  prefectureId: string;
  planStatus: string;
}

@Component({
  selector: 'app-jukus',
  templateUrl: './jukus.component.html',
  styleUrls: ['./jukus.component.scss'],
  animations: [enter]
})
export class JukusComponent implements OnInit, OnDestroy {
  sortDirection: 'asc' | 'desc' = 'desc';
  jukusData$: Observable<JukuData[]>;
  planUnassignedStatus: string = PlanStatuses.UNASSIGNED;
  planActiveStatus: string = PlanStatuses.ACTIVE;
  planEndedStatus: string = PlanStatuses.ENDED;
  displayedColumns: string[] = ['jukuCode', 'jukuName', 'planStatus', 'manage', 'edit', 'remove'];
  otherJukuCode: string = OTHER_JUKU_CODE;

  private LOG_SOURCE = this.constructor.name;
  private title = '塾・予備校一覧';

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeFindJukusState());
    this.store.dispatch(initializeWatchedUsers());
    this.store.dispatch(initializeFindPlansState());
    this.store.dispatch(initializeGetCurrentDateTime());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.WATCH_USERS }));
  }

  plans(jukuCode: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(JUKU_ORGANIZATION_ID, jukuCode) }));
  }

  addJuku() {
    const config: MatDialogConfig = {
      height: ADD_JUKU_DIALOG_HEIGHT,
      width: ADD_JUKU_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      disableClose: true
    };
    this.dialog
      .open(AddJukuDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.store.dispatch(findJukus({}));
      });
  }

  editJuku(juku: JukuData) {
    const data: UpdateJukuData = {
      id: juku.id,
      jukuName: juku.jukuName,
      jukuSubName: juku.jukuSubName,
      jukuCode: juku.jukuCode,
      prefectureId: juku.prefectureId
    };

    const config: MatDialogConfig<EditJukuDialogData> = {
      height: EDIT_JUKU_DIALOG_HEIGHT,
      width: EDIT_JUKU_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: { juku: data },
      disableClose: true
    };
    this.dialog
      .open(EditJukuDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.store.dispatch(findJukus({}));
      });
  }

  removeJuku(juku: JukuData) {
    Log.debug(this.LOG_SOURCE, `次の塾・予備校を削除します: `, juku);
    const data: DeleteJukuDialogData = { id: juku.id, jukuCode: juku.jukuCode, jukuName: juku.jukuName };
    const config: MatDialogConfig = {
      width: DELETE_JUKU_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(DeleteJukuDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.store.dispatch(findJukus({}));
      });
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'admin/accounts':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAccounts() }));
        break;
      case 'admin/schools':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSchools() }));
        break;
    }
  }

  getJukuName(juku: Juku): string {
    return JukuUtil.getDisplayJukuName(juku);
  }

  private setUp() {
    const jukus$: Observable<Juku[]> = this.store.select(getJukus).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    // const highSchools$: Observable<HighSchool[]> = this.store.select(getHighSchool).pipe(
    //   filter(it => it != null),
    //   shareReplay(1)
    // );

    const users$: Observable<User[]> = this.store.select(getWatchedUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const plans$: Observable<Plan[]> = this.store.select(getPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const currentDateTime$: Observable<CurrentDateTime> = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.jukusData$ = combineLatest([jukus$, users$, plans$, currentDateTime$]).pipe(
      map(([jukus, users, plans, currentDateTime]) => {
        const mapedJukus = jukus
          .map(juku => {
            const jukuPlans: Plan[] = plans.filter(plan => plan.organization === JUKU_ORGANIZATION_ID && plan.schoolId === juku.jukuCode);
            return {
              id: juku.id,
              jukuCode: juku.jukuCode,
              jukuName: juku.jukuName,
              jukuSubName: juku.jukuSubName,
              prefectureId: juku.prefectureId,
              planStatus: this.getJukuPlanStatus(jukuPlans, currentDateTime)
            };
          })
          .filter(juku => juku !== null);

        mapedJukus.sort((a, b) => {
          if (a.jukuCode === b.jukuCode) {
            return 0;
          }
          return a.jukuCode > b.jukuCode ? 1 : -1;
        });

        return mapedJukus;
      }),
      shareReplay(1)
    );

    this.store.dispatch(watchUsers({ sortDirection: this.sortDirection }));
    this.store.dispatch(findPlans({}));
    this.store.dispatch(getCurrentDateTime());
    this.store.dispatch(findJukus({}));
  }

  private getJukuPlanStatus(plans: Plan[], currentDateTime: CurrentDateTime): string {
    if (plans.length === 0) return this.planUnassignedStatus;

    return plans.find(plan => {
      const priorityRecord: PriorityRecord = PlanUtil.getPriorityRecord(plan.records, currentDateTime);
      return priorityRecord.status === PlanStatuses.ACTIVE || priorityRecord.status === PlanStatuses.RESERVE;
    })
      ? this.planActiveStatus
      : this.planEndedStatus;
  }
}
