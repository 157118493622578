import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, isEmpty, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { Log } from '../../../utils/log';
import { getUpdateUserIsActiveResult } from 'src/app/selectors/user.selectors';
import { dispatchInfoMessage, dispatchAppError } from 'src/app/actions/core.actions';
import { updateUserIsActive, initializeUpdateUserIsActiveState } from 'src/app/actions/user.actions';
import { enter } from '../../../resources/animations';

export interface ChangeActiveAccountDialogData {
  userId: string;
  school: string;
  name: string;
  email: string;
  isActive: boolean;
}

@Component({
  selector: 'app-change-active-account-dialog',
  templateUrl: './change-active-account-dialog.component.html',
  styleUrls: ['./change-active-account-dialog.component.scss'],
  animations: [enter]
})
export class ChangeActiveAccountDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<ChangeActiveAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeActiveAccountDialogData
  ) {}

  ngOnInit() {}

  changeActiveAccount() {
    if (!this.data || !this.data.userId || this.data.isActive === undefined || this.data.isActive === null) {
      Log.warn(this.LOG_SOURCE, `dialog.data または data.userId または data.isActive が設定されていません: `, this.data);
      return;
    }

    this.disableForms();

    // 有効／無効変更APIの呼び出し処理をここに
    Log.debug(this.LOG_SOURCE, `アカウント有効／無効の変更をします`);
    this.store
      .select(getUpdateUserIsActiveResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateUserIsActiveState());
        if (result.success) {
          const message = `${result.updatedUser.email} のアカウント有効／無効の変更が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `アカウント有効／無効の変更完了: `, result.updatedUser);
          this.enableForms();
        } else {
          Log.error(this.LOG_SOURCE, `アカウント有効／無効の変更でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
        }

        this.dialogRef.close();
      });
    this.store.dispatch(updateUserIsActive({ user: { id: this.data.userId, isActive: !this.data.isActive } }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.data.email} のアカウント有効／無効の変更をしています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close();
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
