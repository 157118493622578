import { UAParser } from 'ua-parser-js';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest, BehaviorSubject, of, throwError } from 'rxjs';
import { map, switchMap, filter, shareReplay, startWith, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { dispatchAppError, dispatchInfoMessage, openWindow, navigate, setTitle } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from 'src/app/actions/common-id/common-id-core.actions';
import {
  ScienceProblem,
  ReadableScienceProblem,
  EnglishProblem,
  ReadableEnglishProblem,
  NationalLanguageProblem,
  ReadableNationalLanguageProblem,
  HistoryProblem,
  ReadableHistoryProblem,
  Problem,
  ReadablePlaylistProblem
} from 'src/app/models/problem';
import { GeneralError } from 'src/app/errors/general-error';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import {
  findScienceProblem,
  findEnglishProblem,
  findNationalLanguageProblemsById,
  findHistoryProblem
} from 'src/app/actions/search.actions'; // TODO

import * as CommonIdPlaylistActions from 'src/app/actions/common-id/common-id-playlist.actions';
import * as CommonIdPlaylistSelectors from 'src/app/selectors/common-id/common-id-playlist.selectors';

import {
  getStaticMathData,
  getFetchedDate,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticEnglishData,
  getStaticNationalLanguageData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData,
  getStaticCommonData,
  getStaticData
} from 'src/app/selectors/static-data.selectors';
import {
  getScienceProblem,
  getEnglishProblem,
  getNationalLanguageProblemsWithSameId,
  getHistoryProblem,
  getReadableMathProblem,
  getReadablePhysicsProblem,
  getReadableChemistryProblem,
  getReadableBiologyProblem,
  getReadableEnglishProblem,
  getReadableNationalLanguageProblemsFromProblems,
  getReadableJapaneseHistoryProblem,
  getReadableWorldHistoryProblem,
  getReadableGeographyProblem,
  getReadablePoliticalEconomyProblem,
  getProblemSearching
} from 'src/app/selectors/search.selectors';
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { fadeInOut, showHide } from 'src/app/resources/animations';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import {
  SubjectId,
  STATIC_DATA_CACHE_DAYS,
  META_VIEWPORT_OTHER,
  SCIENCE_IDS,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  CATEGORY_DELIMITER
} from 'src/app/resources/config';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { User } from 'src/app/models/user';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import { CommonIdPlaylist, CommonIdTheme, CommonIdThemeForDisplay } from 'src/app/models/common-id/common-id-playlist';
import {
  CommonIdAddPlaylistBookmarkRequest,
  CommonIdBookmarkPlaylist,
  CommonIdDeletePlaylistBookmarkRequest,
  CommonIdPlaylistBookmark
} from 'src/app/models/common-id/common-id-playlist-bookmark';
import * as CommonIdPlaylistBookmarkActions from 'src/app/actions/common-id/common-id-playlist-bookmark.actions';
import * as CommonIdPlaylistBookmarkSelectors from 'src/app/selectors/common-id/common-id-playlist-bookmark.selectors';

import { Subject, University } from '../../../../models/common-data';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import {
  ReadableEnglishPlaylistProblem,
  ReadableSciencePlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadableHistoryPlaylistProblem
} from 'src/app/models/problem';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  DESCRIPTION_PREMIUM_DIALOG_WITDH,
  FROM_ANSWERED_PROBLEMS,
  PAPER_INFORMATION_DIALOG_WIDTH
} from '../../../../resources/common-id-config';
import {
  CommonIdPaperInformationDialogComponent,
  CommonIdPaperInformationDialogData
} from '../paper-information-dialog/paper-information-dialog.component';
import {
  CommonIdPaperSourceInformationDialogComponent,
  CommonIdPaperSourceInformationDialogData
} from '../paper-source-information-dialog/paper-source-information-dialog.component';
import { CommonIdAnsweredProblems, CommonIdSaveAnsweredProblemsRequest } from 'src/app//models/common-id/common-id-answered-problem';
import * as CommonIdAnsweredProblemActions from 'src/app/actions/common-id/common-id-answered-problem.actions';
import * as CommonIdAnsweredProblemSelectors from 'src/app/selectors/common-id/common-id-answered-problem.selectors';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'src/app/resources/common-id/ga';
import { GAUtil } from 'src/app/utils/ga-util';
import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { ProblemDetailFrameComponent } from '../../../widgets/problem-detail-frame/problem-detail-frame.component';
import { ReadableDataMapper } from '../../../../mappers/readable-data-mapper';

@Component({
  selector: 'app-common-id-playlist-problem-detail',
  templateUrl: './playlist-problem-detail.component.html',
  styleUrls: ['./playlist-problem-detail.component.scss'],
  animations: [fadeInOut, showHide]
})
export class CommonIdPlaylistProblemDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  private problemId$: Observable<string>;
  private problemIds$: Observable<string[]>;
  private initializedBookmarkSubject = new BehaviorSubject(false);

  scienceIds: string[] = SCIENCE_IDS;
  problemId: string;

  universities$: Observable<University[]>;
  playlist$: Observable<CommonIdPlaylist>;
  playList: CommonIdPlaylist;
  playlistId: string;
  themeId$: Observable<string>;
  themeId: string;
  theme$: Observable<CommonIdTheme>;
  playListProblems$: Observable<ReadablePlaylistProblem[]>;
  playListProblem$: Observable<ReadablePlaylistProblem>;
  englishPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableEnglishPlaylistProblem>>;
  sciencePlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;
  mathPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;
  nationalLanguagePlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableNationalLanguagePlaylistProblem>>;
  physicsPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;
  chemistryPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;
  biologyPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;
  japaneseHistoryPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableHistoryPlaylistProblem>>;
  worldHistoryPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableHistoryPlaylistProblem>>;
  geographyPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;
  politicalEconomyPlaylistTheme$: Observable<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>;

  staticEnglishData$: Observable<StaticEnglishData>;
  staticMathData$: Observable<StaticScienceData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;

  /** science-problems.component をそのまま利用するため array で定義 */
  readableScienceProblems$: Observable<ReadableScienceProblem[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;

  problem$: Observable<Problem>;
  scienceProblem$: Observable<ScienceProblem>;
  englishProblem$: Observable<EnglishProblem>;
  nationalLanguageProblem$: Observable<NationalLanguageProblem>;
  nationalLanguageProblems$: Observable<NationalLanguageProblem[]>;
  historyProblem$: Observable<HistoryProblem>;
  readableSortType$: Observable<string>;
  searching$: Observable<boolean>;
  problemSearching$: Observable<boolean>;
  loadingReadableProblems$: Observable<boolean> = of(true);
  isFirstProblem$: Observable<boolean>;
  isLastProblem$: Observable<boolean>;
  isPreconditionError = false;
  daimonMode$: Observable<boolean>;
  paperMode$: Observable<boolean>;
  trialMode$: Observable<boolean>;
  hidePaperProblemBtn = true;
  navigation$: Observable<boolean>;
  subjectId$: Observable<string>;
  subjectId: string;
  //   thema: ThemePalette;
  menuType$: Observable<'article' | 'spellcheck'> = of('article');
  fromAnsweredProblems$: Observable<boolean>;

  toolbarTitle = '問題詳細';

  user: User;
  signedInUser$: Observable<CommonIdUser>;
  signedInUser: CommonIdUser;
  bookmark: CommonIdPlaylistBookmark;
  // bookmarkSubjectProblems: BookmarkProblem[];
  bookmarkSubjectPlaylists: CommonIdBookmarkPlaylist[];
  initializedBookmark$ = this.initializedBookmarkSubject.asObservable();
  parentComponent = 'playlistProblemDetail';
  isAnswered = false;
  answeredProblems$: Observable<CommonIdAnsweredProblems>;
  isAnswered$: Observable<boolean>;
  isPremiumUser = false;

  @ViewChild(ProblemDetailFrameComponent) problemDetailFrameComponent: ProblemDetailFrameComponent;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private dialog: MatDialog,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.toolbarTitle }));
    this.setUpUser();
    this.findPlaylistIfNeeded();
    this.setUpPlayList();
    this.checkPremium();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    // this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
    // this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
    this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdAddPlaylistBookmarkState());
    this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdDeletePlaylistBookmarkState());
    this.store.dispatch(CommonIdPlaylistActions.initializeEnglishPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeMathPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeNationalLanguagePlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializePhysicsPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeChemistryPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeBiologyPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeJapaneseHistoryPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeWorldHistoryPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeGeographyPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializePoliticalEconomyPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializePlaylistProblemIdsState());
  }

  private setUp() {
    this.setUpStaticData();
    // this.setUpTrialMode();
    this.setUpNavigationAndPaperMode();
    this.setUpSubjectId();
    this.setUpThemeId();
    this.findPlaylistProblems();
    this.setUpFromAnsweredProblems();
    this.setUpProblemId();
    this.setUpProblemIds();
    this.setUpProblem();
    this.setUpTheme();
    this.setUpPlayListProblems();
    this.setUpPlayListProblem();
    this.setUpReadableProblem();
    this.setUpSearching();
    this.setUpProblemPositionFlags();
    this.setUpBookmarks();
    this.setUpBrowserTitle();
    this.setUpAnsweredProblem();
  }

  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.staticMathData$ = this.store.select(getStaticMathData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPhysicsData$ = this.store.select(getStaticPhysicsData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticChemistryData$ = this.store.select(getStaticChemistryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticBiologyData$ = this.store.select(getStaticBiologyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticEnglishData$ = this.store.select(getStaticEnglishData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticNationalLanguageData$ = this.store.select(getStaticNationalLanguageData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticJapaneseHistoryData$ = this.store.select(getStaticJapaneseHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticWorldHistoryData$ = this.store.select(getStaticWorldHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticGeographyData$ = this.store.select(getStaticGeographyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPoliticalEconomyData$ = this.store.select(getStaticPoliticalEconomyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTrialMode() {
    // this.trialMode$ = this.store.select(getSignedInUser).pipe(
    //   filter<User>(it => it != null && it !== 'none'),
    //   map(signedInUser => (signedInUser.isTrial === true ? true : false))
    // );
    // this.trialMode$ = of(false);
  }

  private setUpNavigationAndPaperMode() {
    this.navigation$ = of(true);
    this.daimonMode$ = of(true);
    this.paperMode$ = of(false);
  }

  private setUpFromAnsweredProblems() {
    this.fromAnsweredProblems$ = this.activatedRoute.queryParams.pipe(map(queryParams => queryParams.from === FROM_ANSWERED_PROBLEMS));
  }

  private setUpProblemId() {
    this.problemId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('problemId') || ''),
      shareReplay(1)
    );

    this.subscriptions.push(
      this.problemId$.subscribe(problemId => {
        this.isPreconditionError = problemId === '';
        if (!problemId) {
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
        }
        this.problemId = problemId;
      })
    );
  }

  private setUpSubjectId() {
    this.subjectId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('playlistId').slice(0, 2) || ''),
      shareReplay(1)
    );
    this.subscriptions.push(combineLatest([this.subjectId$]).subscribe(([subjectId]) => (this.subjectId = subjectId)));
  }

  private setUpThemeId() {
    this.themeId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('themeId') || ''),
      shareReplay(1)
    );
    this.subscriptions.push(combineLatest([this.themeId$]).subscribe(([themeId]) => (this.themeId = themeId)));
  }

  private setUpPlayList() {
    this.playlist$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getPlaylists).pipe(filter(it => it != null)),
      this.activatedRoute.paramMap.pipe(map(paramMap => paramMap.get('playlistId') || ''))
    ]).pipe(
      map(([playlists, playlistId]) => {
        const selectedPlaylist = playlists.find(it => it.playlistId === playlistId);
        this.playlistId = playlistId;
        if (!playlistId || !selectedPlaylist) {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: GeneralError.customMessage(CustomErrorMessage.PLAYLIST_DETAIL_INVALID_PLAYLIST_ID)
            })
          );
          throwError(() => GeneralError.invalidArguments(`指定された playlistId は playlists に存在しません. playlistId: ${playlistId}`));
          return null;
        }
        this.playList = selectedPlaylist;
        return selectedPlaylist;
      }),
      shareReplay(1)
    );
  }

  private findPlaylistIfNeeded() {
    this.store
      .select(CommonIdPlaylistSelectors.getPlaylists)
      .pipe(take(1))
      .subscribe(playlists => {
        if (!playlists) this.store.dispatch(CommonIdPlaylistActions.findPlaylists());
      });
  }

  private checkPremium() {
    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    combineLatest([this.signedInUser$, this.playlist$, currentDateTime$])
      .pipe(take(1))
      .subscribe(([signedInUser, playlist, currentDateTime]) => {
        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser);

        if (this.isPremiumUser === false && playlist.freeFlg !== true) {
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylists() }));
        } else {
          this.setUp();
        }
      });
  }

  private findPlaylistProblems() {
    combineLatest([this.playlist$])
      .pipe(take(1))
      .subscribe(([playlist]) => {
        if (playlist.subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(CommonIdPlaylistActions.findEnglishPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.MATH) {
          this.store.dispatch(CommonIdPlaylistActions.findMathPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(CommonIdPlaylistActions.findNationalLanguagePlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.PHYSICS) {
          this.store.dispatch(CommonIdPlaylistActions.findPhysicsPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.CHEMISTRY) {
          this.store.dispatch(CommonIdPlaylistActions.findChemistryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.BIOLOGY) {
          this.store.dispatch(CommonIdPlaylistActions.findBiologyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.JAPANESE_HISTORY) {
          this.store.dispatch(CommonIdPlaylistActions.findJapaneseHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(CommonIdPlaylistActions.findWorldHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.GEOGRAPHY) {
          this.store.dispatch(CommonIdPlaylistActions.findGeographyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.POLITICAL_ECONOMY) {
          this.store.dispatch(CommonIdPlaylistActions.findPoliticalEconomyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
      });
  }

  private setUpProblemIds() {
    this.subscriptions.push(
      combineLatest([this.subjectId$, this.playlist$])
        .pipe(take(1))
        .subscribe(([subjectId, playlist]) => {
          this.store.dispatch(CommonIdPlaylistActions.findPlaylistProblemIds({ subjectId, playlistId: playlist.playlistId }));
        })
    );
    this.problemIds$ = this.store.select(CommonIdPlaylistSelectors.getPlaylistProblemIds).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTheme() {
    this.theme$ = combineLatest([this.themeId$, this.playlist$]).pipe(
      map(([themeId, playlist]) => {
        const selectedTheme = playlist.themes.find(theme => theme.id === themeId);
        return selectedTheme as CommonIdTheme;
      })
    );

    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        if (subjectId === SubjectId.ENGLISH) {
          this.setUpEnglishPlaylistTheme();
        } else if (subjectId === SubjectId.MATH) {
          this.setUpMathPlaylistTheme();
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.setUpNationalLanguagePlaylistTheme();
        } else if (subjectId === SubjectId.PHYSICS) {
          this.setUpPhysicsPlaylistTheme();
        } else if (subjectId === SubjectId.CHEMISTRY) {
          this.setUpChemistryPlaylistTheme();
        } else if (subjectId === SubjectId.BIOLOGY) {
          this.setUpBiologyPlaylistTheme();
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          this.setUpJapaneseHistoryPlaylistTheme();
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          this.setUpWorldHistoryPlaylistTheme();
        } else if (subjectId === SubjectId.GEOGRAPHY) {
          this.setUpGeographyPlaylistTheme();
        } else if (subjectId === SubjectId.POLITICAL_ECONOMY) {
          this.setUpPoliticalEconomyPlaylistTheme();
        }
      })
    );
  }

  private setUpPlayListProblems() {
    this.playListProblems$ = combineLatest([this.subjectId$]).pipe(
      switchMap(([subjectId]) => {
        if (subjectId === SubjectId.ENGLISH) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getEnglishPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableEnglishPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.MATH) {
          return combineLatest([this.store.select(CommonIdPlaylistSelectors.getMathPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableMathPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getNationalLanguagePlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableNationalLanguagePlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.PHYSICS) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getPhysicsPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadablePhysicsPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.CHEMISTRY) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getChemistryPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableChemistryPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.BIOLOGY) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getBiologyPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableBiologyPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getJapaneseHistoryPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableJapaneseHistoryPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getWorldHistoryPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableWorldHistoryPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.GEOGRAPHY) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getGeographyPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadableGeographyPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.POLITICAL_ECONOMY) {
          return combineLatest([
            this.store.select(CommonIdPlaylistSelectors.getPoliticalEconomyPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(CommonIdPlaylistSelectors.getReadablePoliticalEconomyPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        }
      })
    );
  }

  private setUpPlayListProblem() {
    this.playListProblem$ = combineLatest([this.playListProblems$, this.themeId$, this.problemId$]).pipe(
      map(([problems, themeId, problemId]) => {
        return problems.find(problem => problem.themeId === themeId && problem.id === problemId);
      })
    );
  }

  private setUpBiologyPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getBiologyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableBiologyPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpChemistryPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getChemistryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableChemistryPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpPhysicsPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getPhysicsPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadablePhysicsPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpMathPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getMathPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableMathPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpEnglishPlaylistTheme() {
    this.englishPlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getEnglishPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableEnglishPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableEnglishPlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpNationalLanguagePlaylistTheme() {
    this.nationalLanguagePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getNationalLanguagePlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableNationalLanguagePlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableNationalLanguagePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpJapaneseHistoryPlaylistTheme() {
    this.japaneseHistoryPlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getJapaneseHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableJapaneseHistoryPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpWorldHistoryPlaylistTheme() {
    this.worldHistoryPlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getWorldHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableWorldHistoryPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpGeographyPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getGeographyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadableGeographyPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpPoliticalEconomyPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getPoliticalEconomyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            CommonIdPlaylistSelectors.getReadablePoliticalEconomyPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<CommonIdThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpProblem() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.scienceProblem$ = this.store.select(getScienceProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.scienceProblem$.pipe(problem => problem);
      } else if (subjectId === SubjectId.ENGLISH) {
        this.englishProblem$ = this.store.select(getEnglishProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.englishProblem$.pipe(problem => {
          return problem;
        });
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.nationalLanguageProblems$ = this.store.select(getNationalLanguageProblemsWithSameId).pipe(
          filter(it => it != null),
          shareReplay(1),
          map(problems => problems)
        );
        this.problem$ = this.nationalLanguageProblems$.pipe(map(problems => problems[0]));
      } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
        this.historyProblem$ = this.store.select(getHistoryProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.historyProblem$.pipe(problem => problem);
      }
    });

    this.subscriptions.push(
      combineLatest([this.subjectId$, this.problemId$, this.problemIds$]).subscribe(([subjectId, problemId, problemIds]) => {
        if (!problemIds.includes(problemId)) {
          this.isPreconditionError = true;
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
          return;
        }
        this.isPreconditionError = false;

        if (this.scienceIds.includes(subjectId)) {
          this.store.dispatch(findScienceProblem({ subjectId, problemId }));
        } else if (subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(findEnglishProblem({ subjectId, problemId }));
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(findNationalLanguageProblemsById({ subjectId, problemId }));
        } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(findHistoryProblem({ subjectId, problemId }));
        }
      })
    );
  }

  private setUpReadableProblem() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.readableScienceProblems$ = combineLatest([this.scienceProblem$]).pipe(
          switchMap(([problem]) => {
            const selector =
              subjectId === SubjectId.MATH
                ? this.store.select(getReadableMathProblem(problem))
                : subjectId === SubjectId.PHYSICS
                ? this.store.select(getReadablePhysicsProblem(problem))
                : subjectId === SubjectId.CHEMISTRY
                ? this.store.select(getReadableChemistryProblem(problem))
                : subjectId === SubjectId.BIOLOGY
                ? this.store.select(getReadableBiologyProblem(problem))
                : subjectId === SubjectId.GEOGRAPHY
                ? this.store.select(getReadableGeographyProblem(problem))
                : this.store.select(getReadablePoliticalEconomyProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.ENGLISH) {
        this.readableEnglishProblems$ = combineLatest([this.englishProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableEnglishProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.readableNationalLanguageProblems$ = combineLatest([this.nationalLanguageProblems$]).pipe(
          switchMap(([problems]) => {
            const selector = this.store.select(getReadableNationalLanguageProblemsFromProblems(problems));
            return selector;
          }),
          map(problems => {
            const matchedProblem = problems.find(problem => problem.id === this.problemId);
            this.loadingReadableProblems$ = of(false);
            return problems;
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        this.readableJapaneseHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableJapaneseHistoryProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        this.readableWorldHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableWorldHistoryProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      }
    });
  }

  private setUpProblemPositionFlags() {
    const problemId$ = this.problemId$.pipe(filter(it => it !== ''));
    this.isFirstProblem$ = combineLatest([this.playListProblems$, this.problemId$, this.themeId$]).pipe(
      map(([problems, problemId, themeId]) => problems[0].themeId === themeId && problems[0].id === problemId)
    );

    this.isLastProblem$ = combineLatest([this.playListProblems$, this.problemId$, this.themeId$]).pipe(
      map(
        ([problems, problemId, themeId]) =>
          problems[problems.length - 1].themeId === themeId && problems[problems.length - 1].id === problemId
      )
    );
  }

  private setUpBookmarks() {
    combineLatest([this.playlist$])
      .pipe(take(1))
      .subscribe(([playlist]) => {
        this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());
        this.store
          .select(getSignedInUser)
          .pipe(filter<User>(it => it != null && it !== 'none'))
          .pipe(take(1))
          .subscribe(user => {
            this.user = user;
            this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());
            this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmark({ userId: user.id }));
            this.store
              .select(CommonIdPlaylistBookmarkSelectors.getCommonIdPlaylistBookmark)
              .pipe(
                filter(it => it != null),
                take(1)
              )
              .subscribe(bookmark => {
                this.bookmark = bookmark;
                this.bookmarkSubjectPlaylists = [];
                if (bookmark.playlists !== undefined) {
                  this.bookmarkSubjectPlaylists = this.bookmark.playlists.filter(
                    bookmarkPlaylist => bookmarkPlaylist.pId === playlist.playlistId
                  );
                }
                this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());

                this.initializedBookmarkSubject.next(true);
              });
          });
      });
  }

  private setUpBrowserTitle(menuType: string = 'article') {
    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      let readableProblems$: Observable<
        ReadableScienceProblem[] | ReadableEnglishProblem[] | ReadableNationalLanguageProblem[] | ReadableHistoryProblem[]
      >;
      if (this.scienceIds.includes(subjectId)) {
        readableProblems$ = this.readableScienceProblems$;
      } else if (subjectId === SubjectId.ENGLISH) {
        readableProblems$ = this.readableEnglishProblems$;
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        readableProblems$ = this.readableNationalLanguageProblems$;
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        readableProblems$ = this.readableJapaneseHistoryProblems$;
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        readableProblems$ = this.readableWorldHistoryProblems$;
      }

      if (readableProblems$) {
        this.subscriptions.push(
          combineLatest([subjects$, readableProblems$.pipe(filter(it => it !== null)), this.paperMode$]).subscribe(
            ([subjects, problems]) => {
              const university: string = problems[0].university;
              const year: number = problems[0].year;
              const subject: string = SubjectUtil.getName(subjects, subjectId);
              const type = menuType === 'article' ? '問題' : '研究・解答';
              const title = `${university}${problems[0].isOriginalProblem ? '' : ` ${year}`} ${subject}　${type}`;
              this.store.dispatch(setCommonIdBrowserTitle({ subTitle: `${title}`, problemDetailFlag: true }));
              setTimeout(() => {
                this.store.dispatch(setTitle({ title }));
              });
            }
          )
        );
      }
    });
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none'));

    this.subscriptions.push(
      combineLatest([this.signedInUser$]).subscribe(([user]) => {
        this.signedInUser = user;
      })
    );
  }

  private setUpAnsweredProblem() {
    this.subscriptions.push(
      combineLatest([this.signedInUser$, this.problemId$]).subscribe(([user, problemId]) => {
        this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: user.id }));
        this.answeredProblems$ = this.store
          .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
          .pipe(filter(it => it != null));

        this.isAnswered$ = this.answeredProblems$.pipe(
          map(answeredProblems => {
            return answeredProblems.problems === undefined
              ? false
              : answeredProblems.problems.find(problem => problem.pId === problemId) !== undefined;
          })
        );
      })
    );
  }

  private setUpSearching() {
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemSearching$]).pipe(
      map(([problemSearching]) => {
        return problemSearching;
      })
    );
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  showPaperInformation() {
    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const staticData$ = this.store.select(getStaticData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([this.problem$, this.playListProblem$, subjects$, this.subjectId$, staticData$])
      .pipe(take(1))
      .subscribe(([problem, playListProblem, subjects, subjectId, staticData]) => {
        let categories: string[] = [];
        switch (subjectId) {
          case SubjectId.ENGLISH:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapEnglishCategory(cat, staticData.english));
            break;
          case SubjectId.MATH:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticData.math));
            break;
          case SubjectId.NATIONAL_LANGUAGE:
            categories = (problem as any).categories
              .filter(category => {
                // ジャンルのみ
                const splitIds = category.split(CATEGORY_DELIMITER);
                return splitIds[2].startsWith('2') ? false : true;
              })
              .map(cat => ReadableDataMapper.mapNationalLanguageCategory(cat, staticData.nationalLanguage));
            break;
          case SubjectId.PHYSICS:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticData.physics));
            break;
          case SubjectId.CHEMISTRY:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticData.chemistry));
            break;
          case SubjectId.BIOLOGY:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticData.biology));
            break;
          case SubjectId.JAPANESE_HISTORY:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapHistoryCategory(cat, staticData.japaneseHistory));
            break;
          case SubjectId.WORLD_HISTORY:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapHistoryCategory(cat, staticData.worldHistory));
            break;
          case SubjectId.GEOGRAPHY:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticData.geography));
            break;
          case SubjectId.POLITICAL_ECONOMY:
            categories = (problem as any).categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticData.politicalEconomy));
            break;
        }
        const levelLabel = ReadableDataMapper.getLevelDisplayName(problem.level, staticData.common);
        const subject: string = SubjectUtil.getName(subjects, subjectId);
        const data: CommonIdPaperInformationDialogData = {
          title: `${playListProblem.university} ${playListProblem.year}年度 大問${problem.problemNumber}`,
          description: problem.departments,
          examDate: problem.examPaper.examDate ? problem.examPaper.examDate : '-',
          nyushiName: problem.examPaper.nyushiName ? problem.examPaper.nyushiName : '-',
          examTime: problem.examPaper.examTime ? problem.examPaper.examTime : '-',
          subjectName: subject,
          level: problem.level,
          levelLabel,
          categories
        };
        const config: MatDialogConfig = {
          width: PAPER_INFORMATION_DIALOG_WIDTH,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          data,
          disableClose: true
        };
        this.dialog.open(CommonIdPaperInformationDialogComponent, config);
      });
  }

  showPaperSourceInformation() {
    this.universities$ = this.store.select(StaticDataSelectors.getUniversities).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([this.problem$, this.universities$])
      .pipe(take(1))
      .subscribe(([problem, universities]) => {
        const data: CommonIdPaperSourceInformationDialogData = {
          descriptions: problem.originalSource.match(/^[0-9]+:/)
            ? problem.originalSource.split(':').map(description => {
                const year = description.slice(0, 2);
                const universityId = description.slice(2, 6);
                const universityName = universities.find(university => university.id === `D${universityId}`).name;
                let problemNumber = description.slice(10, 12);
                if (problemNumber.startsWith('0')) {
                  problemNumber = problemNumber.slice(1);
                }
                return `20${year}年度 ${universityName} 大問${problemNumber}`;
              })
            : ['旺文社オリジナル']
        };
        const config: MatDialogConfig = {
          width: PAPER_INFORMATION_DIALOG_WIDTH,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          data,
          disableClose: true
        };
        this.dialog.open(CommonIdPaperSourceInformationDialogComponent, config);
      });
  }

  showPaper() {
    this.problemId$.pipe(take(1)).subscribe(problemId => {
      const { queryParams } = this.activatedRoute.snapshot;
      const url = RoutingPathResolver.resolveProblemDetail(problemId);
      this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, mode: 'paper' } } }));
    });
  }

  showPrint() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.SET_DAIMON_PRINT_PREVIEW,
      'event_label': this.problemId,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    const ua = new UAParser(this.window.navigator.userAgent).getResult();
    if (ua.browser.name.indexOf('Safari') > -1) {
      document.execCommand('print', false, null);
    } else {
      (window as any).print();
    }
  }

  changeMenuType(event) {
    this.menuType$ = of(event);
    this.menuType$.pipe(take(1)).subscribe(menuType => this.setUpBrowserTitle(menuType));
  }

  saveAnswered() {
    if (this.isPremiumUser === false) {
      const config: MatDialogConfig = {
        width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
        panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
        autoFocus: false,
        disableClose: true
      };
      this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
      return;
    }

    combineLatest([this.signedInUser$, this.isAnswered$])
      .pipe(take(1))
      .subscribe(([user, isAnswered]) => {
        const requestIsAnswered = !isAnswered;
        if (requestIsAnswered) {
          const eventParams = {
            'event_category': GA_EVENT_CATEGORIES.SET_DAIMON_SOLVED_ON,
            'event_label': this.problemId,
            'value': 1
          };
          GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

          this.store.dispatch(
            dispatchInfoMessage({
              message: `解答済みに登録しています`
            })
          );
        } else {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `解答済みを解除しています`
            })
          );
        }

        const request: CommonIdSaveAnsweredProblemsRequest = {
          userId: user.id,
          problems: [{ problemId: this.problemId, isAdd: requestIsAnswered }]
        };
        Log.debug(this.LOG_SOURCE, 'save Answered Problems', request);
        this.store.dispatch(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblems({ request }));
        this.store
          .select(CommonIdAnsweredProblemSelectors.getCommonIdSaveAnsweredProblemsResult)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(result => {
            Log.debug(this.LOG_SOURCE, `save Answered Problems result: `, result);
            if (result.success) {
              if (requestIsAnswered) {
                this.store.dispatch(
                  dispatchInfoMessage({
                    message: `解答済みに登録しました`
                  })
                );
              } else {
                this.store.dispatch(
                  dispatchInfoMessage({
                    message: `解答済みを解除しました`
                  })
                );
              }
              this.isAnswered$ = of(requestIsAnswered);
            } else {
              Log.warn(
                this.LOG_SOURCE,
                `save Answered Problems error: err.code: ${result.error ? result.error.code : 'none'}`,
                result.error
              );
              this.store.dispatch(
                dispatchInfoMessage({
                  message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
                })
              );
              this.isAnswered$ = of(isAnswered);
              this.problemDetailFrameComponent.resetToggleAnsweredProblemBtn();
            }

            this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
            this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdSaveAnsweredProblemsState());
          });
      });
  }

  addBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを登録します');
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを登録しています`
      })
    );

    const request: CommonIdAddPlaylistBookmarkRequest = {
      userId: this.user.id,
      playlistId: this.playlistId
    };

    Log.debug(this.LOG_SOURCE, 'add bookmark', request);
    this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmark(request));

    this.store
      .select(CommonIdPlaylistBookmarkSelectors.getCommonIdAddPlaylistBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `add bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを登録しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `add bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdAddPlaylistBookmarkState());
        this.setUpBookmarks();
      });
  }

  deleteBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを解除します');
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを解除しています`
      })
    );

    const request: CommonIdDeletePlaylistBookmarkRequest = {
      userId: this.user.id,
      playlistId: this.playlistId
    };

    Log.debug(this.LOG_SOURCE, 'delete bookmark', request);
    this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmark(request));

    this.store
      .select(CommonIdPlaylistBookmarkSelectors.getCommonIdDeletePlaylistBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `delete bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdDeletePlaylistBookmarkState());
        this.setUpBookmarks();
      });
  }

  setLoadingReadableProblems(param: boolean) {
    this.loadingReadableProblems$ = of(param);
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'playlist/detail':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylistDetail(this.playlistId) }));
        break;
    }
  }

  showPreviousProblem() {
    this.setLoadingReadableProblems(true);
    setTimeout(() => {
      this.playListProblems$.pipe(take(1)).subscribe(playListProblems => {
        const currentIndex = playListProblems.findIndex(
          playlistProblem => playlistProblem.themeId === this.themeId && playlistProblem.id === this.problemId
        );
        const previousProblem = playListProblems[currentIndex - 1];

        this.store.dispatch(
          navigate({
            url: RoutingPathResolver.resolveCommonIdPlaylistProblemDetail(this.playlistId, previousProblem.themeId, previousProblem.id)
          })
        );
      });
    }, 200);
  }

  showNextProblem() {
    this.setLoadingReadableProblems(true);
    setTimeout(() => {
      this.playListProblems$.pipe(take(1)).subscribe(playListProblems => {
        const currentIndex = playListProblems.findIndex(
          playlistProblem => playlistProblem.themeId === this.themeId && playlistProblem.id === this.problemId
        );
        const nextProblem = playListProblems[currentIndex + 1];

        this.store.dispatch(
          navigate({ url: RoutingPathResolver.resolveCommonIdPlaylistProblemDetail(this.playlistId, nextProblem.themeId, nextProblem.id) })
        );
      });
    }, 200);
  }
}
