import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, filter, map, shareReplay, startWith, switchMap, take, throwError } from 'rxjs';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { setCommonIdBrowserTitle } from 'src/app/actions/common-id/common-id-core.actions';
import { dispatchAppError, dispatchInfoMessage, navigate, setTitle } from 'src/app/actions/core.actions';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { GeneralError } from 'src/app/errors/general-error';
import { RootState } from 'src/app/reducers';
import { enter } from 'src/app/resources/animations';
import {
  COMPLETE_ANSWERED_DIALOG_WIDTH,
  DESCRIPTION_PREMIUM_DIALOG_WITDH,
  SELECT_ANSWERED_DIALOG_HEIGHT,
  SELECT_ANSWERED_DIALOG_WITDH
} from '../../../../resources/common-id-config';
import { DIALOG_ZERO_PADDING_PANEL_CLASS } from '../../../../resources/config';
import {
  CommonIdSelectAnsweredDialogComponent,
  CommonIdSelectAnsweredDialogData
} from '../select-answered-dialog/select-answered-dialog.component';

import * as CommonIdPlaylistBookmarkActions from 'src/app/actions/common-id/common-id-playlist-bookmark.actions';
import * as CommonIdPlaylistActions from 'src/app/actions/common-id/common-id-playlist.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { CommonIdPlaylist } from 'src/app/models/common-id/common-id-playlist';
import {
  CommonIdAddPlaylistBookmarkRequest,
  CommonIdBookmarkPlaylist,
  CommonIdDeletePlaylistBookmarkRequest,
  CommonIdPlaylistBookmark
} from 'src/app/models/common-id/common-id-playlist-bookmark';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { ThemeForDisplay } from 'src/app/models/playlist';
import {
  ReadableEnglishPlaylistProblem,
  ReadableHistoryPlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadablePlaylistProblem,
  ReadableSciencePlaylistProblem
} from 'src/app/models/problem';
import { SubjectId } from 'src/app/resources/config';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import * as CommonIdPlaylistBookmarkSelectors from 'src/app/selectors/common-id/common-id-playlist-bookmark.selectors';
import * as CommonIdPlaylistSelectors from 'src/app/selectors/common-id/common-id-playlist.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { SubjectUtil } from '../../../../utils/subject-util';

import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'src/app/resources/common-id/ga';
import { GAUtil } from 'src/app/utils/ga-util';
import { Log } from 'src/app/utils/log';
import { ButtonTogglePlaylistBookmarkComponent } from 'src/app/views/widgets/button-toggle-playlist-bookmark/button-toggle-playlist-bookmark.component';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { CommonIdEffectAnimationUtil } from '../../../../utils/common-id/common-id-effect-animation-util';
import { CommonIdCompleteAnsweredDialogComponent } from '../complete-answered-dialog/complete-answered-dialog.component';
import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';
import * as CommonIdAnsweredProblemActions from '../../../../actions/common-id/common-id-answered-problem.actions';
import * as CommonIdAnsweredProblemSelectors from '../../../../selectors/common-id/common-id-answered-problem.selectors';
import { CommonIdAnsweredProblems } from '../../../../models/common-id/common-id-answered-problem';
import { Subject } from 'src/app/models/common-data';

@Component({
  selector: 'app-common-id-playlist-detail',
  templateUrl: './playlist-detail.component.html',
  styleUrls: ['./playlist-detail.component.scss'],
  animations: [enter]
})
export class CommonIdPlaylistDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  playlist$: Observable<CommonIdPlaylist>;
  playlists$: Observable<CommonIdPlaylist[]>;
  loaded$: Observable<boolean>;
  signedInUser$: Observable<CommonIdUser>;
  signedInUser: CommonIdUser;
  playlistId: string;
  englishPlaylistThemes$: Observable<ThemeForDisplay<ReadableEnglishPlaylistProblem>[]>;
  mathPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  nationalLanguagePlaylistThemes$: Observable<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>[]>;
  physicsPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  chemistryPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  biologyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  japaneseHistoryPlaylistThemes$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>[]>;
  worldHistoryPlaylistThemes$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>[]>;
  geographyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  politicalEconomyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  answeredProblems$: Observable<CommonIdAnsweredProblems>;
  isTrial: boolean;
  subjectLabelName: string;
  subjects$: Observable<Subject[]>;
  playlistBookmark: CommonIdPlaylistBookmark;
  bookmarkPlaylists: CommonIdBookmarkPlaylist[];
  isPlaylistBookmarked: boolean;
  subjectLabelStyle: string;
  isPremiumUser: boolean;

  @ViewChild('savePlaylistBtn') buttonTogglePlaylistBookmarkComponent: ButtonTogglePlaylistBookmarkComponent;

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}
  // constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private location: Location) {}

  ngOnInit() {
    this.setUpUser();
    this.findPlaylistIfNeeded();
    this.setUpPlaylist();
    this.setUpSubjects();
    this.setUpTitle();
    this.checkPremium();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch(CommonIdPlaylistActions.initializeEnglishPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeMathPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeNationalLanguagePlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializePhysicsPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeChemistryPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeBiologyPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeJapaneseHistoryPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeWorldHistoryPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializeGeographyPlaylistProblemsState());
    this.store.dispatch(CommonIdPlaylistActions.initializePoliticalEconomyPlaylistProblemsState());
    this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none'));

    combineLatest([this.signedInUser$]).subscribe(([user]) => {
      this.signedInUser = user;
    });
  }

  private setUpSubjects() {
    this.subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTitle() {
    combineLatest([this.subjects$, this.playlist$.pipe(filter(it => it != null))])
      .pipe(take(1))
      .subscribe(([subjects, playlist]) => {
        let suffix = SubjectUtil.getName(subjects, playlist.subjectId);
        suffix = suffix === '' ? '' : `- ${suffix}`;
        const title = `おすすめ問題セット ${suffix}`;
        setTimeout(() => {
          this.store.dispatch(setCommonIdBrowserTitle({ subTitle: title }));
          this.store.dispatch(setTitle({ title }));
        });
      });
  }

  private findPlaylistIfNeeded() {
    this.store
      .select(CommonIdPlaylistSelectors.getPlaylists)
      .pipe(take(1))
      .subscribe(playlists => {
        if (!playlists) this.store.dispatch(CommonIdPlaylistActions.findPlaylists());
      });
    // this.store.dispatch(CommonIdPlaylistActions.findPlaylists());
  }

  private setUpPlaylist() {
    this.playlist$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getPlaylists).pipe(filter(it => it != null)),
      this.activatedRoute.paramMap.pipe(map(paramMap => paramMap.get('playlistId') || ''))
    ]).pipe(
      map(([playlists, playlistId]) => {
        const selectedPlaylist = playlists.find(it => it.playlistId === playlistId);
        if (!playlistId || !selectedPlaylist) {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: GeneralError.customMessage(CustomErrorMessage.PLAYLIST_DETAIL_INVALID_PLAYLIST_ID)
            })
          );
          throwError(() => GeneralError.invalidArguments(`指定された playlistId は playlists に存在しません. playlistId: ${playlistId}`));
          return null;
        }
        this.playlistId = playlistId;
        return selectedPlaylist;
      }),
      shareReplay(1)
    );
  }

  private checkPremium() {
    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    combineLatest([this.signedInUser$, this.playlist$, currentDateTime$])
      .pipe(take(1))
      .subscribe(([signedInUser, playlist, currentDateTime]) => {
        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser);

        if (this.isPremiumUser === false && playlist.freeFlg !== true) {
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylists() }));
        } else {
          this.setUp();
        }
      });
  }

  private setUp() {
    this.setUpSubjectNameAndStyle();
    this.setUpAnsweredProblems();
    this.setUpEnglishPlaylistThemes();
    this.setUpMathPlaylistThemes();
    this.setUpNationalLanguagePlaylistThemes();
    this.setUpPhysicsPlaylistThemes();
    this.setUpChemistryPlaylistThemes();
    this.setUpBiologyPlaylistThemes();
    this.setUpJapaneseHistoryPlaylistThemes();
    this.setUpWorldHistoryPlaylistThemes();
    this.setUpGeographyPlaylistThemes();
    this.setUpPoliticalEconomyPlaylistThemes();
    this.setUpLoaded();
    this.findAnsweredProblems();
    this.findPlaylistProblems();
    this.setUpCommonIdPlaylistBookmarks();
  }

  private setUpSubjectNameAndStyle() {
    combineLatest([this.subjects$, this.playlist$]).subscribe(([subjects, playlist]) => {
      this.subjectLabelName = SubjectUtil.getName(subjects, playlist.subjectId);
      this.subjectLabelStyle = SubjectUtil.getSubjectLabelStyle(playlist.subjectId);
    });
  }

  private setUpAnsweredProblems() {
    this.answeredProblems$ = this.store
      .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
      .pipe(filter<CommonIdAnsweredProblems>(it => it !== null));
  }

  private setUpEnglishPlaylistThemes() {
    this.englishPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getEnglishPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.ENGLISH)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([englishProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableEnglishPlaylistProblems(englishProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableEnglishPlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpMathPlaylistThemes() {
    this.mathPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getMathPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.MATH)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([mathProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableMathPlaylistProblems(mathProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpNationalLanguagePlaylistThemes() {
    this.nationalLanguagePlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getNationalLanguagePlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.NATIONAL_LANGUAGE)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([nationalLanguageProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableNationalLanguagePlaylistProblems(nationalLanguageProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              // 国語の場合は problemId のユニーク数を problemCount とする（同一 problemId が複数存在するため）
              const ids = themeProblems.map(themeProblem => themeProblem.id);
              const problemCount = Array.from(new Set(ids)).length;
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpPhysicsPlaylistThemes() {
    this.physicsPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getPhysicsPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.PHYSICS)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([physicsProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadablePhysicsPlaylistProblems(physicsProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpChemistryPlaylistThemes() {
    this.chemistryPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getChemistryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.CHEMISTRY)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([chemistryProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableChemistryPlaylistProblems(chemistryProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpBiologyPlaylistThemes() {
    this.biologyPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getBiologyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.BIOLOGY)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([biologyProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableBiologyPlaylistProblems(biologyProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpJapaneseHistoryPlaylistThemes() {
    this.japaneseHistoryPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getJapaneseHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.JAPANESE_HISTORY)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([japaneseHistoryProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableJapaneseHistoryPlaylistProblems(japaneseHistoryProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpWorldHistoryPlaylistThemes() {
    this.worldHistoryPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getWorldHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.WORLD_HISTORY)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([worldHistoryProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableWorldHistoryPlaylistProblems(worldHistoryProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpGeographyPlaylistThemes() {
    this.geographyPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getGeographyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.GEOGRAPHY)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([geographyProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadableGeographyPlaylistProblems(geographyProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpPoliticalEconomyPlaylistThemes() {
    this.politicalEconomyPlaylistThemes$ = combineLatest([
      this.store.select(CommonIdPlaylistSelectors.getPoliticalEconomyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.POLITICAL_ECONOMY)),
      this.answeredProblems$
    ]).pipe(
      switchMap(([politicalEconomyProblems, playlist, answeredProblems]) =>
        this.store.select(CommonIdPlaylistSelectors.getReadablePoliticalEconomyPlaylistProblems(politicalEconomyProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id)).map(problem => {
                if (answeredProblems.problems && answeredProblems.problems.find(p => p.pId === problem.id)) {
                  problem.answered = true;
                }
                return problem;
              });
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpLoaded() {
    this.loaded$ = combineLatest([
      this.englishPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableEnglishPlaylistProblem>)),
      this.mathPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.nationalLanguagePlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>)),
      this.physicsPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.chemistryPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.biologyPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.japaneseHistoryPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableHistoryPlaylistProblem>)),
      this.worldHistoryPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableHistoryPlaylistProblem>)),
      this.geographyPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.politicalEconomyPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>))
    ]).pipe(
      map(themes => themes.some(it => it != null)),
      shareReplay(1)
    );
  }

  private findPlaylistProblems() {
    this.playlist$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(playlist => {
        if (playlist.subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(CommonIdPlaylistActions.findEnglishPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.MATH) {
          this.store.dispatch(CommonIdPlaylistActions.findMathPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(CommonIdPlaylistActions.findNationalLanguagePlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.PHYSICS) {
          this.store.dispatch(CommonIdPlaylistActions.findPhysicsPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.CHEMISTRY) {
          this.store.dispatch(CommonIdPlaylistActions.findChemistryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.BIOLOGY) {
          this.store.dispatch(CommonIdPlaylistActions.findBiologyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.JAPANESE_HISTORY) {
          this.store.dispatch(CommonIdPlaylistActions.findJapaneseHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(CommonIdPlaylistActions.findWorldHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.GEOGRAPHY) {
          this.store.dispatch(CommonIdPlaylistActions.findGeographyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.POLITICAL_ECONOMY) {
          this.store.dispatch(CommonIdPlaylistActions.findPoliticalEconomyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        this.store.dispatch(
          dispatchAppError({
            source: this.LOG_SOURCE,
            error: GeneralError.unknown(`不明な科目の playlist が指定されました: ${JSON.stringify(playlist)}`)
          })
        );
      });
  }

  private findAnsweredProblems() {
    this.signedInUser$.pipe(take(1)).subscribe(user => {
      this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: user.id }));
    });
  }

  private setUpCommonIdPlaylistBookmarks() {
    this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());

    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([user]) => {
        this.playlist$.subscribe(playlist => {
          this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());
          this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmark({ userId: user.id }));
          this.store
            .select(CommonIdPlaylistBookmarkSelectors.getCommonIdPlaylistBookmark)
            .pipe(
              filter(it => it != null),
              take(1)
            )
            .subscribe(playlistBookmark => {
              this.playlistBookmark = playlistBookmark;
              this.bookmarkPlaylists = [];
              if (playlistBookmark.playlists !== undefined) {
                this.bookmarkPlaylists = this.playlistBookmark.playlists.filter(
                  BookmarksPlaylist => BookmarksPlaylist.pId === playlist.playlistId
                );
              }
              this.isPlaylistBookmarked = this.bookmarkPlaylists.length > 0;
              this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());

              // this.initializedPlaylistBookmarkSubject.next(true);
            });
        });
      });
  }

  private sortPlaylistProblems<T extends ReadablePlaylistProblem>(problems: T[]): T[] {
    return [...problems].sort((a, b) => {
      if (a.orderInTheme < b.orderInTheme) return -1;
      if (a.orderInTheme > b.orderInTheme) return 1;
      return 0;
    });
  }

  private sortTheme<T extends ReadablePlaylistProblem>(themes: ThemeForDisplay<T>[]): ThemeForDisplay<T>[] {
    return [...themes].sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }

  goPlaylistProblemDetail(playlistId: string, themeId: string, problemId: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylistProblemDetail(playlistId, themeId, problemId) }));
  }

  clickPlaylistBookmarkButton() {
    if (this.isPlaylistBookmarked) {
      this.deletePlaylistBookmark();
    } else {
      this.playlist$.pipe(take(1)).subscribe(playlist => {
        const eventParams = {
          'event_category': GA_EVENT_CATEGORIES.SET_SAVE_PAPER_ON,
          'event_label': playlist.playlistId,
          'value': 1
        };
        GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
      });
      this.addPlaylistBookmark();
    }
  }

  clickTag(tag: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylists(), extras: { queryParams: { tag } } }));
  }

  addPlaylistBookmark() {
    Log.debug(this.LOG_SOURCE, 'あとで解くに登録します');
    // this.initializedPaperBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `あとで解くに登録しています`
      })
    );

    this.subscriptions.push(
      this.playlist$.subscribe(playlist => {
        const request: CommonIdAddPlaylistBookmarkRequest = {
          userId: this.signedInUser.id,
          playlistId: playlist.playlistId
        };
        Log.debug(this.LOG_SOURCE, 'add playlist bookmark', request);
        this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmark(request));
        this.store
          .select(CommonIdPlaylistBookmarkSelectors.getCommonIdAddPlaylistBookmarkResult)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(result => {
            Log.debug(this.LOG_SOURCE, `add playlist bookmark result: `, result);
            if (result.success) {
              this.store.dispatch(
                dispatchInfoMessage({
                  message: `あとで解くに登録しました`
                })
              );
            } else {
              Log.warn(
                this.LOG_SOURCE,
                `add playlist bookmark error: err.code: ${result.error ? result.error.code : 'none'}`,
                result.error
              );
              this.store.dispatch(
                dispatchInfoMessage({
                  message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
                })
              );
              this.buttonTogglePlaylistBookmarkComponent.reset();
            }

            // TODO: initializeAddPlaylistBookmarkStateを作っておく
            this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdAddPlaylistBookmarkState());
            this.setUpCommonIdPlaylistBookmarks();
          });
      })
    );
  }

  deletePlaylistBookmark() {
    Log.debug(this.LOG_SOURCE, 'あとで解くを解除します');
    // this.initializedPaperBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `あとで解くを解除しています`
      })
    );

    this.subscriptions.push(
      this.playlist$.subscribe(playlist => {
        const request: CommonIdDeletePlaylistBookmarkRequest = {
          userId: this.signedInUser.id,
          playlistId: playlist.playlistId
        };

        Log.debug(this.LOG_SOURCE, 'delete playlist bookmark', request);
        this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmark(request));

        this.store
          .select(CommonIdPlaylistBookmarkSelectors.getCommonIdDeletePlaylistBookmarkResult)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(result => {
            Log.debug(this.LOG_SOURCE, `delete playlist bookmark result: `, result);
            if (result.success) {
              this.store.dispatch(
                dispatchInfoMessage({
                  message: `あとで解くを解除しました`
                })
              );
            } else {
              Log.warn(
                this.LOG_SOURCE,
                `delete playlist bookmark error: err.code: ${result.error ? result.error.code : 'none'}`,
                result.error
              );
              this.store.dispatch(
                dispatchInfoMessage({
                  message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
                })
              );
            }

            this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdDeletePlaylistBookmarkState());
            this.setUpCommonIdPlaylistBookmarks();
          });
      })
    );
  }

  selectAnswered() {
    if (this.isPremiumUser === false) {
      const premiumConfig: MatDialogConfig = {
        width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
        panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
        autoFocus: false,
        disableClose: true
      };
      this.dialog.open(CommonIdDescriptionPremiumDialogComponent, premiumConfig);
      return;
    }

    const data: CommonIdSelectAnsweredDialogData = {
      plyalistId: this.playlistId,
      playlist$: this.playlist$,
      englishPlaylistThemes$: this.englishPlaylistThemes$,
      mathPlaylistThemes$: this.mathPlaylistThemes$,
      nationalLanguagePlaylistThemes$: this.nationalLanguagePlaylistThemes$,
      physicsPlaylistThemes$: this.physicsPlaylistThemes$,
      chemistryPlaylistThemes$: this.chemistryPlaylistThemes$,
      biologyPlaylistThemes$: this.biologyPlaylistThemes$,
      japaneseHistoryPlaylistThemes$: this.japaneseHistoryPlaylistThemes$,
      worldHistoryPlaylistThemes$: this.worldHistoryPlaylistThemes$,
      geographyPlaylistThemes$: this.geographyPlaylistThemes$,
      politicalEconomyPlaylistThemes$: this.politicalEconomyPlaylistThemes$
    };

    const config: MatDialogConfig = {
      height: SELECT_ANSWERED_DIALOG_HEIGHT,
      width: SELECT_ANSWERED_DIALOG_WITDH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data,
      disableClose: true
    };
    const dialogRef = this.dialog.open(CommonIdSelectAnsweredDialogComponent, config);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        // 全ての問題を解答済みにした際に全て解答済み用のモーダルを表示する
        if (result === true) {
          const completeAnsweredDialogConfig: MatDialogConfig = {
            width: COMPLETE_ANSWERED_DIALOG_WIDTH,
            panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
            backdropClass: 'overlay-alpha-backdrop'
          };
          this.dialog.open(CommonIdCompleteAnsweredDialogComponent, completeAnsweredDialogConfig);
          CommonIdEffectAnimationUtil.fireCompleteAnsweredConfetti();
        }
      });
  }
}
