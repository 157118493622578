import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { dispatchAppError, navigate, setBrowserTitle, setTitle } from 'src/app/actions/core.actions';
import { reportAppEvent } from 'src/app/actions/event-log.actions';
import * as SearchActions from 'src/app/actions/search.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { GeneralError } from 'src/app/errors/general-error';
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { Subject } from 'src/app/models/common-data';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { AppEvent } from 'src/app/models/event-log';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { SearchCondition, SearchConditionWithProblemCount } from 'src/app/models/search-condition';
import { StaticCommonData } from 'src/app/models/static-common-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { User } from 'src/app/models/user';
import { RootState } from 'src/app/reducers';
import { enter, inOut } from 'src/app/resources/animations';
import {
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  SELECT_CATEGORY_DIALOG_HEIGHT,
  SELECT_CATEGORY_DIALOG_WIDTH,
  SubjectId,
  THEME_HISTORY
} from 'src/app/resources/config';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { getMatchedProblemCount, getProblemCountSearching, getSearchCondition } from 'src/app/selectors/search.selectors';
import * as StaticDataSelectors from 'src/app/selectors/static-data.selectors';
import { getSubject } from 'src/app/selectors/static-data.selectors';
import { Diff } from 'src/app/utils/diff';
import { Log } from 'src/app/utils/log';
import { CommonSearchFormComponent } from '../common-search-form/common-search-form.component';
import { EnglishSearchFormComponent } from '../english-search-form/english-search-form.component';
import { HistorySearchFormComponent } from '../history-search-form/history-search-form.component';
import { NationalLanguageSearchFormComponent } from '../national-language-search-form/national-language-search-form.component';
import {
  SelectCategoryDialogComponent,
  SelectCategoryDialogData,
  SelectCategoryDialogResult
} from '../select-category-dialog/select-category-dialog.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [enter, inOut]
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild(CommonSearchFormComponent)
  commonSearchForm: CommonSearchFormComponent;

  @ViewChild(EnglishSearchFormComponent)
  englishSearchForm: EnglishSearchFormComponent;

  @ViewChild(NationalLanguageSearchFormComponent)
  nationalLanguageSearchForm: NationalLanguageSearchFormComponent;

  @ViewChild(HistorySearchFormComponent)
  historySearchForm: HistorySearchFormComponent;

  private LOG_SOURCE = this.constructor.name;
  private title = '基本検索';
  private commonSearchFormInvalidSubject = new BehaviorSubject<boolean>(false);
  private commonSearchFormInvalid$ = this.commonSearchFormInvalidSubject.asObservable();
  private englishSearchFormInvalidSubject = new BehaviorSubject<boolean>(false);
  private englishSearchFormInvalid$ = this.englishSearchFormInvalidSubject.asObservable();
  private nationalLanguageSearchFormInvalidSubject = new BehaviorSubject<boolean>(false);
  private nationalLanguageSearchFormInvalid$ = this.nationalLanguageSearchFormInvalidSubject.asObservable();
  private routerEventSubscription: Subscription;
  private matchedProblemCount: number | undefined;
  private problemCountSubscription: Subscription;

  ENGLISH_SUBJECT_ID: string;
  MATH_SUBJECT_ID: string;
  NATIONAL_LANGUAGE_SUBJECT_ID: string;
  PHYSICS_SUBJECT_ID: string;
  CHEMISTRY_SUBJECT_ID: string;
  BIOLOGY_SUBJECT_ID: string;
  JAPANESE_HISTORY_SUBJECT_ID: string;
  WORLD_HISTORY_SUBJECT_ID: string;
  GEOGRAPHY_SUBJECT_ID: string;
  POLITICAL_ECONOMY_SUBJECT_ID: string;

  initialDataLoaded$: Observable<boolean>;
  subjects$: Observable<Subject[]>;
  staticCommonData$: Observable<StaticCommonData>;
  staticEnglishData$: Observable<StaticEnglishData>;
  staticMathData$: Observable<StaticScienceData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;
  matchedProblemCount$: Observable<number>;
  searchButtonDisabled$: Observable<boolean>;
  problemCountSearching$: Observable<boolean>;

  /** 科目、分野、単元選択の Dialog から渡される情報を保存 */
  mathSearchCondition: ScienceSearchCondition | undefined;
  nationalLanguageSearchCondition: NationalLanguageSearchCondition | undefined;
  physicsSearchCondition: ScienceSearchCondition | undefined;
  chemistrySearchCondition: ScienceSearchCondition | undefined;
  biologySearchCondition: ScienceSearchCondition | undefined;
  japaneseHistorySearchCondition: HistorySearchCondition | undefined;
  worldHistorySearchCondition: HistorySearchCondition | undefined;
  geographySearchCondition: ScienceSearchCondition | undefined;
  politicalEconomySearchCondition: ScienceSearchCondition | undefined;

  /** Tow-way binding */
  selectedSubjectId: string;
  hasExternalData = false;
  hasWordData = false;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {
    this.ENGLISH_SUBJECT_ID = SubjectId.ENGLISH;
    this.MATH_SUBJECT_ID = SubjectId.MATH;
    this.NATIONAL_LANGUAGE_SUBJECT_ID = SubjectId.NATIONAL_LANGUAGE;
    this.PHYSICS_SUBJECT_ID = SubjectId.PHYSICS;
    this.CHEMISTRY_SUBJECT_ID = SubjectId.CHEMISTRY;
    this.BIOLOGY_SUBJECT_ID = SubjectId.BIOLOGY;
    this.JAPANESE_HISTORY_SUBJECT_ID = SubjectId.JAPANESE_HISTORY;
    this.WORLD_HISTORY_SUBJECT_ID = SubjectId.WORLD_HISTORY;
    this.GEOGRAPHY_SUBJECT_ID = SubjectId.GEOGRAPHY;
    this.POLITICAL_ECONOMY_SUBJECT_ID = SubjectId.POLITICAL_ECONOMY;
  }

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpSubjectsWithRestoreSearchConditionIfNeeded();
    this.setUpObservables();
  }

  ngOnDestroy() {
    if (this.routerEventSubscription) this.routerEventSubscription.unsubscribe();
    this.routerEventSubscription = undefined;
    if (this.problemCountSubscription) this.problemCountSubscription.unsubscribe();
    this.problemCountSubscription = undefined;
    this.store.dispatch(SearchActions.initializeProblemCountState());
  }

  onChangeSubject() {
    this.resetForms(true);
  }

  onChangeSearchCondition() {
    Log.debug(this.LOG_SOURCE, '検索項目が変更されました');
    this.findProblemCount();
  }

  onChangeCommonSearchFormIsValid(isValid: boolean) {
    this.commonSearchFormInvalidSubject.next(!isValid);
  }

  onChangeEnglishSearchFormIsValid(isValid: boolean) {
    this.englishSearchFormInvalidSubject.next(!isValid);
  }

  onChangeNationalLanguageSearchFormIsValid(isValid: boolean) {
    this.nationalLanguageSearchFormInvalidSubject.next(!isValid);
  }

  resetForms(withInitializeProblemCount: boolean = false) {
    if (!withInitializeProblemCount && this.matchedProblemCount != null) {
      const condition = this.getCurrentSearchCondition();
      const conditionWithProblemCount: SearchConditionWithProblemCount<any> = {
        ...condition,
        matchedProblemCount: this.matchedProblemCount
      };
      const event: AppEvent = {
        type: 'reset-search-form',
        value: JSON.stringify(conditionWithProblemCount)
      };
      setTimeout(() => this.store.dispatch(reportAppEvent({ event })));
    }

    this.hasExternalData = false;
    this.hasWordData = false;

    this.resetCommonSearchForm();
    this.resetEnglishSearchForm();
    this.resetMathSearchForm();
    this.resetNationalLanguageSearchForm();
    this.resetPhysicsSearchForm();
    this.resetChemistrySearchForm();
    this.resetBiologySearchForm();
    this.resetJapaneseHistorySearchForm();
    this.resetWorldHistorySearchForm();
    this.resetGeographySearchForm();
    this.resetPoliticalEconomySearchForm();
    if (withInitializeProblemCount) this.store.dispatch(SearchActions.initializeProblemCountState());
    this.findProblemCount();
    this.commonSearchFormInvalidSubject.next(false);
    this.englishSearchFormInvalidSubject.next(false);
  }

  showSearchResultView() {
    // NOTE:
    // form が invalid のときはボタンを disabled にしているが、
    // もしより安全にしたければ XXXform.isValid() を確認するとよい
    const condition = this.getCurrentSearchCondition();

    if (this.matchedProblemCount != null) {
      const conditionWithProblemCount: SearchConditionWithProblemCount<any> = {
        ...condition,
        matchedProblemCount: this.matchedProblemCount
      };
      const event: AppEvent = {
        type: 'show-results',
        value: JSON.stringify(conditionWithProblemCount)
      };
      this.store.dispatch(reportAppEvent({ event }));
    }

    const extras: NavigationExtras = {
      queryParams: QueryParamsMapper.encodeSearchCondition(condition)
    };
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchResults(), extras }));
  }

  openMathCategoryDialog() {
    this.staticMathData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticMathData => {
        const mathSearchCondition = this.mathSearchCondition ? { ...this.mathSearchCondition } : undefined;
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: { dataType: 'MATH', staticScienceData: staticMathData, scienceSearchCondition: mathSearchCondition }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.mathSearchCondition, result)) return;
            this.mathSearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openPhysicsCategoryDialog() {
    this.staticPhysicsData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticPhysicsData => {
        const physicsSearchCondition = this.physicsSearchCondition ? { ...this.physicsSearchCondition } : undefined;
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: { dataType: 'PHYSICS', staticScienceData: staticPhysicsData, scienceSearchCondition: physicsSearchCondition }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.physicsSearchCondition, result)) return;
            this.physicsSearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openChemistryCategoryDialog() {
    this.staticChemistryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticChemistryData => {
        const chemistrySearchCondition = this.chemistrySearchCondition ? { ...this.chemistrySearchCondition } : undefined;
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: { dataType: 'CHEMISTRY', staticScienceData: staticChemistryData, scienceSearchCondition: chemistrySearchCondition }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.chemistrySearchCondition, result)) return;
            this.chemistrySearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openBiologyCategoryDialog() {
    this.staticBiologyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticBiologyData => {
        const biologySearchCondition = this.biologySearchCondition ? { ...this.biologySearchCondition } : undefined;
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: { dataType: 'BIOLOGY', staticScienceData: staticBiologyData, scienceSearchCondition: biologySearchCondition }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.biologySearchCondition, result)) return;
            this.biologySearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openJapaneseHistoryCategoryDialog() {
    this.staticJapaneseHistoryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticJapaneseHistoryData => {
        const japaneseHistorySearchCondition = this.japaneseHistorySearchCondition ? { ...this.japaneseHistorySearchCondition } : undefined;
        const themeHistoryId = staticJapaneseHistoryData.fields.find(it => it.name === THEME_HISTORY).id;
        const dataWithoutThemeHistory: StaticHistoryData = {
          fields: staticJapaneseHistoryData.fields.filter(it => it.id !== themeHistoryId),
          subjects: staticJapaneseHistoryData.subjects.filter(it => it.id !== themeHistoryId),
          units: staticJapaneseHistoryData.units.filter(it => it.parentFieldId !== themeHistoryId)
        };
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: {
            dataType: 'JAPANESE_HISTORY',
            staticHistoryData: dataWithoutThemeHistory,
            historySearchCondition: japaneseHistorySearchCondition
          }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.japaneseHistorySearchCondition, result)) return;
            this.japaneseHistorySearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openWorldHistoryCategoryDialog() {
    this.staticWorldHistoryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticWorldHistoryData => {
        const worldHistorySearchCondition = this.worldHistorySearchCondition ? { ...this.worldHistorySearchCondition } : undefined;
        const themeHistoryId = staticWorldHistoryData.fields.find(it => it.name === THEME_HISTORY).id;
        const dataWithoutThemeHistory: StaticHistoryData = {
          fields: staticWorldHistoryData.fields.filter(it => it.id !== themeHistoryId),
          subjects: staticWorldHistoryData.subjects.filter(it => it.id !== themeHistoryId),
          units: staticWorldHistoryData.units.filter(it => it.parentFieldId !== themeHistoryId)
        };
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: {
            dataType: 'WORLD_HISTORY',
            staticHistoryData: dataWithoutThemeHistory,
            historySearchCondition: worldHistorySearchCondition
          }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.worldHistorySearchCondition, result)) return;
            this.worldHistorySearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openGeographyCategoryDialog() {
    this.staticGeographyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticGeographyData => {
        const geographySearchCondition = this.geographySearchCondition ? { ...this.geographySearchCondition } : undefined;
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: { dataType: 'GEOGRAPHY', staticScienceData: staticGeographyData, scienceSearchCondition: geographySearchCondition }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.geographySearchCondition, result)) return;
            this.geographySearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  openPoliticalEconomyCategoryDialog() {
    this.staticPoliticalEconomyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticPoliticalEconomyData => {
        const politicalEconomySearchCondition = this.politicalEconomySearchCondition
          ? { ...this.politicalEconomySearchCondition }
          : undefined;
        const config: MatDialogConfig<SelectCategoryDialogData> = {
          height: SELECT_CATEGORY_DIALOG_HEIGHT,
          width: SELECT_CATEGORY_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: {
            dataType: 'POLITICAL_ECONOMY',
            staticScienceData: staticPoliticalEconomyData,
            scienceSearchCondition: politicalEconomySearchCondition
          }
        };
        this.dialog
          .open(SelectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.politicalEconomySearchCondition, result)) return;
            this.politicalEconomySearchCondition = { ...result };
            this.findProblemCount();
          });
      });
  }

  // set ups ------------------------------------------------------

  private setUpSubjectsWithRestoreSearchConditionIfNeeded() {
    this.subjects$ = this.store.select(getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([
      this.subjects$,
      this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none')),
      this.store.select(getSearchCondition)
    ])
      .pipe(take(1))
      .subscribe(([subjects, signedInUser, searchCondition]) => {
        if (subjects.length > 0) {
          const _subjects = [];
          subjects.forEach(subject => {
            if (signedInUser.visibleSubjectIds.includes(subject.id)) {
              _subjects.push(subject);
            }
          });

          subjects = _subjects;
          this.subjects$ = of(subjects);
        }

        if (subjects.length === 0) {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: GeneralError.unknown(`subject が 0 件です`)
            })
          );
          return;
        }

        const targetSubjectId = searchCondition ? searchCondition.subjectId : signedInUser.subjectId;
        const foundSubject = subjects.find(it => it.id === targetSubjectId);
        this.selectedSubjectId = foundSubject ? foundSubject.id : subjects[0].id;

        if (searchCondition) setTimeout(() => this.restoreSearchCondition(searchCondition));
        else setTimeout(() => this.findProblemCount());
      });
  }

  private setUpObservables() {
    this.initialDataLoaded$ = this.store.select(StaticDataSelectors.getStaticDataLoaded);
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData);
    this.staticEnglishData$ = this.store.select(StaticDataSelectors.getStaticEnglishData);
    this.staticMathData$ = this.store.select(StaticDataSelectors.getStaticMathData);
    this.staticNationalLanguageData$ = this.store.select(StaticDataSelectors.getStaticNationalLanguageData);
    this.staticPhysicsData$ = this.store.select(StaticDataSelectors.getStaticPhysicsData);
    this.staticChemistryData$ = this.store.select(StaticDataSelectors.getStaticChemistryData);
    this.staticBiologyData$ = this.store.select(StaticDataSelectors.getStaticBiologyData);
    this.staticJapaneseHistoryData$ = this.store.select(StaticDataSelectors.getStaticJapaneseHistoryData);
    this.staticWorldHistoryData$ = this.store.select(StaticDataSelectors.getStaticWorldHistoryData);
    this.staticGeographyData$ = this.store.select(StaticDataSelectors.getStaticGeographyData);
    this.staticPoliticalEconomyData$ = this.store.select(StaticDataSelectors.getStaticPoliticalEconomyData);
    this.matchedProblemCount$ = this.store.select(getMatchedProblemCount).pipe(shareReplay(1));
    this.searchButtonDisabled$ = combineLatest([
      this.commonSearchFormInvalid$,
      this.englishSearchFormInvalid$,
      this.nationalLanguageSearchFormInvalid$,
      this.matchedProblemCount$
    ]).pipe(
      map(
        ([commonSearchFormInvalid, englishFormInvalid, nationalLanguageFormInvalid, matchedProblemCount]) =>
          commonSearchFormInvalid ||
          englishFormInvalid ||
          nationalLanguageFormInvalid ||
          matchedProblemCount == null ||
          matchedProblemCount === 0
      )
    );
    this.problemCountSubscription = this.matchedProblemCount$.subscribe(matchedProblemCount => {
      this.matchedProblemCount = matchedProblemCount;
    });
    this.problemCountSearching$ = this.store.select(getProblemCountSearching);
  }

  // utils ------------------------------------------------------

  private findProblemCount() {
    if (!this.commonSearchForm) {
      Log.warn(this.LOG_SOURCE, 'findProblemCount: commonSearchForm が null です');
      return;
    }
    if (!this.commonSearchForm.isValid()) {
      Log.warn(this.LOG_SOURCE, 'commonSearchForm が invalid です');
      return;
    }
    if (this.selectedSubjectId === SubjectId.ENGLISH) this.findEnglishProblemCount();
    if (this.selectedSubjectId === SubjectId.MATH) this.findMathProblemCount();
    if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) this.findNationalLanguageProblemCount();
    if (this.selectedSubjectId === SubjectId.PHYSICS) this.findPhysicsProblemCount();
    if (this.selectedSubjectId === SubjectId.CHEMISTRY) this.findChemistryProblemCount();
    if (this.selectedSubjectId === SubjectId.BIOLOGY) this.findBiologyProblemCount();
    if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) this.findJapaneseHistoryProblemCount();
    if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) this.findWorldHistoryProblemCount();
    if (this.selectedSubjectId === SubjectId.GEOGRAPHY) this.findGeographyProblemCount();
    if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) this.findPoliticalEconomyProblemCount();
  }

  private findEnglishProblemCount() {
    setTimeout(() => {
      if (!this.englishSearchForm) {
        Log.warn(this.LOG_SOURCE, 'findEnglishProblemCount: englishSearchForm が null です');
        return;
      }
      if (!this.englishSearchForm.isValid()) {
        Log.warn(this.LOG_SOURCE, 'englishSearchForm が invalid です');
        return;
      }
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const englishCondition = this.englishSearchForm.getCurrentCondition();
      const condition: SearchCondition<EnglishSearchCondition> = {
        ...commonCondition,
        subjectCondition: englishCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      this.store.dispatch(SearchActions.findEnglishProblemCount({ condition, logging: true }));
    });
  }

  private findMathProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const condition: SearchCondition<ScienceSearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (this.mathSearchCondition) {
        const selected = this.mathSearchCondition;
        if (
          (selected.subjectIds && selected.subjectIds.length !== 0) ||
          (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) ||
          (selected.categories && selected.categories.length) !== 0
        ) {
          const subjectCondition: ScienceSearchCondition = {};
          if (selected.subjectIds && selected.subjectIds.length !== 0) subjectCondition.subjectIds = [...selected.subjectIds];
          if (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...selected.subjectAndFieldIds];
          }
          if (selected.categories && selected.categories.length !== 0) subjectCondition.categories = [...selected.categories];
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findMathProblemCount({ condition, logging: true }));
    });
  }

  private findNationalLanguageProblemCount() {
    setTimeout(() => {
      if (!this.nationalLanguageSearchForm) {
        Log.warn(this.LOG_SOURCE, 'findNationalLanguageProblemCount: nationalLanguageSearchForm が null です');
        return;
      }
      if (!this.nationalLanguageSearchForm.isValid()) {
        Log.warn(this.LOG_SOURCE, 'nationalLanguageSearchForm が invalid です');
        return;
      }
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const nationalLanguageCondition = this.nationalLanguageSearchForm.getCurrentCondition();
      const condition: SearchCondition<NationalLanguageSearchCondition> = {
        ...commonCondition,
        subjectCondition: nationalLanguageCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      this.store.dispatch(SearchActions.findNationalLanguageProblemCount({ condition, logging: true }));
    });
  }

  private findPhysicsProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const condition: SearchCondition<ScienceSearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (this.physicsSearchCondition) {
        const selected = this.physicsSearchCondition;
        if (
          (selected.subjectIds && selected.subjectIds.length !== 0) ||
          (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) ||
          (selected.categories && selected.categories.length) !== 0
        ) {
          const subjectCondition: ScienceSearchCondition = {};
          if (selected.subjectIds && selected.subjectIds.length !== 0) subjectCondition.subjectIds = [...selected.subjectIds];
          if (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...selected.subjectAndFieldIds];
          }
          if (selected.categories && selected.categories.length !== 0) subjectCondition.categories = [...selected.categories];
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findPhysicsProblemCount({ condition, logging: true }));
    });
  }

  private findChemistryProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const condition: SearchCondition<ScienceSearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (this.chemistrySearchCondition) {
        const selected = this.chemistrySearchCondition;
        if (
          (selected.subjectIds && selected.subjectIds.length !== 0) ||
          (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) ||
          (selected.categories && selected.categories.length) !== 0
        ) {
          const subjectCondition: ScienceSearchCondition = {};
          if (selected.subjectIds && selected.subjectIds.length !== 0) subjectCondition.subjectIds = [...selected.subjectIds];
          if (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...selected.subjectAndFieldIds];
          }
          if (selected.categories && selected.categories.length !== 0) subjectCondition.categories = [...selected.categories];
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findChemistryProblemCount({ condition, logging: true }));
    });
  }

  private findBiologyProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const condition: SearchCondition<ScienceSearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (this.biologySearchCondition) {
        const selected = this.biologySearchCondition;
        if (
          (selected.subjectIds && selected.subjectIds.length !== 0) ||
          (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) ||
          (selected.categories && selected.categories.length) !== 0
        ) {
          const subjectCondition: ScienceSearchCondition = {};
          if (selected.subjectIds && selected.subjectIds.length !== 0) subjectCondition.subjectIds = [...selected.subjectIds];
          if (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...selected.subjectAndFieldIds];
          }
          if (selected.categories && selected.categories.length !== 0) subjectCondition.categories = [...selected.categories];
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findBiologyProblemCount({ condition, logging: true }));
    });
  }

  private findJapaneseHistoryProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const historyCondition = this.historySearchForm.getCurrentCondition();
      const condition: SearchCondition<HistorySearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (historyCondition) {
        if (
          (historyCondition.subjectIds && historyCondition.subjectIds.length !== 0) ||
          (historyCondition.subjectAndFieldIds && historyCondition.subjectAndFieldIds.length !== 0) ||
          (historyCondition.categories && historyCondition.categories.length !== 0) ||
          (historyCondition.keywords && historyCondition.keywords.length !== 0)
        ) {
          const subjectCondition: HistorySearchCondition = {};
          if (historyCondition.subjectIds && historyCondition.subjectIds.length !== 0) {
            subjectCondition.subjectIds = [...historyCondition.subjectIds];
          }
          if (historyCondition.subjectAndFieldIds && historyCondition.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...historyCondition.subjectAndFieldIds];
          }
          if (historyCondition.categories && historyCondition.categories.length !== 0) {
            subjectCondition.categories = [...historyCondition.categories];
          }
          if (historyCondition.keywords && historyCondition.keywords.length !== 0) {
            subjectCondition.keywords = [...historyCondition.keywords];
          }
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findJapaneseHistoryProblemCount({ condition, logging: true }));
    });
  }

  private findWorldHistoryProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const historyCondition = this.historySearchForm.getCurrentCondition();
      const condition: SearchCondition<HistorySearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (historyCondition) {
        if (
          (historyCondition.subjectIds && historyCondition.subjectIds.length !== 0) ||
          (historyCondition.subjectAndFieldIds && historyCondition.subjectAndFieldIds.length !== 0) ||
          (historyCondition.categories && historyCondition.categories.length !== 0) ||
          (historyCondition.keywords && historyCondition.keywords.length !== 0)
        ) {
          const subjectCondition: HistorySearchCondition = {};
          if (historyCondition.subjectIds && historyCondition.subjectIds.length !== 0) {
            subjectCondition.subjectIds = [...historyCondition.subjectIds];
          }
          if (historyCondition.subjectAndFieldIds && historyCondition.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...historyCondition.subjectAndFieldIds];
          }
          if (historyCondition.categories && historyCondition.categories.length !== 0) {
            subjectCondition.categories = [...historyCondition.categories];
          }
          if (historyCondition.keywords && historyCondition.keywords.length !== 0) {
            subjectCondition.keywords = [...historyCondition.keywords];
          }
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findWorldHistoryProblemCount({ condition, logging: true }));
    });
  }

  private findGeographyProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const condition: SearchCondition<ScienceSearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (this.geographySearchCondition) {
        const selected = this.geographySearchCondition;
        if (
          (selected.subjectIds && selected.subjectIds.length !== 0) ||
          (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) ||
          (selected.categories && selected.categories.length) !== 0
        ) {
          const subjectCondition: ScienceSearchCondition = {};
          if (selected.subjectIds && selected.subjectIds.length !== 0) subjectCondition.subjectIds = [...selected.subjectIds];
          if (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...selected.subjectAndFieldIds];
          }
          if (selected.categories && selected.categories.length !== 0) subjectCondition.categories = [...selected.categories];
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findGeographyProblemCount({ condition, logging: true }));
    });
  }

  private findPoliticalEconomyProblemCount() {
    setTimeout(() => {
      const commonCondition = this.commonSearchForm.getCurrentCondition();
      const condition: SearchCondition<ScienceSearchCondition> = {
        ...commonCondition
      };
      if (this.hasExternalData) condition.hasExternalData = true;
      if (this.hasWordData) condition.hasWordData = true;
      if (this.politicalEconomySearchCondition) {
        const selected = this.politicalEconomySearchCondition;
        if (
          (selected.subjectIds && selected.subjectIds.length !== 0) ||
          (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) ||
          (selected.categories && selected.categories.length) !== 0
        ) {
          const subjectCondition: ScienceSearchCondition = {};
          if (selected.subjectIds && selected.subjectIds.length !== 0) subjectCondition.subjectIds = [...selected.subjectIds];
          if (selected.subjectAndFieldIds && selected.subjectAndFieldIds.length !== 0) {
            subjectCondition.subjectAndFieldIds = [...selected.subjectAndFieldIds];
          }
          if (selected.categories && selected.categories.length !== 0) subjectCondition.categories = [...selected.categories];
          condition.subjectCondition = subjectCondition;
        }
      }
      this.store.dispatch(SearchActions.findPoliticalEconomyProblemCount({ condition, logging: true }));
    });
  }

  private resetCommonSearchForm() {
    if (!this.commonSearchForm) return;

    this.commonSearchForm.resetForms();
  }

  private resetEnglishSearchForm() {
    if (!this.englishSearchForm) return;

    this.englishSearchForm.resetForms();
  }

  private resetMathSearchForm() {
    this.mathSearchCondition = undefined;
  }

  private resetNationalLanguageSearchForm() {
    if (!this.nationalLanguageSearchForm) return;

    this.nationalLanguageSearchForm.resetForms();
  }

  private resetPhysicsSearchForm() {
    this.physicsSearchCondition = undefined;
  }

  private resetChemistrySearchForm() {
    this.chemistrySearchCondition = undefined;
  }

  private resetBiologySearchForm() {
    this.biologySearchCondition = undefined;
  }

  private resetJapaneseHistorySearchForm() {
    this.japaneseHistorySearchCondition = undefined;
    if (this.historySearchForm) this.historySearchForm.resetForms();
  }

  private resetWorldHistorySearchForm() {
    this.worldHistorySearchCondition = undefined;
    if (this.historySearchForm) this.historySearchForm.resetForms();
  }

  private resetGeographySearchForm() {
    this.geographySearchCondition = undefined;
  }

  private resetPoliticalEconomySearchForm() {
    this.politicalEconomySearchCondition = undefined;
  }

  private getCurrentSearchCondition(): SearchCondition<EnglishSearchCondition | ScienceSearchCondition | HistorySearchCondition> {
    if (!this.commonSearchForm) Log.warn(this.LOG_SOURCE, 'getCurrentSearchCondition: commonSearchForm が null です');

    const commonCondition = this.commonSearchForm.getCurrentCondition();
    const searchCondition: SearchCondition<EnglishSearchCondition | ScienceSearchCondition | HistorySearchCondition> = {
      ...commonCondition
    };

    if (this.hasExternalData) {
      searchCondition.hasExternalData = this.hasExternalData;
    }
    if (this.hasWordData) {
      searchCondition.hasWordData = this.hasWordData;
    }

    if (this.selectedSubjectId === SubjectId.ENGLISH) {
      const englishCondition = this.getEnglishSearchCondition();
      if (englishCondition) searchCondition.subjectCondition = englishCondition;
    }
    if (this.selectedSubjectId === SubjectId.MATH) {
      const mathCondition = this.getMathSearchCondition();
      if (mathCondition) searchCondition.subjectCondition = mathCondition;
    }
    if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) {
      const nationalLanguageCondition = this.getNationalLanguageSearchCondition();
      if (nationalLanguageCondition) searchCondition.subjectCondition = nationalLanguageCondition;
    }
    if (this.selectedSubjectId === SubjectId.PHYSICS) {
      const physicsCondition = this.getPhysicsSearchCondition();
      if (physicsCondition) searchCondition.subjectCondition = physicsCondition;
    }
    if (this.selectedSubjectId === SubjectId.CHEMISTRY) {
      const chemistryCondition = this.getChemistrySearchCondition();
      if (chemistryCondition) searchCondition.subjectCondition = chemistryCondition;
    }
    if (this.selectedSubjectId === SubjectId.BIOLOGY) {
      const biologyCondition = this.getBiologySearchCondition();
      if (biologyCondition) searchCondition.subjectCondition = biologyCondition;
    }
    if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) {
      const japaneseHistoryCondition = this.getJapaneseHistorySearchCondition();
      if (japaneseHistoryCondition) searchCondition.subjectCondition = japaneseHistoryCondition;
    }
    if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) {
      const worldHistoryCondition = this.getWorldHistorySearchCondition();
      if (worldHistoryCondition) searchCondition.subjectCondition = worldHistoryCondition;
    }
    if (this.selectedSubjectId === SubjectId.GEOGRAPHY) {
      const geographyCondition = this.getGeographySearchCondition();
      if (geographyCondition) searchCondition.subjectCondition = geographyCondition;
    }
    if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) {
      const politicalEconomyCondition = this.getPoliticalEconomySearchCondition();
      if (politicalEconomyCondition) searchCondition.subjectCondition = politicalEconomyCondition;
    }

    return searchCondition;
  }

  private getEnglishSearchCondition(): EnglishSearchCondition | undefined {
    if (!this.englishSearchForm) {
      Log.warn(this.LOG_SOURCE, 'getEnglishSearchCondition: englishSearchForm が null です');
      return undefined;
    }

    return this.englishSearchForm.getCurrentCondition();
  }

  private getMathSearchCondition(): ScienceSearchCondition | undefined {
    return this.mathSearchCondition;
  }

  private getNationalLanguageSearchCondition(): NationalLanguageSearchCondition | undefined {
    if (!this.nationalLanguageSearchForm) {
      Log.warn(this.LOG_SOURCE, 'getNationalLanguageSearchCondition: nationalLanguageSearchForm が null です');
      return undefined;
    }

    return this.nationalLanguageSearchForm.getCurrentCondition();
  }

  private getPhysicsSearchCondition(): ScienceSearchCondition | undefined {
    return this.physicsSearchCondition;
  }

  private getChemistrySearchCondition(): ScienceSearchCondition | undefined {
    return this.chemistrySearchCondition;
  }

  private getBiologySearchCondition(): ScienceSearchCondition | undefined {
    return this.biologySearchCondition;
  }

  private getJapaneseHistorySearchCondition(): HistorySearchCondition | undefined {
    if (!this.historySearchForm) {
      Log.warn(this.LOG_SOURCE, 'getJapaneseHistorySearchCondition: historySearchForm が null です');
      return undefined;
    }

    return this.historySearchForm.getCurrentCondition();
  }

  private getWorldHistorySearchCondition(): HistorySearchCondition | undefined {
    if (!this.historySearchForm) {
      Log.warn(this.LOG_SOURCE, 'getWorldHistorySearchCondition: historySearchForm が null です');
      return undefined;
    }

    return this.historySearchForm.getCurrentCondition();
  }

  private getGeographySearchCondition(): ScienceSearchCondition | undefined {
    return this.geographySearchCondition;
  }

  private getPoliticalEconomySearchCondition(): ScienceSearchCondition | undefined {
    return this.politicalEconomySearchCondition;
  }

  private restoreSearchCondition(condition: SearchCondition<EnglishSearchCondition | ScienceSearchCondition | HistorySearchCondition>) {
    Log.debug(this.LOG_SOURCE, `検索条件を復元します`, condition);
    if (!this.commonSearchForm) {
      Log.warn(this.LOG_SOURCE, 'restoreSearchCondition: commonSearchForm が null です');
      return;
    }

    if (condition.hasExternalData) {
      this.hasExternalData = condition.hasExternalData;
    }
    if (condition.hasWordData) {
      this.hasWordData = condition.hasWordData;
    }

    this.commonSearchForm.patchValue(condition);

    if (condition.subjectId === SubjectId.ENGLISH) {
      if (!this.englishSearchForm) {
        Log.warn(this.LOG_SOURCE, 'restoreSearchCondition: englishSearchForm が null です');
        return;
      }
      this.englishSearchForm.patchValue(condition.subjectCondition as EnglishSearchCondition);
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.MATH) {
      const subCondition = condition.subjectCondition as ScienceSearchCondition;
      this.mathSearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
      if (!this.nationalLanguageSearchForm) {
        Log.warn(this.LOG_SOURCE, 'restoreSearchCondition: nationalLanguageSearchForm が null です');
        return;
      }
      this.nationalLanguageSearchForm.patchValue(condition.subjectCondition as NationalLanguageSearchCondition);
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.PHYSICS) {
      const subCondition = condition.subjectCondition as ScienceSearchCondition;
      this.physicsSearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.CHEMISTRY) {
      const subCondition = condition.subjectCondition as ScienceSearchCondition;
      this.chemistrySearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.BIOLOGY) {
      const subCondition = condition.subjectCondition as ScienceSearchCondition;
      this.biologySearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.JAPANESE_HISTORY) {
      if (this.historySearchForm) {
        this.historySearchForm.patchValue(condition.subjectCondition as HistorySearchCondition);
      }
      const subCondition = condition.subjectCondition as HistorySearchCondition;
      this.japaneseHistorySearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.WORLD_HISTORY) {
      if (this.historySearchForm) {
        this.historySearchForm.patchValue(condition.subjectCondition as HistorySearchCondition);
      }
      const subCondition = condition.subjectCondition as HistorySearchCondition;
      this.worldHistorySearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.GEOGRAPHY) {
      const subCondition = condition.subjectCondition as ScienceSearchCondition;
      this.geographySearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    if (condition.subjectId === SubjectId.POLITICAL_ECONOMY) {
      const subCondition = condition.subjectCondition as ScienceSearchCondition;
      this.politicalEconomySearchCondition = subCondition ? { ...subCondition } : {};
      this.store.dispatch(SearchActions.initializeSearchCondition());
    }

    setTimeout(() => this.findProblemCount());
  }
}
