import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { navigate, setBrowserTitle, setTitle } from 'src/app/actions/core.actions';
import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as ToolsActions from 'src/app/actions/tools.actions';
import { DIALOG_ZERO_PADDING_PANEL_CLASS, PREFECTURES } from 'src/app/resources/config';
import * as ToolsSelectors from 'src/app/selectors/tools.selectors';
import {
  SingleInputDialogComponent,
  SingleInputDialogData,
  SingleInputDialogResultType
} from 'src/app/views/widgets/single-input-dialog/single-input-dialog.component';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { DataUtil } from '../../../utils/data-util';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

const YMD_REG_EXP = /[0-9]{4}-[0,1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}/;

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'テストツール';

  transferStaticDataProcessing$: Observable<boolean>;
  transferPlaylistsProcessing$: Observable<boolean>;
  commonIdTransferPlaylistsProcessing$: Observable<boolean>;

  rotateRawSearchEventGroupProcessing$: Observable<boolean>;
  exportSearchEventLogProcessing$: Observable<boolean>;
  aggregateDailySearchEventLogProcessing$: Observable<boolean>;
  aggregateWeeklySearchEventLogProcessing$: Observable<boolean>;
  cleanUpRawSearchEventGroupProcessing$: Observable<boolean>;

  rotateRawAdminOperationEventGroupProcessing$: Observable<boolean>;
  exportAdminOperationEventLogProcessing$: Observable<boolean>;
  aggregateDailyAdminOperationEventLogProcessing$: Observable<boolean>;
  aggregateMonthlyAdminOperationEventLogProcessing$: Observable<boolean>;
  cleanUpRawAdminOperationEventGroupProcessing$: Observable<boolean>;

  backupFirestoreProcessing$: Observable<boolean>;

  exportHighSchoolProcessing$: Observable<boolean>;

  checkMaxBookmarkCountProcessing$: Observable<boolean>;
  checkMaxUniversityBookmarkCountProcessing$: Observable<boolean>;
  checkPaperBookmarkMaxCountProcessing$: Observable<boolean>;
  checkAnsweredProblemsMaxCountProcessing$: Observable<boolean>;
  checkPlaylistBookmarkMaxCountProcessing$: Observable<boolean>;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.transferStaticDataProcessing$ = this.store.select(ToolsSelectors.getTransferStaticDataProcessing);
    this.transferPlaylistsProcessing$ = this.store.select(ToolsSelectors.getTransferPlaylistsProcessing);
    this.commonIdTransferPlaylistsProcessing$ = this.store.select(ToolsSelectors.getCommonIdTransferPlaylistsProcessing);

    this.rotateRawSearchEventGroupProcessing$ = this.store.select(ToolsSelectors.getRotateRawSearchEventGroupProcessing);
    this.exportSearchEventLogProcessing$ = this.store.select(ToolsSelectors.getExportSearchEventLogProcessing);
    this.aggregateDailySearchEventLogProcessing$ = this.store.select(ToolsSelectors.getAggregateDailySearchEventLogProcessing);
    this.aggregateWeeklySearchEventLogProcessing$ = this.store.select(ToolsSelectors.getAggregateWeeklySearchEventLogProcessing);
    this.cleanUpRawSearchEventGroupProcessing$ = this.store.select(ToolsSelectors.getCleanUpRawSearchEventGroupProcessing);

    this.rotateRawAdminOperationEventGroupProcessing$ = this.store.select(ToolsSelectors.getRotateRawAdminOperationEventGroupProcessing);
    this.exportAdminOperationEventLogProcessing$ = this.store.select(ToolsSelectors.getExportAdminOperationEventLogProcessing);
    this.aggregateDailyAdminOperationEventLogProcessing$ = this.store.select(
      ToolsSelectors.getAggregateDailyAdminOperationEventLogProcessing
    );
    this.aggregateMonthlyAdminOperationEventLogProcessing$ = this.store.select(
      ToolsSelectors.getAggregateMonthlyAdminOperationEventLogProcessing
    );
    this.cleanUpRawAdminOperationEventGroupProcessing$ = this.store.select(ToolsSelectors.getCleanUpRawAdminOperationEventGroupProcessing);

    this.backupFirestoreProcessing$ = this.store.select(ToolsSelectors.getBackupFirestoreProcessing);

    this.exportHighSchoolProcessing$ = of(false);

    this.checkMaxBookmarkCountProcessing$ = this.store.select(ToolsSelectors.getCheckBookmarkMaxCountProcessing);
    this.checkMaxUniversityBookmarkCountProcessing$ = this.store.select(ToolsSelectors.getCheckUniversityBookmarkMaxCountProcessing);
    this.checkPaperBookmarkMaxCountProcessing$ = this.store.select(ToolsSelectors.getCheckPaperBookmarkMaxCountProcessing);
    this.checkAnsweredProblemsMaxCountProcessing$ = this.store.select(ToolsSelectors.getCheckAnsweredProblemsMaxCountProcessing);
    this.checkPlaylistBookmarkMaxCountProcessing$ = this.store.select(ToolsSelectors.getCheckPlaylistBookmarkMaxCountProcessing);
  }

  transferStaticData() {
    Log.debug(this.LOG_SOURCE, 'transferStaticData');
    this.store.dispatch(ToolsActions.transferStaticData());
  }
  transferPlaylists() {
    Log.debug(this.LOG_SOURCE, 'transferPlaylists');
    this.store.dispatch(ToolsActions.transferPlaylists());
  }
  commonIdTransferPlaylists() {
    Log.debug(this.LOG_SOURCE, 'commonIdTransferPlaylists');
    this.store.dispatch(ToolsActions.commonIdTransferPlaylists());
  }

  rotateRawSearchEventGroup() {
    Log.debug(this.LOG_SOURCE, 'rotateRawSearchEventGroup');
    this.store.dispatch(ToolsActions.rotateRawSearchEventGroup());
  }

  exportSearchEventLog() {
    Log.debug(this.LOG_SOURCE, 'exportSearchEventLog');
    this.store.dispatch(ToolsActions.exportSearchEventLog());
  }

  aggregateDailySearchEventLog() {
    Log.debug(this.LOG_SOURCE, 'aggregateDailySearchEventLog');
    const config: MatDialogConfig<SingleInputDialogData> = {
      width: '400px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      restoreFocus: false,
      data: {
        title: '検索ログ daily 集計処理',
        description: 'daily 集計処理を実行する日付を入力してください',
        inputFieldPlaceholder: 'YYYY-MM-DD',
        inputValidationPattern: YMD_REG_EXP,
        patternErrorMessage: 'YYYY-MM-DD 形式で入力してください',
        positiveButtonLabel: '実行'
      }
    };
    this.dialog
      .open(SingleInputDialogComponent, config)
      .afterClosed()
      .subscribe((result: SingleInputDialogResultType) => {
        if (!result) return;
        Log.debug(this.LOG_SOURCE, `received value: ${result.inputValue}`);
        this.store.dispatch(ToolsActions.aggregateDailySearchEventLog({ ymd: result.inputValue }));
      });
  }

  aggregateWeeklySearchEventLog() {
    Log.debug(this.LOG_SOURCE, 'aggregateWeeklySearchEventLog');
    const config: MatDialogConfig<SingleInputDialogData> = {
      width: '400px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      restoreFocus: false,
      data: {
        title: '検索ログ weekly 集計処理',
        description: 'weekly 集計処理を実行する日付を入力してください',
        inputFieldPlaceholder: 'YYYY-MM-DD',
        inputValidationPattern: YMD_REG_EXP,
        patternErrorMessage: 'YYYY-MM-DD 形式で入力してください',
        positiveButtonLabel: '実行'
      }
    };
    this.dialog
      .open(SingleInputDialogComponent, config)
      .afterClosed()
      .subscribe((result: SingleInputDialogResultType) => {
        if (!result) return;
        Log.debug(this.LOG_SOURCE, `received value: ${result.inputValue}`);
        this.store.dispatch(ToolsActions.aggregateWeeklySearchEventLog({ ymd: result.inputValue }));
      });
  }

  cleanUpRawSearchEventGroup() {
    Log.debug(this.LOG_SOURCE, 'cleanUpRawSearchEventGroup');
    this.store.dispatch(ToolsActions.cleanUpRawSearchEventGroup());
  }

  rotateRawAdminOperationEventGroup() {
    Log.debug(this.LOG_SOURCE, 'rotateRawAdminOperationEventGroup');
    this.store.dispatch(ToolsActions.rotateRawAdminOperationEventGroup());
  }

  exportAdminOperationEventLog() {
    Log.debug(this.LOG_SOURCE, 'exportAdminOperationEventLog');
    this.store.dispatch(ToolsActions.exportAdminOperationEventLog());
  }

  aggregateDailyAdminOperationEventLog() {
    Log.debug(this.LOG_SOURCE, 'aggregateDailyAdminOperationEventLog');
    const config: MatDialogConfig<SingleInputDialogData> = {
      width: '400px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      restoreFocus: false,
      data: {
        title: '検索ログ daily 集計処理',
        description: 'daily 集計処理を実行する日付を入力してください',
        inputFieldPlaceholder: 'YYYY-MM-DD',
        inputValidationPattern: YMD_REG_EXP,
        patternErrorMessage: 'YYYY-MM-DD 形式で入力してください',
        positiveButtonLabel: '実行'
      }
    };
    this.dialog
      .open(SingleInputDialogComponent, config)
      .afterClosed()
      .subscribe((result: SingleInputDialogResultType) => {
        if (!result) return;
        Log.debug(this.LOG_SOURCE, `received value: ${result.inputValue}`);
        this.store.dispatch(ToolsActions.aggregateDailyAdminOperationEventLog({ ymd: result.inputValue }));
      });
  }

  aggregateMonthlyAdminOperationEventLog() {
    Log.debug(this.LOG_SOURCE, 'aggregateMonthlyAdminOperationEventLog');
    const config: MatDialogConfig<SingleInputDialogData> = {
      width: '400px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      restoreFocus: false,
      data: {
        title: '検索ログ monthly 集計処理',
        description: 'monthly 集計処理を実行する日付を入力してください',
        inputFieldPlaceholder: 'YYYY-MM-DD',
        inputValidationPattern: YMD_REG_EXP,
        patternErrorMessage: 'YYYY-MM-DD 形式で入力してください',
        positiveButtonLabel: '実行'
      }
    };
    this.dialog
      .open(SingleInputDialogComponent, config)
      .afterClosed()
      .subscribe((result: SingleInputDialogResultType) => {
        if (!result) return;
        Log.debug(this.LOG_SOURCE, `received value: ${result.inputValue}`);
        this.store.dispatch(ToolsActions.aggregateMonthlyAdminOperationEventLog({ ymd: result.inputValue }));
      });
  }

  cleanUpRawAdminOperationEventGroup() {
    Log.debug(this.LOG_SOURCE, 'cleanUpRawAdminOperationEventGroup');
    this.store.dispatch(ToolsActions.cleanUpRawAdminOperationEventGroup());
  }

  backupFirestore() {
    Log.debug(this.LOG_SOURCE, 'backupFirestore');
    this.store.dispatch(ToolsActions.backupFirestore());
  }

  exportHighSchool() {
    Log.debug(this.LOG_SOURCE, 'exportHighSchool');
    this.exportHighSchoolProcessing$ = of(true);

    const staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData);
    staticCommonData$.pipe(take(1)).subscribe(staticCommonData => {
      const highSchools = staticCommonData.highSchools;
      const aggregatedHighSchools = [];
      PREFECTURES.forEach(prefecture => {
        if (prefecture.id && prefecture.id !== '99') {
          const otherSchool = {
            school_id: '99999',
            school_name: 'その他の学校'
          };
          const highSchoolsByPrefecture = { todouhuken: prefecture.name, school: [otherSchool] };
          const filterdSchools = highSchools.filter(highSchool => {
            return highSchool.prefectureId === prefecture.id;
          });
          const sortedSchools = DataUtil.sortObjectArrayBySpecificKey(filterdSchools, 'name');
          sortedSchools.forEach(highSchool => {
            highSchoolsByPrefecture.school.push({
              school_id: highSchool.id,
              school_name: highSchool.name
            });
          });
          aggregatedHighSchools.push(highSchoolsByPrefecture);
        }
      });

      // 出力されたログをjsonファイルに保存する
      Log.info(this.LOG_SOURCE, JSON.stringify(aggregatedHighSchools));

      this.exportHighSchoolProcessing$ = of(false);
    });
  }

  checkMaxBookmarkCount() {
    Log.debug(this.LOG_SOURCE, 'checkMaxBookmarkCount');
    this.store.dispatch(ToolsActions.checkBookmarkMaxCount());
  }

  checkUniversityBookmarkCount() {
    Log.debug(this.LOG_SOURCE, 'checkUniversityBookmarkMaxCount');
    this.store.dispatch(ToolsActions.checkUniversityBookmarkMaxCount());
  }

  checkPaperBookmarkMaxCount() {
    Log.debug(this.LOG_SOURCE, 'checkPaperBookmarkMaxCount');
    this.store.dispatch(ToolsActions.checkPaperBookmarkMaxCount());
  }

  checkAnsweredProblemsMaxCount() {
    Log.debug(this.LOG_SOURCE, 'checkAnsweredProblemsMaxCount');
    this.store.dispatch(ToolsActions.checkAnsweredProblemsMaxCount());
  }

  checkPlaylistBookmarkMaxCount() {
    Log.debug(this.LOG_SOURCE, 'checkPlaylistBookmarkMaxCount');
    this.store.dispatch(ToolsActions.checkPlaylistBookmarkMaxCount());
  }

  deleteMembers() {
    Log.debug(this.LOG_SOURCE, 'deleteMembers');
    this.goToToolsDeleteMembers();
  }

  private goToToolsDeleteMembers() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveToolsDeleteMembers() }));
  }
}
