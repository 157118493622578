import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { navigate } from '../../../../actions/core.actions';
import { RootState } from '../../../../reducers';
import { inOut } from 'src/app/resources/animations';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, GA_EVENT_LABELS } from '../../../../resources/common-id/ga';
import { GAUtil } from '../../../../utils/ga-util';
import { Log } from 'src/app/utils/log';

export interface CommonIdDescriptionPremiumDialogData {
  parent: string;
}

@Component({
  selector: 'app-common-id-description-premium-dialog',
  templateUrl: './description-premium-dialog.component.html',
  styleUrls: ['./description-premium-dialog.component.scss'],
  animations: [inOut]
})
export class CommonIdDescriptionPremiumDialogComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private eventLabel: string;

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private dialogRef: MatDialogRef<CommonIdDescriptionPremiumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdDescriptionPremiumDialogData
  ) {}

  ngOnInit() {
    const univDetailPath = RoutingPathResolver.resolveCommonIdSearchUnivDetail('');
    const searchUnivPath = RoutingPathResolver.resolveCommonIdSearchUniv();
    const playlistPath = RoutingPathResolver.resolveCommonIdPlaylists();
    const searchByCategoriesDetailPath = `${RoutingPathResolver.resolveCommonIdSearchByCategories}/`;
    const searchByCategoriesPath = RoutingPathResolver.resolveCommonIdSearchByCategories();
    const mylistAnsweredProblemsPath = RoutingPathResolver.resolveCommonIdMylistAnswerProblems();
    this.eventLabel =
      this.router.url.indexOf(univDetailPath) !== -1
        ? GA_EVENT_LABELS.UNIV_DETAIL
        : this.router.url.indexOf(searchUnivPath) !== -1
        ? GA_EVENT_LABELS.SEARCH_UNIV
        : this.router.url.indexOf(playlistPath) !== -1
        ? this.getEventLabelFromPlaylistPath()
        : this.router.url.indexOf(searchByCategoriesDetailPath) !== -1
        ? GA_EVENT_LABELS.SEARCH_BY_CATEGORIES_DETAIL
        : this.router.url.indexOf(searchByCategoriesPath) !== -1
        ? GA_EVENT_LABELS.SEARCH_BY_CATEGORIES
        : this.router.url.indexOf(mylistAnsweredProblemsPath) !== -1
        ? GA_EVENT_LABELS.MYLIST_ANSWERED_PROBLEMS
        : '';

    Log.debug(this.LOG_SOURCE, `選択されたGAラベル: ${this.eventLabel}`);
  }

  ngOnDestroy() {}

  close() {
    this.dialogRef.close(false);
  }

  gotoTop() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.PREMIUM_DETAIL_VIEW,
      'event_label': this.eventLabel,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        const url = RoutingPathResolver.resolveCommonIdTop();
        this.store.dispatch(navigate({ url, extras: { queryParams: { to: 'premium' } } }));
      });
    this.dialogRef.close(false);
  }

  gotoPlan() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.PREMIUM_PLAN_VIEW,
      'event_label': this.eventLabel,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        const url = RoutingPathResolver.resolveCommonIdPurchase();
        this.store.dispatch(navigate({ url }));
      });
    this.dialogRef.close(false);
  }

  private getEventLabelFromPlaylistPath(): string {
    // 後方一致でチェックするため念のためクエリやフラグメントを削除する処理をいれておく
    const url = this.router.url.split('?')[0].split('#')[0];
    if (url.match(/\d{12}$/)) {
      return GA_EVENT_LABELS.PLAYLIST_PROBLEM_DETAIL; // 12桁の問題詳細IDがある場合
    } else if (url.match(/\d{7}$/)) {
      return GA_EVENT_LABELS.PLAYLIST_DETAIL; // 7桁のおすすめ問題IDがある場合
    } else {
      return GA_EVENT_LABELS.PLAYLISTS; // おすすめ問題IDも問題詳細IDもない場合
    }
  }
}
