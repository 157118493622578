import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { ActivatedRoute } from '@angular/router';
import { navigate, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { Log } from '../../../utils/log';
import { findPlan, findPlans, initializeFindPlansState, initializeFindPlanState } from '../../../actions/plan.actions';
import { getPlan, getPlans } from '../../../selectors/plan.selectors';
import { filter, shareReplay, take } from 'rxjs/operators';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Plan, PlanAssignment } from '../../../models/plan';
import { CurrentDateTime } from '../../../models/current-date-time';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { User } from '../../../models/user';
import { getUsers } from '../../../selectors/user.selectors';
import { HighSchool, Subject } from '../../../models/common-data';
import { getHighSchool, getSubject } from '../../../selectors/static-data.selectors';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../../actions/current-date-time.actions';
import { UserSearchCondition } from '../../../models/user-search-condition';
import { findUsers, initializeFindUsers } from '../../../actions/user.actions';
import { PlanUtil, PriorityRecord } from '../../../utils/plan-util';
import {
  PLAN_ASSIGN_MEMBER_DIALOG_HEIGHT,
  PLAN_ASSIGN_MEMBER_DIALOG_WIDTH,
  PLAN_UNASSIGN_MEMBER_DIALOG_HEIGHT,
  PLAN_UNASSIGN_MEMBER_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  PLAN_NAMES,
  PlanStatuses,
  PlanSubjectId,
  MEMBER_CONTACT_URL,
  SCHOOL_ORGANIZATION_ID,
  JUKU_ORGANIZATION_ID
} from '../../../resources/config';
import { SubjectUtil } from '../../../utils/subject-util';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { StaticDataUtil } from '../../../utils/static-data-util';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { enter } from '../../../resources/animations';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { UserUtil } from 'src/app/utils/user-util';
import {
  PlanAssignMemberDialogComponent,
  PlanAssignMemberDialogData
} from '../plan-assign-member-dialog/plan-assign-member-dialog.component';
import {
  PlanUnassignMemberDialogComponent,
  PlanUnassignMemberDialogData
} from '../plan-unassign-member-dialog/plan-unassign-member-dialog.component';
import { Juku } from 'src/app/models/juku';
import { getJukus } from 'src/app/selectors/juku.selectors';
import { JukuUtil } from 'src/app/utils/juku-util';
import { findJukus, initializeFindJukusState } from 'src/app/actions/juku.actions';

interface AssignmentMember {
  id: string;
  name: string;
}

interface AssignmentData {
  subjectId: string;
  subjectName: string;
  members: AssignmentMember[];
}

interface Breadcrumb {
  key: string;
  label: string;
}

@Component({
  selector: 'app-plan-assign',
  templateUrl: './plan-assign.component.html',
  styleUrls: ['./plan-assign.component.scss'],
  animations: [enter]
})
export class PlanAssignComponent implements OnInit, OnDestroy {
  isAdmin: boolean;
  isOrganizationAdmin: boolean;
  schoolName: string;
  plan$: Observable<Plan>;
  plan: Plan;
  plans$: Observable<Plan[]>;
  plans: Plan[];
  currentDatetime: CurrentDateTime;
  assignments: AssignmentData[];
  priorityRecord: PriorityRecord = null;
  memberNum: number;
  memberCount: number;
  maxAssignableNum = 0;
  planSubscription: Subscription;
  planName: string;
  isAllPlan: boolean;
  subjects$: Observable<Subject[]>;
  planActiveStatus: string = PlanStatuses.ACTIVE;
  planEndedStatus: string = PlanStatuses.ENDED;
  planReserveStatus: string = PlanStatuses.RESERVE;
  isDataLoading$: Observable<boolean>;
  users: User[];
  isNoAssignableMember = false;
  isMaxMember = false;
  isEndPlan = false;
  memberContactUrl: string = MEMBER_CONTACT_URL;
  breadcrumbs: Breadcrumb[] = [];

  displayedColumns: string[] = ['planStatus', 'validityPeriod', 'memberCount', 'memberList', 'action'];

  private LOG_SOURCE = this.constructor.name;
  private title = 'プランメンバー割当';

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeFindUsers());
    this.planSubscription.unsubscribe();
    this.store.dispatch(initializeFindPlanState());
    this.store.dispatch(initializeFindPlansState());
    this.store.dispatch(initializeGetCurrentDateTime());
    this.store.dispatch(initializeFindJukusState());
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
      case 'admin/schools':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSchools() }));
        break;
      case 'admin/plans':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(this.plan.organization, this.plan.schoolId) }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  private setUp() {
    const planId: string = this.activatedRoute.snapshot.paramMap.get('planId');
    Log.debug(this.LOG_SOURCE, `query params: `, planId);
    this.isDataLoading$ = of(true);

    this.plan$ = this.store.select(getPlan).pipe(
      filter<Plan>(it => it != null),
      shareReplay(1)
    );

    this.store.dispatch(findPlan({ planId }));

    const signedInUser$: Observable<User> = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      shareReplay(1)
    );

    this.planSubscription = combineLatest([this.plan$, signedInUser$]).subscribe(([plan, signedInUser]) => {
      if (!plan.id) {
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
        return;
      }

      this.isAdmin = signedInUser.isAdmin;
      this.isOrganizationAdmin = signedInUser.isOrganizationAdmin;

      if (!this.isAdmin) this.displayedColumns = this.displayedColumns.filter(column => column !== 'action');

      if (!this.isAdmin && (plan.schoolId !== signedInUser.schoolId || plan.organization !== signedInUser.organization)) {
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
        return;
      }
      if (plan.subjectId === PlanSubjectId.TRIAL && this.isOrganizationAdmin) {
        this.goOrganizationAdminPlans();
      }
      this.plan = plan;
      this.planName = PLAN_NAMES[plan.subjectId];
      this.isAllPlan = plan.subjectId === PlanSubjectId.ALL;

      this.setUpData();
    });
  }

  private setUpData() {
    this.store.dispatch(findPlans({ organization: this.plan.organization, schoolId: this.plan.schoolId }));

    const currentDateTime$: Observable<CurrentDateTime> = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const users$: Observable<User[]> = this.store.select(getUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const plans$: Observable<Plan[]> = this.store.select(getPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const highSchools$: Observable<HighSchool[]> = this.store.select(getHighSchool).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const jukus$: Observable<Juku[]> = this.store.select(getJukus).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const subjects$ = this.store.select(getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([currentDateTime$, users$, plans$, highSchools$, jukus$, subjects$])
      .pipe(take(1))
      .subscribe(([currentDateTime, users, plans, highSchools, jukus, subjects]) => {
        this.users = users;
        this.plans = plans;

        if (this.plan.organization === SCHOOL_ORGANIZATION_ID) {
          this.schoolName = StaticDataUtil.getHiSchoolName(highSchools, this.plan.schoolId);
        } else if (this.plan.organization === JUKU_ORGANIZATION_ID) {
          const juku: Juku = JukuUtil.getJukuByJukuCode(jukus, this.plan.schoolId);
          if (juku !== undefined && juku !== null) {
            this.schoolName = JukuUtil.getDisplayJukuName(juku);
          }
        }

        if (this.schoolName === '') {
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        }

        this.breadcrumbs = [];
        this.breadcrumbs.push({ key: 'admin', label: 'Admin機能' });

        if (this.plan.organization === SCHOOL_ORGANIZATION_ID) {
          this.breadcrumbs.push({ key: 'admin/schools', label: 'アカウント管理 - 学校' });
        } else if (this.plan.organization === JUKU_ORGANIZATION_ID) {
          this.breadcrumbs.push({ key: 'admin/jukus', label: 'アカウント管理 - 塾・予備校' });
        }

        this.breadcrumbs.push({ key: 'admin/plans', label: this.schoolName + ' - プラン一覧' });
        this.breadcrumbs.push({ key: '', label: this.planName });

        this.currentDatetime = currentDateTime;
        this.priorityRecord = PlanUtil.getPriorityRecord(this.plan.records, currentDateTime);
        const planSubjectIds = Object.keys(PlanSubjectId).map(key => PlanSubjectId[key]);
        this.assignments = planSubjectIds
          .map(planSubjectId => {
            let assignment: PlanAssignment = this.priorityRecord.record.assignment.find(
              _assignment => _assignment.subjectId === planSubjectId
            );
            assignment = assignment
              ? assignment
              : this.isAllPlan && planSubjectId !== PlanSubjectId.TRIAL && planSubjectId !== PlanSubjectId.ALL
              ? { subjectId: planSubjectId, memberIds: [] }
              : null;
            return assignment ? this.setUpAssignment(assignment, users, subjects) : null;
          })
          .filter(it => it !== null);
        this.memberNum = PlanUtil.getAssignmentMemberCount(this.priorityRecord.record);
        this.memberCount = this.priorityRecord.record.memberCount;

        //// メッセージフラグ
        if (this.memberCount - this.memberNum < 1) {
          // メンバーが上限に達しこれ以上登録できない。上限に達し「+」が表示されない場合
          this.isMaxMember = true;
        } else {
          this.isMaxMember = false;
          // 割当枠に空きがあるが、メンバーがいない場合
          // アサイン可能チェック
          const assignableUsers = users.filter(user => PlanUtil.isAssignableUsers(this.plan, user, plans, currentDateTime));
          this.isNoAssignableMember = assignableUsers.length < 1;
          // 登録できるメンバーの上限数
          this.maxAssignableNum = this.memberCount - this.memberNum;
        }

        this.isEndPlan = this.priorityRecord.status === PlanStatuses.ENDED;

        this.isDataLoading$ = of(false);
      });

    this.store.dispatch(getCurrentDateTime());
    const userSearchCondition: UserSearchCondition = {
      organization: this.plan.organization,
      schoolId: this.plan.schoolId
    };
    this.store.dispatch(findUsers({ userSearchCondition }));
    this.store.dispatch(findJukus({}));
  }

  private setUpAssignment(assignment: PlanAssignment, users: User[], subjects: Subject[]): AssignmentData {
    const members: AssignmentMember[] = [];

    for (const memberId of assignment.memberIds) {
      members.push({ id: memberId, name: UserUtil.getUserName(users, memberId) });
    }

    return {
      subjectId: assignment.subjectId,
      subjectName: this.isAllPlan ? SubjectUtil.getName(subjects, assignment.subjectId) : null,
      members
    };
  }

  menuClickHandler(key) {
    switch (key) {
      case 'fact_check_black':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlanStatuses(this.plan.id) }));
        break;
    }
  }

  goMembers() {
    if (this.isAdmin) {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveMembers(this.plan.organization, this.plan.schoolId) }));
    } else {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveMembers() }));
    }
  }

  goOrganizationAdminPlans() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(null, null) }));
  }

  goHelp() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveHelp() }));
  }

  assignMember(subjectId: string) {
    const config: MatDialogConfig<PlanAssignMemberDialogData> = {
      height: PLAN_ASSIGN_MEMBER_DIALOG_HEIGHT,
      width: PLAN_ASSIGN_MEMBER_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: {
        isAdmin: this.isAdmin,
        plan: this.plan,
        subjectId,
        plans: this.plans,
        currentDatetime: this.currentDatetime,
        maxAssignableNum: this.maxAssignableNum
      },
      disableClose: true
    };
    this.dialog.open(PlanAssignMemberDialogComponent, config);
    this.dialog.afterAllClosed.pipe(take(1)).subscribe(() => {
      this.store.dispatch(initializeFindUsers());
      this.store.dispatch(initializeFindPlansState());
      this.store.dispatch(initializeGetCurrentDateTime());
      this.store.dispatch(findPlan({ planId: this.plan.id }));
    });
  }

  unAssignMember(subjectId: string, memberId: string) {
    const config: MatDialogConfig<PlanUnassignMemberDialogData> = {
      height: PLAN_UNASSIGN_MEMBER_DIALOG_HEIGHT,
      width: PLAN_UNASSIGN_MEMBER_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: {
        planId: this.plan.id,
        subjectId,
        user: this.users.find(user => user.id === memberId)
      },
      disableClose: true
    };
    this.dialog.open(PlanUnassignMemberDialogComponent, config);
    this.dialog.afterAllClosed.pipe(take(1)).subscribe(() => {
      this.store.dispatch(initializeFindUsers());
      this.store.dispatch(initializeFindPlansState());
      this.store.dispatch(initializeGetCurrentDateTime());
      this.store.dispatch(findPlan({ planId: this.plan.id }));
    });
  }
}
