import { AfterViewInit, Component, Renderer2, OnDestroy, OnInit, Inject, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { navigate, setTitle } from '../../../../actions/core.actions';
import { Meta } from '@angular/platform-browser';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { DIALOG_ZERO_PADDING_PANEL_CLASS, META_VIEWPORT_LP } from '../../../../resources/config';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { ActivatedRoute } from '@angular/router';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonIdGotoManabiIdDialogComponent } from '../goto-manabi-id-dialog/goto-manabi-id-dialog.component';
import { COMMON_ID_FAQS, CommonIdFaq } from '../../../../resources/common-id/faqs';
import { Subscription } from 'rxjs';
import { getCookie } from 'typescript-cookie';
import { COOKIE_NAME_SSC_ID } from '../../../../resources/common-id-config';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class CommonIdTopComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = '大学入試過去問が見つかる';
  private scrolled = 0;
  private scrollTo: string;
  private fragmentSubscription: Subscription;

  faqs: CommonIdFaq[];
  category1Faqs: CommonIdFaq[];
  category2Faqs: CommonIdFaq[];
  category3Faqs: CommonIdFaq[];

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private viewportScroller: ViewportScroller,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW_OBJECT) private window: Window,
    private meta: Meta
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    const currentPos: number = e.target.scrollingElement.scrollTop;
    this.scrolled = currentPos;
  }

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_LP);
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.renderer.addClass(this.document.body, 'page-top');

    const sscId = getCookie(COOKIE_NAME_SSC_ID) || '';
    if (sscId) {
      gtag('set', 'user_properties', { ssc_id: sscId });
    }

    this.faqs = JSON.parse(JSON.stringify(COMMON_ID_FAQS.filter(faq => faq.lpFlag)));
    this.faqs.forEach((faq, index) => {
      this.faqs[index].body = faq.body.replace(/data-href="#faq-(\d+)"/g, 'href="/c#faq-$1"');
    });
    this.category1Faqs = this.faqs.filter(faq => faq.categoryId === 1);
    this.category2Faqs = this.faqs.filter(faq => faq.categoryId === 2);
    this.category3Faqs = this.faqs.filter(faq => faq.categoryId === 3);
  }

  ngAfterViewInit() {
    if (this.activatedRoute.snapshot.queryParams.to) {
      const scrollTo = this.activatedRoute.snapshot.queryParams.to;
      setTimeout(() => this.anchorScrollingHandler(scrollTo, 70), 500);
    } else {
      this.fragmentSubscription = this.activatedRoute.fragment.subscribe(fragment => {
        if (fragment) {
          const targetFaq = this.faqs.find(faq => faq.id === fragment);
          if (targetFaq) {
            targetFaq.isExpanded = true;
            setTimeout(() => this.anchorScrollingHandler(fragment, 70), 500);
          }
        } else {
          setTimeout(() => window.scrollTo(0, 0));
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }

  getParallaxPositionY(layer: number): string {
    const pos: number = this.scrolled / (layer * 50);
    return pos + 'vw';
  }

  openManabiModal() {
    const config: MatDialogConfig = {
      width: '500px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      disableClose: true
    };
    this.dialog.open(CommonIdGotoManabiIdDialogComponent, config);
  }

  gotoPurchase() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPurchase() }));
  }

  gotoFaq(id: string) {
    const targetFaq = this.faqs.find(faq => faq.id === id);
    targetFaq.isExpanded = true;
    this.anchorScrollingHandler(id, 70);
  }

  anchorScrollingHandler(id: string, buffer: number = 0) {
    const targetElement = this.document.getElementById(id);
    if (targetElement) {
      const rectTop = targetElement.getBoundingClientRect().top;
      const offsetTop = window.pageYOffset;
      const top = rectTop + offsetTop - buffer;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  }
}
