import { CurrentDateTime } from './../../../../models/current-date-time';
import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, filter, shareReplay, startWith, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { dispatchAppError, setBrowserTitle, setTitle } from 'src/app/actions/core.actions';
import {
  ScienceProblem,
  ReadableScienceProblem,
  EnglishProblem,
  ReadableEnglishProblem,
  NationalLanguageProblem,
  ReadableNationalLanguageProblem,
  HistoryProblem,
  ReadableHistoryProblem,
  Problem
} from 'src/app/models/problem';
import { GeneralError } from 'src/app/errors/general-error';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import {
  findEnglishProblemByPaperId,
  findScienceProblemByPaperId,
  findNationalLanguageProblemByPaperId,
  findHistoryProblemByPaperId
} from 'src/app/actions/search.actions';
import { getFetchedDate, getStaticCommonData } from 'src/app/selectors/static-data.selectors';
import {
  getScienceProblem,
  getEnglishProblem,
  getNationalLanguageProblem,
  getHistoryProblem,
  getReadableMathProblem,
  getProblemSearching,
  getReadablePhysicsProblem,
  getReadableChemistryProblem,
  getReadableBiologyProblem,
  getReadableEnglishProblem,
  getReadableNationalLanguageProblem,
  getReadableJapaneseHistoryProblem,
  getReadableWorldHistoryProblem
} from 'src/app/selectors/search.selectors';
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { fadeInOut, showHide } from 'src/app/resources/animations';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { SubjectId, STATIC_DATA_CACHE_DAYS, META_VIEWPORT_OTHER, SCIENCE_IDS } from 'src/app/resources/config';
import { Subject } from '../../../../models/common-data';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';

import { StaticCommonData } from '../../../../models/static-common-data';
import * as SearchActions from '../../../../actions/search.actions';
import { GAUtil } from '../../../../utils/ga-util';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from '../../../../resources/common-id/ga';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';

@Component({
  selector: 'app-common-id-problem-detail',
  templateUrl: './problem-detail.component.html',
  styleUrls: ['./problem-detail.component.scss'],
  animations: [fadeInOut, showHide]
})
export class TryProblemDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  paperId$: Observable<string>;
  daimonId$: Observable<string>;
  private problemSearching$: Observable<boolean>;
  scienceIds: string[] = SCIENCE_IDS;

  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticEnglishData$: Observable<StaticEnglishData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;

  /** science-problems.component をそのまま利用するため array で定義 */
  readableScienceProblems$: Observable<ReadableScienceProblem[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;

  problem$: Observable<Problem>;
  scienceProblem$: Observable<ScienceProblem>;
  englishProblem$: Observable<EnglishProblem>;
  nationalLanguageProblem$: Observable<NationalLanguageProblem>;
  historyProblem$: Observable<HistoryProblem>;
  searching$: Observable<boolean>;
  isPreconditionError = false;
  paperMode$: Observable<boolean>;
  trialMode$: Observable<boolean>;
  canDisplay$: Observable<boolean>;
  showPaperBookmarkBtn = true;
  currentDateTime$: Observable<CurrentDateTime>;
  subjectId$: Observable<string>;
  menuType$: Observable<'article' | 'spellcheck'> = of('article');

  toolbarTitle = '問題詳細';

  universityId$: Observable<string>;
  universityName$: Observable<string>;
  year$: Observable<string>;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.toolbarTitle }));
    this.setUpPaperId();
    this.setUpDaimonId();
    this.setUpYear();
    this.setUpCanDisplay();
    this.setUpStaticData();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch(SearchActions.initializeProblemState());
  }

  private setUpPaperId() {
    this.paperId$ = this.activatedRoute.queryParamMap.pipe(
      map(paramMap => paramMap.get('paperId') || ''),
      shareReplay(1)
    );
    this.subscriptions.push(
      this.paperId$.subscribe(paperId => {
        this.isPreconditionError = paperId === '';
        if (!paperId) {
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
        }
      })
    );
  }

  private setUpDaimonId() {
    this.daimonId$ = this.activatedRoute.queryParamMap.pipe(
      map(paramMap => paramMap.get('daimon_Id') || ''),
      shareReplay(1)
    );
    this.subscriptions.push(
      this.daimonId$.subscribe(daimonId => {
        this.isPreconditionError = daimonId === '';
        if (!daimonId) {
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
        }
      })
    );
  }

  private setUpYear() {
    this.year$ = this.paperId$.pipe(
      take(1),
      map(paperId => '20' + paperId.slice(0, 2))
    );
  }

  private setUpCanDisplay() {
    this.canDisplay$ = of(true);
  }

  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.store
      .select(getStaticCommonData)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticCommonData => {
        this.setUpTrialMode();
        this.setUpNavigationAndPaperMode();
        this.setUpUnivId();
        this.setUpUnivName(staticCommonData);
        this.setUpSubjectId();
        this.setUpProblem();
        this.setUpReadableProblem();
        this.setUpSearching();
        this.setUpBrowserTitle();
      });
  }

  private setUpTrialMode() {
    this.trialMode$ = of(false);
  }

  private setUpNavigationAndPaperMode() {
    this.paperMode$ = of(false);
  }

  private setUpUnivId() {
    this.universityId$ = this.paperId$.pipe(
      take(1),
      map(paperId => 'D' + paperId.slice(2, 6))
    );
  }

  private setUpUnivName(staticCommonData: StaticCommonData) {
    this.universityName$ = this.paperId$.pipe(
      take(1),
      map(paperId => staticCommonData.universities.find(staticData => staticData.id === 'D' + paperId.slice(2, 6)).name)
    );
  }

  private setUpSubjectId() {
    this.subjectId$ = this.paperId$.pipe(
      take(1),
      map(paperId => paperId.slice(6, 8))
    );
  }

  private setUpProblem() {
    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        if (this.scienceIds.includes(subjectId)) {
          this.scienceProblem$ = this.store.select(getScienceProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.scienceProblem$.pipe(problem => problem);
        } else if (subjectId === SubjectId.ENGLISH) {
          this.englishProblem$ = this.store.select(getEnglishProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.englishProblem$.pipe(problem => problem);
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.nationalLanguageProblem$ = this.store.select(getNationalLanguageProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.nationalLanguageProblem$.pipe(map(problems => problems));
        } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
          this.historyProblem$ = this.store.select(getHistoryProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.historyProblem$.pipe(problem => problem);
        }
      })
    );

    this.subscriptions.push(
      combineLatest([this.subjectId$, this.paperId$.pipe(filter(it => it !== ''))]).subscribe(([subjectId, paperId]) => {
        this.isPreconditionError = false;
        if (this.scienceIds.includes(subjectId)) {
          this.store.dispatch(findScienceProblemByPaperId({ subjectId, paperId }));
        } else if (subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(findEnglishProblemByPaperId({ subjectId, paperId }));
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(findNationalLanguageProblemByPaperId({ subjectId, paperId }));
        } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(findHistoryProblemByPaperId({ subjectId, paperId }));
        }
      })
    );
  }

  private setUpReadableProblem() {
    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        if (this.scienceIds.includes(subjectId)) {
          this.readableScienceProblems$ = combineLatest([this.scienceProblem$]).pipe(
            switchMap(([problem]) => {
              const selector =
                subjectId === SubjectId.MATH
                  ? this.store.select(getReadableMathProblem(problem))
                  : subjectId === SubjectId.PHYSICS
                  ? this.store.select(getReadablePhysicsProblem(problem))
                  : subjectId === SubjectId.CHEMISTRY
                  ? this.store.select(getReadableChemistryProblem(problem))
                  : this.store.select(getReadableBiologyProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.ENGLISH) {
          this.readableEnglishProblems$ = combineLatest([this.englishProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableEnglishProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.readableNationalLanguageProblems$ = combineLatest([this.nationalLanguageProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableNationalLanguageProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          this.readableJapaneseHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableJapaneseHistoryProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          this.readableWorldHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableWorldHistoryProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        }
      })
    );
  }

  private setUpSearching() {
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemSearching$]).pipe(map(([problemSearching]) => problemSearching));
  }

  private setUpBrowserTitle(menuType: string = 'article') {
    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        let readableProblems$: Observable<
          ReadableScienceProblem[] | ReadableEnglishProblem[] | ReadableNationalLanguageProblem[] | ReadableHistoryProblem[]
        >;
        if (this.scienceIds.includes(subjectId)) {
          readableProblems$ = this.readableScienceProblems$;
        } else if (subjectId === SubjectId.ENGLISH) {
          readableProblems$ = this.readableEnglishProblems$;
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          readableProblems$ = this.readableNationalLanguageProblems$;
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          readableProblems$ = this.readableJapaneseHistoryProblems$;
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          readableProblems$ = this.readableWorldHistoryProblems$;
        }

        if (readableProblems$) {
          this.subscriptions.push(
            combineLatest([subjects$, readableProblems$.pipe(filter(it => it !== null))]).subscribe(([subjects, problems]) => {
              if (!problems[0]) return;

              const university: string = problems[0].university;
              const year: number = problems[0].year;
              const subject: string = SubjectUtil.getName(subjects, subjectId);
              const type = menuType === 'article' ? '問題' : '研究・解答';
              const title = `${university}　${year}年度　${subject}　${type}`;
              this.store.dispatch(setCommonIdBrowserTitle({ subTitle: `${title}`, problemDetailFlag: true }));
              setTimeout(() => {
                this.store.dispatch(setTitle({ title }));
              });
            })
          );
        }
      })
    );
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  changeMenuType(event) {
    this.menuType$ = of(event);
    combineLatest([this.menuType$, this.paperId$])
      .pipe(take(1))
      .subscribe(([menuType, paperId]) => {
        const eventParams = {
          'event_category': menuType === 'article' ? GA_EVENT_CATEGORIES.PROBLEM_TAB : GA_EVENT_CATEGORIES.ANSWER_TAB,
          'event_label': paperId,
          'value': 1
        };
        GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
        this.setUpBrowserTitle(menuType);
      });
  }
}
