import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { EnglishLongSentenceType, EnglishField, EnglishSubType } from 'src/app/models/english-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { Log } from 'src/app/utils/log';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { Diff } from 'src/app/utils/diff';
import {
  KEYBOARD_INPUT_DEBOUNCE_TIME,
  ENGLISH_LOWEST_WORD_COUNT,
  ENGLISH_LOWEST_WORD_COUNT_DISPLAY_NAME,
  ENGLISH_HIGHEST_WORD_COUNT,
  ENGLISH_HIGHEST_WORD_COUNT_DISPLAY_NAME
} from 'src/app/resources/config';

interface LongSentenceTypeData extends EnglishLongSentenceType {
  checked: boolean;
}

interface FieldData extends EnglishField {
  checked: boolean;
}

interface SubTypeData extends EnglishSubType {
  checked: boolean;
}

interface SelectableWordCount {
  count: number;
  displayName: string;
}

const VALIDATION_INVALID_VALUE_ERROR_KEY = 'invalid-value';
const VALIDATION_INVALID_VALUE_ERROR = { 'invalid-value': true };

@Component({
  selector: 'app-english-search-form',
  templateUrl: './english-search-form.component.html',
  styleUrls: ['./english-search-form.component.scss']
})
export class EnglishSearchFormComponent implements OnInit, OnDestroy {
  @Input() staticEnglishData: StaticEnglishData;

  @Output() changeCondition = new EventEmitter<void>();
  @Output() formIsValid = new EventEmitter<boolean>();

  private LOG_SOURCE = this.constructor.name;
  private lastNotifiedCondition: EnglishSearchCondition | undefined;
  private keyWordSubscription: Subscription;
  private defaultMinWordCount: SelectableWordCount;
  private defaultMaxWordCount: SelectableWordCount;
  private lowestWordCount: SelectableWordCount;
  private highestWordCount: SelectableWordCount;

  /**
   * resetForms した際に、keyword の input が空文字で初期化された直後に、
   * change event を発火してしまうため、それを回避するために使用する flag
   */
  private needToIgnoreChangeEvent = false;

  longSentenceTypes: LongSentenceTypeData[];
  lowWordCounts: SelectableWordCount[];
  highWordCounts: SelectableWordCount[];
  fields: FieldData[];
  subTypes: SubTypeData[];

  minWordCountFormControl = new FormControl();
  maxWordCountFormControl = new FormControl();
  keywordFormControl = new FormControl('');

  constructor() {}

  ngOnInit() {
    this.setUpLongSentenceTypes();
    this.setUpLongSentenceWordCount();
    this.setUpFields();
    this.setUpSubTypes();
    this.setUpKeywordChange();
  }

  ngOnDestroy() {
    if (this.keyWordSubscription) this.keyWordSubscription.unsubscribe();
  }

  // exposed methods --------------------------------------------------------------

  isValid(): boolean {
    return !this.maxWordCountFormControl.hasError(VALIDATION_INVALID_VALUE_ERROR_KEY);
  }

  getCurrentCondition(): EnglishSearchCondition {
    return this.collectCondition();
  }

  resetForms() {
    this.needToIgnoreChangeEvent = true;
    this.longSentenceTypes.forEach(longSentenceType => (longSentenceType.checked = false));
    this.fields.forEach(field => (field.checked = false));
    this.subTypes.forEach(subType => (subType.checked = false));
    this.minWordCountFormControl.setValue(this.defaultMinWordCount);
    this.maxWordCountFormControl.setValue(this.defaultMaxWordCount);
    this.keywordFormControl.setValue('');
    this.lastNotifiedCondition = undefined;
  }

  patchValue(condition: EnglishSearchCondition) {
    Log.debug(this.LOG_SOURCE, '値を復元します', condition);
    if (condition.fieldIds && condition.fieldIds.length !== 0) {
      this.fields.forEach(field => {
        if (condition.fieldIds.includes(field.id)) field.checked = true;
      });
    }
    if (condition.subTypeIds && condition.subTypeIds.length !== 0) {
      this.subTypes.forEach(subType => {
        if (condition.subTypeIds.includes(subType.id)) subType.checked = true;
      });
    }
    if (condition.longSentenceTypeIds && condition.longSentenceTypeIds.length !== 0) {
      this.longSentenceTypes.forEach(longSentenceType => {
        if (condition.longSentenceTypeIds.includes(longSentenceType.id)) longSentenceType.checked = true;
      });
    }
    if (condition.longSentenceWordCount) {
      const raw = condition.longSentenceWordCount;
      const min = this.lowWordCounts.find(it => it.count === raw.min);
      const max = this.highWordCounts.find(it => it.count === raw.max);
      this.minWordCountFormControl.setValue(min != null ? min : this.defaultMinWordCount);
      this.maxWordCountFormControl.setValue(max != null ? max : this.defaultMaxWordCount);
    }
    if (condition.longSentenceKeywords && condition.longSentenceKeywords.length !== 0) {
      this.keywordFormControl.setValue(condition.longSentenceKeywords.join(' '));
    }

    const currentCondition = this.collectCondition();
    this.lastNotifiedCondition = { ...currentCondition };
  }

  // components internal methods ---------------------------------------------------------------

  onChangeValues() {
    const minWordCount: SelectableWordCount = this.minWordCountFormControl.value;
    const maxWordCount: SelectableWordCount = this.maxWordCountFormControl.value;

    if (minWordCount !== this.defaultMinWordCount && maxWordCount !== this.defaultMaxWordCount && minWordCount.count > maxWordCount.count) {
      Log.warn(this.LOG_SOURCE, '長文語数の max の値が min より小さいです');
      this.maxWordCountFormControl.setErrors(VALIDATION_INVALID_VALUE_ERROR);
      this.formIsValid.emit(false);
      return;
    }

    this.minWordCountFormControl.updateValueAndValidity();
    this.maxWordCountFormControl.updateValueAndValidity();
    this.formIsValid.emit(true);

    const currentCondition = this.collectCondition();
    Log.debug(this.LOG_SOURCE, `現在の選択状況: `, currentCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, currentCondition)) {
      Log.debug(this.LOG_SOURCE, '前回 emit したデータと同じため無視します');
      return;
    }

    if (this.needToIgnoreChangeEvent) {
      Log.debug(this.LOG_SOURCE, 'notify 不要な event のため無視します');
      this.needToIgnoreChangeEvent = false;
      return;
    }

    Log.debug(this.LOG_SOURCE, '前回と異なるデータのため emit します');
    this.lastNotifiedCondition = { ...currentCondition };
    this.changeCondition.emit();
  }

  onChangeMinWordCount() {
    const minWordCount: SelectableWordCount = this.minWordCountFormControl.value;
    const maxWordCount: SelectableWordCount = this.maxWordCountFormControl.value;

    if (minWordCount !== this.defaultMinWordCount && maxWordCount === this.defaultMaxWordCount) {
      this.maxWordCountFormControl.setValue(this.highestWordCount);
    }
    if (minWordCount === this.defaultMinWordCount) {
      this.maxWordCountFormControl.setValue(this.defaultMaxWordCount);
    }
    setTimeout(() => this.onChangeValues());
  }

  onChangeMaxWordCount() {
    const minWordCount: SelectableWordCount = this.minWordCountFormControl.value;
    const maxWordCount: SelectableWordCount = this.maxWordCountFormControl.value;

    if (maxWordCount !== this.defaultMaxWordCount && minWordCount === this.defaultMinWordCount) {
      this.minWordCountFormControl.setValue(this.lowestWordCount);
    }
    if (maxWordCount === this.defaultMaxWordCount) {
      this.minWordCountFormControl.setValue(this.defaultMinWordCount);
    }
    setTimeout(() => this.onChangeValues());
  }

  // set ups ---------------------------------------------------------------

  private setUpLongSentenceTypes() {
    const data = this.sortNumberIdentifiedData([...this.staticEnglishData.longSentenceTypes]).map<LongSentenceTypeData>(
      longSentenceType => ({
        ...longSentenceType,
        checked: false
      })
    );
    this.longSentenceTypes = data;
  }

  private setUpLongSentenceWordCount() {
    this.defaultMinWordCount = { count: -1, displayName: '語数を選択' };
    this.defaultMaxWordCount = { count: -1, displayName: '語数を選択' };
    this.lowestWordCount = { count: ENGLISH_LOWEST_WORD_COUNT, displayName: ENGLISH_LOWEST_WORD_COUNT_DISPLAY_NAME };
    this.highestWordCount = { count: ENGLISH_HIGHEST_WORD_COUNT, displayName: ENGLISH_HIGHEST_WORD_COUNT_DISPLAY_NAME };

    const lowCounts = this.staticEnglishData.longSentenceWordCount.lowCounts.map<SelectableWordCount>(count => ({
      count,
      displayName: `${count}`
    }));
    const highCounts = this.staticEnglishData.longSentenceWordCount.highCounts.map<SelectableWordCount>(count => ({
      count,
      displayName: `${count}`
    }));

    this.lowWordCounts = [this.defaultMinWordCount, this.lowestWordCount, ...lowCounts];
    this.highWordCounts = [this.defaultMaxWordCount, ...highCounts, this.highestWordCount];
    this.minWordCountFormControl.setValue(this.defaultMinWordCount);
    this.maxWordCountFormControl.setValue(this.defaultMaxWordCount);
  }

  private setUpFields() {
    const data = this.sortNumberIdentifiedData([...this.staticEnglishData.fields]).map<FieldData>(field => ({
      ...field,
      checked: false
    }));
    this.fields = data;
  }

  private setUpSubTypes() {
    const data = this.sortNumberIdentifiedData([...this.staticEnglishData.subTypes]).map<SubTypeData>(subType => ({
      ...subType,
      checked: false
    }));
    this.subTypes = data;
  }

  /** 一定時間入力値が変化しなければ onChangeValues() をコールします */
  private setUpKeywordChange() {
    this.keyWordSubscription = this.keywordFormControl.valueChanges
      .pipe(
        debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME),
        tap(keywords => Log.debug(this.LOG_SOURCE, `after debounce keyword: ${keywords}`))
      )
      .subscribe(() => this.onChangeValues());
  }

  // utils ---------------------------------------------------------------

  private sortNumberIdentifiedData<T extends { id?: string }>(array: T[]): T[] {
    return array.sort((a, b) => {
      if (Number(a.id) < Number(b.id)) return -1;
      if (Number(a.id) > Number(b.id)) return 1;
      return 0;
    });
  }

  private collectCondition(): EnglishSearchCondition {
    const condition: EnglishSearchCondition = {};

    const selectedLongSentenceTypeIds = this.longSentenceTypes.filter(it => it.checked).map(it => it.id);
    const selectedFieldIds = this.fields.filter(it => it.checked).map(it => it.id);
    const selectedSubTypeIds = this.subTypes.filter(it => it.checked).map(it => it.id);
    if (selectedLongSentenceTypeIds.length !== 0) condition.longSentenceTypeIds = selectedLongSentenceTypeIds;
    if (selectedFieldIds.length !== 0) condition.fieldIds = selectedFieldIds;
    if (selectedSubTypeIds.length !== 0) condition.subTypeIds = selectedSubTypeIds;

    if (
      this.minWordCountFormControl.value !== this.defaultMinWordCount &&
      this.maxWordCountFormControl.value !== this.defaultMaxWordCount
    ) {
      const min = (this.minWordCountFormControl.value as SelectableWordCount).count;
      const max = (this.maxWordCountFormControl.value as SelectableWordCount).count;
      condition.longSentenceWordCount = { min, max };
    }

    if (this.keywordFormControl.value !== '') {
      const rawString: string = this.keywordFormControl.value;
      const keywords = rawString.split(/[ 　]+/).filter(it => it !== '');
      if (keywords.length !== 0) condition.longSentenceKeywords = [...keywords];
    }

    return condition;
  }
}
