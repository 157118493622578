import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JUKU_ORGANIZATION_ID, PLAN_NAMES, SCHOOL_ORGANIZATION_ID } from '../../../resources/config';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { enter } from '../../../resources/animations';
import { CustomValidators } from '../../../utils/custom-validator';
import { UpdatePlanData, Plan, PlanRecord } from '../../../models/plan';
import { Log } from '../../../utils/log';
import { Dates } from '../../../utils/dates';
import { initializeUpdatePlanState, updatePlan } from '../../../actions/plan.actions';
import { getUpdatePlanResult } from '../../../selectors/plan.selectors';
import { filter, take } from 'rxjs/operators';
import { dispatchInfoMessage } from '../../../actions/core.actions';

export interface EditPlanDialogData {
  plan: Plan;
  recordId: number;
  school: string;
  organization: string;
}

@Component({
  selector: 'app-edit-plan-dialog',
  templateUrl: './edit-plan-dialog.component.html',
  styleUrls: ['./edit-plan-dialog.component.scss'],
  animations: [enter]
})
export class EditPlanDialogComponent implements OnInit, OnDestroy {
  minDate: Date = new Date();
  planForm: FormGroup;
  plan: Plan;
  school: string;
  organization: string;
  record: PlanRecord;
  recordId: number;
  subjects;
  startAtDisabled = false;
  planEditing = false;
  errorMessage = '';
  schoolOrganizationId = SCHOOL_ORGANIZATION_ID;
  jukuOrganizationId = JUKU_ORGANIZATION_ID;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<EditPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: EditPlanDialogData
  ) {}

  ngOnInit(): void {
    this.plan = this.data.plan;
    this.recordId = this.data.recordId;
    this.record = this.plan.records.find(record => record.id === this.recordId);
    this.school = this.data.school;
    this.organization = this.data.organization;
    this.subjects = PLAN_NAMES;

    this.setUpForms();
    this.setUpValues();
  }

  ngOnDestroy(): void {}

  editPlan() {
    Object.keys(this.planForm.controls).forEach(key => {
      this.planForm.controls[key].updateValueAndValidity();
    });

    if (this.planForm.invalid) {
      this.planForm.markAllAsTouched();
      return;
    }
    Log.debug(this.LOG_SOURCE, 'プランを更新します');
    this.disableForms();
    this.errorMessage = '';

    const plan: UpdatePlanData = {
      id: this.plan.id,
      school: this.school,
      recordId: this.data.recordId,
      memberCount: Number(this.planForm.get('memberCount').value),
      startAt: Dates.simple4YmdStringFromIso(this.planForm.get('startAt').value),
      endAt: Dates.simple4YmdStringFromIso(this.planForm.get('endAt').value)
    };

    Log.debug(this.LOG_SOURCE, 'edit plan', plan);
    this.store.dispatch(updatePlan({ plan }));

    this.store
      .select(getUpdatePlanResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdatePlanState());
        Log.debug(this.LOG_SOURCE, `edit plan result: `, result);
        if (result.success) {
          this.dialogRef.close(true);
          this.store.dispatch(
            dispatchInfoMessage({
              message: `プランを更新しました`
            })
          );
          return;
        }

        Log.warn(this.LOG_SOURCE, `edit plan error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onChangeStartAt() {
    if (this.planForm.get('endAt').value === '') {
      this.planForm.patchValue({ endAt: Dates.get1YearsLaterDay(this.planForm.get('startAt').value) });
    } else {
      this.planForm.get('endAt').updateValueAndValidity();
    }
  }

  private setUpForms() {
    this.startAtDisabled = !Dates.isAfter(Dates.now(), Dates.isoDateFromSimple4YmdFormat(this.record.startAt));

    this.planForm = new FormGroup({
      memberCount: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      startAt: new FormControl('', this.startAtDisabled ? [] : [Validators.required]),
      endAt: new FormControl('', [Validators.required, CustomValidators.endAtIsAfterStartAt()])
    });
    this.enableForms();
  }

  private setUpValues() {
    this.planForm.patchValue({
      memberCount: this.record.memberCount,
      startAt: Dates.isoDateFromSimple4YmdFormat(this.record.startAt),
      endAt: Dates.isoDateFromSimple4YmdFormat(this.record.endAt)
    });
  }

  private disableForms() {
    Object.keys(this.planForm.controls).forEach(ctrlName => {
      this.planForm.get(ctrlName).disable();
    });
    this.planEditing = true;
  }

  private enableForms() {
    Object.keys(this.planForm.controls).forEach(ctrlName => {
      this.planForm.get(ctrlName).enable();
    });
    this.planEditing = false;
  }
}
