import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { updateSignedInUser } from '../../../actions/auth.actions';
import { dispatchInfoMessage } from '../../../actions/core.actions';
import { findSignedInUserPlans, initializeUnAssignPlanState, unAssignPlan } from '../../../actions/plan.actions';
import { UnAssignPlanData } from '../../../models/plan';
import { RootState } from '../../../reducers';
import { enter } from '../../../resources/animations';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { getUnAssignPlanResult } from '../../../selectors/plan.selectors';
import { Log } from '../../../utils/log';

export interface PlanUnassignMemberDialogData {
  planId: string;
  subjectId: string;
  user: User;
}

@Component({
  selector: 'app-plan-unassign-member-dialog',
  templateUrl: './plan-unassign-member-dialog.component.html',
  styleUrls: ['./plan-unassign-member-dialog.component.scss'],
  animations: [enter]
})
export class PlanUnassignMemberDialogComponent implements OnInit, OnDestroy {
  planId: string;
  subjectId: string;
  user: User;
  unassignForm: FormGroup;
  errorMessage = '';
  unassigning = false;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<PlanUnassignMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PlanUnassignMemberDialogData
  ) {}

  ngOnInit(): void {
    this.planId = this.data.planId;
    this.subjectId = this.data.subjectId;
    this.user = this.data.user;

    this.setUp();
    this.setUpForms();
  }

  ngOnDestroy(): void {}

  unassign() {
    Log.debug(this.LOG_SOURCE, 'ユーザーの割当を解除します。');
    this.disableForms();
    this.errorMessage = '';

    const userIds = [this.user.id];
    const requestData: UnAssignPlanData = {
      userIds,
      planId: this.planId,
      subjectId: this.subjectId
    };

    const unAssignPlanResult$ = this.store.select(getUnAssignPlanResult).pipe(
      filter(it => it != null),
      take(1)
    );
    const signedInUser$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      take(1)
    );

    combineLatest([unAssignPlanResult$, signedInUser$])
      .pipe(take(1))
      .subscribe(([result, user]) => {
        this.store.dispatch(initializeUnAssignPlanState());
        Log.debug(this.LOG_SOURCE, `un assign user result: `, result);
        if (result.success) {
          if (user.id === this.user.id) {
            // 自身のプランを解除した場合はstore上のsignedInUserを更新
            const signedInUser = Object.assign({}, user);
            signedInUser.visibleSubjectIds = signedInUser.visibleSubjectIds.filter(subjectId => subjectId !== this.data.subjectId);
            signedInUser.subjectId = signedInUser.visibleSubjectIds.length > 0 ? signedInUser.visibleSubjectIds[0] : '';
            this.store.dispatch(updateSignedInUser({ user: signedInUser }));
            this.store.dispatch(findSignedInUserPlans({ signedInUser }));
          }

          this.dialogRef.close();
          this.store.dispatch(
            dispatchInfoMessage({
              message: `ユーザーの割当を解除しました`
            })
          );
          return;
        }

        Log.warn(this.LOG_SOURCE, `un assign user error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });

    Log.debug(this.LOG_SOURCE, 'unassign member', requestData);
    this.store.dispatch(unAssignPlan(requestData));
  }

  cancel() {
    this.dialogRef.close();
  }

  private setUp() {}

  private setUpForms() {
    this.enableForms();
  }

  private disableForms() {
    this.unassigning = true;
  }

  private enableForms() {
    this.unassigning = false;
  }
}
