import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';

import { RootState } from '../../../reducers';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';

import { enter, inOut } from 'src/app/resources/animations';
import { NO_DISPLAY_DEPARTMENT_CATEGORY_IDS } from '../../../resources/config';

import { DepartmentCategory, University } from 'src/app/models/common-data';
import { UniversityCondition, UniversitySearchCondition } from 'src/app/models/university-search-condition';
import { StaticCommonData } from '../../../models/static-common-data';

import { ChangeValuesParams, Year } from '../../../models/search-univ-interfaces';
import { UnivChipListComponent } from '../univ-chip-list/univ-chip-list-component';

@Component({
  selector: 'app-search-univ-base',
  templateUrl: './search-univ-base.component.html',
  styleUrls: ['./search-univ-base.component.scss'],
  animations: [enter, inOut]
})
export class SearchUnivBaseComponent implements OnInit {
  // static:trueを設定することで、ngOnInitより、下記のコンポーネントにアクセスできる
  @ViewChild(UnivChipListComponent, { static: true }) univChipListComponent: UnivChipListComponent;

  @Input() isSearchSummaryDisplayed: boolean;
  @Input() matchedUniversityCount$: Observable<number>;
  @Input() defaultDepartmentCategory: DepartmentCategory;
  @Input() previousCondition: UniversitySearchCondition<UniversityCondition>;
  @Input() searchButtonDisabled$: Observable<boolean>;
  @Input() universityCountSearching$: Observable<boolean>;
  @Input() isBToC: boolean;
  @Input() selectableYears: Year[];
  @Input() visibleSubjectNames$: Observable<string>;

  @Output() menuHandlerClick = new EventEmitter<string>();
  @Output() changeValues = new EventEmitter<ChangeValuesParams>();
  @Output() resetFormsClick = new EventEmitter<ChangeValuesParams>();
  @Output() showSearchResultViewClick = new EventEmitter();

  constructor(private store: Store<RootState>) {}

  private defaultYear: string;

  univChipListLabelName = '大学名 (必須 ・複数入力可) ';
  univChipListfloatLabel = 'always';
  univChipListAppearance = 'outline';
  univChipListMatFormFiledClass = 'university-form-field';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedUniversities: University[] = [];
  selectedUniversityNamesForSummary: string;

  universityNameFormControl = new FormControl();
  yearFormControl = new FormControl();
  selectedYear: string | null;

  selectedDepartmentCategory: DepartmentCategory;
  selectableDepartmentCategories: DepartmentCategory[];

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;

  ngOnInit() {
    this.setUpYears();
    this.setUpStaticCommonData();

    this.setUpSelectableDepartmentCategories();
    this.initializeSearchSummaryDisplayed();

    // 前回の検索条件を設定
    if (this.previousCondition) {
      this.setPreviousSearchCondition(this.previousCondition);
    }
  }

  resetForms() {
    this.selectedUniversities = [];
    this.yearFormControl.setValue(this.defaultYear);
    this.selectedDepartmentCategory = this.defaultDepartmentCategory;

    this.univChipListComponent.initializeSelectedUniversitiesAndNamePlaceholder();
    this.univChipListComponent.updateSelectableUniversities();

    this.initializeSearchSummaryDisplayed();

    this.resetFormsClick.emit({
      universities: this.selectedUniversities,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory
    });
  }

  onSelectionChange() {
    // 年度の値を取得
    this.selectedYear = this.yearFormControl.value;
    this.changeValues.emit({
      universities: this.selectedUniversities,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory
    });
  }

  onChangeConditions() {
    this.isSearchSummaryDisplayed = false;

    const top = 0;
    window.scrollTo({
      top,
      behavior: 'smooth'
    });
  }

  changeSelectedUniversities(selectedUniversities: University[]) {
    this.selectedUniversities = selectedUniversities;
    this.updateSelectedUniversityNamesForSummary();

    this.changeValues.emit({
      universities: this.selectedUniversities,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory
    });
  }

  private updateSelectedUniversityNamesForSummary() {
    this.selectedUniversityNamesForSummary = this.selectedUniversities
      ? this.selectedUniversities.map(selectedUniversity => selectedUniversity.name).join(', ')
      : null;
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpYears() {
    this.defaultYear = this.selectableYears.map(year => year)[0].value;
    this.yearFormControl.setValue(this.defaultYear);
    this.selectedYear = this.defaultYear;
  }

  private setUpSelectableDepartmentCategories() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        // 学部系統IDの昇順に並び替え
        this.selectableDepartmentCategories = [
          ...staticCommonData.departmentCategories.filter(it => !NO_DISPLAY_DEPARTMENT_CATEGORY_IDS.includes(it.id)),
          this.defaultDepartmentCategory
        ].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : Number(b.id) > Number(a.id) ? -1 : 0));
      });

    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
  }

  private initializeSearchSummaryDisplayed() {
    this.isSearchSummaryDisplayed = false;
  }

  private setPreviousSearchCondition(condition: UniversitySearchCondition<UniversityCondition>) {
    // 大学名に前回の検索条件を設定
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        condition.universityCondition.universityIds.map(universityId => {
          const name = staticCommonData.universities.find(staticUniversity => staticUniversity.id === universityId).name;
          this.selectedUniversities.push({ id: universityId, name });
          this.updateSelectedUniversityNamesForSummary();
        });
        this.univChipListComponent.updateUnivChipList(this.selectedUniversities);
      });

    // 年度に前回の検索条件を設定
    this.selectedYear = condition.year.toString();
    this.yearFormControl.setValue(this.selectedYear);

    // 学部系統に前回の検索条件を設定
    if (condition.departmentCategoryId) {
      const departmentCategoryId = condition.departmentCategoryId.toString();
      const depCategory = this.selectableDepartmentCategories.find(it => it.id === departmentCategoryId);
      this.selectedDepartmentCategory = depCategory;
    } else {
      this.selectedDepartmentCategory = this.defaultDepartmentCategory;
    }
  }
}
