<app-common-id-top-header></app-common-id-top-header>
<main class="top-root">
  <div class="top-wrapper">
    <div id="introduction" class="introduction">
      <section class="section-hero">
        <div class="introduction-wrapper">
          <div class="section-hero-picture">
            <div class="section-hero-picture-item">
              <picture>
                <source media="(min-width: 600px)" srcset="/assets/images/lp/student/hero@2x.jpg 2x, /assets/images/lp/student/hero.jpg" />
                <img srcset="/assets/images/lp/student/hero-sp.jpg" alt="入試正解デジタル画面イメージ" width="930" height="614" />
              </picture>
            </div>
          </div>

          <div class="section-hero-item ml-6 mr-6 md:ml-0 md:mr-0">
            <div class="section-hero-title">
              <h1 class="section-hero-title-logo text-primary">
                過去問を<br />
                たくさん解くことは、<br />
                合格への近道
              </h1>
              <p class="section-hero-title-lead md:mt-8 mb-10">
                <img
                  srcset="assets/images/lp/student/hero-copy.svg"
                  alt="大学入試過去問Webサービス 旺文社入試正解デジタル"
                  width="510"
                  height="72"
                />
              </p>
            </div>
            <div class="section-hero-buttons mb-12 md:mb-4">
              <button
                id="cv-top-installation"
                class="mt-4 block w-full mat-button-override-large-in-lp cta-button-primary text-base md:text-lg"
                mat-flat-button
                (click)="openManabiModal()"
              >
                新規登録・ログイン
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="section-publish">
        <div class="section-publish-border">
          <picture>
            <source media="(min-width: 900px)" srcset="/assets/images/lp/student/publish.svg 2x, /assets/images/lp/student/publish.svg" />
            <img
              srcset="assets/images/lp/student/publish-sp.svg"
              alt="全国200大学以上の過去問(解答・解説付き)を掲載！"
              width="826"
              height="48"
            />
          </picture>
        </div>
        <div class="section-publish-subject">
          <div class="section-publish-subject-inner">
            <p>対応科目</p>
            <div class="section-publish-subject-circle">
              <div class="circle" data-subject-type="eigo">
                <span>英語</span>
              </div>
              <div class="circle" data-subject-type="sugaku">
                <span>数学</span>
              </div>
              <div class="circle" data-subject-type="kokugo">
                <span>国語</span>
              </div>
              <div class="circle" data-subject-type="butsuri">
                <span>物理</span>
              </div>
              <div class="circle" data-subject-type="kagaku">
                <span>化学</span>
              </div>
              <div class="circle" data-subject-type="seibutsu">
                <span>生物</span>
              </div>
              <div class="circle" data-subject-type="nihonshi">
                <span>日本史</span>
              </div>
              <div class="circle" data-subject-type="sekaishi">
                <span>世界史</span>
              </div>
              <div class="circle" data-subject-type="chiri">
                <span>地理</span>
              </div>
              <div class="circle" data-subject-type="seikei">
                <span>政治<br />・<br />経済</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-commentary section-cols bg-gray">
        <div class="section-cols-inner">
          <div class="section-commentary-picture">
            <div>
              <picture>
                <source
                  media="(min-width: 780px)"
                  srcset="/assets/images/lp/student/commentary@2x.jpg 2x, /assets/images/lp/student/commentary.jpg"
                />
                <img
                  srcset="/assets/images/lp/student/commentary.jpg"
                  alt="解答解説付き「全国大学入試問題正解」"
                  width="560"
                  height="350"
                />
              </picture>
            </div>
          </div>
          <div class="section-commentary-item">
            <h2>
              解答解説付き<sup>※1</sup>だから<br />
              一人でも志望校対策が可能
            </h2>
            <p class="">
              <a class="underline text-primary" (click)="anchorScrollingHandler('about', 70)">
                <span class="text-primary">「全国大学入試問題正解」</span></a
              >
              掲載の解答・解説を収録！<br />
              大学・高校・予備校の教育にたずさわっているベテランの先生方による<br
                class="hidden md:block"
              />正確でわかりやすい親切な解答・解説つき。<br />
              このサービスだけで複数の志望校の対策ができます。<br />
              <small>※1 一部「問題・解答のみ」を掲載</small>
            </p>
          </div>
        </div>
      </section>
      <section class="section-commentary section-cols section-text-right bg-pink">
        <div class="section-cols-inner">
          <div class="section-commentary-picture">
            <div>
              <picture>
                <source
                  media="(min-width: 780px)"
                  srcset="/assets/images/lp/student/commentary02@2x.png 2x, /assets/images/lp/student/commentary02.png"
                />
                <img
                  srcset="/assets/images/lp/student/commentary02.png"
                  alt="「マイリスト」を活用して学習する書影"
                  width="560"
                  height="350"
                />
              </picture>
            </div>
          </div>
          <div class="section-commentary-item">
            <h2>
              「マイリスト」を活用して<br />
              学習の進捗管理ができる
            </h2>
            <p class="">
              時間がないけれど後で挑戦したい場合は、「あとで解く」を使いましょう。<br />
              問題に挑戦して解き終えたら「解答済み」にできますので、<br />
              学習の進捗や復習に便利です。
            </p>
          </div>
        </div>
      </section>
      <section id="premium" class="section-premium">
        <div class="premium-bg">
          <h2 class="section-basic-header">
            <img src="/assets/images/lp/student/crown-head.png" alt="プレミアム王冠" width="85" height="85" />
            <span class="section-basic-header-bg">入試正解デジタル<br class="md:hidden" />プレミアム</span><br />
            <small>に登録して、<br class="md:hidden" />もっと合格に近づこう</small>
          </h2>
          <p class="text-center section-basic-description">
            10科目対応で、苦手科目対策から志望校<br class="md:hidden" />対策まで徹底的に演習ができます。<br />
            志望校を変更しても、追加購入の必要がなく安心です。<br />
            期間ごとにプランを選んで、<br class="md:hidden" />自分の好きなペースで学習をしましょう。
          </p>
        </div>
        <div class="section-basic-inner">
          <div class="block-col col02">
            <div class="col">
              <i><span class="en en-black">01</span></i>
              <div class="col-content">
                <p>
                  200大学以上・最大9年分<br />
                  過去問を<br class="md:hidden" />無制限で閲覧！
                </p>
                <img src="/assets/images/lp/student/premium01.jpg" alt="過去問最大9年分を無制限で閲覧できる！" width="414" height="267" />
              </div>
            </div>
            <div class="col">
              <i><span class="en en-black">02</span></i>
              <div class="col-content">
                <p>
                  プロ厳選<br class="md:hidden" />おすすめ問題<br />
                  セットが解き放題！
                </p>
                <img
                  src="/assets/images/lp/student/premium02.jpg"
                  alt="プロ厳選オリジナル問題セットが解き放題！"
                  width="414"
                  height="267"
                />
              </div>
            </div>
          </div>
          <div class="block-col col02">
            <div class="col">
              <i><span class="en en-black">03</span></i>
              <div class="col-content">
                <p>
                  複数年度の問題から<br />
                  分野を絞って集中対策<br class="md:hidden" />できる！
                </p>
                <img
                  src="/assets/images/lp/student/premium03.jpg"
                  alt="複数年度の問題から分野を絞って集中対策できる"
                  width="414"
                  height="267"
                />
              </div>
            </div>
            <div class="col">
              <i><span class="en en-black">04</span></i>
              <div class="col-content">
                <p>
                  共通テスト対策に<br class="md:hidden" />使える<br class="hidden md:block" />予想問題<br class="md:hidden" />セットを掲載！
                </p>
                <img src="/assets/images/lp/student/premium04.jpg" alt="オリジナル問題をプロが厳選" width="414" height="267" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="price" class="section-basic section-price">
        <h2 class="section-basic-header"><span class="">機能比較</span><br /></h2>
        <div>
          <div class="section-basic-inner">
            <table class="table-price">
              <thead>
                <tr>
                  <th class="w-4/12 transparent"></th>
                  <th class="w-4/12" data-cell-type="free">無料会員</th>
                  <th class="w-4/12" data-cell-type="premium">入試正解デジタル<br />プレミアム</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    過去問閲覧<br />
                    <small class="text-xs md:text-sm inline-block leading-snug">（大学から探す・分野から探す）</small>
                  </th>
                  <td data-cell-type="free">2023年度<wbr />入試</td>
                  <td data-cell-type="premium">無制限<sup>※2</sup></td>
                </tr>
                <tr>
                  <th>おすすめ問題<br class="md:hidden" />セット</th>
                  <td class="" data-cell-type="free">
                    お試しのみ
                  </td>
                  <td class="" data-cell-type="premium">
                    無制限
                  </td>
                </tr>
                <tr>
                  <th>
                    共通テスト対策<br />
                    予想問題セット<sup class="text-xs" style="top: -0.2em;">※3</sup>
                  </th>
                  <td class="" data-cell-type="free">
                    -
                  </td>
                  <td class="" data-cell-type="premium">
                    無制限
                  </td>
                </tr>
                <tr>
                  <th>
                    マイリスト
                  </th>
                  <td data-cell-type="free">
                    <img src="/assets/images/lp/student/check-silver.svg" alt="✓" />
                  </td>
                  <td data-cell-type="premium">
                    <img src="/assets/images/lp/student/check-gold.svg" alt="✓" />
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="section-price-notion">
              ※2【対応科目と掲載年度】<br />
              数学、物理、化学：2015年度～2023年度<br />
              生物：2018年度～2023年度<br />
              日本史、世界史：2019年度～2023年度<br />
              英語、国語、地理、政治・経済：2020年度～2023年度<br />
              年度についての詳細は<button class="text-primary underline hover:no-underline" (click)="gotoFaq('faq-10')">こちらのFAQ</button
              >をご覧ください。<br />
              著作権等の関係で、一部閲覧できない問題もございます。<br />
              （2024年度入試は、2024年12月頃に搭載予定です。）<br />
              ※3 共通テスト対策予想問題セットはおすすめ問題セットの中で掲載しています。
            </p>
          </div>
        </div>
      </section>
      <section class="section-basic section-plan">
        <h2 class="section-basic-header"><span class="">プラン料金について</span><br /></h2>
        <div>
          <div class="section-basic-inner">
            <div class="flex flex-col md:flex-row md:justify-center gap-6">
              <!-- <div class="col is-highlighted">
                <i
                  ><span>期間<br />限定</span></i
                >
                <div class="col-content">
                  <div class="min-h-[4rem] flex items-center justify-center">
                    <p class="plan-text">
                      ＼短期集中／<br />
                      <b class="en">3</b>ヶ月プラン
                    </p>
                  </div>
                  <p class="plan-price"><span class="en en-black">¥3,500</span><small>(税込)</small></p>
                  <div class="plan-bar justify-center">
                    <p><b class="text-md">24年3月末までの限定販売!</b></p>
                  </div>
                  <div class="plan-date">
                    <p>サービス利用開始から</p>
                    <p>92日間</p>
                  </div>
                </div>
              </div> -->
              <div class="col is-highlighted">
                <div class="col-content">
                  <div class="min-h-[4rem] flex items-center justify-center">
                    <p class="plan-text"><b class="en">6</b>ヶ月プラン</p>
                  </div>
                  <p class="plan-price"><span class="en en-black">¥5,500</span><small>(税込)</small></p>
                  <div class="plan-bar">
                    <p>ひと月あたり</p>
                    <p><b class="en en-black text-lg">￥917</b> (税込)</p>
                  </div>
                  <div class="plan-date">
                    <p>サービス利用開始から</p>
                    <p>183日間</p>
                  </div>
                </div>
              </div>
              <div class="col is-highlighted">
                <i><span>おトク!</span></i>
                <div class="col-content">
                  <div class="min-h-[4rem] flex items-center justify-center">
                    <p class="plan-text"><b class="en">12</b>ヶ月プラン</p>
                  </div>
                  <p class="plan-price"><span class="en en-black">¥7,700</span><small>(税込)</small></p>
                  <div class="plan-bar">
                    <p>ひと月あたり</p>
                    <p><b class="en en-black text-lg">￥642</b> (税込)</p>
                  </div>
                  <div class="plan-date">
                    <p>サービス利用開始から</p>
                    <p>365日間</p>
                  </div>
                </div>
              </div>
            </div>
            <button class="mt-4 block w-full mat-button-override-large-in-lp text-base md:text-lg" mat-flat-button (click)="gotoPurchase()">
              ご購入はこちら
            </button>
          </div>
        </div>
      </section>
      <section id="flow" class="section-basic section-flow">
        <h2 class="section-basic-header"><span class="">ご購入の流れ</span><br /></h2>
        <div class="section-basic-inner">
          <div class="flow-list">
            <section class="flow-item">
              <div class="flow-image">
                <i class="en en-black">01</i>
                <img srcset="/assets/images/lp/student/step01.png" alt="STEP1 プラン選択画面" />
              </div>
              <h3 class="flow-item-title">
                <strong>プランを選択<br class="hidden md:block" /><small class="hidden md:block">&nbsp;</small> </strong>
              </h3>
              <div class="flow-item-body">
                <p class="mb-1">
                  ログイン後ご希望のプランを選択します。 （外部の<strong>Stripeサイト</strong>に移動します。）
                </p>
                <p class="text-sm">※ログインには「旺文社まなびID」へのご登録（無料）が必要です。</p>
              </div>
            </section>
            <section class="flow-item">
              <div class="flow-image">
                <i class="en en-black">02</i>
                <img srcset="/assets/images/lp/student/step02.png" alt="STEP2 購入情報画面" />
              </div>
              <h3 class="flow-item-title">
                <strong>購入情報入力<br /><small class="font-normal">（保護者様ご入力箇所）</small> </strong>
              </h3>
              <div class="flow-item-body">
                <p>
                  <strong class="font-bold">＜クレジットカード支払い＞</strong><br />
                  <span class="text-sm">メールアドレス（領収書送信先）とカード番号を入力してください。</span>
                </p>
                <p>
                  <strong class="font-bold">＜コンビニ支払い＞</strong><br />
                  <span class="text-sm">支払いコードと確認番号を受け取ります。 その後、コンビニエンスストアでお支払いください。</span>
                </p>
              </div>
            </section>
            <section class="flow-item">
              <div class="flow-image">
                <i class="en en-black">03</i>
                <img srcset="/assets/images/lp/student/step03.png" alt="STEP3 ご利用開始画面" />
              </div>
              <h3 class="flow-item-title">
                <strong>ご利用を開始<br class="hidden md:block" /><small class="hidden md:block">&nbsp;</small></strong>
              </h3>
              <div class="flow-item-body">
                <p class="">購入完了後から<strong class="font-bold">「入試正解デジタルプレミアム」</strong>をお使いいただけます。</p>
              </div>
            </section>
          </div>
          <div class="flow-notion">
            <h4>お支払いについて</h4>
            <ul>
              <li>お支払い方法は「クレジットカード」または「コンビニ」決済に対応しています。</li>
              <li>ご利用できるクレジットカードの種類は、Visa / Mastercard / JCB / American Express です。</li>
              <li>ご利用できるコンビニエンスストアは、ファミリーマート / ローソン / ミニストップ / セイコーマートです。</li>
              <li>
                未成年者によるクレジットカードのご購入の場合は、保護者が保護者名義のカードにてお申し込みください。
              </li>
              <li>当サイトではストライプジャパン株式会社 が提供する決済システム「Stripe」を利用しています。</li>
              <li>
                ご入力いただいたカード情報等は当サイトのサーバーを経由せず、SSL暗号化通信で Stripe
                の決済システムに直接登録され、ストライプジャパン株式会社にて厳重に管理され安全性を確保しております。
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="about" class="section-basic section-what">
        <div class="section-basic-inner ">
          <div class="section-what-image md:w-1/2 w-full">
            <img srcset="/assets/images/lp/student/books.png" alt="全国大学入試問題正解" />
          </div>
          <div class="section-what-text md:w-1/2 w-full">
            <h1>
              <span>全国大学入試問題正解とは</span>
              <span class="text-primary"
                >80年以上のロングセラー<br />
                旺文社<br class="md:hidden" />『全国大学入試問題正解』</span
              >
            </h1>
            <p>
              『全国大学入試問題正解』シリーズは、国公私立大学の入試情報、入試傾向や科目の特徴がわかる書籍で、英語・数学・国語・物理・化学・生物など20点刊行しています。<br />
              1935年<sup class="top-0">(※3)</sup
              >の刊行以来、毎年多くの高等学校で採用されており、2022年受験用の書籍は2,000校以上の高等学校に採用いただきました。学校現場では、進路指導室や教科室、職員室に設置され、授業や補講、過去問演習に利用されています。<br />
              <small>(※3)「全国大学入試問題正解」の前身である「入学試験問題詳解」の刊行年度。</small>
            </p>
          </div>
        </div>
      </section>
      <section id="faq" class="section-basic section-faq">
        <h2 class="section-basic-header">
          <b class="en">FAQ</b>
          <span class="text-primary">よくあるご質問</span>
        </h2>
        <div class="section-basic-inner">
          <h3>サービス全般について</h3>
          <div class="-mx-2 mx-0">
            <div class="logs" *ngFor="let faq of category1Faqs">
              <div [id]="faq.id" class="mb-4 md:mb-6">
                <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                  <div class="mt-0" [innerHTML]="faq.body"></div>
                </app-faq-list-item>
              </div>
            </div>
          </div>
          <h3>掲載内容について</h3>
          <div class="-mx-2 mx-0">
            <div class="logs" *ngFor="let faq of category2Faqs">
              <div [id]="faq.id" class="mb-4 md:mb-6">
                <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                  <div class="mt-0" [innerHTML]="faq.body"></div>
                </app-faq-list-item>
              </div>
            </div>
          </div>
          <h3>プレミアムについて</h3>
          <div class="-mx-2 mx-0">
            <div class="logs" *ngFor="let faq of category3Faqs">
              <div [id]="faq.id" class="mb-4 md:mb-6">
                <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                  <div class="mt-0" [innerHTML]="faq.body"></div>
                </app-faq-list-item>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cv-bottom" class="bottom-cta">
        <div class="section-basic-inner block md:flex">
          <div class="">
            <h2 class="bottom-cta-headline">
              過去問をたくさん解いて<br />
              合格をつかもう！
            </h2>
            <button
              class="mt-4 block w-full mat-button-override-large-in-lp cta-button-primary text-base md:text-lg"
              mat-flat-button
              (click)="openManabiModal()"
            >
              新規登録・ログイン
            </button>
          </div>
          <div>
            <img srcset="/assets/images/lp/student/footer-image.png" alt="合格を掴む様子" />
          </div>
        </div>
      </section>
    </div>
    <app-common-id-footer footerTypeLP="true"></app-common-id-footer>
  </div>
</main>
