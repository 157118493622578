import { AfterViewInit, Component, Renderer2, OnDestroy, OnInit, Inject, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { navigate, openWindow, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { Meta } from '@angular/platform-browser';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { META_VIEWPORT_LP, TRIAL_INQUIRY_URL } from '../../../resources/config';
import { DOCUMENT } from '@angular/common';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';
import { NEW_INSTALLATION_URL, OTHER_INQUIRY_URL } from '../../../resources/config';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = '大学入試過去問が見つかる';
  private scrolled = 0;
  private scrollTo: string;

  newInstallationUrl: string = NEW_INSTALLATION_URL;
  otherInquiryUrl: string = OTHER_INQUIRY_URL;
  trialInquiryUrl: string = TRIAL_INQUIRY_URL;
  universitiesListPdfUrl: string = environment.downloadFiles.universitiesListPdf;

  faqList = [
    {
      id: '',
      title: '動作環境を教えてください。',
      body: `
        <p>当社が推奨している環境は、OSはWindows/Mac/Chrome OS、ブラウザはGoogle Chrome 最新版、Microsoft Edge 最新版に対応しています。</p>
        <p><small class="block">
          ※上記推奨環境以外では、正常に機能しない、または正常に表示されない場合があります。また、上記推奨環境であっても、今後のOS・ブラウザ等のバージョンアップなどに伴い、正常に動作しなくなる場合もございますのであらかじめご了承ください。<br>
          ※ パソコンからのみご利用いただけます。スマートフォン・タブレット端末には対応しておりません。
        </small></p>
      `,
      isExpanded: false
    },
    {
      id: '',
      title: '年額料金となっていますが、いつからいつまで利用ができますか。',
      body: `年間いつでもお申込みいただけます。ご希望の利用開始日から1年間ご利用ができますが、契約日終了後はサービスのご利用はできなくなります。`
    },
    {
      id: '',
      title: '塾や個人で利用できますか。',
      body: `
        <p>恐れ入りますが個人向けには販売はしておりません。</p>
        <p>塾・予備校でのご利用を検討いただいている方で、無料体験版をご希望の方は、<a href="https://reg34.smp.ne.jp/regist/is?SMPFORM=mbo-ljpfsj-729c0714c969c2fa4e0dd4828a5e1f8b" target="_blank">こちら</a>からお申込みください。<br>お申込み後1週間以内に、体験版のご利用に必要なアカウント情報をメールでお送りします。</p>
      `,
      isExpanded: false
    },
    {
      id: '',
      title: '体験版について教えてください。',
      body: `
        <p>本サービスに興味をお持ちいただいている先生向けに、無料の体験版を提供しています。</p>
        <p>
          ・利用期間…10日間<br>
          ・科目…10科目すべて<br>
          ・機能…基本検索、おすすめ問題セットなど主要機能を一部閲覧・利用できます。
        <p>
          無料体験版をご希望の方は、<a href="${this.trialInquiryUrl}" target="_blank">こちら</a>からお申込みください。<br>
          お申込み後1週間以内に、体験版のご利用に必要なアカウント情報をメールでお送りします。
        </p>
      `,
      isExpanded: false
    },
    {
      id: '',
      title: '新規導入後に、学校側で必要な設定を教えてください。',
      body: `
        <p>
          新規導入の申し込み時に「団体管理ユーザー」として申請された先生のアドレス宛に、アカウント発行のご連絡が届きます。<br>
          初回サインインのパスワードを設定後、「入試正解デジタル for School」にログインいただき、所属メンバーの登録、メンバーを各ご利用科目に割り当ててください。<br>
          所属メンバーの登録の際には、一人ずつご登録または CSVアップロードで一括登録が可能です。必要な項目は、姓名（せいめい）、メールアドレス、ユーザー権限の情報です。<br>
          メンバーをご登録後に、ご利用科目の割り当てを行っていただきますと、そのメンバーに招待メールが自動的に送信されます。<br>
          ご利用開始時に詳細なマニュアルをご案内しますので、マニュアルに沿ってご設定をお願いします。
        </p>
      `,
      isExpanded: false
    },
    {
      id: '',
      title: '全国大学入試問題正解に掲載している学校の問題すべてを閲覧できますか。',
      body: `著作権等の関係で、一部、閲覧できない問題がございます。`,
      isExpanded: false
    },
    {
      id: '',
      title: '対応している書籍を教えてください。',
      body: `対応書籍と掲載年数の最新情報は、<a href="#subject">こちら</a>をご覧ください。`,
      isExpanded: false
    },
    {
      id: '',
      title: '過去問を検索した結果、表示される問題と解答解説はPDF形式でしょうか。',
      body: `PDF形式ではなく、Web上で画像として表示されます。`,
      isExpanded: false
    },
    {
      id: '',
      title: '科目プランで申込後に追加のアカウントを購入することはできますか。',
      body: `
        <p>できます。ただし、追加されたアカウントは、すでにご利用中のプランに対しての利用期間が適用されます。</p>
        <p><small class="block">例）数学プラン（10人まで）を2021年12月15日～2022年12月14日でご契約中に、2022年3月1日から3人を追加される場合、すべてのアカウントの利用期間は2021年12月15日～2022年12月14日となります。また、追加料金は追加アカウント分のご請求となります。</small></p>
      `,
      isExpanded: false
    },
    {
      id: '',
      title: '入試の「年度」の意味を教えてください。',
      body: `当サービスで表記している入試の「年度」とは、その年度に入学する学生用の入試であることを意味します。<br>たとえば2023年度入試とは、2023年4月に入学した学生が受けた試験です。`,
      isExpanded: false
    },
    {
      id: '',
      title: '最新年度の入試問題が追加された場合、追加料金や掲載年度はどのようになりますか。',
      body: `
        <p>定額制サービスのため、追加料金はかかりません。</p>
        <p>
          <small class="block">
            最新年度が追加された場合は、1年分が追加されます。英語、国語（現代文）は著作権等の関係で最新4年分の問題・解答を閲覧いただけます。
          </small>
        </p>
      `,
      isExpanded: false
    },
    {
      id: '',
      title: '2024年度入試（2025年受験用）の過去問の搭載時期はいつですか。',
      body: `2024年12月頃に搭載予定です。`,
      isExpanded: false
    },
    {
      id: '',
      title: '2年目以降の継続方法や、料金を教えてください。',
      body: `本サービスは年額制ですが、自動継続ではありません。翌年以降もご利用いただく場合は、継続の申し込みが必要です。`,
      isExpanded: false
    },
    {
      id: '',
      title: '入試正解デジタル for School のコンテンツを用いて、オンライン配信授業で配信することはできますか。',
      body: `できます。ただし、ユーザーが所属する団体等における授業・講義において、生徒もしくは受講者に対してリアルタイムに配信する場合に限ります。例えば、GoogleClassroomのMeetやZoomを使い、ユーザーが所属する団体内の生徒に限定したオンライン授業で利用することは可能です。録画した講義動画をサーバにアップして使うなど、上記に定めるリアルタイム配信以外での利用はできません。`,
      isExpanded: false
    },
    {
      id: '',
      title: '申込み後のキャンセルはできますか。',
      body: `原則、キャンセルはお受けできません。<br>恐れ入りますが、内容を十分ご確認の上、お申し込みいただきますようお願いいたします。`,
      isExpanded: false
    },
    {
      id: '',
      title: '途中解約・返金はできますか。',
      body: `プランご契約期間途中で解約することはできません。<br>途中でご利用を停止されても、本サービスの利用にあたりお支払いいただいた料金の返金等はいたしかねますのでご了承ください。`,
      isExpanded: false
    }
  ];

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW_OBJECT) private window: Window,
    private meta: Meta
  ) {
    this.faqList = this.faqList.map((faq, index) => {
      faq.id = `faq-${index + 1}`;
      return faq;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    const currentPos: number = e.target.scrollingElement.scrollTop;
    this.scrolled = currentPos;
  }

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_LP);
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    this.renderer.addClass(this.document.body, 'page-top');
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    if (this.activatedRoute.snapshot.queryParams.to) {
      this.scrollTo = this.activatedRoute.snapshot.queryParams.to;
    }
  }

  getParallaxPositionY(layer: number): string {
    const pos: number = this.scrolled / (layer * 50);
    return pos + 'vw';
  }

  ngAfterViewInit() {
    if (this.scrollTo) {
      setTimeout(() => this.anchorScrollingHandler(this.scrollTo));
    } else {
      setTimeout(() => window.scrollTo(0, 0));
    }
  }

  ngOnDestroy() {}

  gotoSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
  }

  newInstallationUrlClickHandler() {
    this.store.dispatch(openWindow({ url: this.newInstallationUrl }));
  }

  otherInquiryUrlClickHandler() {
    this.store.dispatch(openWindow({ url: this.otherInquiryUrl }));
  }

  freeTrialApplicationUrlClickHandler() {
    this.store.dispatch(openWindow({ url: this.trialInquiryUrl }));
  }

  universitiesListDownloadClickHandler() {
    this.store.dispatch(openWindow({ url: this.universitiesListPdfUrl }));
  }

  gotoFaq(id: string) {
    const targetFaq = this.faqList.find(faq => faq.id === id);
    targetFaq.isExpanded = true;
    this.anchorScrollingHandler(id, 70);
  }

  anchorScrollingHandler(id: string, buffer: number = 0) {
    const targetElement = this.document.getElementById(id);
    if (targetElement) {
      const rectTop = targetElement.getBoundingClientRect().top;
      const offsetTop = window.pageYOffset;
      const top = rectTop + offsetTop - buffer;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  }
}
