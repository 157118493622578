import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';
import { getDeleteUserResult } from 'src/app/selectors/user.selectors';
import { dispatchInfoMessage, dispatchAppError } from 'src/app/actions/core.actions';
import { deleteUser, initializeDeleteUserState } from 'src/app/actions/user.actions';
import { enter } from '../../../resources/animations';
import { getDeleteJukuResult } from 'src/app/selectors/juku.selectors';
import { deleteJuku, initializeDeleteJukuState } from 'src/app/actions/juku.actions';

export interface DeleteJukuDialogData {
  id: string;
  jukuCode: string;
  jukuName: string;
}

@Component({
  selector: 'app-delete-juku-dialog',
  templateUrl: './delete-juku-dialog.component.html',
  styleUrls: ['./delete-juku-dialog.component.scss'],
  animations: [enter]
})
export class DeleteJukuDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<DeleteJukuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteJukuDialogData
  ) {}

  ngOnInit() {}

  deleteJuku() {
    if (!this.data || !this.data.id) {
      Log.warn(this.LOG_SOURCE, `dialog.data または data.id が設定されていません: `, this.data);
      return;
    }

    this.disableForms();

    Log.debug(this.LOG_SOURCE, `塾・予備校を削除します`);
    this.store
      .select(getDeleteJukuResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeDeleteJukuState());
        if (result.success) {
          const message = `${this.data.jukuName} の削除が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `塾・予備校削除完了: `, this.data);
          this.dialogRef.close(true);
        } else {
          Log.error(this.LOG_SOURCE, `塾・予備校削除でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
          this.dialogRef.close(false);
        }

        this.enableForms();
      });
    this.store.dispatch(deleteJuku({ id: this.data.id }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.data.jukuName} を削除しています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
