import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { Log } from 'src/app/utils/log';
import { inOut } from 'src/app/resources/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMON_ID_PREFECTURES, COMMON_ID_REGION } from 'src/app/resources/common-id-config';
import { PrefectureData } from 'src/app/models/university-search-prefecture-dialog-condition';

interface PrefectureDataWithChecked extends PrefectureData {
  checked: boolean;
}

@Component({
  selector: 'app-select-prefecture-dialog',
  templateUrl: './select-prefecture-dialog.component.html',
  styleUrls: ['./select-prefecture-dialog.component.scss'],
  animations: [inOut]
})
export class SelectPrefectureDialogComponent implements OnInit, OnDestroy {
  dialogTitle: string;
  private LOG_SOURCE = this.constructor.name;

  prefectureDataWithChecked: PrefectureDataWithChecked[];
  hokkaidoTohoku: PrefectureDataWithChecked[];
  hokkaidoTohokuSelectAllChecked: boolean;
  kanto: PrefectureDataWithChecked[];
  kantoSelectAllChecked: boolean;
  koshinetsu: PrefectureDataWithChecked[];
  koshinetsuSelectAllChecked: boolean;
  hokuriku: PrefectureDataWithChecked[];
  hokurikuSelectAllChecked: boolean;
  tokai: PrefectureDataWithChecked[];
  tokaiSelectAllChecked: boolean;
  kinki: PrefectureDataWithChecked[];
  kinkiSelectAllChecked: boolean;
  chugoku: PrefectureDataWithChecked[];
  chugokuSelectAllChecked: boolean;
  shikoku: PrefectureDataWithChecked[];
  shikokuSelectAllChecked: boolean;
  kyusyuOkinawa: PrefectureDataWithChecked[];
  kyusyuOkinawaSelectAllChecked: boolean;
  selectedPrefectures: PrefectureData[] = [];

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<SelectPrefectureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PrefectureData[]
  ) {}

  ngOnInit() {
    this.dialogTitle = '都道府県を選択';
    this.setUpRegions();
    this.setUpSelectAllCheckboxes();
  }

  ngOnDestroy() {}

  submit() {
    this.selectedPrefectures.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

    Log.debug(this.LOG_SOURCE, '選択された都道府県: ', this.selectedPrefectures);
    this.dialogRef.close(this.selectedPrefectures);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onChangePrefecture(prefecture: PrefectureDataWithChecked) {
    // 都道府県が選択された場合は、選択されている都道府県の配列に追加
    if (prefecture.checked === true) {
      this.selectedPrefectures.push({ id: prefecture.id, region: prefecture.region, name: prefecture.name });
    } else {
      const indexOfThePrefecture = this.selectedPrefectures.findIndex(pref => {
        return pref.id === prefecture.id;
      });
      if (indexOfThePrefecture >= 0) this.selectedPrefectures.splice(indexOfThePrefecture, 1);
    }

    // 各地域の都道府県がすべて選択されている場合は、すべてを選択するのチェックボックスにチェックを入れる
    this.setUpSelectAllCheckboxes();
  }

  onSelectAll(region: string) {
    const selectedPrefecturesInTheRegion = this.selectedPrefectures.filter(prefecture => prefecture.region === region);
    const prefecturesInTheRegion = COMMON_ID_PREFECTURES.filter(prefecture => prefecture.region === region);

    // 各地域の全都道府県が選択されている場合にすべてを選択するチェックを入れると、全ての選択を解除する
    if (selectedPrefecturesInTheRegion.length === prefecturesInTheRegion.length) {
      // 全都道府県のチェックボックスを未選択にする
      this.setCheckedFlag(region, false);

      // 全都道府県を、選択されている都道府県の配列から削除する
      prefecturesInTheRegion.map(prefecture => {
        const indexOfThePrefecture = this.selectedPrefectures.findIndex(selectedPrefecture => {
          return selectedPrefecture.id === prefecture.id;
        });
        if (indexOfThePrefecture >= 0) this.selectedPrefectures.splice(indexOfThePrefecture, 1);
      });

      // 全てを選択するチェックを入れると、現在チェック済みの都道府県も含めて、すべての都道府県を選択する
    } else {
      // 全都道府県のチェックボックスにチェックを入れる
      this.setCheckedFlag(region, true);

      // 全都道府県を、選択されている都道府県の配列に追加する（すでに選択されている都道府県は追加しない）
      prefecturesInTheRegion.map(prefecture => {
        if (!this.selectedPrefectures.find(selectedPrefecture => selectedPrefecture.id === prefecture.id)) {
          this.selectedPrefectures.push({ id: prefecture.id, region: prefecture.region, name: prefecture.name });
        }
      });
    }
  }

  private setCheckedFlag(region: string, flag: boolean) {
    switch (region) {
      case COMMON_ID_REGION.HOKKAIDO_TOHOKU:
        this.hokkaidoTohoku.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.KANTO:
        this.kanto.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.KOSHINETSU:
        this.koshinetsu.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.HOKURIKU:
        this.hokuriku.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.TOKAI:
        this.tokai.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.KINKI:
        this.kinki.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.CHUGOKU:
        this.chugoku.forEach(prefecture => (prefecture.checked = flag));
        break;
      case COMMON_ID_REGION.SHIKOKU:
        this.shikoku.forEach(prefecture => (prefecture.checked = flag));
        break;
      default:
        this.kyusyuOkinawa.forEach(prefecture => (prefecture.checked = flag));
    }
  }

  private setUpRegions() {
    // 選択されている都道府県のデータを受け取り、チェックボックス用にデータを加工
    const selectedPrefectureIds = this.data.map(selectedPrefecture => selectedPrefecture.id);

    this.prefectureDataWithChecked = COMMON_ID_PREFECTURES.map(prefecture => {
      return {
        id: prefecture.id,
        name: prefecture.name,
        region: prefecture.region,
        checked: selectedPrefectureIds.find(selectedPrefectureId => selectedPrefectureId === prefecture.id) ? true : false
      };
    });

    // チェックボックス用に各地域のデータを作成
    this.hokkaidoTohoku = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.HOKKAIDO_TOHOKU);
    this.kanto = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.KANTO);
    this.koshinetsu = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.KOSHINETSU);
    this.hokuriku = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.HOKURIKU);
    this.tokai = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.TOKAI);
    this.kinki = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.KINKI);
    this.chugoku = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.CHUGOKU);
    this.shikoku = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.SHIKOKU);
    this.kyusyuOkinawa = this.prefectureDataWithChecked.filter(prefecture => prefecture.region === COMMON_ID_REGION.KYUSYU_OKINAWA);

    // 選択されている都道府県を格納する配列を作成
    this.selectedPrefectures = this.prefectureDataWithChecked
      .filter(prefecture => prefecture.checked)
      .map(selectedPrefecture => {
        return {
          id: selectedPrefecture.id,
          region: selectedPrefecture.region,
          name: selectedPrefecture.name
        };
      });
  }

  private setUpSelectAllCheckboxes() {
    // 各地域の都道府県がすべて選択されている場合は、すべてを選択するのチェックボックスにチェックを入れる
    const numOfSelectedInHokkaidoTohoku = this.hokkaidoTohoku.filter(prefecture => prefecture.checked === true).length;
    this.hokkaidoTohokuSelectAllChecked = this.hokkaidoTohoku.length === numOfSelectedInHokkaidoTohoku ? true : false;

    const numOfSelectedInKanto = this.kanto.filter(prefecture => prefecture.checked === true).length;
    this.kantoSelectAllChecked = this.kanto.length === numOfSelectedInKanto ? true : false;

    const numOfSelectedInKoshinetsu = this.koshinetsu.filter(prefecture => prefecture.checked === true).length;
    this.koshinetsuSelectAllChecked = this.koshinetsu.length === numOfSelectedInKoshinetsu ? true : false;

    const numOfSelectedInHokuriku = this.hokuriku.filter(prefecture => prefecture.checked === true).length;
    this.hokurikuSelectAllChecked = this.hokuriku.length === numOfSelectedInHokuriku ? true : false;

    const numOfSelectedInTokai = this.tokai.filter(prefecture => prefecture.checked === true).length;
    this.tokaiSelectAllChecked = this.tokai.length === numOfSelectedInTokai ? true : false;

    const numOfSelectedInKinki = this.kinki.filter(prefecture => prefecture.checked === true).length;
    this.kinkiSelectAllChecked = this.kinki.length === numOfSelectedInKinki ? true : false;

    const numOfSelectedInChugoku = this.chugoku.filter(prefecture => prefecture.checked === true).length;
    this.chugokuSelectAllChecked = this.chugoku.length === numOfSelectedInChugoku ? true : false;

    const numOfSelectedInShikoku = this.shikoku.filter(prefecture => prefecture.checked === true).length;
    this.shikokuSelectAllChecked = this.shikoku.length === numOfSelectedInShikoku ? true : false;

    const numOfSelectedInKyusyuOkinawa = this.kyusyuOkinawa.filter(prefecture => prefecture.checked === true).length;
    this.kyusyuOkinawaSelectAllChecked = this.kyusyuOkinawa.length === numOfSelectedInKyusyuOkinawa ? true : false;
  }
}
