import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';
import { findUsers, initializeFindUsers } from 'src/app/actions/user.actions';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { User } from 'src/app/models/user';
import { UserSearchCondition } from 'src/app/models/user-search-condition';
import { getUsers } from 'src/app/selectors/user.selectors';
import { PlanUtil } from 'src/app/utils/plan-util';
import { updateSignedInUser } from '../../../actions/auth.actions';
import { dispatchInfoMessage, navigate } from '../../../actions/core.actions';
import { assignPlan, findSignedInUserPlans, initializeAssignPlanState } from '../../../actions/plan.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { AssignPlanData, Plan } from '../../../models/plan';
import { RootState } from '../../../reducers';
import { enter } from '../../../resources/animations';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { getAssignPlanResult } from '../../../selectors/plan.selectors';
import { Log } from '../../../utils/log';
import { MultiselectOption } from '../../widgets/autocomplete-multiselect/autocomplete-multiselect.component';

export interface PlanAssignMemberDialogData {
  isAdmin: boolean;
  plan: Plan;
  subjectId: string;
  plans: Plan[];
  currentDatetime: CurrentDateTime;
  maxAssignableNum: number;
}

@Component({
  selector: 'app-plan-assign-member-dialog',
  templateUrl: './plan-assign-member-dialog.component.html',
  styleUrls: ['./plan-assign-member-dialog.component.scss'],
  animations: [enter]
})
export class PlanAssignMemberDialogComponent implements OnInit, OnDestroy {
  users$: Observable<User[]>;
  filteredUsers$: Observable<User[]>;
  errorMessage = '';
  IsAssigning = false;
  maxAssignableNum: number;

  selectedUsers: MultiselectOption[] = [];
  selectableUsers: MultiselectOption[] = [];
  isFormDisabled = false;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<PlanAssignMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PlanAssignMemberDialogData
  ) {}

  ngOnInit(): void {
    this.setUp();
    this.enableForms();
    this.setUpSelectableUsers();
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeFindUsers());
  }

  goMembers() {
    this.dialogRef.close();
    if (this.data.isAdmin) {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveMembers(this.data.plan.organization, this.data.plan.schoolId) }));
    } else {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveMembers() }));
    }
  }

  assign() {
    Log.debug(this.LOG_SOURCE, 'メンバーを割当します');
    this.disableForms();
    this.errorMessage = '';

    const selectedUsersIds = this.selectedUsers.map(selectedUser => {
      return selectedUser.id;
    });

    const request: AssignPlanData = {
      userIds: selectedUsersIds,
      planId: this.data.plan.id,
      subjectId: this.data.subjectId
    };

    const assignPlanResult$ = this.store.select(getAssignPlanResult).pipe(
      filter(it => it != null),
      take(1)
    );
    const signedInUser$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      take(1)
    );

    combineLatest([assignPlanResult$, signedInUser$])
      .pipe(take(1))
      .subscribe(([result, user]) => {
        this.store.dispatch(initializeAssignPlanState());
        Log.debug(this.LOG_SOURCE, `assign member result: `, result);
        if (result.success) {
          if (selectedUsersIds.includes(user.id)) {
            // 自身にプランを割当てた場合はstore上のsignedInUserを更新
            const signedInUser = Object.assign({}, user);
            signedInUser.subjectId = this.data.subjectId;
            const visibleSubjectIds = signedInUser.visibleSubjectIds.concat();
            visibleSubjectIds.push(this.data.subjectId);
            visibleSubjectIds.sort();
            signedInUser.visibleSubjectIds = visibleSubjectIds;
            this.store.dispatch(updateSignedInUser({ user: signedInUser }));
            this.store.dispatch(findSignedInUserPlans({ signedInUser }));
          }

          this.dialogRef.close();
          this.store.dispatch(
            dispatchInfoMessage({
              message: `メンバーを割当しました`
            })
          );
          return;
        }

        Log.warn(this.LOG_SOURCE, `assign member error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });

    Log.debug(this.LOG_SOURCE, 'assign plan', request);
    this.store.dispatch(assignPlan(request));
  }

  cancel() {
    this.dialogRef.close();
  }

  multiselectChange(event: MultiselectOption[]) {
    // 選択された複数のユーザーを取得するための処理
    // console.log('selected members : ', event);
    this.selectedUsers = event;
  }

  private setUp() {
    this.maxAssignableNum = this.data.maxAssignableNum;
    this.users$ = this.store.select(getUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const userSearchCondition: UserSearchCondition = {
      organization: this.data.plan.organization,
      schoolId: this.data.plan.schoolId
    };
    this.store.dispatch(findUsers({ userSearchCondition }));
  }

  private setUpSelectableUsers() {
    this.users$.pipe(take(1)).subscribe(users => {
      const assignableUsers = users.filter(user =>
        PlanUtil.isAssignableUsers(this.data.plan, user, this.data.plans, this.data.currentDatetime, this.data.subjectId)
      );

      assignableUsers.sort((a, b) => {
        const aFullNameKana = a.familyNameKana + a.firstNameKana;
        const bFullNameKana = b.familyNameKana + b.firstNameKana;
        return aFullNameKana > bFullNameKana ? 1 : -1;
      });

      this.selectableUsers = assignableUsers.map(user => {
        return { id: user.id, name: user.familyName + ' ' + user.firstName };
      });
    });
  }

  private disableForms() {
    this.IsAssigning = true;
  }

  private enableForms() {
    this.IsAssigning = false;
  }
}
