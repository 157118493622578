import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { getUsersLoaded, getWatchedUsers } from 'src/app/selectors/user.selectors';
import { getPlans } from 'src/app/selectors/plan.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import {
  ADD_ACCOUNT_DIALOG_ADMIN_HEIGHT,
  ADD_ACCOUNT_DIALOG_HEIGHT,
  ADD_ACCOUNT_DIALOG_WIDTH,
  CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH,
  DELETE_ACCOUNT_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  EDIT_ACCOUNT_DIALOG_ADMIN_HEIGHT,
  EDIT_ACCOUNT_DIALOG_HEIGHT,
  EDIT_ACCOUNT_DIALOG_WIDTH,
  ACCOUNT_DIALOG_TYPE_ACCOUNTS,
  ORGANIZATIONS,
  PLAN_NAMES,
  PLAN_STATUS_NAMES,
  PlanStatuses,
  UnsubscribeTarget,
  SCHOOL_ORGANIZATION_ID,
  JUKU_ORGANIZATION_ID,
  ACCOUNT_FILTER_ACCOUNT_STATUS,
  ACCOUNT_FILTER_AUTHORIZATION,
  ACCOUNT_FILTER_ORGANIZATION,
  ACCOUNT_FILTER_PLAN_STATUS,
  ACCOUNT_FILTER_PLAN_SUBJECT,
  DEFAULT_EMAIL_PLACEHOLDER,
  DEFAULT_NAME_PLACEHOLDER,
  KEYBOARD_INPUT_DEBOUNCE_TIME,
  ACCOUNTS_PAGE_SIZE_OPTIONS
} from 'src/app/resources/config';
import { Log } from 'src/app/utils/log';
import { initializeWatchedUsers, watchUsers } from 'src/app/actions/user.actions';
import { findPlans, initializeFindPlansState } from 'src/app/actions/plan.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { navigate, setBrowserTitle, setTitle, unsubscribeWatchedProps } from 'src/app/actions/core.actions';
import { Dates } from 'src/app/utils/dates';
import { AddAccountDialogComponent, AddAccountDialogData } from '../add-account-dialog/add-account-dialog.component';
import { HighSchool, Organization } from 'src/app/models/common-data';
import { enter } from 'src/app/resources/animations';
import { DeleteAccountDialogComponent, DeleteAccountDialogData } from '../delete-account-dialog/delete-account-dialog.component';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { User } from 'src/app/models/user';
import { EditAccountDialogComponent, EditAccountDialogData } from '../edit-account-dialog/edit-account-dialog.component';
import {
  ChangeActiveAccountDialogComponent,
  ChangeActiveAccountDialogData
} from '../change-active-account-dialog/change-active-account-dialog.component';
import { Plan } from 'src/app/models/plan';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import {
  AccountStatusFilterType,
  AuthorizationFilterType,
  OrganizationFilterType,
  PlanStatusFilterType
} from 'src/app/models/account-filter';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { PlanUtil, UserAssignmentPlan } from '../../../utils/plan-util';
import { Juku } from '../../../models/juku';
import { getJukus } from '../../../selectors/juku.selectors';
import { findJukus } from '../../../actions/juku.actions';

interface AccountData {
  id: string;
  email: string;
  name: string;
  familyName: string;
  firstName: string;
  kana: string;
  familyNameKana: string;
  firstNameKana: string;
  plan: Plan;
  planName: string;
  organization: string;
  organizationName: string;
  schoolId: string;
  school: string;
  isAdmin: boolean;
  isActive: boolean;
  latestAppLaunchedDate: string;
  isOrganizationAdmin: boolean;
  isSchoolOrganization: boolean;
  isTermsAgree: boolean;
}

@Component({
  selector: 'app-account-list',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  animations: [enter]
})
export class AccountsComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  pageIndex = 0;
  pageSize = ACCOUNTS_PAGE_SIZE_OPTIONS[0];
  pageSizeOptions = ACCOUNTS_PAGE_SIZE_OPTIONS;
  totalAccounts = 0;
  totalFilteredAccounts = 0;
  pageEvent: PageEvent;

  defaultDisplayedColumns: string[] = [
    'isActive',
    'id',
    'name',
    'kana',
    'school',
    'organization',
    'authority',
    'latestAppLaunchedDate',
    'planName',
    'editAccount',
    'removeAccount'
  ];
  accounts$: Observable<AccountData[]>;
  signInUser$: Observable<User>;
  highSchools$: Observable<HighSchool[]>;
  jukus$: Observable<Juku[]>;
  usersLoaded$: Observable<boolean>;
  watchedUsers$: Observable<User[]>;
  plans$: Observable<Plan[]>;
  currentDateTime$: Observable<CurrentDateTime>;
  displayedColumns: string[] = [...this.defaultDisplayedColumns];
  sortDirection: 'asc' | 'desc' = 'desc';
  isSortDirectionChanged = false;
  private LOG_SOURCE = this.constructor.name;
  private title = 'BtoSアカウント一覧';

  filterForm: FormGroup;
  accountStatusFilterTypes: AccountStatusFilterType[] = ACCOUNT_FILTER_ACCOUNT_STATUS;
  selectedAccountStatusId: number;
  organizationFilterTypes: OrganizationFilterType[] = ACCOUNT_FILTER_ORGANIZATION;
  selectedOrganizationId: number;
  authorizationFilterTypes: AuthorizationFilterType[] = ACCOUNT_FILTER_AUTHORIZATION;
  selectedAuthorizationId: number;
  planStatusFilterTypes: PlanStatusFilterType[] = ACCOUNT_FILTER_PLAN_STATUS;
  selectedPlanStatusId: number;
  planSubjectFilterTypes: PlanStatusFilterType[] = ACCOUNT_FILTER_PLAN_SUBJECT;
  selectedPlanSubjectId: number;

  emailPlaceholder: string = DEFAULT_EMAIL_PLACEHOLDER;
  private emailSubscription: Subscription;
  enteredEmail: string;
  namePlaceholder: string = DEFAULT_NAME_PLACEHOLDER;
  private nameSubscription: Subscription;
  enteredName: string;

  constructor(private store: Store<RootState>, private dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
    this.usersLoaded$ = this.store.select(getUsersLoaded);
    this.signInUser$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
  }

  ngOnDestroy() {
    this.store.dispatch(initializeFindPlansState());
    this.store.dispatch(initializeWatchedUsers());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.WATCH_USERS }));
    if (this.emailSubscription) this.emailSubscription.unsubscribe();
    if (this.nameSubscription) this.nameSubscription.unsubscribe();
  }

  addAccount() {
    combineLatest([this.signInUser$, this.highSchools$, this.jukus$])
      .pipe(take(1))
      .subscribe(([signInUser, highSchools, jukus]) => {
        const config: MatDialogConfig<AddAccountDialogData> = {
          height: signInUser.isAdmin ? ADD_ACCOUNT_DIALOG_ADMIN_HEIGHT : ADD_ACCOUNT_DIALOG_HEIGHT,
          width: ADD_ACCOUNT_DIALOG_WIDTH,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          data: { signInUser, highSchools, jukus, type: ACCOUNT_DIALOG_TYPE_ACCOUNTS },
          disableClose: true
        };
        this.dialog.open(AddAccountDialogComponent, config);
      });
  }

  editAccount(account: AccountData) {
    const user: User = {
      id: account.id,
      familyName: account.familyName,
      firstName: account.firstName,
      familyNameKana: account.familyNameKana,
      firstNameKana: account.firstNameKana,
      organization: account.organization,
      schoolId: account.schoolId,
      school: account.school,
      isAdmin: account.isAdmin,
      isOrganizationAdmin: account.isOrganizationAdmin
    };

    combineLatest([this.signInUser$, this.highSchools$, this.jukus$])
      .pipe(take(1))
      .subscribe(([signInUser, highSchools, jukus]) => {
        const config: MatDialogConfig<EditAccountDialogData> = {
          height: signInUser.isAdmin ? EDIT_ACCOUNT_DIALOG_ADMIN_HEIGHT : EDIT_ACCOUNT_DIALOG_HEIGHT,
          width: EDIT_ACCOUNT_DIALOG_WIDTH,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          data: { signInUser, highSchools, jukus, user, type: ACCOUNT_DIALOG_TYPE_ACCOUNTS },
          disableClose: true
        };
        this.dialog.open(EditAccountDialogComponent, config);
      });
  }

  removeAccount(account: AccountData) {
    Log.debug(this.LOG_SOURCE, `次のアカウント削除します: `, account);
    const data: DeleteAccountDialogData = { userId: account.id, email: account.email, isSignedInUserAdmin: true };
    const config: MatDialogConfig = {
      width: DELETE_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog.open(DeleteAccountDialogComponent, config);
  }

  changeActiveAccount(account: AccountData) {
    const data: ChangeActiveAccountDialogData = {
      userId: account.id,
      school: account.school,
      name: account.familyName + account.firstName,
      email: account.email,
      isActive: account.isActive
    };
    const config: MatDialogConfig = {
      width: CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog.open(ChangeActiveAccountDialogComponent, config);
  }

  toggleSortDirection() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.WATCH_USERS }));
    this.store.dispatch(watchUsers({ sortDirection: this.sortDirection }));
    // 並び順を変更した際は、最初のページに戻るようにするためのフラグを設定
    this.isSortDirectionChanged = true;
  }

  toggleDisplayEmail(isDisplayEmail) {
    if (isDisplayEmail) {
      this.displayedColumns.splice(1, 0, 'email');
    } else {
      this.displayedColumns = [...this.defaultDisplayedColumns];
    }
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'admin/schools':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSchools() }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  goPlans(organization, schoolId) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(organization, schoolId) }));
  }

  goPlanStatuses(planId) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlanStatuses(planId) }));
  }

  pageChangeEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    this.totalFilteredAccounts = event.length;
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  private pageAccounts(filteredAccounts: User[], pageIndex: number, pageSize: number): User[] {
    this.totalFilteredAccounts = filteredAccounts.length;
    const startIndex = (pageIndex = 0 ? pageIndex : pageIndex * pageSize);
    const endIndex = startIndex + pageSize;

    // フィルタをかけた際、または並び順を変更した場合は、最初のページに戻るようにする
    if ((this.pageEvent && this.pageEvent.length !== this.totalFilteredAccounts) || this.isSortDirectionChanged) {
      this.pageIndex = 0;
      if (this.isSortDirectionChanged) this.isSortDirectionChanged = false;
      return filteredAccounts.slice(0, pageSize);
    } else {
      return filteredAccounts.slice(startIndex, endIndex);
    }
  }

  private filterAccounts(users: User[], currentDateTime: CurrentDateTime, plans: Plan[]): User[] {
    let filteredAccounts: User[] = users;
    this.totalAccounts = users.length;

    if (this.selectedAccountStatusId !== 0) {
      const selectedAccountStatus = ACCOUNT_FILTER_ACCOUNT_STATUS.find(accountStatus => accountStatus.id === this.selectedAccountStatusId);
      filteredAccounts = filteredAccounts.filter(
        user => user.isActive === selectedAccountStatus.isActive || (user.isActive === undefined && this.selectedAccountStatusId === 1)
      );
    }

    if (this.selectedOrganizationId !== 0) {
      const selectedOrganization = ACCOUNT_FILTER_ORGANIZATION.find(organization => organization.id === this.selectedOrganizationId);
      filteredAccounts = filteredAccounts.filter(user => user.organization === selectedOrganization.organization);
    }

    switch (this.selectedAuthorizationId) {
      case 1:
        const selectedAuthorization = ACCOUNT_FILTER_AUTHORIZATION.find(authorization => authorization.id === this.selectedAuthorizationId);
        filteredAccounts = filteredAccounts.filter(user => user.isOrganizationAdmin === selectedAuthorization.isOrganizationAdmin);
        break;
      case 2:
        filteredAccounts = filteredAccounts.filter(user => user.isAdmin !== true && user.isOrganizationAdmin !== true);
        break;
      default:
        break;
    }

    if (this.selectedPlanStatusId !== 0) {
      filteredAccounts = filteredAccounts
        .map(account => this.filterByPlanStatus(account, currentDateTime, plans, this.selectedPlanStatusId))
        .filter(account => account !== null);
    }

    if (this.selectedPlanSubjectId !== 0) {
      filteredAccounts = filteredAccounts
        .map(account => this.filterByPlanSubject(account, currentDateTime, plans, this.selectedPlanSubjectId))
        .filter(account => account !== null);
    }

    if (this.enteredEmail) {
      filteredAccounts = filteredAccounts.filter(account => account.email.includes(this.enteredEmail));
    }

    if (this.enteredName) {
      filteredAccounts = filteredAccounts.filter(
        account =>
          (account.familyName + account.firstName).includes(this.enteredName) ||
          (account.familyNameKana + account.firstNameKana).includes(this.enteredName)
      );
    }

    Log.debug(this.LOG_SOURCE, `絞り込み件数: ${filteredAccounts.length}`);

    return filteredAccounts;
  }

  private filterByPlanStatus(user: User, currentDateTime: CurrentDateTime, plans: Plan[], planStatusRadioId: number): User | null {
    const userAssignmentPlan: UserAssignmentPlan | null = this.getUserAssignmentPlan(user, currentDateTime, plans);

    if (userAssignmentPlan && planStatusRadioId === 1) {
      return user;
    } else if (!userAssignmentPlan && planStatusRadioId === 2) {
      return user;
    }

    return null;
  }

  private filterByPlanSubject(user: User, currentDateTime: CurrentDateTime, plans: Plan[], planSubjectRadioId: number): User | null {
    const userAssignmentPlan: UserAssignmentPlan | null = this.getUserAssignmentPlan(user, currentDateTime, plans);

    // 未割当の場合
    if (!userAssignmentPlan) return null;

    // プランが割当られている場合、該当の教科であれば、ユーザー情報を返す
    const planSubjectIdFromRadioId = ACCOUNT_FILTER_PLAN_SUBJECT.find(subject => subject.id === planSubjectRadioId).planSubjectId;

    if (userAssignmentPlan.assignmentSubjectIds.includes(planSubjectIdFromRadioId)) {
      return user;
    } else {
      return null;
    }
  }

  private getUserAssignmentPlan(user: User, currentDateTime: CurrentDateTime, plans: Plan[]): UserAssignmentPlan | null {
    const schoolPlans = plans.filter(
      schoolPlan => user.schoolId !== undefined && schoolPlan.schoolId === user.schoolId && schoolPlan.records !== undefined
    );

    const userAssignmentPlan = PlanUtil.getUserAssignmentPlan(user.id, schoolPlans, currentDateTime);
    return userAssignmentPlan;
  }

  private setUp() {
    this.setUpForms();
    this.resetForms();
    this.setUpEmailChange();
    this.setUpNameChange();
    this.setUpData();
  }

  private setUpAccountData(user: User, currentDateTime: CurrentDateTime, plans: Plan[]): AccountData {
    let planName: string;

    const schoolPlans = plans.filter(
      schoolPlan => user.schoolId !== undefined && schoolPlan.schoolId === user.schoolId && schoolPlan.records !== undefined
    );

    const userAssignmentPlan: UserAssignmentPlan | null = PlanUtil.getUserAssignmentPlan(user.id, schoolPlans, currentDateTime);
    planName = userAssignmentPlan !== null ? PLAN_NAMES[userAssignmentPlan.plan.subjectId] : PLAN_STATUS_NAMES[PlanStatuses.UNASSIGNED];

    if (userAssignmentPlan !== null) planName += PLAN_STATUS_NAMES[userAssignmentPlan.status];

    const userOrganization: Organization = ORGANIZATIONS.find(organization => organization.id === user.organization);
    const userOrganizationName: string = userOrganization ? userOrganization.name : '';

    return {
      id: user.id,
      email: user.email,
      name: `${user.familyName}　${user.firstName}`,
      familyName: user.familyName,
      firstName: user.firstName,
      kana: `${user.familyNameKana}　${user.firstNameKana}`,
      familyNameKana: user.familyNameKana,
      firstNameKana: user.firstNameKana,
      plan: userAssignmentPlan !== null ? userAssignmentPlan.plan : null,
      planName,
      organization: user.organization,
      organizationName: userOrganizationName,
      schoolId: user.schoolId,
      school: user.school,
      isAdmin: user.isAdmin != null ? user.isAdmin : false,
      isActive: user.isActive === undefined ? true : user.isActive,
      isOrganizationAdmin: user.isOrganizationAdmin === undefined ? false : user.isOrganizationAdmin,
      isTermsAgree: user.isTermsAgree === undefined ? false : user.isTermsAgree,
      isSchoolOrganization: user.organization === SCHOOL_ORGANIZATION_ID || user.organization === JUKU_ORGANIZATION_ID ? true : false,
      latestAppLaunchedDate: user.latestAppLaunchedDate
        ? Dates.stdDateStringFromIso(user.latestAppLaunchedDate)
            .split(' ')
            .join('\n')
        : '-'
    };
  }

  private setUpForms() {
    this.filterForm = new FormGroup({
      accountStatus: new FormControl(),
      organization: new FormControl(),
      authorization: new FormControl(),
      planStatus: new FormControl(),
      planSubject: new FormControl(),
      email: new FormControl(),
      name: new FormControl()
    });
  }

  private resetForms() {
    this.filterForm.get('accountStatus').setValue(0);
    this.selectedAccountStatusId = 0;
    this.filterForm.get('organization').setValue(0);
    this.selectedOrganizationId = 0;
    this.filterForm.get('authorization').setValue(0);
    this.selectedAuthorizationId = 0;
    this.filterForm.get('planStatus').setValue(0);
    this.selectedPlanStatusId = 0;
    this.filterForm.get('planSubject').setValue(0);
    this.selectedPlanSubjectId = 0;

    this.filterForm.get('email').setValue('');
    this.enteredEmail = '';
    this.filterForm.get('name').setValue('');
    this.enteredName = '';
  }

  private setUpData() {
    this.highSchools$ = this.store.select(StaticDataSelectors.getHighSchool).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.jukus$ = this.store.select(getJukus).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.plans$ = this.store.select(getPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);

    this.store.dispatch(watchUsers({ sortDirection: this.sortDirection }));
    this.store.dispatch(findJukus({}));
    this.store.dispatch(findPlans({}));
    this.store.dispatch(getCurrentDateTime());
  }

  private setUpFilteredAndPagedAccountData(pageIndex: number, pageSize: number) {
    combineLatest([this.currentDateTime$, this.plans$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([currentDateTime, plans]) => {
        this.accounts$ = this.store.select(getWatchedUsers).pipe(
          filter(it => it != null),
          map(users => {
            const filteredAccounts = this.filterAccounts(users, currentDateTime, plans);
            return this.pageAccounts(filteredAccounts, pageIndex, pageSize).map<AccountData>(user =>
              this.setUpAccountData(user, currentDateTime, plans)
            );
          })
        );
        // テーブルにデータの変更を反映する
        this.changeDetectorRefs.detectChanges();
      });
  }

  accountStatusChange() {
    this.selectedAccountStatusId = this.filterForm.get('accountStatus').value;
    const selectedAccountStatus = ACCOUNT_FILTER_ACCOUNT_STATUS.find(accountStatus => accountStatus.id === this.selectedAccountStatusId);
    Log.debug(this.LOG_SOURCE, `選択されたアカウント種別: ${selectedAccountStatus.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  organizationChange() {
    this.selectedOrganizationId = this.filterForm.get('organization').value;
    const selectedOrganization = ACCOUNT_FILTER_ORGANIZATION.find(organization => organization.id === this.selectedOrganizationId);
    Log.debug(this.LOG_SOURCE, `選択された団体種別: ${selectedOrganization.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  authorizationChange() {
    this.selectedAuthorizationId = this.filterForm.get('authorization').value;
    const selectedAuthorization = ACCOUNT_FILTER_AUTHORIZATION.find(authorization => authorization.id === this.selectedAuthorizationId);
    Log.debug(this.LOG_SOURCE, `選択された権限: ${selectedAuthorization.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  planStatusChange() {
    this.selectedPlanStatusId = this.filterForm.get('planStatus').value;
    const selectedPlanStatus = ACCOUNT_FILTER_PLAN_STATUS.find(planStatus => planStatus.id === this.selectedPlanStatusId);
    Log.debug(this.LOG_SOURCE, `選択されたプラン教科: ${selectedPlanStatus.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  planSubjectChange() {
    this.selectedPlanSubjectId = this.filterForm.get('planSubject').value;
    const selectedPlanSubject = ACCOUNT_FILTER_PLAN_SUBJECT.find(planSubject => planSubject.id === this.selectedPlanSubjectId);
    Log.debug(this.LOG_SOURCE, `選択された科目: ${selectedPlanSubject.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  private setUpEmailChange() {
    this.emailSubscription = this.filterForm
      .get('email')
      .valueChanges.pipe(debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME))
      .subscribe(() => this.emailInputChange());
  }

  private emailInputChange() {
    this.enteredEmail = this.filterForm.get('email').value;
    Log.debug(this.LOG_SOURCE, `入力されたメールアドレス: ${this.enteredEmail}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  private setUpNameChange() {
    this.nameSubscription = this.filterForm
      .get('name')
      .valueChanges.pipe(debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME))
      .subscribe(() => this.nameInputChange());
  }

  private nameInputChange() {
    this.enteredName = this.filterForm.get('name').value;
    Log.debug(this.LOG_SOURCE, `入力された氏名: ${this.enteredName}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  resetFilter() {
    this.resetForms();
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }
}
