import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { inOut } from 'src/app/resources/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CommonIdPaperInformationDialogData {
  title: string;
  description: string;
  examDate: string;
  nyushiName: string;
  examTime: string;
  subjectName: string;
  level: number;
  levelLabel: string;
  categories: string[];
}

@Component({
  selector: 'app-common-id-paper-information-dialog',
  templateUrl: './paper-information-dialog.component.html',
  styleUrls: ['./paper-information-dialog.component.scss'],
  animations: [inOut]
})
export class CommonIdPaperInformationDialogComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  title: any;
  description: any;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<CommonIdPaperInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdPaperInformationDialogData // @Inject(MAT_DIALOG_DATA) public title: string, // @Inject(MAT_DIALOG_DATA) public description: string, // @Inject(MAT_DIALOG_DATA) public examData: string, // @Inject(MAT_DIALOG_DATA) public nyushiName: string, // @Inject(MAT_DIALOG_DATA) public examTime: string
  ) {}

  // @Input() description: string;
  // @Input() examDate: string;
  // @Input() nyushiName: string;
  // @Input() examTime: string;
  // @Input() paper: ExamPaper;

  ngOnInit() {
    // console.log(this.data);
    // console.log(this.description);
  }

  ngOnDestroy() {}

  displayLevel() {
    switch (this.data.level) {
      case 1:
        return '★';
      case 2:
        return '★★';
      case 3:
        return '★★★';
      case 4:
        return '★★★★';
      case 5:
        return '';
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}
