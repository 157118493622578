import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of, combineLatest } from 'rxjs';
import { filter, shareReplay, map, startWith, take } from 'rxjs/operators';

import { DIALOG_ZERO_PADDING_PANEL_CLASS, SubjectId } from 'src/app/resources/config';
import { RootState } from 'src/app/reducers';
import { setTitle, navigate } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from 'src/app/actions/common-id/common-id-core.actions';

import { getPlaylists } from 'src/app/selectors/common-id/common-id-playlist.selectors';
import { findPlaylists } from 'src/app/actions//common-id/common-id-playlist.actions';
import { CommonIdPlaylist } from 'src/app/models/common-id/common-id-playlist';
import { enter } from 'src/app/resources/animations';
import { DataUtil } from 'src/app/utils/data-util';
import { Subject } from 'src/app/models/common-data';
import { Log } from 'src/app/utils/log';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { SubjectUtil } from '../../../../utils/subject-util';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
// import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import { Dates } from 'src/app/utils/dates';
import { ActivatedRoute } from '@angular/router';
import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';
import { DESCRIPTION_PREMIUM_DIALOG_WITDH } from 'src/app/resources/common-id-config';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

interface CommonIdPlaylistSubject {
  subjectName: string;
  playlists: CommonIdPlaylist;
}

interface PlaylistSubject {
  subjectName: string;
  playlist: CommonIdPlaylist;
}

@Component({
  selector: 'app-common-id-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  animations: [enter]
})
export class CommonIdPlaylistsComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セット';

  SUBJECT_ALL_ID = '99';
  SUBJECT_ALL_NAME = 'すべての科目';

  playlistSubjects$: Observable<PlaylistSubject[]>;
  playlists$: Observable<CommonIdPlaylist[]>;
  subjects$: Observable<Subject[]>;
  subjectOption: Subject[] = [];
  loaded$: Observable<boolean>;
  playlistForm: FormGroup;
  defaultSubjectId: string;
  signedInUser$: Observable<CommonIdUser>;
  subjectId$: Observable<string>;
  isPremiumUser: boolean;
  currentDateTime: CurrentDateTime;
  currentDateTimeString: string;
  currentDateTime$: Observable<CurrentDateTime>;
  tag$: Observable<string>;

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.defaultSubjectId = this.SUBJECT_ALL_ID;
    this.subjectId$ = of(this.defaultSubjectId);
    this.setUpForms();
    this.setUpTag();
    this.setUpPlaylistSubjects();
    this.loaded$ = of(false);

    this.setUpSubjectOption();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {}

  showPlaylistDetail(playlist: CommonIdPlaylist) {
    if (playlist.pending) return;

    Log.debug(this.LOG_SOURCE, 'playlist詳細を開きます: ', playlist);
    const url = RoutingPathResolver.resolvePlaylistDetail(playlist.playlistId);
    this.store.dispatch(navigate({ url }));
  }

  private setUpPlaylistSubjects() {
    this.store.dispatch(findPlaylists());

    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const playlists$ = this.store.select(getPlaylists).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([
      this.subjectId$,
      currentDateTime$,
      this.subjects$,
      this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none')),
      this.tag$
    ])
      //       .pipe(take(1))
      .subscribe(([subjectId, currentDateTime, subjects, signedInUser, tag]) => {
        // 体験プランのユーザーは、各教科１つ目のみクリック可とする
        const editPlayList = (playlists: CommonIdPlaylist[]) => {
          const editedPlaylist = playlists.filter(playlist =>
            tag === ''
              ? subjectId === this.SUBJECT_ALL_ID || subjectId === ''
                ? true
                : playlist.subjectId === subjectId
              : playlist.tags.find(playlistTag => playlistTag === tag) !== undefined
          );
          // TODO 有料おすすめ問題のフラグはどこか？
          return editedPlaylist.map(
            playlist => playlist
            // playlist.order !== 1 && signedInUser.isTrial ? { ...playlist, pending: true } : { ...playlist, pending: false }
          );
        };
        this.currentDateTime = currentDateTime;
        this.currentDateTimeString = Dates.stdDateStringFromIso(this.currentDateTime?.dateTime);

        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(this.currentDateTime, signedInUser);

        this.playlists$ = playlists$.pipe(map(playlists => editPlayList(playlists)));

        // TODO 全教科を含む教科リストはマスターデータはありますでしょうか？
        // TODO おすすめ問題の画像はどのタグに設定するか？
        this.playlistSubjects$ = playlists$.pipe(
          map(playlists => {
            const sortedPlayList = DataUtil.sortObjectArrayBySpecificKey(playlists, 'order');
            const editedPlayList = editPlayList(sortedPlayList).map(editedPlaylist => {
              return { subjectName: SubjectUtil.getName(subjects, editedPlaylist.subjectId), playlist: editedPlaylist };
            });
            return editedPlayList;
          })
        );
        this.loaded$ = this.playlistSubjects$.pipe(
          map(it => it != null),
          startWith(false)
        );

        /*
        const toPlayList = (subjectName: string) => (playLists: Playlist[]) => ({
          subjectName,
          playlists: editPlayList(DataUtil.sortObjectArrayBySpecificKey(playLists, 'order'))
        });

        const _playlistSubjects: Observable<CommonIdPlaylistSubject>[] = [];

        const englishPlaylists$ = playlists$.pipe(map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.ENGLISH)));
        _playlistSubjects.push(englishPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.ENGLISH)))));

        const mathPlaylists$ = playlists$.pipe(map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.MATH)));
        _playlistSubjects.push(mathPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.MATH)))));

        const nationalLanguagePlaylists$ = playlists$.pipe(
          map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.NATIONAL_LANGUAGE))
        );
        _playlistSubjects.push(
          nationalLanguagePlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.NATIONAL_LANGUAGE))))
        );

        const physicsPlaylists$ = playlists$.pipe(map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.PHYSICS)));
        _playlistSubjects.push(physicsPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.PHYSICS)))));

        const chemistryPlaylists$ = playlists$.pipe(
          map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.CHEMISTRY))
        );
        _playlistSubjects.push(chemistryPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.CHEMISTRY)))));

        const biologyPlaylists$ = playlists$.pipe(map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.BIOLOGY)));
        _playlistSubjects.push(biologyPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.BIOLOGY)))));

        const japaneseHistoryPlaylists$ = playlists$.pipe(
          map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.JAPANESE_HISTORY))
        );
        _playlistSubjects.push(japaneseHistoryPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.JAPANESE_HISTORY)))));

        const worldHistoryPlaylists$ = playlists$.pipe(
          map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.WORLD_HISTORY))
        );
        _playlistSubjects.push(worldHistoryPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.WORLD_HISTORY)))));
        this.playlistSubjects$ = combineLatest(_playlistSubjects);
        */
      });
  }

  private setUpSubjectOption() {
    combineLatest([this.subjects$]).subscribe(([subjects]) => {
      // 全ての科目の選択肢を追加する
      const allSubjectOption: Subject[] = [{ id: this.SUBJECT_ALL_ID, name: this.SUBJECT_ALL_NAME }];
      this.subjectOption = allSubjectOption.concat(subjects);
    });
  }

  private setUpTag() {
    this.tag$ = this.activatedRoute.queryParamMap.pipe(
      shareReplay(),
      map(queryParamMap => queryParamMap.get('tag') || '')
    );
  }

  private setUpForms() {
    this.playlistForm = new FormGroup({
      subjectId: new FormControl('', [Validators.required])
    });

    this.playlistForm.get('subjectId').setValue(this.defaultSubjectId);

    this.enableForms();
  }

  private disableForms() {
    Object.keys(this.playlistForm.controls).forEach(ctrlName => {
      this.playlistForm.get(ctrlName).disable();
    });
    // this.planCreating = true;
  }

  private enableForms() {
    Object.keys(this.playlistForm.controls).forEach(ctrlName => {
      this.playlistForm.get(ctrlName).enable();
    });
    // this.planCreating = false;
  }

  onChangeSubject() {
    // if (this.planForm.get('subjectId').value === PlanSubjectId.TRIAL) {
    this.subjectId$ = of(this.playlistForm.get('subjectId').value);
    this.setUpPlaylistSubjects();
  }

  clickPlaylist(playlist: CommonIdPlaylist) {
    if (this.isPremiumUser === false && playlist.freeFlg !== true) {
      const config: MatDialogConfig = {
        width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
        panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
        autoFocus: false,
        disableClose: true
      };
      this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
    } else {
      this.goPlaylistDetail(playlist.playlistId);
    }
  }

  goPlaylistDetail(playlistId: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylistDetail(playlistId) }));
  }
}
