import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { RootState } from 'src/app/reducers';
import { User } from '../../../models/user';
import { Log } from '../../../utils/log';
import { initializeUpdateUserIsOrganizationAdminState, updateUserIsOrganizationAdmin } from '../../../actions/user.actions';
import { getUpdateUserIsOrganizationAdminResult } from '../../../selectors/user.selectors';
import { filter, take } from 'rxjs/operators';
import { dispatchAppError, dispatchInfoMessage } from '../../../actions/core.actions';
import { enter } from '../../../resources/animations';

export interface ChangeOrganizationAuthorityDialogData {
  user: User;
}

@Component({
  selector: 'app-change-organization-authority-dialog',
  templateUrl: './change-organization-authority-dialog.component.html',
  styleUrls: ['./change-organization-authority-dialog.component.scss'],
  animations: [enter]
})
export class ChangeOrganizationAuthorityDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<ChangeOrganizationAuthorityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeOrganizationAuthorityDialogData
  ) {}

  ngOnInit() {}

  changeAuthority() {
    this.disableForms();

    const user: User = {
      id: this.data.user.id,
      isOrganizationAdmin: !this.data.user.isOrganizationAdmin
    };
    Log.debug(this.LOG_SOURCE, 'Update IsOrganizationAdmin', user);
    this.store.dispatch(updateUserIsOrganizationAdmin({ user }));

    this.store
      .select(getUpdateUserIsOrganizationAdminResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateUserIsOrganizationAdminState());
        if (result.success) {
          const message = `権限の変更が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          this.dialogRef.close(true);
        } else {
          Log.error(this.LOG_SOURCE, `権限の変更でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
          this.dialogRef.close(false);
        }

        this.enableForms();
      });
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.data.user.email} の権限の変更をしています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
