import { UAParser } from 'ua-parser-js';
import { Injectable, Inject } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as CoreActions from '../actions/core.actions';
import { Log } from '../utils/log';
import { ErrorSnackBarComponent } from '../views/components/error-snack-bar/error-snack-bar.component';
import { SubscriptionService } from '../services/subscription.service';
import {
  SNACK_BAR_DISPLAY_DURATION,
  SNACK_BAR_ZERO_PADDING_PANEL_CLASS,
  PROD_APP_NAME,
  STAGING_APP_NAME,
  DEBUG_APP_NAME,
  COMMON_PAGE_TITLE
} from '../resources/config';
import { environment } from 'src/environments/environment';
import { WINDOW_OBJECT } from '../utils/injection-tokens';

@Injectable()
export class CoreEffects {
  dispatchAppError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.dispatchAppError),
        tap(action => {
          const source = action.source;
          const error = action.error;
          Log.error(source, `${error.code}: ${error.message}, cause: ${error.cause}`, error.stack);
          this.snackBar.openFromComponent(ErrorSnackBarComponent, {
            data: error,
            panelClass: SNACK_BAR_ZERO_PADDING_PANEL_CLASS
          });
        })
      ),
    { dispatch: false }
  );

  dispatchInfoMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.dispatchInfoMessage),
        tap(action => {
          this.snackBar.open(action.message, null, { duration: SNACK_BAR_DISPLAY_DURATION });
        })
      ),
    { dispatch: false }
  );

  setBrowserTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.setBrowserTitle),
        tap(action => {
          const base =
            environment.envName === 'prod' ? PROD_APP_NAME : environment.envName === 'staging' ? STAGING_APP_NAME : DEBUG_APP_NAME;
          const pageTitle = action.subTitle ? action.subTitle : '';
          const title = `${base}${pageTitle} | ${COMMON_PAGE_TITLE}`;
          this.titleService.setTitle(title);
        })
      ),
    { dispatch: false }
  );

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.navigate),
        tap(action => {
          // プラン購入画面で遷移元URLを使用するため、画面遷移前に現在のURLを記録しておく
          localStorage.setItem('previousUrl', this.router.url);
          if (action.extras) this.router.navigate([action.url], action.extras);
          else this.router.navigate([action.url]);
        })
      ),
    { dispatch: false }
  );

  openWindow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.openWindow),
        tap(action => {
          const target = action.target ? action.target : '_blank';
          if (action.extras) {
            const tree = this.router.createUrlTree([action.url], action.extras);
            const url = this.urlSerializer.serialize(tree);
            this.window.open(url, target);
            return;
          }
          this.window.open(action.url, target);
        })
      ),
    { dispatch: false }
  );

  openPrintDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.openPrintDialog),
        tap(() => {
          const ua = new UAParser(this.window.navigator.userAgent).getResult();
          if (ua.browser.name.indexOf('Safari') > -1) {
            document.execCommand('print', false, null);
          } else {
            (this.window as any).print();
          }
        })
      ),
    { dispatch: false }
  );

  redirectPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.redirectPage),
        tap(action => {
          this.window.location.href = action.url;
        })
      ),
    { dispatch: false }
  );

  unsubscribeWatchedProps$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.unsubscribeWatchedProps),
        tap(action => {
          this.subscriptionService.unsubscribe(action.target);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private router: Router,
    private urlSerializer: UrlSerializer,
    @Inject(WINDOW_OBJECT) private window: Window,
    private subscriptionService: SubscriptionService,
    private titleService: Title
  ) {}
}
