import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, filter, shareReplay, startWith, take } from 'rxjs/operators';

// redux
import { dispatchAppError, dispatchInfoMessage, navigate, setTitle } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from 'src/app/actions/common-id/common-id-core.actions';
import {
  ScienceProblem,
  ReadableScienceProblem,
  EnglishProblem,
  ReadableEnglishProblem,
  NationalLanguageProblem,
  ReadableNationalLanguageProblem,
  HistoryProblem,
  ReadableHistoryProblem,
  Problem,
  ExamPaper
} from 'src/app/models/problem';
import {
  findScienceProblem,
  findEnglishProblem,
  findNationalLanguageProblemsById,
  findHistoryProblem,
  findScienceProblemIds,
  findEnglishProblemIds,
  findNationalLanguageProblemIds,
  findHistoryProblemIds,
  initializeMatchedProblemIdsState
} from 'src/app/actions/search.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import * as CommonIdAnsweredProblemActions from 'src/app/actions/common-id/common-id-answered-problem.actions';

import { RootState } from 'src/app/reducers';

import {
  getStaticMathData,
  getFetchedDate,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticEnglishData,
  getStaticNationalLanguageData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from 'src/app/selectors/static-data.selectors';
import {
  getScienceProblem,
  getEnglishProblem,
  getNationalLanguageProblemsWithSameId,
  getHistoryProblem,
  getReadableMathProblem,
  getReadablePhysicsProblem,
  getReadableChemistryProblem,
  getReadableBiologyProblem,
  getReadableEnglishProblem,
  getReadableNationalLanguageProblemsFromProblems,
  getReadableJapaneseHistoryProblem,
  getReadableWorldHistoryProblem,
  getReadableGeographyProblem,
  getReadablePoliticalEconomyProblem,
  getProblemSearching,
  getFirstProblemIdFromMatchedProblemIds,
  getLastProblemIdFromMatchedProblemIds,
  getMatchedProblemIds
} from 'src/app/selectors/search.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import * as CommonIdAnsweredProblemSelectors from 'src/app/selectors/common-id/common-id-answered-problem.selectors';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';

// utils
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { GAUtil } from 'src/app/utils/ga-util';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';

// models
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { CommonIdAnsweredProblems, CommonIdSaveAnsweredProblemsRequest } from 'src/app/models/common-id/common-id-answered-problem';
import { Level, Subject, University } from '../../../../models/common-data';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { User } from 'src/app/models/user';
import { SearchProblemsCondition } from 'src/app/models/search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { StaticCommonData } from 'src/app/models/static-common-data';

// components
import {
  CommonIdPaperInformationDialogComponent,
  CommonIdPaperInformationDialogData
} from '../paper-information-dialog/paper-information-dialog.component';
import { ProblemDetailFrameComponent } from '../../../widgets/problem-detail-frame/problem-detail-frame.component';

// configs & others
import { fadeInOut, showHide } from 'src/app/resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import {
  SubjectId,
  STATIC_DATA_CACHE_DAYS,
  META_VIEWPORT_OTHER,
  SCIENCE_IDS,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  CATEGORY_DELIMITER
} from 'src/app/resources/config';

import { COMMON_ID_FREE_YEARS, FROM_ANSWERED_PROBLEMS, PAPER_INFORMATION_DIALOG_WIDTH } from '../../../../resources/common-id-config';

import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'src/app/resources/common-id/ga';
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { ReadableDataMapper } from 'src/app/mappers/readable-data-mapper';
import { UAParser } from 'ua-parser-js';
import { GeneralError } from 'src/app/errors/general-error';
import { CustomErrorMessage } from 'src/app/errors/error-info';

type SearchProblemsConditionType =
  | SearchProblemsCondition<ScienceSearchCondition>
  | SearchProblemsCondition<EnglishSearchCondition>
  | SearchProblemsCondition<NationalLanguageSearchCondition>
  | SearchProblemsCondition<HistorySearchCondition>;

@Component({
  selector: 'app-common-id-search-by-categories-problem-detail',
  templateUrl: './search-by-categories-problem-detail.component.html',
  styleUrls: ['./search-by-categories-problem-detail.component.scss'],
  animations: [fadeInOut, showHide]
})
export class CommonIdSearchByCategoriesProblemDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  private problemId$: Observable<string>;
  private problemIds$: Observable<string[]>;
  private searchProblemsCondition$: Observable<SearchProblemsConditionType>;

  scienceIds: string[] = SCIENCE_IDS;
  problemId: string;

  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticEnglishData$: Observable<StaticEnglishData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;
  staticCommonData$: Observable<StaticCommonData>;
  staticSubjects$: Observable<Subject[]>;

  /** science-problems.component をそのまま利用するため array で定義 */
  readableScienceProblems$: Observable<ReadableScienceProblem[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableProblem$: Observable<ReadableScienceProblem | ReadableEnglishProblem | ReadableHistoryProblem | ReadableNationalLanguageProblem>;

  problem$: Observable<Problem>;
  scienceProblem$: Observable<ScienceProblem>;
  englishProblem$: Observable<EnglishProblem>;
  nationalLanguageProblems$: Observable<NationalLanguageProblem[]>;
  historyProblem$: Observable<HistoryProblem>;

  searching$: Observable<boolean>;
  problemSearching$: Observable<boolean>;
  loadingReadableProblems$: Observable<boolean> = of(true);

  isFirstProblem$: Observable<boolean>;
  isLastProblem$: Observable<boolean>;

  isPreconditionError = false;

  paperMode$: Observable<boolean>;
  trialMode$: Observable<boolean>;

  hidePaperProblemBtn = true;

  fromAnsweredProblems$: Observable<boolean>;
  subjectId$: Observable<string>;
  menuType$: Observable<'article' | 'spellcheck'> = of('article');

  toolbarTitle = '問題詳細';

  signedInUser$: Observable<CommonIdUser>;
  currentDateTime$: Observable<CurrentDateTime>;

  answeredProblems$: Observable<CommonIdAnsweredProblems>;
  isAnswered$: Observable<boolean>;
  isSavingAnswered$: Observable<boolean> = of(false);

  isPaidPaper$: Observable<boolean>;
  isPremiumUser$: Observable<boolean>;
  canDisplay$: Observable<boolean>;

  queryParams$: Observable<Params>;

  @ViewChild(ProblemDetailFrameComponent) problemDetailFrameComponent: ProblemDetailFrameComponent;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private dialog: MatDialog,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.toolbarTitle }));

    this.setUpCurrentDateTime();
    this.setUpUser();
    this.setUpProblemId(); // 該当の問題ID
    this.setUpCanDisplay(); // 問題画像を表示できるユーザーかチェック
    this.setUpStaticData();
    this.setUpTrialMode();
    this.setUpNavigationAndPaperMode();

    this.setUpSubjectId();
    this.setUpQueryParams();
    this.setUpFromAnsweredProblems();
    this.setUpSearchCondition();
    this.setUpProblemIds(); // 前へ次へに遷移していくために、該当の検索条件の問題IDを格納するため
    this.setUpSubjectProblem(); // 印刷用　examPaperがある時
    this.setUpProblem(); // 印刷用　examPaperがある時
    this.checkPreConditionError(); // 問題IDのチェック処理
    this.setUpReadableProblems(); // 画像の表示用 - app-problem-detail-frameにデータを渡す
    this.setUpReadableProblem(); // タイトル部分の表示用 + この試験の情報のダイアログ用のデータ
    this.setUpSearching();
    this.setUpProblemPositionFlags();

    this.setUpBrowserTitle();
    this.setUpAnsweredProblem();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch(initializeMatchedProblemIdsState());
    this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
    this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdSaveAnsweredProblemsState());
  }

  goBack() {
    combineLatest([this.queryParams$])
      .pipe(take(1))
      .subscribe(([queryParams]) => {
        this.store.dispatch(
          navigate({
            url: RoutingPathResolver.resolveCommonIdSearchByCategories(),
            extras: { queryParams }
          })
        );
      });
  }

  showPrint() {
    this.problemId$.pipe(take(1)).subscribe(problemId => {
      const eventParams = {
        'event_category': GA_EVENT_CATEGORIES.PRINT_PREVIEW,
        'event_label': problemId,
        'value': 1
      };
      GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
    });

    const ua = new UAParser(this.window.navigator.userAgent).getResult();
    if (ua.browser.name.indexOf('Safari') > -1) {
      document.execCommand('print', false, null);
    } else {
      (window as any).print();
    }
  }

  changeMenuType(event) {
    this.menuType$ = of(event);
    this.menuType$.pipe(take(1)).subscribe(menuType => this.setUpBrowserTitle(menuType));
    combineLatest([this.menuType$, this.problemId$])
      .pipe(take(1))
      .subscribe(([menuType, problemId]) => {
        const eventParams = {
          'event_category': menuType === 'article' ? GA_EVENT_CATEGORIES.PROBLEM_TAB : GA_EVENT_CATEGORIES.ANSWER_TAB,
          'event_label': problemId,
          'value': 1
        };
        GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
        this.setUpBrowserTitle(menuType);
      });
  }

  saveAnswered() {
    this.isSavingAnswered$ = of(true);
    combineLatest([this.signedInUser$, this.isAnswered$, this.problemId$, this.isPremiumUser$, this.canDisplay$])
      .pipe(take(1))
      .subscribe(([user, isAnswered, problemId, isPremiumUser, canDisplay]) => {
        // プレミアム会員または無料会員の最新年度の場合のみ、解答済みを保存・解除する処理を実行
        if (isPremiumUser || canDisplay) {
          const requestIsAnswered = !isAnswered;
          if (requestIsAnswered) {
            const eventParams = {
              'event_category': GA_EVENT_CATEGORIES.SOLVED_ON,
              'event_label': this.problemId,
              'value': 1
            };
            GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

            this.store.dispatch(
              dispatchInfoMessage({
                message: `解答済みに登録しています`
              })
            );
          } else {
            this.store.dispatch(
              dispatchInfoMessage({
                message: `解答済みを解除しています`
              })
            );
          }

          const request: CommonIdSaveAnsweredProblemsRequest = {
            userId: user.id,
            problems: [{ problemId, isAdd: requestIsAnswered }]
          };
          Log.debug(this.LOG_SOURCE, 'commonIdSaveAnsweredProblemsへのリクエスト: ', request);
          this.store.dispatch(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblems({ request }));
          this.store
            .select(CommonIdAnsweredProblemSelectors.getCommonIdSaveAnsweredProblemsResult)
            .pipe(
              filter(it => it != null),
              take(1)
            )
            .subscribe(result => {
              Log.debug(this.LOG_SOURCE, `commonIdSaveAnsweredProblemsからの結果: `, result);
              if (result.success) {
                if (requestIsAnswered) {
                  this.store.dispatch(
                    dispatchInfoMessage({
                      message: `解答済みに登録しました`
                    })
                  );
                } else {
                  this.store.dispatch(
                    dispatchInfoMessage({
                      message: `解答済みを解除しました`
                    })
                  );
                }
                this.isAnswered$ = of(requestIsAnswered);
              } else {
                Log.warn(
                  this.LOG_SOURCE,
                  `save Answered Problems error: err.code: ${result.error ? result.error.code : 'none'}`,
                  result.error
                );
                this.store.dispatch(
                  dispatchInfoMessage({
                    message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
                  })
                );
                this.isAnswered$ = of(isAnswered);
                this.problemDetailFrameComponent.resetToggleAnsweredProblemBtn();
              }

              this.isSavingAnswered$ = of(false);
              this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
              this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdSaveAnsweredProblemsState());
            });
        } else {
          this.isSavingAnswered$ = of(false);
        }
      });
  }

  setLoadingReadableProblems(param: boolean) {
    this.loadingReadableProblems$ = of(param);
  }

  showPreviousProblem() {
    this.setLoadingReadableProblems(true);
    setTimeout(() => {
      combineLatest([this.problemIds$, this.problemId$, this.queryParams$])
        .pipe(take(1))
        .subscribe(([problemIds, problemId, queryParameters]) => {
          const currentIndex = problemIds.findIndex(ids => ids === problemId);
          const nextProblemId = problemIds[currentIndex - 1];
          const queryParams = queryParameters;
          this.store.dispatch(
            navigate({
              url: RoutingPathResolver.resolveCommonIdSearchByCategoriesProblemDetail(nextProblemId),
              extras: { queryParams }
            })
          );
        });
    }, 200);
  }

  showNextProblem() {
    this.setLoadingReadableProblems(true);
    setTimeout(() => {
      combineLatest([this.problemIds$, this.problemId$, this.queryParams$])
        .pipe(take(1))
        .subscribe(([problemIds, problemId, queryParameters]) => {
          const currentIndex = problemIds.findIndex(ids => ids === problemId);
          const nextProblemId = problemIds[currentIndex + 1];
          const queryParams = queryParameters;
          this.store.dispatch(
            navigate({
              url: RoutingPathResolver.resolveCommonIdSearchByCategoriesProblemDetail(nextProblemId),
              extras: { queryParams }
            })
          );
        });
    }, 200);
  }

  showPaperInformation() {
    combineLatest([this.subjectId$])
      .pipe(take(1))
      .subscribe(([subjectId]) => {
        if (subjectId === SubjectId.ENGLISH) this.getEnglishDataForInfoDialog(subjectId);
        if (this.scienceIds.includes(subjectId)) this.getScienceDataForInfoDialog(subjectId);
        if (subjectId === SubjectId.NATIONAL_LANGUAGE) this.getNationalLanguageDataForInfoDialog(subjectId);
        if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) this.getHistoryDataForInfoDialog(subjectId);
      });
  }

  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.staticEnglishData$ = this.store.select(getStaticEnglishData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticMathData$ = this.store.select(getStaticMathData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticNationalLanguageData$ = this.store.select(getStaticNationalLanguageData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPhysicsData$ = this.store.select(getStaticPhysicsData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticChemistryData$ = this.store.select(getStaticChemistryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticBiologyData$ = this.store.select(getStaticBiologyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticJapaneseHistoryData$ = this.store.select(getStaticJapaneseHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticWorldHistoryData$ = this.store.select(getStaticWorldHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticGeographyData$ = this.store.select(getStaticGeographyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPoliticalEconomyData$ = this.store.select(getStaticPoliticalEconomyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticSubjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTrialMode() {
    this.trialMode$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      map(signedInUser => (signedInUser.isTrial === true ? true : false))
    );
    this.trialMode$ = of(false);
  }

  private setUpNavigationAndPaperMode() {
    this.paperMode$ = of(false);
  }

  private setUpProblemId() {
    this.problemId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('problemId') || ''),
      shareReplay(1)
    );

    combineLatest([this.problemId$])
      .pipe(take(1))
      .subscribe(([problemId]) => {
        this.isPreconditionError = problemId === '';
        // 問題IDが未入力の場合は「都合により～」の画面を表示する
        if (!problemId) {
          const error = GeneralError.customMessage(CustomErrorMessage.SEARCH_BY_CATEGORY_PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
          return;
        }
        this.problemId = problemId;
      });
  }

  private setUpSubjectId() {
    this.subjectId$ = this.activatedRoute.queryParams.pipe(
      map(queryParams => QueryParamsMapper.decodeSearchProblemsConditionQueryParams(queryParams)),
      map(condition => condition.subjectId)
    );
  }

  private setUpQueryParams() {
    this.queryParams$ = this.activatedRoute.queryParams.pipe(map(queryParams => queryParams));
  }

  private setUpFromAnsweredProblems() {
    this.fromAnsweredProblems$ = this.activatedRoute.queryParams.pipe(map(queryParams => queryParams.from === FROM_ANSWERED_PROBLEMS));
  }

  private setUpSearchCondition() {
    this.searchProblemsCondition$ = this.activatedRoute.queryParams.pipe(
      map(queryParams => QueryParamsMapper.decodeSearchProblemsConditionQueryParams(queryParams)),
      map(condition => {
        if (this.scienceIds.includes(condition.subjectId)) {
          const _condition = condition as SearchProblemsCondition<ScienceSearchCondition>;
          return _condition;
        } else if (condition.subjectId === SubjectId.ENGLISH) {
          const _condition = condition as SearchProblemsCondition<EnglishSearchCondition>;
          return _condition;
        } else if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          const _condition = condition as SearchProblemsCondition<NationalLanguageSearchCondition>;
          return _condition;
        } else if (condition.subjectId === SubjectId.JAPANESE_HISTORY || condition.subjectId === SubjectId.WORLD_HISTORY) {
          const _condition = condition as SearchProblemsCondition<HistorySearchCondition>;
          return _condition;
        }
      })
    );
  }

  private setUpProblemIds() {
    this.store.dispatch(initializeMatchedProblemIdsState());
    this.problemIds$ = this.store.select(getMatchedProblemIds).pipe(filter(it => it != null));

    this.searchProblemsCondition$.pipe(take(1)).subscribe(condition => {
      if (this.scienceIds.includes(condition.subjectId)) {
        this.store.dispatch(findScienceProblemIds({ condition }));
      } else if (condition.subjectId === SubjectId.ENGLISH) {
        this.store.dispatch(findEnglishProblemIds({ condition }));
      } else if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.store.dispatch(findNationalLanguageProblemIds({ condition }));
      } else if (condition.subjectId === SubjectId.JAPANESE_HISTORY || condition.subjectId === SubjectId.WORLD_HISTORY) {
        this.store.dispatch(findHistoryProblemIds({ condition }));
      }
    });
  }

  private setUpSubjectProblem() {
    this.scienceProblem$ = this.store.select(getScienceProblem).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.englishProblem$ = this.store.select(getEnglishProblem).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.nationalLanguageProblems$ = this.store.select(getNationalLanguageProblemsWithSameId).pipe(
      filter(it => it != null),
      shareReplay(1),
      map(problems => problems)
    );

    this.historyProblem$ = this.store.select(getHistoryProblem).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpProblem() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) this.problem$ = this.scienceProblem$.pipe(problem => problem);
      if (subjectId === SubjectId.ENGLISH) this.problem$ = this.englishProblem$.pipe(problem => problem);
      if (subjectId === SubjectId.NATIONAL_LANGUAGE) this.problem$ = this.nationalLanguageProblems$.pipe(map(problems => problems[0]));
      if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
        this.problem$ = this.historyProblem$.pipe(problem => problem);
      }
    });
  }

  private checkPreConditionError() {
    this.subscriptions.push(
      combineLatest([this.problemId$.pipe(filter(it => it !== '')), this.problemIds$, this.searchProblemsCondition$]).subscribe(
        ([problemId, problemIds, condition]) => {
          if (!problemIds.includes(problemId)) {
            this.isPreconditionError = true;
            this.searching$ = of(false); // ローディングアイコンを表示させないため
            this.loadingReadableProblems$ = of(false); // ローディングアイコンを表示させないため
            const error = GeneralError.customMessage(CustomErrorMessage.SEARCH_BY_CATEGORY_PROBLEM_DETAIL_FAILED_PRECONDITION);
            this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
            return;
          }
          this.isPreconditionError = false;

          this.callFindProblem(problemId, condition);
        }
      )
    );
  }

  private callFindProblem(problemId: string, condition: SearchProblemsConditionType) {
    if (this.scienceIds.includes(condition.subjectId)) {
      this.store.dispatch(findScienceProblem({ subjectId: condition.subjectId, problemId }));
    }
    if (condition.subjectId === SubjectId.ENGLISH) this.store.dispatch(findEnglishProblem({ subjectId: condition.subjectId, problemId }));
    if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
      this.store.dispatch(findNationalLanguageProblemsById({ subjectId: condition.subjectId, problemId }));
    }
    if (condition.subjectId === SubjectId.JAPANESE_HISTORY || condition.subjectId === SubjectId.WORLD_HISTORY) {
      this.store.dispatch(findHistoryProblem({ subjectId: condition.subjectId, problemId }));
    }
  }

  private setUpReadableProblems() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.readableScienceProblems$ = combineLatest([this.scienceProblem$]).pipe(
          switchMap(([problem]) => {
            const selector =
              subjectId === SubjectId.MATH
                ? this.store.select(getReadableMathProblem(problem))
                : subjectId === SubjectId.PHYSICS
                ? this.store.select(getReadablePhysicsProblem(problem))
                : subjectId === SubjectId.CHEMISTRY
                ? this.store.select(getReadableChemistryProblem(problem))
                : subjectId === SubjectId.BIOLOGY
                ? this.store.select(getReadableBiologyProblem(problem))
                : subjectId === SubjectId.GEOGRAPHY
                ? this.store.select(getReadableGeographyProblem(problem))
                : this.store.select(getReadablePoliticalEconomyProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      }
      if (subjectId === SubjectId.ENGLISH) {
        this.readableEnglishProblems$ = combineLatest([this.englishProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableEnglishProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      }
      if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.readableNationalLanguageProblems$ = combineLatest([this.nationalLanguageProblems$]).pipe(
          switchMap(([problems]) => {
            const selector = this.store.select(getReadableNationalLanguageProblemsFromProblems(problems));
            return selector;
          }),
          map(problems => {
            this.loadingReadableProblems$ = of(false);
            return problems;
          }),
          filter(it => it != null)
        );
      }
      if (subjectId === SubjectId.JAPANESE_HISTORY) {
        this.readableJapaneseHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableJapaneseHistoryProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      }
      if (subjectId === SubjectId.WORLD_HISTORY) {
        this.readableWorldHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableWorldHistoryProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      }
    });
  }

  private setUpReadableProblem() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.readableProblem$ = combineLatest([this.readableScienceProblems$, this.problemId$]).pipe(
          map(([readableScienceProblems, problemId]) => {
            return readableScienceProblems.find(problem => problem.id === problemId);
          })
        );
      }
      if (subjectId === SubjectId.ENGLISH) {
        this.readableProblem$ = combineLatest([this.readableEnglishProblems$, this.problemId$]).pipe(
          map(([readableEnglishProblems, problemId]) => {
            return readableEnglishProblems.find(problem => problem.id === problemId);
          })
        );
      }
      if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.readableProblem$ = combineLatest([this.readableNationalLanguageProblems$, this.problemId$]).pipe(
          map(([readableNationalLanguageProblems, problemId]) => {
            return readableNationalLanguageProblems.find(problem => problem.id === problemId);
          })
        );
      }
      if (subjectId === SubjectId.JAPANESE_HISTORY) {
        this.readableProblem$ = combineLatest([this.readableJapaneseHistoryProblems$, this.problemId$]).pipe(
          map(([readableJapaneseHistoryProblems, problemId]) => {
            return readableJapaneseHistoryProblems.find(problem => problem.id === problemId);
          })
        );
      }
      if (subjectId === SubjectId.WORLD_HISTORY) {
        this.readableProblem$ = combineLatest([this.readableWorldHistoryProblems$, this.problemId$]).pipe(
          map(([readableWorldHistoryProblems, problemId]) => {
            return readableWorldHistoryProblems.find(problem => problem.id === problemId);
          })
        );
      }
    });
  }

  private setUpProblemPositionFlags() {
    const problemId$ = this.problemId$.pipe(filter(it => it !== ''));
    this.isFirstProblem$ = combineLatest([
      problemId$,
      this.store.select(getFirstProblemIdFromMatchedProblemIds).pipe(filter(it => it != null))
    ]).pipe(map(([problemId, firstProblemId]) => problemId === firstProblemId));

    this.isLastProblem$ = combineLatest([
      problemId$,
      this.store.select(getLastProblemIdFromMatchedProblemIds).pipe(filter(it => it != null))
    ]).pipe(map(([problemId, lastProblemId]) => problemId === lastProblemId));
  }

  private setUpBrowserTitle(menuType: string = 'article') {
    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        let readableProblems$: Observable<
          ReadableScienceProblem[] | ReadableEnglishProblem[] | ReadableNationalLanguageProblem[] | ReadableHistoryProblem[]
        >;
        if (this.scienceIds.includes(subjectId)) {
          readableProblems$ = this.readableScienceProblems$;
        } else if (subjectId === SubjectId.ENGLISH) {
          readableProblems$ = this.readableEnglishProblems$;
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          readableProblems$ = this.readableNationalLanguageProblems$;
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          readableProblems$ = this.readableJapaneseHistoryProblems$;
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          readableProblems$ = this.readableWorldHistoryProblems$;
        }

        if (readableProblems$) {
          this.subscriptions.push(
            combineLatest([this.staticSubjects$, readableProblems$.pipe(filter(it => it !== null))]).subscribe(([subjects, problems]) => {
              const university: string = problems[0].university;
              const year: number = problems[0].year;
              const subject: string = SubjectUtil.getName(subjects, subjectId);
              const type = menuType === 'article' ? '問題' : '研究・解答';
              const title = `${university}　${year}年度　${subject}　${type}`;
              this.store.dispatch(setCommonIdBrowserTitle({ subTitle: `${title}`, problemDetailFlag: true }));
              setTimeout(() => {
                this.store.dispatch(setTitle({ title }));
              });
            })
          );
        }
      })
    );
  }

  private setUpCurrentDateTime() {
    this.store.dispatch(getCurrentDateTime());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none'));

    this.isPremiumUser$ = combineLatest([this.currentDateTime$, this.signedInUser$]).pipe(
      filter(it => it != null),
      map(([currentDateTime, signedInUser]) => CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser)),
      shareReplay(1)
    );
  }

  private setUpCanDisplay() {
    this.isPaidPaper$ = this.problemId$.pipe(
      map(problemId => !COMMON_ID_FREE_YEARS.includes('20' + problemId.slice(0, 2))),
      shareReplay(1)
    );

    this.canDisplay$ = combineLatest([this.isPaidPaper$, this.isPremiumUser$]).pipe(
      filter(it => it != null),
      map(([isPaidPaper, isPremiumUser]) => !(isPaidPaper && !isPremiumUser)),
      shareReplay(1)
    );
  }

  private setUpAnsweredProblem() {
    this.subscriptions.push(
      combineLatest([this.signedInUser$, this.problemId$]).subscribe(([user, problemId]) => {
        this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: user.id }));
        this.answeredProblems$ = this.store
          .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
          .pipe(filter(it => it != null));

        this.isAnswered$ = this.answeredProblems$.pipe(
          map(answeredProblems => {
            return answeredProblems.problems === undefined
              ? false
              : answeredProblems.problems.find(problem => problem.pId === problemId) !== undefined;
          })
        );
      })
    );
  }

  private setUpSearching() {
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemSearching$]).pipe(
      map(([problemSearching]) => {
        return problemSearching;
      })
    );
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  private getScienceDataForInfoDialog(subjectId: string) {
    combineLatest([
      this.scienceProblem$,
      this.staticCommonData$,
      this.staticMathData$,
      this.staticPhysicsData$,
      this.staticChemistryData$,
      this.staticBiologyData$,
      this.staticGeographyData$,
      this.staticPoliticalEconomyData$
    ])
      .pipe(take(1))
      .subscribe(
        ([
          scienceProblem,
          staticCommonData,
          staticMathData,
          staticPhysicsData,
          staticChemistryData,
          staticBiologyData,
          staticGeographyData,
          staticPoliticalEconomyData
        ]) => {
          let categories: string[] = [];
          if (subjectId === SubjectId.MATH) categories = this.getScienceCategories(scienceProblem, staticMathData);
          if (subjectId === SubjectId.PHYSICS) categories = this.getScienceCategories(scienceProblem, staticPhysicsData);
          if (subjectId === SubjectId.CHEMISTRY) categories = this.getScienceCategories(scienceProblem, staticChemistryData);
          if (subjectId === SubjectId.BIOLOGY) categories = this.getScienceCategories(scienceProblem, staticBiologyData);
          if (subjectId === SubjectId.GEOGRAPHY) categories = this.getScienceCategories(scienceProblem, staticGeographyData);
          if (subjectId === SubjectId.POLITICAL_ECONOMY) categories = this.getScienceCategories(scienceProblem, staticPoliticalEconomyData);

          const examPaper = scienceProblem.examPaper ? scienceProblem.examPaper : null;

          this.paperInfoDialogOpen(
            staticCommonData,
            subjectId,
            scienceProblem.universityId,
            scienceProblem.year,
            scienceProblem.departments,
            scienceProblem.problemNumber,
            scienceProblem.level,
            examPaper,
            categories
          );
        }
      );
  }

  private getScienceCategories(scienceProblem: ScienceProblem, staticScienceData: StaticScienceData): string[] {
    const categories = scienceProblem.categories.map(category => {
      const [subjectId, fieldId, unitId] = category.split(CATEGORY_DELIMITER);
      const subjectName = ReadableDataMapper.getScienceSubjectName(subjectId, staticScienceData);
      const fieldName = ReadableDataMapper.getScienceFieldName(fieldId, staticScienceData);
      const unitName = ReadableDataMapper.getScienceUnitName(unitId, staticScienceData);
      return `${subjectName} / ${fieldName} / ${unitName}`;
    });

    return categories;
  }

  private getEnglishDataForInfoDialog(subjectId: string) {
    combineLatest([this.englishProblem$, this.staticCommonData$, this.staticEnglishData$])
      .pipe(take(1))
      .subscribe(([englishProblem, staticCommonData, staticEnglishData]) => {
        const categories = this.getEnglishCategories(englishProblem, staticEnglishData);

        this.paperInfoDialogOpen(
          staticCommonData,
          subjectId,
          englishProblem.universityId,
          englishProblem.year,
          englishProblem.departments,
          englishProblem.problemNumber,
          englishProblem.level,
          englishProblem.examPaper,
          categories
        );
      });
  }

  private getEnglishCategories(englishProblem: EnglishProblem, staticEnglishData: StaticEnglishData): string[] {
    const categories = englishProblem.categories.map(category => {
      const [subjectId, fieldId, unitId] = category.split(CATEGORY_DELIMITER);
      const subjectName = ReadableDataMapper.getEnglishBunyaSubjectName(subjectId, staticEnglishData);
      const unitName = ReadableDataMapper.getEnglishBunyaUnitName(unitId, staticEnglishData);
      return `${subjectName} / ${unitName}`;
    });

    return categories;
  }

  private getNationalLanguageDataForInfoDialog(subjectId: string) {
    combineLatest([this.nationalLanguageProblems$, this.staticCommonData$, this.staticNationalLanguageData$])
      .pipe(take(1))
      .subscribe(([nationalLanguageProblems, staticCommonData, staticNationalLanguageData]) => {
        const nationalLanguageProblem = nationalLanguageProblems[0];
        const categories = this.getNationalLanguageCategories(nationalLanguageProblem, staticNationalLanguageData);

        this.paperInfoDialogOpen(
          staticCommonData,
          subjectId,
          nationalLanguageProblem.universityId,
          nationalLanguageProblem.year,
          nationalLanguageProblem.departments,
          nationalLanguageProblem.problemNumber,
          nationalLanguageProblem.level,
          nationalLanguageProblem.examPaper,
          categories
        );
      });
  }

  private getNationalLanguageCategories(
    nationalLanguageProblem: NationalLanguageProblem,
    staticNationalLanguageData: StaticNationalLanguageData
  ): string[] {
    // 重複している分野は表示しない
    const categories = nationalLanguageProblem.categories
      .filter((item, index) => nationalLanguageProblem.categories.indexOf(item) === index)
      .filter(category => {
        // ジャンルのみ
        const splitIds = category.split(CATEGORY_DELIMITER);
        return splitIds[2].startsWith('2') ? false : true;
      })
      .map(category => {
        const [subjectId, fieldId, unitId] = category.split(CATEGORY_DELIMITER);
        const subjectName = ReadableDataMapper.getNationalLanguageSubjectName(subjectId, staticNationalLanguageData);
        const unitName = ReadableDataMapper.getNationalLanguageUnitName(unitId, staticNationalLanguageData);
        return `${subjectName} / ${unitName}`;
      });

    return categories;
  }

  private getHistoryDataForInfoDialog(subjectId: string) {
    combineLatest([this.historyProblem$, this.staticCommonData$, this.staticJapaneseHistoryData$, this.staticWorldHistoryData$])
      .pipe(take(1))
      .subscribe(([historyProblem, staticCommonData, staticJapaneseHistoryData, staticWorldHistoryData]) => {
        const categories =
          subjectId === SubjectId.JAPANESE_HISTORY
            ? this.getHistoryCategories(historyProblem, staticJapaneseHistoryData)
            : this.getHistoryCategories(historyProblem, staticWorldHistoryData);

        this.paperInfoDialogOpen(
          staticCommonData,
          subjectId,
          historyProblem.universityId,
          historyProblem.year,
          historyProblem.departments,
          historyProblem.problemNumber,
          historyProblem.level,
          historyProblem.examPaper,
          categories
        );
      });
  }

  private getHistoryCategories(historyProblem: HistoryProblem, staticHistoryData: StaticHistoryData): string[] {
    const categories = historyProblem.categories.map(category => {
      const [subjectId, fieldId, unitId] = category.split(CATEGORY_DELIMITER);
      const subjectName = ReadableDataMapper.getScienceSubjectName(subjectId, staticHistoryData);
      const unitName = ReadableDataMapper.getHistoryUnitName(unitId, staticHistoryData);

      // 出題テーマを表示する
      if (category.startsWith('6')) {
        return `出題テーマ / ${unitName}`;
        // 分野融合の場合はユニット名のみ - 重複するため
      } else if (fieldId === '5') {
        return unitName;
      } else {
        return `${subjectName} / ${unitName}`;
      }
    });

    return categories;
  }

  private paperInfoDialogOpen(
    staticCommonData: StaticCommonData,
    subjectId: string,
    universityId: string,
    year: number,
    departments: string,
    problemNumber: number,
    level: number,
    examPaper: ExamPaper | null,
    categories: string[]
  ) {
    const universityName = this.getUniversityName(staticCommonData.universities, universityId);
    const subjectName = this.getSubjectName(staticCommonData.subjects, subjectId);
    const title = `${universityName} ${year}年度 大問${problemNumber}`;
    const examDate = examPaper && examPaper.examDate ? examPaper.examDate : '-';
    const nyushiName = examPaper && examPaper.nyushiName ? examPaper.nyushiName : '-';
    const examTime = examPaper && examPaper.examTime ? examPaper.examTime : '-';
    const levelDisplayName = this.getLevelName(staticCommonData.levels, level);
    const data: CommonIdPaperInformationDialogData = {
      title,
      description: departments,
      examDate,
      nyushiName,
      examTime,
      subjectName,
      level,
      levelLabel: levelDisplayName,
      categories
    };

    const config: MatDialogConfig = {
      width: PAPER_INFORMATION_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data,
      disableClose: true
    };
    this.dialog.open(CommonIdPaperInformationDialogComponent, config);
  }

  private getSubjectName(subjects: Subject[], subjectId: string): string {
    const subjectName = subjects.find(staticDataSubject => staticDataSubject.id === subjectId).name;
    return subjectName;
  }

  private getUniversityName(universities: University[], universityId: string): string {
    const universityName = universities.find(staticDataUniversity => staticDataUniversity.id === universityId).name;
    return universityName;
  }

  private getLevelName(levels: Level[], levelId: number): string {
    const levelName = levels.find(staticDataLevel => staticDataLevel.level === levelId).displayName;
    return levelName;
  }
}
