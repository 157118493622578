import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable, forkJoin, fromEvent, of, retry } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CommonIdPlaylist } from '../../../models/common-id/common-id-playlist';
import { Playlist, Theme, ThemeForDisplay } from '../../../models/playlist';
import {
  ExamPaper,
  ReadableEnglishPlaylistProblem,
  ReadableEnglishProblem,
  ReadableHistoryPlaylistProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadableNationalLanguageProblem,
  ReadableProblem,
  ReadableSciencePlaylistProblem,
  ReadableScienceProblem
} from '../../../models/problem';
import { User } from '../../../models/user';
import { SUBJECT_NAMES, SubjectId } from '../../../resources/config';
import { Dates } from '../../../utils/dates';
import { Log } from '../../../utils/log';
import { ProblemImageUtil } from '../../../utils/problem-image-util';
import { University } from '../../../models/common-data';

interface ProblemImage {
  url: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-problem-detail-frame-for-print',
  templateUrl: './problem-detail-frame-for-print.component.html',
  styleUrls: ['./problem-detail-frame-for-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProblemDetailFrameForPrintComponent implements OnInit, OnChanges {
  @Input() menuType: 'article' | 'spellcheck' = 'article';
  @Input() universities: University[];
  @Input() readableEnglishProblems: ReadableEnglishProblem[];
  @Input() readableScienceProblems: ReadableScienceProblem[];
  @Input() readableNationalLanguageProblems: ReadableNationalLanguageProblem[];
  @Input() readableJapaneseHistoryProblems: ReadableHistoryProblem[];
  @Input() readableWorldHistoryProblems: ReadableHistoryProblem[];
  @Input() englishPlaylistTheme: ThemeForDisplay<ReadableEnglishPlaylistProblem>;
  @Input() sciencePlaylistTheme: ThemeForDisplay<ReadableSciencePlaylistProblem>;
  @Input() nationalLanguagePlaylistTheme: ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>;
  @Input() japaneseHistoryPlaylistTheme: ThemeForDisplay<ReadableHistoryPlaylistProblem>;
  @Input() worldHistoryPlaylistTheme: ThemeForDisplay<ReadableHistoryPlaylistProblem>;
  @Input() playlist: Playlist | CommonIdPlaylist;
  @Input() theme: Theme;
  @Input() paperMode: boolean;
  @Input() trialMode: boolean;
  @Input() subjectId: string;
  // 頭紙フッタ部分のテキストをBtoS、BtoCで切り替えるためのフラグ
  @Input() isBToC: boolean;
  // BtoSの場合のみ必要
  @Input() signedInUser: User;

  playlistTheme:
    | ThemeForDisplay<ReadableEnglishPlaylistProblem>
    | ThemeForDisplay<ReadableSciencePlaylistProblem>
    | ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>
    | ThemeForDisplay<ReadableHistoryPlaylistProblem>;
  readablePlaylistProblem:
    | ReadableEnglishPlaylistProblem
    | ReadableSciencePlaylistProblem
    | ReadableNationalLanguagePlaylistProblem
    | ReadableHistoryPlaylistProblem;
  loaded$: Observable<boolean> = of(false);
  problemId: string;
  year: string;
  layoutType: number;
  readableProblem: ReadableProblem;
  subjectName: string;
  examDate: string;
  nyushiName: string;
  examTime: string;
  specialInfoData: string[];
  fImage: ProblemImage;
  problemImages: ProblemImage[];
  daimonId: string | null;
  daimonName: string | null;
  seriesName: string;
  headerProblemLabel: string;
  originalSources: string[];
  outputDate: string;

  private LOG_SOURCE = this.constructor.name;

  constructor(private detector: ChangeDetectorRef) {}
  ngOnInit() {}

  ngOnChanges(): void {
    this.setUpReadableProblemBySubjectId();
    this.setUpPlaylistThemeBySubjectId();

    this.subjectName = SUBJECT_NAMES[this.subjectId];
    const menuLabel = this.menuType === 'article' ? '問題' : '研究・解答';
    this.outputDate = Dates.simple4YmdStringFromIso(Dates.now());
    this.year = `20${this.problemId.slice(0, 2)}`;
    if (this.trialMode) {
      // 体験版プランで大問単位表示の際はは１つ目の大問を固定で表示する
      this.daimonId = !this.paperMode ? '01' : null;
    } else {
      this.daimonId = !this.paperMode ? this.problemId.slice(-2) : null;
    }
    this.daimonName = !this.paperMode ? this.problemId.slice(-1) : null;
    const fImageUrl: string = environment.cloudfrontHost + ProblemImageUtil.getPath(this.readableProblem.fImage, this.trialMode);
    const examPaper: ExamPaper = this.readableProblem.examPaper;
    if (this.readableProblem.isOriginalProblem) {
      this.originalSources = this.readableProblem.originalSource.match(/^[0-9]+:/)
        ? this.readableProblem.originalSource.split(':').map(description => {
            const year = description.slice(0, 2);
            const universityId = description.slice(2, 6);
            const universityName = this.universities.find(university => university.id === `D${universityId}`).name;
            let problemNumber = description.slice(10, 12);
            if (problemNumber.startsWith('0')) {
              problemNumber = problemNumber.slice(1);
            }
            return `20${year}年度 ${universityName} 大問${problemNumber}`;
          })
        : [this.readableProblem.originalSource];
      this.headerProblemLabel = `${this.subjectName} ${this.readableProblem.originalOverview} ${menuLabel}`;
    } else {
      this.headerProblemLabel = `${this.year}年 ${this.subjectName} ${this.readableProblem?.university} ${menuLabel}`;
    }
    this.examDate = examPaper && examPaper.examDate ? examPaper.examDate : '-';
    this.nyushiName = examPaper && examPaper.nyushiName ? examPaper.nyushiName : '-';
    this.examTime = examPaper && examPaper.examTime ? examPaper.examTime : '-';
    this.specialInfoData = [
      examPaper && examPaper.bikou ? `〔備考〕${examPaper.bikou}` : '',
      examPaper && examPaper.nyushiKamoku ? `〔入試科目〕${examPaper.nyushiKamoku}` : '',
      examPaper && examPaper.chuui ? `〔注意〕${examPaper.chuui}` : ''
    ].filter(Boolean);
    // 体験版ではf画像を使用ない
    if (!this.trialMode && this.paperMode && examPaper.chkFlg === '1' && this.readableProblem.fImage !== '') {
      this.getImage(fImageUrl)
        .pipe(retry(3))
        .pipe(
          take(1),
          catchError(error => {
            Log.debug(this.LOG_SOURCE, `load failed:`, error);
            return of(null);
          })
        )
        .subscribe((image: ImageData) => {
          const isRetina = fImageUrl.includes('@2x');
          this.fImage = {
            width: isRetina ? image.width / 2 : image.width,
            height: isRetina ? image.height / 2 : image.height,
            url: fImageUrl
          };

          this.setUp();
        });
    } else {
      this.setUp();
    }
  }

  calcImageLayout1(layoutType: number, images: ProblemImage[]): Array<Array<ProblemImage[]>> {
    const layoutImages: Array<Array<ProblemImage[]>> = [];
    let pages: Array<ProblemImage[]> = [];
    let containers: ProblemImage[] = [];

    if (images === undefined) {
      return layoutImages;
    }

    const maxHeight: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-content-h'))
        .trim()
        .replace('px', '')
    );
    const maxWidth: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-1-max-w'))
        .trim()
        .replace('px', '')
    );
    const gapY: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-1-gap-y'))
        .trim()
        .replace('px', '')
    );

    let height = 0;
    let daimon = '';
    images.map((image, index) => {
      const currentDaimon: string = this.getDaimonId(image.url);
      if (daimon === '') daimon = currentDaimon;

      // aspect ratio
      if (image.width > maxWidth) {
        image.height = image.height * (maxWidth / image.width);
        image.width = maxWidth;
      }

      // aspect ratio
      if (image.height > maxHeight) {
        image.width = image.width * (maxHeight / image.height);
        image.height = maxHeight;
      }

      if (height + image.height > maxHeight) {
        pages.push(containers);
        layoutImages.push(pages);
        containers = [];
        pages = [];
        height = 0;
      } else if (daimon !== currentDaimon) {
        daimon = currentDaimon;

        pages.push(containers);
        containers = [];
        height += gapY;
      }

      height += image.height;
      containers.push(image);

      if (index === images.length - 1) {
        if (containers.length > 0) pages.push(containers);
        if (pages.length > 0) layoutImages.push(pages);
      }
    });

    Log.debug(this.LOG_SOURCE, `calcImageLayout1:`, layoutImages);
    return layoutImages;
  }

  calcImageLayout2(layoutType: number, images: ProblemImage[]): Array<Array<ProblemImage[]>> {
    const layoutImages: Array<Array<ProblemImage[]>> = [];
    let pages: Array<ProblemImage[]> = [];
    let containers: ProblemImage[] = [];

    if (images === undefined) {
      return layoutImages;
    }

    const maxWidth: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-2-max-w'))
        .trim()
        .replace('px', '')
    );
    const maxHeight: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-2-max-h'))
        .trim()
        .replace('px', '')
    );
    const offSetTop: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-2-offset-top'))
        .trim()
        .replace('px', '')
    );
    const gapX: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-2-gap-x'))
        .trim()
        .replace('px', '')
    );
    const gapY: number = Number(
      String(getComputedStyle(document.querySelector('.problem-detail-frame-for-print')).getPropertyValue('--pd-print-type-2-gap-y'))
        .trim()
        .replace('px', '')
    );

    let width = 0;
    let daimon = '';
    images.map((image, index) => {
      const currentDaimon: string = this.getDaimonId(image.url);
      if (daimon === '') daimon = currentDaimon;

      // aspect ratio
      if (image.height > maxHeight) {
        image.width = image.width * (maxHeight / image.height);
        image.height = maxHeight;
      }

      // aspect ratio
      if (image.width > maxWidth) {
        image.height = image.height * (maxWidth / image.width);
        image.width = maxWidth;
      }

      if (width + image.width > maxWidth) {
        pages.push(containers);
        if (pages.length === 2) {
          layoutImages.push(pages);
          pages = [];
        }
        containers = [];
        width = 0;
      } else if (daimon !== currentDaimon) {
        daimon = currentDaimon;

        pages.push(containers);
        if (pages.length === 2) {
          layoutImages.push(pages);
          pages = [];
        }
        containers = [];
        width = 0;
      }

      width += image.width + gapX;
      containers.push(image);

      if (index === images.length - 1) {
        if (containers.length > 0) pages.push(containers);
        if (pages.length > 0) layoutImages.push(pages);
      }
    });

    Log.debug(this.LOG_SOURCE, `calcImageLayout2:`, layoutImages);
    return layoutImages;
  }

  calcImageLayout3(layoutType: number, images: ProblemImage[]): Array<Array<ProblemImage[]>> {
    const layoutImages: Array<Array<ProblemImage[]>> = [];
    let pages: Array<ProblemImage[]> = [];
    let containers: ProblemImage[] = [];

    if (images === undefined) {
      return layoutImages;
    }

    images.map((image, index) => {
      containers.push(image);

      if (containers.length === 2) {
        pages.push(containers);
        containers = [];

        if (pages.length === 2) {
          layoutImages.push(pages);
          pages = [];
        }
      }

      if (index === images.length - 1) {
        if (containers.length > 0) pages.push(containers);
        if (pages.length > 0) layoutImages.push(pages);
      }
    });

    Log.debug(this.LOG_SOURCE, `calcImageLayout3:`, layoutImages);
    return layoutImages;
  }

  private getDaimonId(url: string): string {
    // 掲載見合わせ画像の場合、大問IDがないので、ない場合には乱数で返す
    const daimonId: string | undefined = url.match('.+/(.+?).[a-z]+([?#;].*)?$')[1].split('_')[1];
    return daimonId
      ? daimonId
      : Math.random()
          .toString(32)
          .substring(2);
  }

  private setUp() {
    if (this.playlist) {
      // BtoCのおすすめ問題詳細ではシリーズ名が入る
      this.seriesName = (this.playlist as CommonIdPlaylist).seriesName;
    }

    this.loaded$ = of(false);
    const daimonHead: string = this.menuType === 'article' ? 'q' : 'a';
    this.layoutType =
      this.menuType === 'article' ? this.readableProblem.questionLayout.layoutType : this.readableProblem.answerLayout.layoutType;
    const targetImages: string[] = this.menuType === 'article' ? this.readableProblem.questionImages : this.readableProblem.answerImages;
    if (targetImages === undefined || targetImages.length === 0) {
      return;
    }
    const imageUrls: string[] = targetImages
      .map(img => environment.cloudfrontHost + ProblemImageUtil.getPath(img, this.trialMode))
      .filter(url => this.daimonId === null || url.includes(daimonHead + this.daimonId));

    const requests$: Observable<ImageData | Error>[] = imageUrls.map(url => this.getImage(url));
    forkJoin(requests$.map(request => request.pipe(retry(3))))
      .pipe(
        take(1),
        catchError(error => {
          Log.debug(this.LOG_SOURCE, `load failed:`, error);
          return of(null);
        })
      )
      .subscribe((images: ImageData[]) => {
        Log.debug(this.LOG_SOURCE, `images:`, images);
        if (images !== null) {
          this.problemImages = images.map((image: ImageData, i) => {
            const isRetina = imageUrls[i].includes('@2x');
            return {
              width: isRetina ? image.width / 2 : image.width,
              height: isRetina ? image.height / 2 : image.height,
              url: imageUrls[i]
            };
          });
        }

        this.loaded$ = of(true);
        this.detector.detectChanges();
      });
  }

  private getImage(imageUrl: string): Observable<ImageData | Error> {
    return new Observable<any>(observer => {
      const image = new Image();
      fromEvent(image, 'load')
        .pipe(take(1))
        .subscribe(e => {
          Log.debug(this.LOG_SOURCE, `load success:`, e.target);

          observer.next(e.target);
          observer.complete();
        });
      fromEvent(image, 'error')
        .pipe(take(1))
        .subscribe(error => {
          Log.debug(this.LOG_SOURCE, `load error:`, error);

          observer.error(error);
          image.remove();
        });
      image.src = imageUrl;
    });
  }

  private setUpReadableProblemBySubjectId() {
    switch (this.subjectId) {
      case SubjectId.ENGLISH:
        this.readableProblem = this.readableEnglishProblems[0];
        break;
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        this.readableProblem = this.readableScienceProblems[0];
        break;
      case SubjectId.NATIONAL_LANGUAGE:
        this.readableProblem = this.readableNationalLanguageProblems[0];
        break;
      case SubjectId.JAPANESE_HISTORY:
        this.readableProblem = this.readableJapaneseHistoryProblems[0];
        break;
      case SubjectId.WORLD_HISTORY:
        this.readableProblem = this.readableWorldHistoryProblems[0];
        break;
    }

    if (!this.readableProblem) return;
    Log.debug(this.LOG_SOURCE, `print readableProblem:`, this.readableProblem);
    Log.debug(this.LOG_SOURCE, `paperMode:`, this.paperMode);

    this.problemId = this.readableProblem.id;
  }

  private setUpPlaylistThemeBySubjectId() {
    switch (this.subjectId) {
      case SubjectId.ENGLISH:
        this.playlistTheme = this.englishPlaylistTheme;
        this.readablePlaylistProblem = this.englishPlaylistTheme
          ? this.englishPlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        this.playlistTheme = this.sciencePlaylistTheme;
        this.readablePlaylistProblem = this.sciencePlaylistTheme
          ? this.sciencePlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.NATIONAL_LANGUAGE:
        this.playlistTheme = this.nationalLanguagePlaylistTheme;
        this.readablePlaylistProblem = this.nationalLanguagePlaylistTheme
          ? this.nationalLanguagePlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.JAPANESE_HISTORY:
        this.playlistTheme = this.japaneseHistoryPlaylistTheme;
        this.readablePlaylistProblem = this.japaneseHistoryPlaylistTheme
          ? this.japaneseHistoryPlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.WORLD_HISTORY:
        this.playlistTheme = this.worldHistoryPlaylistTheme;
        this.readablePlaylistProblem = this.worldHistoryPlaylistTheme
          ? this.worldHistoryPlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
    }
  }
}
