import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, filter, take } from 'rxjs';

import { navigate, openWindow, redirectPage, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { findUsersByEmail, initializeCreateUsersState, initializeFindUsersByEmail } from 'src/app/actions/user.actions';
import { getUsersByEmail } from 'src/app/selectors/user.selectors';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { RootState } from '../../../reducers';

import { MailUtil } from 'src/app/utils/mail-util';
import { enter } from '../../../resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { User } from 'src/app/models/user';
import { CreateUsersRequest } from 'src/app/models/create-users-request';
import { DELETE_BOOKMARK_DIALOG_WIDTH, DIALOG_ZERO_PADDING_PANEL_CLASS } from 'src/app/resources/config';
import { MembersImportDialogComponent } from '../members-import-dialog/members-import-dialog.component';
import { environment } from '../../../../environments/environment';
import { UserFromCSV, Email, CSVError } from '../../../models/user-csv';

@Component({
  selector: 'app-members-import',
  templateUrl: './members-import.component.html',
  styleUrls: ['./members-import.component.scss'],
  animations: [enter]
})
export class MembersImportComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  private LOG_SOURCE = this.constructor.name;
  private title = '所属メンバー一括登録';

  // クエリパラメタ
  organizationId: string;
  schoolId: string;

  // ログインユーザー
  signedInUser$: Observable<User>;

  // フラグ
  isCsvUploading = false;
  isReadyToRegister = false;
  isRegisteringSuccess = false;
  isRegisteringError = false;

  // CSVファイル
  fileName: string;
  usersFromCsv: UserFromCSV[] = [];
  numOfCsvUsers = 0;
  numOfOrganizationAdminUsers = 0;
  numOfNonOrganizationAdminUsers = 0;
  emails: Email[] = [];

  // CSVエラーチェック
  csvErrors: CSVError[] = [];
  usersFoundByEmail$: Observable<User[]>;

  // 登録メンバーテーブル
  dataSource: UserFromCSV[] = [];
  displayedColumns: string[] = ['lineNumber', 'familyName', 'firstName', 'familyNameKana', 'firstNameKana', 'mail', 'authority'];
  HEADER_ITEM_NAMES: string[] = ['行番号', '姓', '名', 'せい', 'めい', 'メールアドレス', '権限'];

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpSignedInUser();
    this.getQueryParams();
    this.setUpUsersFoundByEmail();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeFindUsersByEmail());
    this.store.dispatch(initializeCreateUsersState());
  }

  goToMembers() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveMembers() }));
    this.signedInUser$.pipe(take(1)).subscribe(user => {
      this.store.dispatch(
        navigate({
          url: RoutingPathResolver.resolveMembers(user.isAdmin ? this.organizationId : null, user.isAdmin ? this.schoolId : null)
        })
      );
    });
  }

  openTemplateCsv() {
    this.store.dispatch(redirectPage({ url: environment.downloadFiles.membersImportTemplateCsv }));
  }

  openManualPdf() {
    this.store.dispatch(openWindow({ url: environment.downloadFiles.membersImportManualPdf }));
  }

  resetFile() {
    if (this.fileInput) this.fileInput.nativeElement.value = '';
    this.initializeVariables();
  }

  register() {
    // indexプロパティを省く
    const users: User[] = this.usersFromCsv.map(({ recordIndex, ...rest }) => {
      return rest;
    });

    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        let organization: string = null;
        let schoolId: string = null;

        if (signedInUser.isAdmin) {
          organization = this.organizationId;
          schoolId = this.schoolId;
        }
        if (signedInUser.isOrganizationAdmin) {
          organization = signedInUser.organization;
          schoolId = signedInUser.schoolId;
        }

        const data: CreateUsersRequest = {
          organization,
          schoolId,
          users
        };

        const config: MatDialogConfig = {
          width: DELETE_BOOKMARK_DIALOG_WIDTH,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          autoFocus: false,
          data,
          disableClose: true
        };

        this.dialog
          .open(MembersImportDialogComponent, config)
          .afterClosed()
          .subscribe(result => {
            switch (result) {
              case true: // 一括登録が成功した場合
                this.isReadyToRegister = false;
                this.isRegisteringSuccess = true;
                break;
              case false: // 一括登録が失敗した場合
                this.isReadyToRegister = false;
                this.isRegisteringError = true;
                break;
              default:
            }
          });
      });
  }

  onClick(event) {
    // 同一ファイルを選択しようとした際に onChange が発火しないため空にする
    event.target.value = '';
  }

  onFileUpload(event) {
    this.initializeVariables();
    this.isCsvUploading = true;

    // CSVの取り込み処理
    const file = event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    // UTF-8として読み込む
    reader.readAsText(file, 'utf-8');
    // ファイルの読み込み処理
    reader.onload = (e: any) => {
      const text = e.target.result as string;
      if (text.includes('�')) {
        // Shift-JISとして読み込み
        this.readAsShiftJIS(file);
      } else {
        // UTF-8として読み込み成功
        this.csvOperation(text);
      }
    };
  }

  private readAsShiftJIS(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const buffer = event.target.result;
      const decoder = new TextDecoder('shift-jis');
      const text = decoder.decode(buffer);
      this.csvOperation(text);
    };

    reader.readAsArrayBuffer(file);
  }

  private csvOperation(csvData: string) {
    // carriage return または Line feedごとにデータを作成して、配列に格納 (1行ずつのデータに分ける)
    const csvRecords = csvData.split(/\r\n|\n/);

    // CSVの件数が100件以上の場合は、エラー表示、その他の場合は、メールアドレスの重複チェックに進む
    const isNumOfUsersOk = this.numberOfUsersCheck(csvRecords);
    if (isNumOfUsersOk) {
      this.usersFromCsv = this.getUsersFromCSV(csvRecords);
      // Firestore内のメールアドレス重複チェック - Firestore内に重複しているアドレスがないか検索
      this.emailExistenceCheck();

      // storeより重複しているアドレスの取得
      this.subscribeExistenceEmails();
    } else {
      this.isCsvUploading = false;
    }
  }

  private numberOfUsersCheck(csvRecords: string[]): boolean {
    // csvの行数をチェック - ヘッダ行のみ、または、ユーザーが100人以上の場合はエラーオブジェクトを追加
    const excludeBlankCount = csvRecords.filter(record => record.trim() !== '').length - 1;
    this.numOfCsvUsers = excludeBlankCount;

    if (this.numOfCsvUsers < 1) {
      const message = 'ユーザーが登録されていません';
      const csvError: CSVError = { recordIndex: length, item: 'csv', message, itemValue: '' };
      this.csvErrors.push(csvError);
      return false;
    }

    if (this.numOfCsvUsers > 100) {
      const message = 'CSVファイルに設定できるユーザーの上限は100人です';
      const csvError: CSVError = { recordIndex: length, item: 'csv', message, itemValue: '' };
      this.csvErrors.push(csvError);
      return false;
    }
    return true;
  }

  private getUsersFromCSV(csvRecords: string[]): UserFromCSV[] {
    const usersFromCsv: UserFromCSV[] = [];

    const headerLength = this.HEADER_ITEM_NAMES.length - 1;
    for (let index = 1; index < csvRecords.length; index++) {
      const currentRecord = csvRecords[index].split(',');
      // 空白行かどうか
      const isBlank = currentRecord.length === 1 && currentRecord[0] === '' ? true : false;

      if (currentRecord.length === headerLength) {
        // ユーザー1件ずつ、エラーチェックと項目の取得を行い、配列に追加
        const userFromCsv = this.getUserFromCSV(index, currentRecord);
        usersFromCsv.push(userFromCsv);
      } else {
        // 行内に空の項目が存在する場合はエラーとし、空白行の場合は無視する
        if (!isBlank) {
          const message = 'CSVファイルの形式が規定と異なります。各行には6つの項目のみを含めてください';
          const recordIndex = index + 1;
          const csvError: CSVError = { recordIndex, item: '', message, itemValue: null };
          this.csvErrors.push(csvError);
        }
      }
    }
    return usersFromCsv;
  }

  private getUserFromCSV(index: number, csvRecord: string[]): UserFromCSV {
    // 行番号の設定
    const recordIndex = index + 1;

    // 苗字のチェック
    const familyName = csvRecord[0];
    this.nameErrorCheck(this.HEADER_ITEM_NAMES[1], index, familyName);

    // 名前のチェック
    const firstName = csvRecord[1];
    this.nameErrorCheck(this.HEADER_ITEM_NAMES[2], index, firstName);

    // 苗字かなのチェック
    const familyNameKana = csvRecord[2];
    this.nameErrorCheck(this.HEADER_ITEM_NAMES[3], index, familyNameKana);

    // 名前かなのチェック
    const firstNameKana = csvRecord[3];
    this.nameErrorCheck(this.HEADER_ITEM_NAMES[4], index, firstNameKana);

    // メールアドレスのチェック
    const email = csvRecord[4].trim();
    this.emailErrorCheck(this.HEADER_ITEM_NAMES[5], index, email);

    // 権限のチェック
    const isOrganizationAdmin = csvRecord[5] === '1' ? true : false;
    this.isOrganizationAdminErrorCheck(this.HEADER_ITEM_NAMES[6], index, csvRecord[5]);

    const userFromCsv: UserFromCSV = {
      recordIndex,
      familyName,
      firstName,
      familyNameKana,
      firstNameKana,
      email,
      isOrganizationAdmin
    };

    return userFromCsv;
  }

  private nameErrorCheck(item: string, recordIndex: number, itemValue: string) {
    this.requireCheck(item, recordIndex, itemValue);
    this.lengthCheck(item, recordIndex, itemValue, 20);
  }

  private requireCheck(item: string, recordIndex: number, itemValue: string) {
    if (itemValue.length === 0) {
      const message = item === this.HEADER_ITEM_NAMES[6] ? `${item}は必須項目です。半角で0か1を指定してください` : `${item}は必須項目です`;
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    }
  }

  private lengthCheck(item: string, recordIndex: number, itemValue: string, itemLength: number) {
    if (itemValue.length > itemLength) {
      const message = `${item}の文字数は${itemLength}文字以内にしてください`;
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    }
  }

  private emailErrorCheck(item: string, recordIndex: number, itemValue: string) {
    this.requireCheck(item, recordIndex, itemValue);
    if (itemValue) {
      const isEmailValidated = MailUtil.isValidEmail(itemValue);
      if (!isEmailValidated) {
        const message = 'メールアドレス形式にしてください';
        const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
        this.csvErrors.push(csvError);
      }
      this.lengthCheck(item, recordIndex, itemValue, 256);
      // ファイル内の重複チェック
      this.emailDuplicateCheck(item, recordIndex, itemValue);
    }
  }

  private emailDuplicateCheck(item: string, recordIndex: number, itemValue: string) {
    const emails = this.emails.map(element => {
      return element.email;
    });

    if (emails.includes(itemValue)) {
      const message = 'ファイル内に重複したメールアドレスが存在します';
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    } else {
      this.emails.push({ index: recordIndex, email: itemValue });
    }
  }

  private emailExistenceCheck() {
    const emails = this.emails.map(element => {
      return element.email;
    });

    // 10件ごとのメールアドレスの配列を、最大10件作成する
    const emailGroups: string[][] = [];
    for (let i = 0; i < emails.length; i += 10) {
      emailGroups.push(emails.slice(i, i + 10));
    }

    this.store.dispatch(initializeFindUsersByEmail());
    this.store.dispatch(findUsersByEmail({ emailGroups }));
  }

  private isOrganizationAdminErrorCheck(item: string, recordIndex: number, itemValue: string) {
    this.requireCheck(item, recordIndex, itemValue);
    if (itemValue && itemValue !== '0' && itemValue !== '1') {
      const message = `${item}は半角で0か1を指定してください`;
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    }
  }

  private subscribeExistenceEmails() {
    this.usersFoundByEmail$.pipe(take(1)).subscribe(users => {
      users.map(user => {
        if (user) {
          const message = 'メールアドレスが既に存在します';
          const recordIndex = this.emails.find(element => element.email === user.email).index;

          const csvError: CSVError = { recordIndex: recordIndex + 1, item: 'email', message, itemValue: user.email };
          this.csvErrors.push(csvError);
        }
      });

      // アップロードアイコンを非表示にする
      this.isCsvUploading = false;

      // エラーがある場合は、エラー表示をし、無い場合は登録メンバーの表示
      if (this.csvErrors.length > 0) {
        // エラーを行番号順に並び替え
        this.csvErrors.sort((a, b) => a.recordIndex - b.recordIndex);
        this.isReadyToRegister = false;
      } else {
        this.displayMembers();
      }
    });
  }

  private displayMembers() {
    // ユーザーを権限ごとにカウントアップ
    this.usersFromCsv.forEach(user => {
      if (user.isOrganizationAdmin === true) {
        this.numOfOrganizationAdminUsers = this.numOfOrganizationAdminUsers + 1;
      } else {
        this.numOfNonOrganizationAdminUsers = this.numOfNonOrganizationAdminUsers + 1;
      }
    });
    this.dataSource = this.usersFromCsv;
    this.isReadyToRegister = true;
  }

  private setUpSignedInUser() {
    this.signedInUser$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
  }

  private getQueryParams() {
    this.organizationId = this.activatedRoute.snapshot.paramMap.get('organization');
    this.schoolId = this.activatedRoute.snapshot.paramMap.get('schoolId');
  }

  private setUpUsersFoundByEmail() {
    this.usersFoundByEmail$ = this.store.select(getUsersByEmail).pipe(filter(it => it != null));
  }

  private initializeVariables() {
    this.dataSource = [];
    this.usersFromCsv = [];
    this.csvErrors = [];
    this.emails = [];
    this.fileName = '';

    this.isReadyToRegister = false;
    this.numOfCsvUsers = 0;
    this.numOfOrganizationAdminUsers = 0;
    this.numOfNonOrganizationAdminUsers = 0;
  }
}
