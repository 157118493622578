import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';

import { RootState } from '../../../reducers';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';

import { inOut } from 'src/app/resources/animations';
import { COMMON_ID_PREFECTURES } from '../../../resources/common-id-config';
import {
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  DISPLAY_UNIVERSITY_TYPES,
  NO_DISPLAY_DEPARTMENT_CATEGORY_IDS,
  SELECT_PREFECTURE_DIALOG_HEIGHT,
  SELECT_PREFECTURE_DIALOG_WITDH
} from '../../../resources/config';

import { DepartmentCategory } from 'src/app/models/common-data';
import { PrefectureChangeValuesParams, UniversityTypeWithChecked, Year } from 'src/app/models/search-univ-interfaces';
import { PrefectureCondition, UniversitySearchCondition } from 'src/app/models/university-search-condition';
import { PrefectureData } from 'src/app/models/university-search-prefecture-dialog-condition';
import { StaticCommonData } from '../../../models/static-common-data';

import { SelectPrefectureDialogComponent } from 'src/app/views/components/select-prefecture-dialog/select-prefecture-dialog.component';
import { DataUtil } from '../../../utils/data-util';

@Component({
  selector: 'app-search-univ-pref-base',
  templateUrl: './search-univ-pref-base.component.html',
  styleUrls: ['./search-univ-pref-base.component.scss'],
  animations: [inOut]
})
export class SearchUnivPrefBaseComponent implements OnInit {
  @Input() isSearchSummaryDisplayed: boolean;
  @Input() matchedUniversityCount$: Observable<number>;
  @Input() defaultDepartmentCategory: DepartmentCategory;
  @Input() previousCondition: UniversitySearchCondition<PrefectureCondition>;
  @Input() searchButtonDisabled$: Observable<boolean>;
  @Input() universityCountSearching$: Observable<boolean>;
  @Input() isBToC: boolean;
  @Input() selectableYears: Year[];
  @Input() visibleSubjectNames$: Observable<string>;

  @Output() menuHandlerClick = new EventEmitter<string>();
  @Output() changeValues = new EventEmitter<PrefectureChangeValuesParams>();
  @Output() resetFormsClick = new EventEmitter<PrefectureChangeValuesParams>();
  @Output() showSearchResultViewClick = new EventEmitter();

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  private defaultYear: string;

  matchedUniversityCount: number | undefined;

  selectedPrefectures: PrefectureData[] = [];
  selectedPrefectureNamesForSummary: string;

  selectedUniversityTypes: UniversityTypeWithChecked[];
  universityTypes: UniversityTypeWithChecked[];
  selectedUniversityTypeIds: string[] = [];

  yearFormControl = new FormControl();
  selectedYear: string | null;

  selectedDepartmentCategory: DepartmentCategory;
  selectableDepartmentCategories: DepartmentCategory[];

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;

  isResetButtonShown: boolean;

  ngOnInit() {
    this.setUpYears();

    this.setUpStaticCommonData();
    this.setUpSelectableDepartmentCategories();
    this.setUpUniversityTypes();
    this.initializeIsSearchSummaryDisplayed();
    this.resetSelectedPrefectures();

    // 前回の検索条件を設定
    if (this.previousCondition) {
      this.setPreviousSearchCondition(this.previousCondition);
      this.setIsResetButtonShown();
    }
  }

  resetForms() {
    this.resetSelectedPrefectures();
    this.yearFormControl.setValue(this.defaultYear);
    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
    this.selectedUniversityTypeIds = [];
    this.selectedUniversityTypes = [];

    this.initializeIsSearchSummaryDisplayed();

    this.resetFormsClick.emit({
      prefectures: this.selectedPrefectures,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory,
      universityTypeIds: this.selectedUniversityTypeIds
    });
  }

  onChangeConditions() {
    this.isSearchSummaryDisplayed = false;

    const top = 0;
    window.scrollTo({
      top,
      behavior: 'smooth'
    });
  }

  onUniversityTypeChange(eventValue) {
    this.selectedUniversityTypeIds = eventValue.map(univType => univType.id);
    this.changeValues.emit({
      prefectures: this.selectedPrefectures,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory,
      universityTypeIds: this.selectedUniversityTypeIds
    });
  }

  selectPrefectures() {
    const config: MatDialogConfig = {
      height: SELECT_PREFECTURE_DIALOG_HEIGHT,
      width: SELECT_PREFECTURE_DIALOG_WITDH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      disableClose: true,
      data: this.selectedPrefectures
    };
    this.dialog
      .open(SelectPrefectureDialogComponent, config)
      .afterClosed()
      .subscribe((result: PrefectureData[]) => {
        if (!result) return;
        this.selectedPrefectures = result.map(prefecture => {
          return { id: prefecture.id, region: prefecture.region, name: prefecture.name };
        });

        // 都道府県の選択がされている場合は都道府県のリセットボタンを表示する
        this.setIsResetButtonShown();

        this.changeValues.emit({
          prefectures: this.selectedPrefectures,
          year: this.selectedYear,
          department: this.selectedDepartmentCategory,
          universityTypeIds: this.selectedUniversityTypeIds
        });
      });
  }

  resetPrefectures() {
    this.resetSelectedPrefectures();

    this.changeValues.emit({
      prefectures: this.selectedPrefectures,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory,
      universityTypeIds: this.selectedUniversityTypeIds
    });
  }

  onSelectionChange() {
    // 年度の値を取得
    this.selectedYear = this.yearFormControl.value;
    this.changeValues.emit({
      prefectures: this.selectedPrefectures,
      year: this.selectedYear,
      department: this.selectedDepartmentCategory,
      universityTypeIds: this.selectedUniversityTypeIds
    });
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpYears() {
    this.defaultYear = this.selectableYears.map(year => year)[0].value;
    this.yearFormControl.setValue(this.defaultYear);
    this.selectedYear = this.defaultYear;
  }

  private setUpUniversityTypes() {
    this.selectedUniversityTypeIds = [];
    this.selectedUniversityTypes = [];

    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const filteredUniversityTypes = staticCommonData.universityTypes
          .filter(ut => DISPLAY_UNIVERSITY_TYPES.includes(ut.id))
          .map<UniversityTypeWithChecked>(ut => ({
            ...ut,
            checked: false
          }));
        // 大学種別IDの昇順に並び替え
        const sortedUniversityTypes = DataUtil.sortObjectArrayBySpecificKey(filteredUniversityTypes, 'id');
        this.universityTypes = sortedUniversityTypes;
      });
  }

  private setUpSelectableDepartmentCategories() {
    this.staticCommonData$.pipe(take(1)).subscribe(staticCommonData => {
      // 学部系統IDの昇順に並び替え
      this.selectableDepartmentCategories = [
        ...staticCommonData.departmentCategories.filter(it => !NO_DISPLAY_DEPARTMENT_CATEGORY_IDS.includes(it.id)),
        this.defaultDepartmentCategory
      ].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : Number(b.id) > Number(a.id) ? -1 : 0));
    });

    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
  }

  private initializeIsSearchSummaryDisplayed() {
    this.isSearchSummaryDisplayed = false;
  }

  private setPreviousSearchCondition(condition: UniversitySearchCondition<PrefectureCondition>) {
    // 都道府県名に前回の検索条件を設定
    condition.universityCondition.prefectureIds.map(prefectureId => {
      const name = COMMON_ID_PREFECTURES.find(commonIdPrefecture => commonIdPrefecture.id === prefectureId).name;
      const region = COMMON_ID_PREFECTURES.find(commonIdPrefecture => commonIdPrefecture.id === prefectureId).region;
      this.selectedPrefectures.push({ id: prefectureId, name, region });
      this.updateSelectedPrefectureNamesForSummary();
    });

    // 年度に前回の検索条件を設定
    this.selectedYear = condition.year.toString();
    this.yearFormControl.setValue(this.selectedYear);

    // 学部系統に前回の検索条件を設定
    if (condition.departmentCategoryId) {
      const departmentCategoryId = condition.departmentCategoryId.toString();
      const depCategory = this.selectableDepartmentCategories.find(it => it.id === departmentCategoryId);
      this.selectedDepartmentCategory = depCategory;
    } else {
      this.selectedDepartmentCategory = this.defaultDepartmentCategory;
    }

    // 大学種別に前回の検索条件を設定
    const universityTypeIds = condition.universityCondition.universityTypeIds;
    if (universityTypeIds && universityTypeIds.length > 0) {
      this.selectedUniversityTypeIds = universityTypeIds;
      this.selectedUniversityTypes = this.universityTypes.filter(universityType => universityTypeIds.includes(universityType.id));
    } else {
      this.selectedUniversityTypeIds = [];
      this.selectedUniversityTypes = [];
    }
  }

  private setIsResetButtonShown() {
    if (this.selectedPrefectures.length > 0) {
      this.isResetButtonShown = true;
      this.updateSelectedPrefectureNamesForSummary();
    }
  }

  private resetSelectedPrefectures() {
    this.selectedPrefectures = [];
    this.isResetButtonShown = false;
  }

  private updateSelectedPrefectureNamesForSummary() {
    this.selectedPrefectureNamesForSummary = this.selectedPrefectures.map(selectedPrefecture => selectedPrefecture.name).join(', ');
  }
}
