<app-wrapper size="xl" @enter *ngIf="plansData$ | async; else loadingProgress" isFitWithSmallScreen="true">
  <app-content-row *ngIf="isAdmin">
    <app-breadcrumbs *ngIf="isAdmin" (breadcrumbsClick)="breadcrumbsClickHandler($event)" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" *ngIf="isAdmin">settings</mat-icon>
        <mat-icon class="card-content-head-icon" *ngIf="isOrganizationAdmin">manage_accounts</mat-icon>
        <strong class="font-bold">{{ schoolName }}</strong>
      </app-card-content-head>
      <app-card-content-nav
        (menuClick)="menuClickHandler($event)"
        [menus]="[
          { key: 'admin/plans', icon: 'outlined_flag', label: 'プラン一覧', fontSet: 'material-icons-outlined', isActive: true },
          { key: 'admin/members', icon: 'group', label: '所属メンバー一覧', isActive: false }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row *ngIf="otherSchoolCheck()">
          <span *ngIf="organization === schoolOrganizationId">その他の学校にはプランを登録することができません。</span>
          <span *ngIf="organization === jukuOrganizationId">その他の塾・予備校にはプランを登録することができません。</span>
        </app-content-row>

        <app-content-row *ngIf="!otherSchoolCheck()">
          <div class="flex flex-wrap">
            <div>
              <p class="my-2 text-md pr-2">ご契約プランの管理を行います</p>
            </div>
            <span class="mx-auto"></span>
            <div>
              <button mat-flat-button color="primary" *ngIf="isAdmin && (plansData$ | async)?.length > 0" (click)="addPlan()">
                <mat-icon class="">add_circle_outline</mat-icon>
                プランを登録
              </button>
              <a
                mat-flat-button
                class="bg-gray-50 text-primary font-normal"
                target="_blank"
                [href]="memberContactUrl"
                *ngIf="!isAdmin && (plansData$ | async)?.length > 0"
              >
                プラン更新・変更のご相談
              </a>
            </div>
          </div>
        </app-content-row>
      </app-wrapper>

      <app-content-row *ngIf="!otherSchoolCheck()">
        <app-wrapper size="fluid">
          <app-content-no-data *ngIf="(plansData$ | async)?.length === 0">
            <span>契約プランがまだありません</span>
            <button mat-flat-button color="primary" class="mt-4" *ngIf="isAdmin" (click)="addPlan()">
              <mat-icon class="">add_circle_outline</mat-icon>
              プランを登録
            </button>
            <a mat-flat-button class="mt-4 mr-2 font-normal" color="primary" target="_blank" [href]="memberContactUrl" *ngIf="!isAdmin">
              プランを申込・お問合せ
            </a>
          </app-content-no-data>
        </app-wrapper>
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <app-table-overflow>
            <table mat-table [dataSource]="plansData$ | async" class="w-full" *ngIf="(plansData$ | async)?.length > 0">
              <ng-container matColumnDef="planName">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">プラン名</th>
                <td mat-cell *matCellDef="let element">
                  <app-label-with-icon iconName="lock" class="text-primary" *ngIf="element.isTrial">
                    <span class="text-black font-bold">{{ element.planName }}</span>
                  </app-label-with-icon>
                  <app-label-with-icon iconName="outlined_flag" class="text-primary" *ngIf="!element.isTrial">
                    <span class="text-black font-bold">{{ element.planName }}</span>
                  </app-label-with-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="memberNum">
                <th mat-header-cell *matHeaderCellDef style="min-width: 80px;">ユーザー数</th>
                <td mat-cell *matCellDef="let element">{{ element.memberNum }}</td>
              </ng-container>
              <ng-container matColumnDef="memberNames">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">ユーザー</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <ng-container *ngFor="let memberName of element.memberNames; let i = index">
                    <ng-container *ngIf="i < 10">{{ memberName }}</ng-container>
                    <ng-container *ngIf="i < 10 && element.memberNames.length > i + 1">, </ng-container>
                  </ng-container>
                  <ng-container *ngIf="element.memberNames.length > 10">...</ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="planStatus">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">契約状況</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <div class="text-primary" *ngIf="element.planStatus === planActiveStatus">
                    【アクティブ】 {{ element.planStartAt }} 〜 {{ element.planEndAt }} (終了まで{{ element.planLeftDay }}日)
                  </div>
                  <div class="text-black" *ngIf="element.planStatus === planReserveStatus">
                    【予約中】{{ element.planStartAt }} 〜 {{ element.planEndAt }} (開始まで{{ element.planToStartDay }}日)
                  </div>
                  <div class="text-gray-300" *ngIf="element.planStatus === planEndedStatus">
                    【終了】{{ element.planStartAt }} 〜 {{ element.planEndAt }}
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="text-right whitespace-nowrap">
                  <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal" (click)="planStatuses(element.planId)">
                    契約履歴
                  </button>
                  <button mat-flat-button color="primary" *ngIf="isAdmin || !element.isTrial" (click)="planAssign(element.planId)">
                    メンバー割当
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </app-table-overflow>
        </app-wrapper>
      </app-content-row>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
