import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CommonIdAnsweredProblem,
  CommonIdAnsweredProblems,
  CommonIdSaveAnsweredProblem,
  CommonIdSaveAnsweredProblemsRequest,
  CommonIdSaveAnsweredProblemsResponse
} from '../../models/common-id/common-id-answered-problem';
import { CallableFunction, Collection } from '../../resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class CommonIdAnsweredProblemService {
  constructor(private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  commonIdFindAnsweredProblems(userId: string): Observable<CommonIdAnsweredProblems> {
    return this.afs
      .collection<CommonIdAnsweredProblems>(Collection.COMMON_ID_ANSWERED_PROBLEM, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? ({} as CommonIdAnsweredProblems)
            : snapshot.docs.map(doc => {
                const answeredProblemId: string = doc.id;
                const data = doc.data();
                data.id = answeredProblemId;
                return data as CommonIdAnsweredProblems;
              })[0]
        )
      );
  }
  commonIdSaveAnsweredProblems(userId: string, problems: CommonIdSaveAnsweredProblem[]): Observable<CommonIdSaveAnsweredProblemsResponse> {
    const req: CommonIdSaveAnsweredProblemsRequest = { userId, problems };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_SAVE_ANSWERED_PROBLEMS);
    return callable(req);
  }
}
