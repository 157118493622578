import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { navigate, setTitle } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

import * as CommonIdPlaylistBookmarkActions from 'src/app/actions/common-id/common-id-playlist-bookmark.actions';
import * as CommonIdPlaylistBookmarkSelector from 'src/app/selectors/common-id/common-id-playlist-bookmark.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { BehaviorSubject, combineLatest, filter, Observable, of, take } from 'rxjs';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdPlaylistBookmark } from 'src/app/models/common-id/common-id-playlist-bookmark';
import * as CommonIdPlaylistActions from 'src/app/actions/common-id/common-id-playlist.actions';
import * as CommonIdPlaylistSelectors from 'src/app/selectors/common-id/common-id-playlist.selectors';

import * as CommonIdAnsweredProblemActions from 'src/app/actions/common-id/common-id-answered-problem.actions';
import * as CommonIdAnsweredProblemSelectors from 'src/app/selectors/common-id/common-id-answered-problem.selectors';

import { shareReplay } from 'rxjs/operators';
import { CommonIdPlaylist } from 'src/app/models/common-id/common-id-playlist';

import { Subject } from 'src/app/models/common-data';
import { getSubject } from 'src/app/selectors/static-data.selectors';
import { CommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsResult } from 'src/app/reducers/common-id/common-id-playlist-bookmark.reducer';
import { CommonIdAnsweredProblems } from 'src/app/models/common-id/common-id-answered-problem';
import { enter } from 'src/app/resources/animations';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DESCRIPTION_PREMIUM_DIALOG_WITDH } from 'src/app/resources/common-id-config';
import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';
import { DIALOG_ZERO_PADDING_PANEL_CLASS } from '../../../../resources/config';
import { DataUtil } from '../../../../utils/data-util';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, GA_EVENT_LABELS } from '../../../../resources/common-id/ga';
import { GAUtil } from '../../../../utils/ga-util';

@Component({
  selector: 'app-common-id-mylist-playlists',
  templateUrl: './mylist-playlists.component.html',
  styleUrls: ['./mylist-playlists.component.scss'],
  animations: [enter]
})
export class CommonIdMylistPlaylistsComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'マイリスト - おすすめ問題セット';
  private initializedShowingPlaylistSubject = new BehaviorSubject(false);
  initializedShowingPlaylist$ = this.initializedShowingPlaylistSubject.asObservable();

  user: CommonIdUser;
  isPremiumUser: boolean;
  currentDateTime: CurrentDateTime;
  bookmarkedPlaylists: CommonIdPlaylist[] = [];
  showingPlaylists: CommonIdPlaylist[] = [];

  playlistBookmarks$: Observable<CommonIdPlaylistBookmark>;
  bookmarkPlaylistProblemIds$: Observable<CommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsResult>;
  commonIdPlaylists$: Observable<CommonIdPlaylist[]>;
  subjects$: Observable<Subject[]>;
  answeredProblems$: Observable<CommonIdAnsweredProblems>;

  selectedSubjectId: string;

  onlyAllAnsweredPlaylists = false;
  checkboxStatus = false;

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  menuClickHandler(key) {
    switch (key) {
      case 'mylist-univ':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistUniversities() }));
        break;
      case 'mylist-problem':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistProblems() }));
        break;
      case 'mylist-answer-problem':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistAnswerProblems() }));
        break;
    }
  }

  ngOnDestroy() {}

  setUp() {
    this.commonIdPlaylists$ = this.store.select(CommonIdPlaylistSelectors.getPlaylists).pipe(filter<CommonIdPlaylist[]>(it => it != null));
    this.playlistBookmarks$ = this.store
      .select(CommonIdPlaylistBookmarkSelector.getCommonIdPlaylistBookmark)
      .pipe(filter<CommonIdPlaylistBookmark>(it => it != null));
    this.bookmarkPlaylistProblemIds$ = this.store
      .select(CommonIdPlaylistBookmarkSelector.getCommonIdPlaylistProblemIdsByPlaylistBookmarkIdsResult)
      .pipe(filter<CommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsResult>(it => it != null));
    this.answeredProblems$ = this.store
      .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
      .pipe(filter<CommonIdAnsweredProblems>(it => it != null));
    this.subjects$ = this.store.select(getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([
      currentDateTime$,
      this.subjects$,
      this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none'))
    ])
      .pipe(take(1))
      .subscribe(([currentDateTime, subjects, user]) => {
        this.user = user;

        this.currentDateTime = currentDateTime;
        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(this.currentDateTime, this.user);

        const _subjects = subjects.concat();

        _subjects.unshift({ id: '00', name: 'すべて' });
        this.subjects$ = of(_subjects);
        this.selectedSubjectId = '00';

        this.setUpPlaylistBookmark();
      });
  }

  setUpPlaylistBookmark() {
    // initialize
    this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState());

    // dispatch actions
    this.store.dispatch(CommonIdPlaylistActions.findPlaylists());
    this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmark({ userId: this.user.id }));

    // get latest
    combineLatest([this.playlistBookmarks$, this.commonIdPlaylists$])
      .pipe(take(1))
      .subscribe(([playlistBookmark, commonIdPlaylists]) => {
        const _bookmarkedPlaylists = Object.keys(playlistBookmark).length !== 0 ? playlistBookmark.playlists.map(pl => pl.pId) : [];
        const forSort = commonIdPlaylists.filter(pl => _bookmarkedPlaylists.includes(pl.playlistId));
        this.bookmarkedPlaylists = DataUtil.sortObjectArrayBySpecificKey(forSort, 'order');
        this.switchShowingPlaylists();
      });
  }

  switchShowingPlaylists() {
    let showPlaylists = this.bookmarkedPlaylists.concat();
    // 科目で絞り込み
    if (this.selectedSubjectId !== '00') {
      showPlaylists = showPlaylists.filter(playlist => playlist.subjectId === this.selectedSubjectId);
    }
    // 全問解答解答済みで絞り込み
    if (this.onlyAllAnsweredPlaylists) {
      combineLatest([this.bookmarkPlaylistProblemIds$, this.answeredProblems$])
        .pipe(take(1))
        .subscribe(([playlistProblemIds, answeredProblem]) => {
          const answered = Object.keys(answeredProblem).length !== 0 ? answeredProblem.problems.map(problem => problem.pId) : [];
          const showIds = [];
          playlistProblemIds.response.map(playlist => {
            const filteredArray = playlist.problemIds.filter(id => !answered.includes(id));
            if (filteredArray.length > 0) {
              showIds.push(playlist.playlistId);
            }
          });
          this.showingPlaylists = showPlaylists.filter(playlist => showIds.includes(playlist.playlistId));
          this.checkboxStatus = false;
          this.initializedShowingPlaylistSubject.next(true);
        });
    } else {
      this.showingPlaylists = showPlaylists;
      this.checkboxStatus = false;
      this.initializedShowingPlaylistSubject.next(true);
    }
  }

  changeShowingFlg(checked: boolean) {
    this.checkboxStatus = true;
    this.initializedShowingPlaylistSubject.next(false);
    this.onlyAllAnsweredPlaylists = checked;
    this.store.dispatch(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsState());
    this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
    if (checked) {
      this.store.dispatch(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds());
      this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: this.user.id }));
    }
    this.switchShowingPlaylists();
  }

  goPlaylistDetail(playlistId: string, flag: boolean) {
    if (flag) {
      const config: MatDialogConfig = {
        width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
        panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
        autoFocus: false,
        disableClose: true
      };
      this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
    } else {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylistDetail(playlistId) }));
    }
  }

  goPlaylist() {
    const url = RoutingPathResolver.resolveCommonIdPlaylists();
    this.store.dispatch(navigate({ url }));
  }

  bannerClick() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.NYUSHI_PREMIUM_BANNER,
      'event_label': GA_EVENT_LABELS.MYLIST_PLAYLISTS,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop(), extras: { queryParams: { to: 'premium' } } }));
  }
}
