import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, combineLatest, from, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { UAParser } from 'ua-parser-js';
import { CurrentDateTime } from './../../../../models/current-date-time';

import * as CommonIdPaperBookmarkActions from 'src/app/actions/common-id/common-id-paper-bookmark.actions';
import { dispatchAppError, dispatchInfoMessage, navigate, openWindow, setTitle } from 'src/app/actions/core.actions';
import {
  findEnglishProblemByPaperId,
  findHistoryProblemByPaperId,
  findNationalLanguageProblemByPaperId,
  findScienceProblemByPaperId
} from 'src/app/actions/search.actions';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { GeneralError } from 'src/app/errors/general-error';
import {
  CommonIdAddPaperBookmarkData,
  CommonIdBookmarkPaper,
  CommonIdDeletePaperBookmarkData,
  CommonIdPaperBookmark
} from 'src/app/models/common-id/common-id-paper-bookmark';
import {
  EnglishProblem,
  HistoryProblem,
  NationalLanguageProblem,
  Problem,
  ReadableEnglishProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguageProblem,
  ReadableScienceProblem,
  ScienceProblem
} from 'src/app/models/problem';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { RootState } from 'src/app/reducers';
import { fadeInOut, showHide } from 'src/app/resources/animations';
import {
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  META_VIEWPORT_OTHER,
  SCIENCE_IDS,
  STATIC_DATA_CACHE_DAYS,
  SubjectId
} from 'src/app/resources/config';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import * as CommonIdPaperBookmarkSelectors from 'src/app/selectors/common-id/common-id-paper-bookmark.selectors';
import {
  getEnglishProblem,
  getHistoryProblem,
  getNationalLanguageProblem,
  getProblemSearching,
  getReadableBiologyProblem,
  getReadableChemistryProblem,
  getReadableEnglishProblem,
  getReadableJapaneseHistoryProblem,
  getReadableMathProblem,
  getReadableNationalLanguageProblem,
  getReadablePhysicsProblem,
  getReadableWorldHistoryProblem,
  getScienceProblem
} from 'src/app/selectors/search.selectors';
import { getFetchedDate, getStaticCommonData } from 'src/app/selectors/static-data.selectors';
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { Subject } from '../../../../models/common-data';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import {
  commonIdFindAnsweredProblems,
  initializeCommonIdFindAnsweredProblems
} from './../../../../actions/common-id/common-id-answered-problem.actions';
import { getCommonIdAnsweredProblem } from './../../../../selectors/common-id/common-id-answered-problem.selectors';

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as CommonIdVisitedPaperActions from 'src/app/actions/common-id/common-id-visited-paper.actions';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';
import {
  COMMON_ID_FREE_YEARS,
  COMPLETE_ANSWERED_DIALOG_WIDTH,
  COOKIE_NAME_SSC_ID,
  SELECT_ANSWERED_DIALOG_WITDH
} from 'src/app/resources/common-id-config';
import * as CommonIdVisitedPaperSelectors from 'src/app/selectors/common-id/common-id-visited-paper.selectors';
import { ProblemDetailFrameComponent } from 'src/app/views/widgets/problem-detail-frame/problem-detail-frame.component';
import { getCookie } from 'typescript-cookie';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { getCurrentDateTime } from '../../../../actions/current-date-time.actions';
import * as SearchActions from '../../../../actions/search.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { StaticCommonData } from '../../../../models/static-common-data';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from '../../../../resources/common-id/ga';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../../selectors/current-date-time.selectors';
import { CommonIdEffectAnimationUtil } from '../../../../utils/common-id/common-id-effect-animation-util';
import { CommonIdUserUtil } from '../../../../utils/common-id/common-id-user-util';
import { GAUtil } from '../../../../utils/ga-util';
import { CommonIdCompleteAnsweredDialogComponent } from '../complete-answered-dialog/complete-answered-dialog.component';
import {
  CommonIdAnsweredProblemDaimonData,
  CommonIdAnsweredProblemDialogData,
  CommonIdSelectAnsweredForProblemComponent
} from '../select-answered-for-problem-dialog/select-answered-for-problem-dialog.component';

@Component({
  selector: 'app-common-id-problem-detail',
  templateUrl: './problem-detail.component.html',
  styleUrls: ['./problem-detail.component.scss'],
  animations: [fadeInOut, showHide]
})
export class CommonIdProblemDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  paperId$: Observable<string>;
  private problemSearching$: Observable<boolean>;
  private initializedPaperBookmarkSubject = new BehaviorSubject(false);
  scienceIds: string[] = SCIENCE_IDS;

  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticEnglishData$: Observable<StaticEnglishData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;

  /** science-problems.component をそのまま利用するため array で定義 */
  readableScienceProblems$: Observable<ReadableScienceProblem[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;

  problem$: Observable<Problem>;
  scienceProblem$: Observable<ScienceProblem>;
  englishProblem$: Observable<EnglishProblem>;
  nationalLanguageProblem$: Observable<NationalLanguageProblem>;
  // nationalLanguageProblems$: Observable<NationalLanguageProblem[]>;
  historyProblem$: Observable<HistoryProblem>;
  searching$: Observable<boolean>;
  isPreconditionError = false;
  paperMode$: Observable<boolean>;
  trialMode$: Observable<boolean>;
  isPaidPaper$: Observable<boolean>;
  isPremiumUser$: Observable<boolean>;
  canDisplay$: Observable<boolean>;
  showPaperBookmarkBtn = true;
  currentDateTime$: Observable<CurrentDateTime>;
  subjectId$: Observable<string>;
  menuType$: Observable<'article' | 'spellcheck'> = of('article');
  ENGLISH_SUBJECT_ID: string;
  MATH_SUBJECT_ID: string;
  NATIONAL_LANGUAGE_SUBJECT_ID: string;
  PHYSICS_SUBJECT_ID: string;
  CHEMISTRY_SUBJECT_ID: string;
  BIOLOGY_SUBJECT_ID: string;
  JAPANESE_HISTORY_SUBJECT_ID: string;
  WORLD_HISTORY_SUBJECT_ID: string;

  toolbarTitle = '問題詳細';

  signedInUser$: Observable<CommonIdUser>;
  user: CommonIdUser;
  paperBookmark: CommonIdPaperBookmark;
  bookmarkPapers: CommonIdBookmarkPaper[] = [];
  initializedPaperBookmark$ = this.initializedPaperBookmarkSubject.asObservable();
  isPaperBookmarked = false;

  universityId$: Observable<string>;
  universityName$: Observable<string>;
  year$: Observable<string>;

  headerBannerImageUrl$: Observable<string>;
  headerBannerClickUrl$: Observable<string>;
  footerBannerImageUrl$: Observable<string>;
  footerBannerClickUrl$: Observable<string>;

  @ViewChild(ProblemDetailFrameComponent) problemDetailFrameComponent: ProblemDetailFrameComponent;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private dialog: MatDialog,
    private location: Location,
    private http: HttpClient,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.toolbarTitle }));
    this.setUpPaperId();
    this.setUpYear();
    this.setUpCanDisplay();
    this.setUpStaticData();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch(SearchActions.initializeProblemState());
    this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdAddPaperBookmarkState());
    this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdDeletePaperBookmarkState());
    this.store.dispatch(initializeCommonIdFindAnsweredProblems());
  }

  private setUpPaperId() {
    this.paperId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('paperId') || ''),
      shareReplay(1)
    );
    this.subscriptions.push(
      this.paperId$.subscribe(paperId => {
        this.isPreconditionError = paperId === '';
        if (!paperId) {
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
        }
      })
    );
  }

  private setUpYear() {
    this.year$ = this.paperId$.pipe(
      take(1),
      map(paperId => '20' + paperId.slice(0, 2))
    );
  }

  private setUpCanDisplay() {
    this.isPaidPaper$ = this.paperId$.pipe(
      map(paperId => !COMMON_ID_FREE_YEARS.includes('20' + paperId.slice(0, 2))),
      shareReplay(1)
    );

    this.store.dispatch(getCurrentDateTime());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      shareReplay(1)
    );

    this.isPremiumUser$ = combineLatest([this.currentDateTime$, this.signedInUser$]).pipe(
      filter(it => it != null),
      map(([currentDateTime, signedInUser]) => CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser)),
      shareReplay(1)
    );

    this.canDisplay$ = combineLatest([this.isPaidPaper$, this.isPremiumUser$]).pipe(
      filter(it => it != null),
      map(([isPaidPaper, isPremiumUser]) => !(isPaidPaper && !isPremiumUser)),
      shareReplay(1)
    );
  }

  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.store
      .select(getStaticCommonData)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticCommonData => {
        this.setUpTrialMode();
        this.setUpNavigationAndPaperMode();
        this.setUpUnivId();
        this.setUpUnivName(staticCommonData);
        this.setUpSubjectId();
        this.setUpVisitedPaperId();
        this.setUpProblem();
        this.setUpReadableProblem();
        this.setUpSearching();
        this.setUpCommonIdPaperBookmarks();
        this.setUpBrowserTitle();
      });
  }

  private setUpTrialMode() {
    this.trialMode$ = of(false);
  }

  private setUpNavigationAndPaperMode() {
    this.paperMode$ = of(true);
  }

  private setUpUnivId() {
    this.universityId$ = this.paperId$.pipe(
      take(1),
      map(paperId => 'D' + paperId.slice(2, 6))
    );

    this.universityId$.pipe(take(1)).subscribe(universityId => {
      this.setUpDuraBanner(universityId);
    });
  }

  private setUpDuraBanner(universityId: string) {
    const sscId = getCookie(COOKIE_NAME_SSC_ID) || '';
    const duraCallURL = (global as any).DuraCallURL;
    const duraCallURLPromise = duraCallURL.multiCallDuraADWithKakomonIgnoreFlag('kakomon_other_banner', 2, sscId, universityId);
    from<string>(duraCallURLPromise)
      .pipe(take(1))
      .subscribe(url => {
        // console.log(url);
        this.http
          .get<any>(url)
          .pipe(take(1))
          .subscribe(json => {
            // console.log(json);
            if (json[0] && json[0].imgUrl && json[0].clickUrl) {
              this.headerBannerImageUrl$ = of(json[0].imgUrl);
              this.headerBannerClickUrl$ = of(json[0].clickUrl);
            } else {
              this.headerBannerImageUrl$ = of('');
              this.headerBannerClickUrl$ = of('');
            }
            if (json[1] && json[1].imgUrl && json[1].clickUrl) {
              this.footerBannerImageUrl$ = of(json[1].imgUrl);
              this.footerBannerClickUrl$ = of(json[1].clickUrl);
            } else {
              this.footerBannerImageUrl$ = of('');
              this.footerBannerClickUrl$ = of('');
            }
          });
      });
  }

  private setUpUnivName(staticCommonData: StaticCommonData) {
    this.universityName$ = this.paperId$.pipe(
      take(1),
      map(paperId => staticCommonData.universities.find(staticData => staticData.id === 'D' + paperId.slice(2, 6)).name)
    );
  }

  private setUpSubjectId() {
    this.subjectId$ = this.paperId$.pipe(
      take(1),
      map(paperId => paperId.slice(6, 8))
    );
  }

  private setUpVisitedPaperId() {
    this.subscriptions.push(
      combineLatest([this.store.select(CommonIdVisitedPaperSelectors.getCommonIdVisitedPapers), this.paperId$]).subscribe(
        ([paperIds, paperId]) => {
          const _paperIds: string[] = paperIds != null ? paperIds.concat() : [];
          if (!_paperIds.includes(paperId)) {
            _paperIds.push(paperId);
            this.store.dispatch(CommonIdVisitedPaperActions.commonIdAddVisitedPaper({ paperIds: _paperIds }));
          }
        }
      )
    );
  }

  private setUpProblem() {
    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        if (this.scienceIds.includes(subjectId)) {
          this.scienceProblem$ = this.store.select(getScienceProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.scienceProblem$.pipe(problem => problem);
        } else if (subjectId === SubjectId.ENGLISH) {
          this.englishProblem$ = this.store.select(getEnglishProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.englishProblem$.pipe(problem => problem);
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.nationalLanguageProblem$ = this.store.select(getNationalLanguageProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.nationalLanguageProblem$.pipe(map(problems => problems));
        } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
          this.historyProblem$ = this.store.select(getHistoryProblem).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.problem$ = this.historyProblem$.pipe(problem => problem);
        }
      })
    );

    this.subscriptions.push(
      combineLatest([this.subjectId$, this.paperId$.pipe(filter(it => it !== ''))]).subscribe(([subjectId, paperId]) => {
        this.isPreconditionError = false;
        if (this.scienceIds.includes(subjectId)) {
          this.store.dispatch(findScienceProblemByPaperId({ subjectId, paperId }));
        } else if (subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(findEnglishProblemByPaperId({ subjectId, paperId }));
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(findNationalLanguageProblemByPaperId({ subjectId, paperId }));
        } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(findHistoryProblemByPaperId({ subjectId, paperId }));
        }
      })
    );
  }

  private setUpReadableProblem() {
    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        if (this.scienceIds.includes(subjectId)) {
          this.readableScienceProblems$ = combineLatest([this.scienceProblem$]).pipe(
            switchMap(([problem]) => {
              const selector =
                subjectId === SubjectId.MATH
                  ? this.store.select(getReadableMathProblem(problem))
                  : subjectId === SubjectId.PHYSICS
                  ? this.store.select(getReadablePhysicsProblem(problem))
                  : subjectId === SubjectId.CHEMISTRY
                  ? this.store.select(getReadableChemistryProblem(problem))
                  : this.store.select(getReadableBiologyProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.ENGLISH) {
          this.readableEnglishProblems$ = combineLatest([this.englishProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableEnglishProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.readableNationalLanguageProblems$ = combineLatest([this.nationalLanguageProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableNationalLanguageProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          this.readableJapaneseHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableJapaneseHistoryProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          this.readableWorldHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
            switchMap(([problem]) => {
              const selector = this.store.select(getReadableWorldHistoryProblem(problem));
              return selector;
            }),
            map(problem => [problem]),
            filter(it => it != null)
          );
        }
      })
    );
  }

  private setUpSearching() {
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemSearching$]).pipe(map(([problemSearching]) => problemSearching));
  }

  private setUpCommonIdPaperBookmarks() {
    this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdFindPaperBookmarkState());
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(filter<CommonIdUser>(it => it != null && it !== 'none'))
      .pipe(take(1))
      .subscribe(user => {
        this.user = user;
        this.subscriptions.push(
          this.paperId$.subscribe(paperId => {
            this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdFindPaperBookmarkState());
            this.store.dispatch(CommonIdPaperBookmarkActions.commonIdFindPaperBookmark({ userId: user.id }));
            this.store
              .select(CommonIdPaperBookmarkSelectors.getCommonIdPaperBookmark)
              .pipe(
                filter(it => it != null),
                take(1)
              )
              .subscribe(paperBookmark => {
                this.paperBookmark = paperBookmark;
                this.bookmarkPapers = [];
                if (paperBookmark.papers !== undefined) {
                  this.bookmarkPapers = this.paperBookmark.papers.filter(paper => paper.pId === paperId);
                }
                this.isPaperBookmarked = this.bookmarkPapers.length > 0;
                this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdFindPaperBookmarkState());

                this.initializedPaperBookmarkSubject.next(true);
              });
          })
        );
      });
  }

  private setUpBrowserTitle(menuType: string = 'article') {
    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subscriptions.push(
      this.subjectId$.subscribe(subjectId => {
        let readableProblems$: Observable<
          ReadableScienceProblem[] | ReadableEnglishProblem[] | ReadableNationalLanguageProblem[] | ReadableHistoryProblem[]
        >;
        if (this.scienceIds.includes(subjectId)) {
          readableProblems$ = this.readableScienceProblems$;
        } else if (subjectId === SubjectId.ENGLISH) {
          readableProblems$ = this.readableEnglishProblems$;
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          readableProblems$ = this.readableNationalLanguageProblems$;
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          readableProblems$ = this.readableJapaneseHistoryProblems$;
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          readableProblems$ = this.readableWorldHistoryProblems$;
        }

        if (readableProblems$) {
          this.subscriptions.push(
            combineLatest([subjects$, readableProblems$.pipe(filter(it => it !== null))]).subscribe(([subjects, problems]) => {
              const university: string = problems[0].university;
              const year: number = problems[0].year;
              const subject: string = SubjectUtil.getName(subjects, subjectId);
              const type = menuType === 'article' ? '問題' : '研究・解答';
              const title = `${university}　${year}年度　${subject}　${type}`;
              this.store.dispatch(setCommonIdBrowserTitle({ subTitle: `${title}`, problemDetailFlag: true }));
              setTimeout(() => {
                this.store.dispatch(setTitle({ title }));
              });
            })
          );
        }
      })
    );
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  goUniversityDetail() {
    combineLatest([this.universityId$, this.year$])
      .pipe(take(1))
      .subscribe(([universityId, year]) => {
        const url = RoutingPathResolver.resolveCommonIdSearchUnivDetail(universityId);
        const extras: NavigationExtras = {
          queryParams: UniversitySearchQueryParamsMapper.encodeUniversitySearchSelectedYear(year)
        };

        this.store.dispatch(navigate({ url, extras }));
      });
  }

  showPrint() {
    this.paperId$.pipe(take(1)).subscribe(paperId => {
      const eventParams = {
        'event_category': GA_EVENT_CATEGORIES.PRINT_PREVIEW,
        'event_label': paperId,
        'value': 1
      };
      GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
    });

    const ua = new UAParser(this.window.navigator.userAgent).getResult();
    if (ua.browser.name.indexOf('Safari') > -1) {
      document.execCommand('print', false, null);
    } else {
      (window as any).print();
    }
  }

  changeMenuType(event) {
    this.menuType$ = of(event);
    combineLatest([this.menuType$, this.paperId$])
      .pipe(take(1))
      .subscribe(([menuType, paperId]) => {
        const eventParams = {
          'event_category': menuType === 'article' ? GA_EVENT_CATEGORIES.PROBLEM_TAB : GA_EVENT_CATEGORIES.ANSWER_TAB,
          'event_label': paperId,
          'value': 1
        };
        GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
        this.setUpBrowserTitle(menuType);
      });
    this.universityId$.pipe(take(1)).subscribe(universityId => {
      this.setUpDuraBanner(universityId);
    });
  }

  closeWindow() {
    this.window.close();
  }

  addPaperBookmark() {
    Log.debug(this.LOG_SOURCE, 'あとで解くに登録します');
    this.initializedPaperBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `あとで解くに登録しています`
      })
    );

    this.subscriptions.push(
      this.paperId$.subscribe(paperId => {
        const eventParams = {
          'event_category': GA_EVENT_CATEGORIES.SAVE_PAPER_ON,
          'event_label': paperId,
          'value': 1
        };
        GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

        const request: CommonIdAddPaperBookmarkData = {
          userId: this.user.id,
          paperId
        };
        Log.debug(this.LOG_SOURCE, 'add paper bookmark', request);
        this.store.dispatch(CommonIdPaperBookmarkActions.commonIdAddPaperBookmark(request));
        this.store
          // TODO: あとで解く登録の結果をセレクト
          .select(CommonIdPaperBookmarkSelectors.getCommonIdAddPaperBookmarkResult)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(result => {
            Log.debug(this.LOG_SOURCE, `add paper bookmark result: `, result);
            if (result.success) {
              this.store.dispatch(
                dispatchInfoMessage({
                  message: `あとで解くに登録しました`
                })
              );
            } else {
              Log.warn(this.LOG_SOURCE, `add paper bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
              this.store.dispatch(
                dispatchInfoMessage({
                  message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
                })
              );
              this.problemDetailFrameComponent.resetTogglePaperBookmarkBtn();
            }

            // TODO: initializeAddPaperBookmarkStateを作っておく
            this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdAddPaperBookmarkState());
            this.setUpCommonIdPaperBookmarks();
          });
      })
    );
  }

  deletePaperBookmark() {
    Log.debug(this.LOG_SOURCE, 'あとで解くを解除します');
    this.initializedPaperBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `あとで解くを解除しています`
      })
    );

    this.subscriptions.push(
      this.paperId$.subscribe(paperId => {
        const request: CommonIdDeletePaperBookmarkData = {
          userId: this.user.id,
          paperId
        };

        Log.debug(this.LOG_SOURCE, 'delete bookmark', request);
        this.store.dispatch(CommonIdPaperBookmarkActions.commonIdDeletePaperBookmark(request));

        this.store
          .select(CommonIdPaperBookmarkSelectors.getCommonIdDeletePaperBookmarkResult)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(result => {
            Log.debug(this.LOG_SOURCE, `delete bookmark result: `, result);
            if (result.success) {
              this.store.dispatch(
                dispatchInfoMessage({
                  message: `あとで解くを解除しました`
                })
              );
            } else {
              Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
              this.store.dispatch(
                dispatchInfoMessage({
                  message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
                })
              );
            }

            this.store.dispatch(CommonIdPaperBookmarkActions.initializeCommonIdDeletePaperBookmarkState());
            this.setUpCommonIdPaperBookmarks();
          });
      })
    );
  }

  openAnsweredDialog() {
    this.subscriptions.push(
      combineLatest([this.signedInUser$, this.problem$]).subscribe(([user, problem]) => {
        // 保存されている解答済み問題の取得
        this.store.dispatch(initializeCommonIdFindAnsweredProblems());
        this.store.dispatch(commonIdFindAnsweredProblems({ userId: user.id }));
        this.store
          .select(getCommonIdAnsweredProblem)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(answeredProblems => {
            // search stateより画像がある大問データのみ取得
            const daimonDataWithImages = problem.questionImages.filter(question => {
              if (question.slice(12, 14) >= '01' && question.slice(12, 14) <= '99') {
                return question;
              }
            });

            // 大問データを大問ID、問題ID、保存フラグの形に変換
            const daimonDataWithIsSaved: CommonIdAnsweredProblemDaimonData[] = daimonDataWithImages.map(question => {
              const daimonId = question.slice(12, 14);
              const problemId = question.slice(0, 10) + daimonId;
              return { daimonId, problemId, isSaved: false };
            });

            // 重複データの削除
            const daimonDataWithoutDupulicates: CommonIdAnsweredProblemDaimonData[] = daimonDataWithIsSaved.filter((daimon, index) => {
              const daimonObject = JSON.stringify(daimon);
              return (
                index ===
                daimonDataWithIsSaved.findIndex(obj => {
                  return JSON.stringify(obj) === daimonObject;
                })
              );
            });

            // 解答済み問題のIDを配列に格納
            const answeredProblemIds: string[] =
              answeredProblems && answeredProblems.problems
                ? answeredProblems.problems.map(answeredProblem => {
                    return answeredProblem.pId;
                  })
                : [];

            // 保存済みの大問を設定
            const daimonData: CommonIdAnsweredProblemDaimonData[] = daimonDataWithoutDupulicates.map(daimon => {
              return {
                daimonId: daimon.daimonId,
                problemId: daimon.problemId,
                isSaved: answeredProblemIds.includes(daimon.problemId)
              };
            });

            const data: CommonIdAnsweredProblemDialogData = {
              userId: user.id,
              daimonData
            };

            const config: MatDialogConfig<CommonIdAnsweredProblemDialogData> = {
              width: SELECT_ANSWERED_DIALOG_WITDH,
              panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
              data,
              disableClose: true
            };
            const dialogRef = this.dialog.open(CommonIdSelectAnsweredForProblemComponent, config);
            dialogRef
              .afterClosed()
              .pipe(take(1))
              .subscribe(result => {
                // 全ての問題を解答済みにした際に全て解答済み用のモーダルを表示する
                if (result === true) {
                  const completeAnsweredDialogConfig: MatDialogConfig = {
                    width: COMPLETE_ANSWERED_DIALOG_WIDTH,
                    panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
                    backdropClass: 'overlay-alpha-backdrop'
                  };
                  this.dialog.open(CommonIdCompleteAnsweredDialogComponent, completeAnsweredDialogConfig);
                  CommonIdEffectAnimationUtil.fireCompleteAnsweredConfetti();
                }
              });
          });
      })
    );
  }

  headerBannerClick() {
    this.headerBannerClickUrl$.pipe(take(1)).subscribe(url => {
      this.store.dispatch(openWindow({ url }));
    });
  }

  footerBannerClick() {
    this.footerBannerClickUrl$.pipe(take(1)).subscribe(url => {
      this.store.dispatch(openWindow({ url }));
    });
  }
}
