import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { debounceTime, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { navigate, setBrowserTitle } from '../../../actions/core.actions';
import { RootState } from '../../../reducers';
import { BulkMailDialogComponent, BulkMailDialogDialogData } from '../bulk-mail-dialog/bulk-mail-dialog.component';
import {
  BULK_MAIL_DIALOG_HEIGHT,
  BULK_MAIL_DIALOG_WIDTH,
  DEFAULT_MAIL_RECEIVER_ID,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  JUKU_ORGANIZATION_ID,
  JUKU_ORGANIZATION_NAME,
  KEYBOARD_INPUT_DEBOUNCE_TIME,
  OBUNSHA_DEFAULT_MAIL_SENDER_NAME,
  OBUNSHA_ORGANIZATION_ID,
  OBUNSHA_ORGANIZATION_NAME,
  OTHER_ORGANIZATION_ID,
  OTHER_ORGANIZATION_NAME,
  SCHOOL_ORGANIZATION_ID,
  SCHOOL_ORGANIZATION_NAME
} from '../../../resources/config';
import { Log } from 'src/app/utils/log';

export interface MailReceiverOptions {
  id: string;
  checked: boolean;
  name: string;
  disabled: boolean;
}

@Component({
  selector: 'app-information',
  templateUrl: './bulk-mail.component.html',
  styleUrls: ['./bulk-mail.component.scss']
})
export class BulkMailComponent implements OnInit, OnDestroy {
  classicEditor = ClassicEditor;
  config = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        // 'toDoList',
        '|',
        'outdent',
        'indent',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo',
        // 'imageUpload',
        // 'classicEditor',
        'blockQuote'
        // 'list',
        // 'mediaEmbed'
        // 'pasteFromOffice',
        // 'fontFamily',
        // 'todoList',
        // 'youtube'
      ]
    },
    placeholder: 'お知らせ本文を入力して下さい',
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    }
  };
  bulkMailTitle = new FormControl();
  mailReceiverOptions: MailReceiverOptions[] = [
    { id: SCHOOL_ORGANIZATION_ID, checked: false, name: SCHOOL_ORGANIZATION_NAME, disabled: false },
    { id: JUKU_ORGANIZATION_ID, checked: false, name: JUKU_ORGANIZATION_NAME, disabled: false },
    { id: OTHER_ORGANIZATION_ID, checked: false, name: OTHER_ORGANIZATION_NAME, disabled: false },
    { id: OBUNSHA_ORGANIZATION_ID, checked: false, name: OBUNSHA_ORGANIZATION_NAME, disabled: false },
    { id: DEFAULT_MAIL_RECEIVER_ID, checked: true, name: OBUNSHA_DEFAULT_MAIL_SENDER_NAME, disabled: true }
  ];
  selectedMailReceivers: MailReceiverOptions[] = [];
  mailTitle: string;
  mailBody: string;
  editorData: string;
  isConfirmButtonDisabled: boolean;

  private LOG_SOURCE = this.constructor.name;
  private title = 'メール一斉送信';
  private mailTitleSubscription: Subscription;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    this.setUp();
  }

  ngOnDestroy() {
    if (this.mailTitleSubscription) this.mailTitleSubscription.unsubscribe();
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
  }

  confirm() {
    const config: MatDialogConfig<BulkMailDialogDialogData> = {
      height: BULK_MAIL_DIALOG_HEIGHT,
      width: BULK_MAIL_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: { title: this.mailTitle, body: this.mailBody, organizations: this.selectedMailReceivers },
      disableClose: true
    };
    this.dialog
      .open(BulkMailDialogComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result) this.setUpData();
      });
  }

  onChangeMailReceiver() {
    this.selectedMailReceivers = this.mailReceiverOptions.filter(receiver => receiver.checked);
    const selectedMailReceiverNames = this.selectedMailReceivers.map(receiver => receiver.name);
    Log.debug(this.LOG_SOURCE, `選択されたお知らせの対象: ${selectedMailReceiverNames}`);
  }

  onEditorChange({ editor }: ChangeEvent) {
    const editorData = editor.getData();
    if (editorData === '') {
      this.mailBody = null;
    } else {
      this.mailBody = editorData;
    }

    this.setUpConfirmButton();
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
    }
  }

  private setUp() {
    this.setUpData();
    this.setUpTitleChange();
  }

  private setUpData() {
    this.mailReceiverOptions.map(mailReceiver =>
      mailReceiver.id === DEFAULT_MAIL_RECEIVER_ID ? (mailReceiver.checked = true) : (mailReceiver.checked = false)
    );
    this.selectedMailReceivers = this.mailReceiverOptions.filter(receiver => receiver.checked);

    this.bulkMailTitle.setValue(null);
    this.mailTitle = null;

    this.editorData = null;
    this.mailBody = null;

    this.isConfirmButtonDisabled = true;
  }

  private setUpTitleChange() {
    this.mailTitleSubscription = this.bulkMailTitle.valueChanges
      .pipe(debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME))
      .subscribe(() => this.mailTitleChange());
  }

  private mailTitleChange() {
    if (this.bulkMailTitle.value === '') {
      this.mailTitle = null;
    } else {
      this.mailTitle = this.bulkMailTitle.value;
    }

    if (this.mailTitle !== null) Log.debug(this.LOG_SOURCE, `入力されたお知らせタイトル: ${this.mailTitle}`);
    this.setUpConfirmButton();
  }

  private setUpConfirmButton() {
    if (this.mailTitle === null || this.mailBody === null) {
      this.isConfirmButtonDisabled = true;
    } else {
      this.isConfirmButtonDisabled = false;
    }
  }
}
