import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { inOut } from 'src/app/resources/animations';
import { RootState } from '../../../../reducers';

import {
  commonIdSaveAnsweredProblems,
  initializeCommonIdSaveAnsweredProblemsState
} from 'src/app/actions/common-id/common-id-answered-problem.actions';
import { dispatchInfoMessage, navigate } from 'src/app/actions/core.actions';
import { getCommonIdSaveAnsweredProblemsResult } from 'src/app/selectors/common-id/common-id-answered-problem.selectors';

import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { CommonIdSaveAnsweredProblemsRequest } from 'src/app/models/common-id/common-id-answered-problem';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'src/app/resources/common-id/ga';
import { GAUtil } from 'src/app/utils/ga-util';
import { Log } from 'src/app/utils/log';
import { enter } from '../../../../resources/animations';

export interface CommonIdAnsweredProblemDialogData {
  userId: string;
  daimonData: CommonIdAnsweredProblemDaimonData[];
}
export interface CommonIdAnsweredProblemDaimonData {
  daimonId: string;
  problemId: string;
  isSaved: boolean;
}

@Component({
  selector: 'app-common-id-select-answered-for-problem-dialog',
  templateUrl: './select-answered-for-problem-dialog.component.html',
  styleUrls: ['./select-answered-for-problem-dialog.component.scss'],
  animations: [inOut, enter]
})
export class CommonIdSelectAnsweredForProblemComponent implements OnInit, OnDestroy {
  dialogTitle: string;
  private LOG_SOURCE = this.constructor.name;

  disabled = false;
  daimonData: CommonIdAnsweredProblemDaimonData[];
  userId: string;

  // UI
  totalCount: number;
  savedCount: number;
  progress: number;
  allCheck: boolean;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<CommonIdSelectAnsweredForProblemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdAnsweredProblemDialogData
  ) {}

  ngOnInit() {
    this.setUp();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeCommonIdSaveAnsweredProblemsState());
  }

  setUp() {
    this.dialogTitle = '解答済み';
    this.userId = this.data.userId;
    this.daimonData = this.data.daimonData.map(daimon => {
      return {
        daimonId: parseInt(daimon.daimonId, 10).toString(),
        problemId: daimon.problemId,
        isSaved: daimon.isSaved
      };
    });
    this.totalCount = this.daimonData.length;
    this.updateToggleList();
  }

  goToMylistAnsweredProblems() {
    this.dialogRef.close(false);

    const url = RoutingPathResolver.resolveCommonIdMylistAnswerProblems();
    this.store.dispatch(navigate({ url }));
  }

  cancel() {
    this.dialogRef.close(false);
  }

  toggleClick(event: { problemId: string; isAdd: boolean }) {
    const targetDaimon = this.daimonData.find(daimon => daimon.problemId === event.problemId);
    if (event.isAdd) this.changeAnsweredProblemStatus(targetDaimon);
    targetDaimon.isSaved = event.isAdd;
    this.updateToggleList();
  }

  allToggleClick(event: { problemId: string; isAdd: boolean }) {
    this.daimonData.map(daimon => {
      if (!daimon.isSaved && event.isAdd) this.changeAnsweredProblemStatus(daimon);
      daimon.isSaved = event.isAdd;
    });
    this.updateToggleList();
  }

  updateToggleList() {
    const answeredProblems = this.daimonData.filter(problem => problem.isSaved);
    this.savedCount = answeredProblems.length;
    this.allCheck = this.savedCount === this.totalCount ? true : this.savedCount === 0 ? false : this.allCheck;
    this.progress = this.savedCount / this.totalCount;
  }

  changeAnsweredProblemStatus(daimon: CommonIdAnsweredProblemDaimonData) {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.SOLVED_ON,
      'event_label': daimon.problemId,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
  }

  saveAnsweredProblems() {
    Log.debug(this.LOG_SOURCE, '解答済み問題を登録します. 大問データ: ', this.daimonData);
    this.disableForms();

    const problems = this.daimonData.map(daimon => {
      return {
        problemId: daimon.problemId,
        isAdd: daimon.isSaved
      };
    });

    // 解答済み問題登録APIとの接続
    const request: CommonIdSaveAnsweredProblemsRequest = {
      userId: this.userId,
      problems
    };

    this.store.dispatch(commonIdSaveAnsweredProblems({ request }));
    this.store
      .select(getCommonIdSaveAnsweredProblemsResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeCommonIdSaveAnsweredProblemsState());
        Log.debug(this.LOG_SOURCE, `save Answered Problems result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: '解答済み問題の登録が完了しました'
            })
          );
          const allAddedFlag = !problems.map(problem => problem.isAdd).includes(false);
          this.dialogRef.close(allAddedFlag);
        } else {
          Log.warn(this.LOG_SOURCE, `save Answered Problems error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
          this.enableForms();
          this.dialogRef.close();
        }
      });
    this.store.dispatch(
      dispatchInfoMessage({
        message: `解答済み問題の登録をしています…`
      })
    );
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
