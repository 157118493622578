import { Injectable, Inject } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as CommonIdCoreActions from '../../actions/common-id/common-id-core.actions';
import { SubscriptionService } from '../../services/subscription.service';
import { PROD_APP_NAME, STAGING_APP_NAME, DEBUG_APP_NAME } from '../../resources/config';
import { COMMON_PAGE_TITLE } from '../../resources/common-id-config';
import { environment } from 'src/environments/environment';
import { WINDOW_OBJECT } from '../../utils/injection-tokens';

@Injectable()
export class CommonIdCoreEffects {
  setCommonIdBrowserTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonIdCoreActions.setCommonIdBrowserTitle),
        tap(action => {
          const base =
            environment.envName === 'prod' ? PROD_APP_NAME : environment.envName === 'staging' ? STAGING_APP_NAME : DEBUG_APP_NAME;
          const pageTitle = action.subTitle ? action.subTitle : '';
          const title = `${base}${pageTitle} | ${COMMON_PAGE_TITLE}`;
          this.titleService.setTitle(title);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private router: Router,
    private urlSerializer: UrlSerializer,
    @Inject(WINDOW_OBJECT) private window: Window,
    private subscriptionService: SubscriptionService,
    private titleService: Title
  ) {}
}
