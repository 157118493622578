<app-wrapper size="lg">
  <div @enter *ngIf="(loaded$ | async) === true">
    <app-content-row>
      <app-card-content [showSeparator]="true" *ngIf="(tag$ | async) === ''">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">view_module</mat-icon>
          おすすめ問題セット
        </app-card-content-head>
        <app-wrapper size="fluid">
          <form [formGroup]="playlistForm">
            <app-content-row>
              <div class="row-sort-area flex items-center">
                <div class="mr-auto">
                  <mat-form-field class="mb-0" appearance="outline" floatLabel="always">
                    <mat-label>科目</mat-label>
                    <div class="nb-0">
                      <mat-select class="mb-0" formControlName="subjectId" (selectionChange)="onChangeSubject()">
                        <mat-option *ngFor="let subject of subjectOption" [value]="subject.id">
                          {{ subject.name }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </mat-form-field>
                </div>
                <div class="text-base md:text-xl font-bold ml-2">
                  <span class="inline-block text-sm md:text-base">件数</span>
                  <b class="inline-block text-primary px-1 md:px-1.5">{{ (playlistSubjects$ | async).length }}</b>
                  <span class="inline-block text-xs md:text-sm font-normal">セット</span>
                </div>
              </div>
            </app-content-row>
          </form>
        </app-wrapper>
      </app-card-content>
      <app-card-content [showSeparator]="false" *ngIf="(tag$ | async) !== ''">
        <app-wrapper size="fluid">
          <form [formGroup]="playlistForm">
            <app-content-row>
              <div class="playlist-select flex">
                <div class="mr-auto">
                  <b class="mr-3"
                    ><mat-icon class="w-4 h-4 text-base italic text-primary font-bold mr-1 relative top-0.5">tag</mat-icon
                    >{{ tag$ | async }}</b
                  >
                  <span class="block md:inline">のタグがついた問題セット一覧</span>
                </div>
                <div class="text-base md:text-xl font-bold place-self-end">
                  件数<b class="text-primary pl-1.5 pr-1.5">{{ (playlistSubjects$ | async).length }}</b
                  ><span class="text-sm font-normal">セット</span>
                </div>
              </div>
            </app-content-row>
          </form>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
    <app-content-row>
      <div class="grid grid-cols-1 grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
        <app-playlist-card-item
          *ngFor="let playlistSubject of playlistSubjects$ | async"
          [title]="playlistSubject.playlist.seriesName"
          [subTitle]="playlistSubject.playlist.name"
          [subjectId]="playlistSubject.playlist.subjectId"
          [disabledAsNotPremium]="isPremiumUser === false && playlistSubject.playlist.freeFlg !== true"
          [playlistId]="playlistSubject.playlist.playlistId"
          [tags]="playlistSubject.playlist.tags"
          [problemCount]="playlistSubject.playlist.problemCount"
          [duration]="playlistSubject.playlist.duration"
          (goPlaylistDetail)="clickPlaylist(playlistSubject.playlist)"
        ></app-playlist-card-item>
      </div>
    </app-content-row>
  </div>
</app-wrapper>

<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
