<app-content-row>
  <app-common-id-mylist-banner (bannerClick)="bannerClick()"></app-common-id-mylist-banner>

  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon aria-hidden="true" class="card-content-head-icon" fontSet="material-icons-outlined">fact_check</mat-icon>
          マイリスト
        </app-card-content-head>
        <app-card-content-nav
          (menuClick)="menuClickHandler($event)"
          [menus]="[
            {
              key: 'mylist-answer-problem',
              icon: 'check_circle',
              fontSet: 'material-icons-outlined',
              label: '解答済み',
              isActive: false
            },
            {
              key: 'mylist-univ',
              icon: 'star',
              label: '大学',
              isActive: false
            },
            {
              key: 'mylist-problem',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: '過去問',
              isActive: false
            },
            {
              key: 'mylist-playlist',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: 'おすすめ問題セット',
              isActive: true
            }
          ]"
        ></app-card-content-nav>

        <app-wrapper size="fluid" *ngIf="bookmarkedPlaylists.length > 0">
          <app-content-row>
            <form class="row-sort-area" action="">
              <div class="flex gap-4 items-center justify-between flex-wrap">
                <div>
                  <mat-form-field floatLabel="always" appearance="outline" class="text-sm grow w-32">
                    <mat-label>科目</mat-label>
                    <mat-select [(value)]="selectedSubjectId" (selectionChange)="switchShowingPlaylists()">
                      <mat-option *ngFor="let subject of subjects$ | async" [value]="subject.id">
                        {{ subject.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-checkbox
                    color="primary"
                    [checked]="onlyAllAnsweredPlaylists"
                    [disabled]="checkboxStatus"
                    (change)="changeShowingFlg($event.checked)"
                    >未解答を含むセットのみ表示</mat-checkbox
                  >
                </div>
              </div>
            </form>
          </app-content-row>
        </app-wrapper>
        <!-- １件も登録していない場合に表示するエリア -->
        <app-wrapper size="fluid" *ngIf="showingPlaylists.length == 0 && initializedShowingPlaylist$ | async">
          <app-content-row>
            <app-content-no-data>
              <div class="flex flex-col-reverse md:flex-row items-center justify-center w-9/12 mx-auto my-4">
                <div class="md:w-1/2 text-center leading-relaxed">
                  <p class="text-md md:text-lg font-bold">おすすめ問題セットの登録はありません</p>
                  <p class="text-sm md:text-md my-6">
                    これから解きたいおすすめ問題セットを<br />
                    あとで解くに登録して挑戦しましょう。
                  </p>
                  <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal" (click)="goPlaylist()">
                    <mat-icon aria-hidden="true" class="card-content-head-icon">view_module</mat-icon>
                    おすすめ問題セット
                  </button>
                </div>
                <div class="md:w-1/2 -mt-4 md:mt-0">
                  <picture>
                    <source media="(min-width: 900px)" srcset="assets/images/mylist-example-playlist@2x.png" />
                    <img
                      class="pointer-events-none"
                      src="assets/images/mylist-example-playlist-sp@2x.png"
                      width="940"
                      height="840"
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </app-content-no-data>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-wrapper>
  </app-content-row>
</app-content-row>

<app-content-row>
  <app-wrapper size="lg" @enter *ngIf="initializedShowingPlaylist$ | async; else loadingProgress">
    <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
      <app-playlist-card-item
        *ngFor="let playlist of showingPlaylists"
        [title]="playlist.name"
        [subTitle]="playlist.seriesName"
        [subjectId]="playlist.subjectId"
        [disabledAsNotPremium]="isPremiumUser === false && playlist.freeFlg !== true"
        [playlistId]="playlist.playlistId"
        [tags]="playlist.tags"
        [problemCount]="playlist.problemCount"
        [duration]="playlist.duration"
        (goPlaylistDetail)="goPlaylistDetail(playlist.playlistId, isPremiumUser === false && playlist.freeFlg !== true)"
      ></app-playlist-card-item>
    </div>
  </app-wrapper>
  <ng-template #loadingProgress>
    <div class="progress-48">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </ng-template>
</app-content-row>

<!-- <app-content-row>
  <app-wrapper size="lg" @enter *ngIf="initializedShowingPlaylist$ | async; else loadingProgress">
    <app-card-content>
      <app-content-row>
        <app-paginator [currentPage]="2" [totalItemCount]="10" [pageSize]="20" [windowSize]="5"></app-paginator>
      </app-content-row>
    </app-card-content>
  </app-wrapper>
  <ng-template #loadingProgress>
    <div class="progress-48">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </ng-template>
</app-content-row> -->
