import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { RootState } from '../../../reducers';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';

import { NO_DISPLAY_UNIVERSITY_IDS } from '../../../resources/config';

import { University } from 'src/app/models/common-data';
import { StaticCommonData } from '../../../models/static-common-data';

import { DataUtil } from '../../../utils/data-util';

const DEFAULT_UNIVERSITY_NAMES_PLACEHOLDER = '入力してください';

@Component({
  selector: 'app-univ-chip-list',
  templateUrl: './univ-chip-list.component.html',
  styleUrls: ['./univ-chip-list.component.scss']
})
export class UnivChipListComponent implements OnInit {
  @ViewChild('univNameInput') universityNameInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) universityNameAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() matLabel: string;
  @Input() floatLabel: string;
  @Input() appearance: string;
  @Input() matFormFieldClass: string;

  @Output() changeSelectedUniversities = new EventEmitter<University[]>();

  constructor(private store: Store<RootState>) {}

  private selectableUniversities$: BehaviorSubject<University[]>;

  universityNamesPlaceholder: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedUniversities: University[] = [];
  filteredUniversities$: Observable<University[]>;

  universityNameFormControl = new FormControl();

  staticCommonData$: Observable<StaticCommonData>;

  ngOnInit() {
    if (this.selectedUniversities.length === 0) {
      // 前回の検索条件を設定する際は不要
      this.setUpStaticCommonData();
      this.setUpSelectableUniversities();
    }
  }

  universitySelected(event: MatAutocompleteSelectedEvent) {
    const univ: University = event.option.value;
    this.selectedUniversities.push(univ);

    this.universityNameFormControl.setValue(null);
    this.universityNameInput.nativeElement.value = '';

    this.updateUniversityNamePlaceholderIfNeeded();
    this.updateSelectableUniversities();

    this.changeSelectedUniversities.emit(this.selectedUniversities);
  }

  removeSelectedUniversity(university: University) {
    const index = this.selectedUniversities.indexOf(university);
    if (index >= 0) this.selectedUniversities.splice(index, 1);
    this.updateUniversityNamePlaceholderIfNeeded();
    this.updateSelectableUniversities();

    this.changeSelectedUniversities.emit(this.selectedUniversities);
  }

  updateSelectableUniversities() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const universities = [...staticCommonData.universities.filter(it => !NO_DISPLAY_UNIVERSITY_IDS.includes(it.id))];
        const filteredUniversities = [...universities].filter(
          univOrg => this.selectedUniversities.findIndex(selectedUniv => selectedUniv.id === univOrg.id) === -1
        );
        const sortedUniversites = DataUtil.sortObjectArrayBySpecificKey(filteredUniversities, 'id');

        this.selectableUniversities$.next(sortedUniversites);
      });
  }

  openUniversityNamesAutocomplete() {
    this.universityNameAutocompleteTrigger.openPanel();
  }

  initializeSelectedUniversitiesAndNamePlaceholder() {
    this.selectedUniversities = [];
    this.universityNamesPlaceholder = DEFAULT_UNIVERSITY_NAMES_PLACEHOLDER;
  }

  // 親コンポーネントより大学名のリストを設定するメソッド
  updateUnivChipList(selectedUniversities: University[]) {
    this.selectedUniversities = selectedUniversities;
    // 大学名を設定
    this.universityNameFormControl.setValue(selectedUniversities);

    // リストより選択できる大学名を設定
    this.setUpStaticCommonData(); // 大学検索の問題詳細からの戻りの場合に、staticCommonDataが必要
    this.setUpSelectableUniversities();
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const universities = [...staticCommonData.universities.filter(it => !NO_DISPLAY_UNIVERSITY_IDS.includes(it.id))];
        const filteredUniversities = [...universities].filter(
          univOrg => this.selectedUniversities.findIndex(selectedUniv => selectedUniv.id === univOrg.id) === -1
        );
        const sortedUniversities = DataUtil.sortObjectArrayBySpecificKey(filteredUniversities, 'id');

        this.selectableUniversities$.next(sortedUniversities);
      });
  }

  updateUnivName(selectedUniversities: University[]) {
    // 大学名を設定
    this.universityNameFormControl.setValue(selectedUniversities);
  }

  private updateUniversityNamePlaceholderIfNeeded() {
    if (this.selectedUniversities.length === 0) {
      this.universityNamesPlaceholder = DEFAULT_UNIVERSITY_NAMES_PLACEHOLDER;
    } else {
      this.universityNamesPlaceholder = '';
    }
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpSelectableUniversities() {
    // 大学名が選択されていない場合は、大学名の欄に入力補助メッセージを表示する
    if (this.selectedUniversities.length === 0) {
      this.universityNamesPlaceholder = DEFAULT_UNIVERSITY_NAMES_PLACEHOLDER;
    } else {
      this.universityNamesPlaceholder = '';
    }

    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const universities = [...staticCommonData.universities.filter(it => !NO_DISPLAY_UNIVERSITY_IDS.includes(it.id))];
        const sortedUniversites = DataUtil.sortObjectArrayBySpecificKey(universities, 'id');
        this.selectableUniversities$ = new BehaviorSubject(sortedUniversites);
        this.filteredUniversities$ = this.universityNameFormControl.valueChanges.pipe(
          startWith(null as string),
          switchMap(univName => this.filterSelectableUniversities(univName))
        );
      });
  }

  private filterSelectableUniversities(univName: string | null): Observable<University[]> {
    if (!univName) return this.selectableUniversities$;
    return this.selectableUniversities$.pipe(
      map(currentSelectableUnivs => currentSelectableUnivs.filter(univ => univ.name.indexOf(univName) !== -1))
    );
  }
}
