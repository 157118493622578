import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdAuthActions from '../../actions/common-id/common-id-auth.actions';
import { CommonIdUser, CommonIdUserPurchaseData } from '../../models/common-id/common-id-user';

interface CommonIdSignInResult {
  success: boolean;
  signedInUser: CommonIdUser | null;
}

interface UpdateSignedInUserIsTermsAgreeResult {
  success: boolean;
}

export const commonIdAuthFeatureKey = 'commonIdAuth';

export interface State {
  signedInUser: CommonIdUser | 'none' | null;
  signInResult: CommonIdSignInResult | null;
  updateSignedInUserIsTermAgreeResult: UpdateSignedInUserIsTermsAgreeResult | null;
  savePurchaseResult: boolean | null;
}

export const initialState: State = {
  signedInUser: null,
  signInResult: null,
  updateSignedInUserIsTermAgreeResult: null,
  savePurchaseResult: null
};

const commonIdAuthReducer = createReducer(
  initialState,
  on(CommonIdAuthActions.commonIdSignInSuccess, (state, { user }) => ({
    ...state,
    signedInUser: user,
    signInResult: { success: true, signedInUser: user }
  })),
  on(CommonIdAuthActions.commonIdSignInFailure, state => {
    const next: State = { ...state, signInResult: { success: false, signedInUser: null } };
    return next;
  }),
  on(CommonIdAuthActions.initializeCommonIdSignInResult, state => {
    const next: State = { ...state, signInResult: null };
    return next;
  }),
  on(CommonIdAuthActions.commonIdSignOutSuccess, state => {
    const next: State = { ...state, signedInUser: null };
    return next;
  }),
  on(CommonIdAuthActions.findCommonIdSignedInUserSuccess, (state, { user }) => ({ ...state, signedInUser: user })),
  on(CommonIdAuthActions.findCommonIdSignedInUserFailure, state => {
    const next: State = { ...state, signedInUser: 'none' };
    return next;
  }),
  on(CommonIdAuthActions.commonIdSavePurchaseSuccess, (state, { response, result }) => ({
    ...state,
    signedInUser: { ...(state.signedInUser as object), ...response },
    savePurchaseResult: result
  })),
  on(CommonIdAuthActions.initializeCommonIdSavePurchaseResult, state => {
    const next: State = { ...state, savePurchaseResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commonIdAuthReducer(state, action);
}
