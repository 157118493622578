import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PURCHASE_PLAN_NAMES, PurchasePlan } from 'src/app/resources/common-id-config';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'src/app/resources/common-id/ga';
import { COMMON_ID_PURCHASE_DIALOG_WIDTH } from 'src/app/resources/config';
import { GAUtil } from 'src/app/utils/ga-util';
import { CommonIdGetStripeCheckoutUrlRequest } from '../../../../models/common-id/common-id-get-stripe-checkout-url-request';
import { enter } from '../../../../resources/animations';
import { Log } from '../../../../utils/log';
import { CommonIdPurchaseDialogComponent } from '../purchase-dialog/purchase-dialog.component';

@Component({
  selector: 'app-common-id-purchase-confirm-dialog.',
  templateUrl: './purchase-confirm-dialog.component.html',
  styleUrls: ['./purchase-confirm-dialog.component.scss'],
  animations: [enter]
})
export class CommonIdPurchaseConfirmDialogComponent implements OnInit {
  disabled = true;
  private LOG_SOURCE = this.constructor.name;

  planName: string;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CommonIdPurchaseConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {}

  ngOnInit() {
    if (!this.data) {
      Log.warn(this.LOG_SOURCE, `dialog.data が設定されていません: `, this.data);
      return;
    } else {
      this.planName = PURCHASE_PLAN_NAMES.find(it => it.id === this.data).name;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getStripeCheckoutUrl() {
    const eventCategory = this.data === PurchasePlan.ANNUAL ? GA_EVENT_CATEGORIES.MONTH_12_BUY : GA_EVENT_CATEGORIES.MONTH_6_BUY;
    const eventLabel = localStorage.getItem('previousUrl');
    const eventParams = {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.dialogRef.close();

    const data: CommonIdGetStripeCheckoutUrlRequest = {
      planId: this.data,
      isLocal: /^http:\/\/localhost:4200/.test(location.href)
    };
    const config: MatDialogConfig = {
      width: COMMON_ID_PURCHASE_DIALOG_WIDTH,
      autoFocus: true,
      data,
      disableClose: true
    };
    this.dialog.open(CommonIdPurchaseDialogComponent, config).afterClosed();
  }
}
