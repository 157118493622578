import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { Log } from 'src/app/utils/log';
import { Diff } from 'src/app/utils/diff';
import { DataUtil } from 'src/app/utils/data-util';
import { inOut } from 'src/app/resources/animations';
import { COMMON_ID_FREE_YEARS, COMMON_ID_SEARCH_YEARS, COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS } from 'src/app/resources/common-id-config';

import { Area, DepartmentCategory, University, UniversityType } from 'src/app/models/common-data';
import { StaticCommonData } from 'src/app/models/static-common-data';
import { CommonSearchCondition } from 'src/app/models/common-search-condition';
import { Year } from 'src/app/models/search-univ-interfaces';

import { DISPLAY_UNIVERSITY_TYPES, NO_DISPLAY_DEPARTMENT_CATEGORY_IDS, BUNYA_KENSAKU_DEFAULT_YEAR } from 'src/app/resources/config';

import { CommonIdSearchCategoryDialogSharedService } from 'src/app/services/common-id/common-id-search-category-dialog-shared.service';
import { UnivChipListComponent } from 'src/app/views/widgets/univ-chip-list/univ-chip-list-component';

interface UniversityTypeData extends UniversityType {
  checked: boolean;
}

interface AreaData extends Area {
  checked: boolean;
}

export interface SelectDetailedSearchConditionDialogData {
  commonSearchCondition: CommonSearchCondition;
  staticCommonData: StaticCommonData;
  matchedProblemCount$: Observable<number>;
  problemCountSearching$: Observable<boolean>;
  isPremiumUser: boolean;
}

const DEFAULT_DEPARTMENT_CATEGORY: DepartmentCategory = { id: '0', name: '指定なし' };

@Component({
  selector: 'app-common-id-select-detailed-search-condition-dialog',
  templateUrl: './select-detailed-search-condition-dialog.component.html',
  styleUrls: ['./select-detailed-search-condition-dialog.component.scss'],
  animations: [inOut]
})
export class CommonIdSelectDetailedSearchConditionDialogComponent implements OnInit {
  dialogTitle: string;
  private LOG_SOURCE = this.constructor.name;
  private defaultDepartmentCategory: DepartmentCategory;
  private defaultStartYear: string;
  private defaultEndYear: string;
  private lastNotifiedCondition: CommonSearchCondition | undefined;

  // static:trueを設定することで、ngOnInitより、下記のコンポーネントにアクセスできる
  @ViewChild(UnivChipListComponent, { static: true }) univChipListComponent: UnivChipListComponent;

  univChipListLabelName = '大学名(※)';
  univChipListFloatLabel = 'always';
  univChipListAppearance = 'outline';
  univChipListMatFormFiledClass = 'university-form-field-detailed-search-dialog';

  isThinking: boolean;
  selectedStartYear: string;
  selectedEndYear: string;
  universityTypes: UniversityTypeData[];
  areas: AreaData[];
  selectedDepartmentCategory: DepartmentCategory;

  selectedUniversities: University[] = [];
  selectableYears: Year[];
  selectableDepartmentCategories: DepartmentCategory[];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  universityNamesPlaceholder: string;

  universityNameFormControl = new FormControl();
  startYearFormControl = new FormControl();
  endYearFormControl = new FormControl();

  staticCommonData: StaticCommonData;
  selectedCondition: CommonSearchCondition;
  subjectId: string;

  matchedProblemCount$: Observable<number>;
  problemCountSearching$: Observable<boolean>;

  isPremiumUser: boolean;

  constructor(
    private dialogRef: MatDialogRef<CommonIdSelectDetailedSearchConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectDetailedSearchConditionDialogData,
    private commonIdSearchCategoryDialogSharedService: CommonIdSearchCategoryDialogSharedService
  ) {}

  ngOnInit() {
    this.dialogTitle = '詳しい検索条件で絞り込む';
    this.setUpDialogData();
    this.setUpYears();
    this.setUpUniversityTypes();
    this.setUpAreas();
    this.setUpDepartmentCategories();
    if (this.data.commonSearchCondition) {
      this.setUpIncomingData();
    }
  }

  reset() {
    this.isThinking = false;
    this.universityTypes.forEach(univType => (univType.checked = false));
    this.areas.forEach(area => (area.checked = false));
    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
    this.startYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
    this.selectedStartYear = this.defaultStartYear;
    this.endYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
    this.selectedEndYear = this.defaultEndYear;
    this.endYearFormControl.updateValueAndValidity();
    this.selectedUniversities = [];
    this.univChipListComponent.initializeSelectedUniversitiesAndNamePlaceholder();
    this.univChipListComponent.updateSelectableUniversities();

    this.lastNotifiedCondition = undefined;

    this.selectedCondition = this.collectCondition();

    Log.debug(this.LOG_SOURCE, '詳しい条件のリセット時の選択状況: ', this.selectedCondition);
    this.commonIdSearchCategoryDialogSharedService.emitSelectedCommonSearchCondition(this.selectedCondition);
  }

  close() {
    this.dialogRef.close(this.selectedCondition);
  }

  onStartYearChangeValues() {
    this.selectedStartYear = this.startYearFormControl.value;
    this.selectedEndYear = this.endYearFormControl.value;

    if (this.startYearFormControl.value === BUNYA_KENSAKU_DEFAULT_YEAR && this.endYearFormControl.value === BUNYA_KENSAKU_DEFAULT_YEAR) {
      this.selectedStartYear = this.defaultStartYear;
      this.selectedEndYear = this.defaultEndYear;
    }

    if (this.startYearFormControl.value !== BUNYA_KENSAKU_DEFAULT_YEAR && this.endYearFormControl.value === BUNYA_KENSAKU_DEFAULT_YEAR) {
      this.endYearFormControl.setValue(this.defaultEndYear);
      this.selectedEndYear = this.defaultEndYear;
    }

    if (this.startYearFormControl.value === BUNYA_KENSAKU_DEFAULT_YEAR && this.endYearFormControl.value !== BUNYA_KENSAKU_DEFAULT_YEAR) {
      this.selectedStartYear = this.defaultStartYear;
      this.endYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
      this.selectedEndYear = this.defaultEndYear;
    }

    if (this.isValidatedYears(this.selectedStartYear, this.selectedEndYear)) this.onChangeValues();
  }

  onEndYearChangeValues() {
    this.selectedStartYear = this.startYearFormControl.value;
    this.selectedEndYear = this.endYearFormControl.value;

    if (this.endYearFormControl.value !== BUNYA_KENSAKU_DEFAULT_YEAR && this.startYearFormControl.value === BUNYA_KENSAKU_DEFAULT_YEAR) {
      this.startYearFormControl.setValue(this.defaultStartYear);
      this.selectedStartYear = this.defaultStartYear;
    }

    if (this.endYearFormControl.value === BUNYA_KENSAKU_DEFAULT_YEAR && this.startYearFormControl.value !== BUNYA_KENSAKU_DEFAULT_YEAR) {
      this.selectedEndYear = this.defaultEndYear;
      this.startYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
      this.selectedStartYear = this.defaultStartYear;
    }

    if (this.isValidatedYears(this.selectedStartYear, this.selectedEndYear)) this.onChangeValues();
  }

  onChangeValues() {
    this.selectedCondition = this.collectCondition();
    Log.debug(this.LOG_SOURCE, '現在の詳しい条件の選択状況: ', this.selectedCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, this.selectedCondition)) {
      Log.debug(this.LOG_SOURCE, '前回 emit したデータと同じため無視します');
      return;
    }

    this.lastNotifiedCondition = this.selectedCondition;

    this.commonIdSearchCategoryDialogSharedService.emitSelectedCommonSearchCondition(this.selectedCondition);
  }

  changeSelectedUniversities(selectedUniversities: University[]) {
    this.selectedUniversities = selectedUniversities;
    this.onChangeValues();
  }

  private isValidatedYears(startYear: string, endYear: string): boolean {
    if (startYear > endYear) {
      Log.warn(this.LOG_SOURCE, '入試年度が不正な範囲です');
      this.endYearFormControl.setErrors({ 'min': true });
      return false;
    }

    this.endYearFormControl.updateValueAndValidity();
    return true;
  }

  private collectCondition(): CommonSearchCondition {
    // 科目
    const condition: CommonSearchCondition = {
      subjectId: this.subjectId
    };

    // 思考力
    if (this.isThinking) condition.isThinking = true;

    // 開始年度と終了年度
    const startYear: string = this.startYearFormControl.value;
    const endYear: string = this.endYearFormControl.value;
    if (startYear !== BUNYA_KENSAKU_DEFAULT_YEAR) condition.startYear = Number(startYear);
    if (endYear !== BUNYA_KENSAKU_DEFAULT_YEAR) condition.endYear = Number(endYear);

    // 大学種別
    const selectedUniversityTypeIds = this.universityTypes.filter(it => it.checked).map(it => it.id);
    if (selectedUniversityTypeIds.length === 0) {
      delete condition.universityTypeIds;
    } else {
      condition.universityTypeIds = selectedUniversityTypeIds;
    }

    // エリア
    const selectedAreaIds = this.areas.filter(it => it.checked).map(it => it.id);
    if (selectedAreaIds.length === 0) {
      delete condition.areaIds;
    } else {
      condition.areaIds = selectedAreaIds;
    }

    // 大学名
    const selectedUniversityIds = this.selectedUniversities.map(it => it.id);
    if (selectedUniversityIds.length === 0) {
      delete condition.universityIds;
    } else {
      condition.universityIds = selectedUniversityIds;
    }

    // 学部系統
    if (this.selectedDepartmentCategory.id === this.defaultDepartmentCategory.id) {
      delete condition.departmentCategoryId;
    } else {
      condition.departmentCategoryId = this.selectedDepartmentCategory.id;
    }

    return condition;
  }

  private setUpUniversityTypes() {
    const data = DataUtil.sortObjectArrayBySpecificKey(this.staticCommonData.universityTypes, 'id')
      .filter(ut => DISPLAY_UNIVERSITY_TYPES.includes(ut.id))
      .map<UniversityTypeData>(ut => ({
        ...ut,
        checked: false
      }));
    this.universityTypes = data;
  }

  private setUpAreas() {
    const data = DataUtil.sortObjectArrayBySpecificKey(this.staticCommonData.areas, 'id').map<AreaData>(area => ({
      ...area,
      checked: false
    }));
    this.areas = data;
  }

  private setUpIncomingData() {
    const condition = this.data.commonSearchCondition;
    this.subjectId = condition.subjectId;

    // 思考力
    this.isThinking = condition.isThinking;

    // 年度
    if (condition.startYear) {
      this.selectedStartYear = condition.startYear.toString();
      this.startYearFormControl.setValue(this.selectedStartYear);
    } else {
      this.selectedStartYear = this.defaultStartYear;
      this.startYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
    }
    if (condition.endYear) {
      this.selectedEndYear = condition.endYear.toString();
      this.endYearFormControl.setValue(this.selectedEndYear);
    } else {
      this.selectedEndYear = this.defaultEndYear;
      this.endYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
    }

    // 大学種別
    if (condition.universityTypeIds && condition.universityTypeIds.length !== 0) {
      this.universityTypes.forEach(univType => {
        if (condition.universityTypeIds.includes(univType.id)) univType.checked = true;
      });
    }

    // 大学名
    if (condition.universityIds && condition.universityIds.length !== 0) {
      const selectedUnivs = this.staticCommonData.universities.filter(univ => condition.universityIds.includes(univ.id));
      selectedUnivs.forEach(selectedUniv => this.selectedUniversities.push(selectedUniv));
      this.univChipListComponent.updateUnivChipList(this.selectedUniversities);
    }

    // エリア
    if (condition.areaIds && condition.areaIds.length !== 0) {
      this.areas.forEach(area => {
        if (condition.areaIds.includes(area.id)) area.checked = true;
      });
    }

    // 学部系統
    if (condition.departmentCategoryId) {
      const depCategory = this.selectableDepartmentCategories.find(it => it.id === condition.departmentCategoryId);
      if (depCategory) this.selectedDepartmentCategory = depCategory;
    }
  }

  private setUpDialogData() {
    this.staticCommonData = this.data.staticCommonData;
    this.matchedProblemCount$ = this.data.matchedProblemCount$;
    this.problemCountSearching$ = this.data.problemCountSearching$;
    this.isPremiumUser = this.data.isPremiumUser;
  }

  private setUpYears() {
    let searchYearsStringArray: string[] = [];

    searchYearsStringArray = COMMON_ID_SEARCH_YEARS.map(year => {
      return year.toString();
    });
    this.defaultEndYear = searchYearsStringArray[0];
    this.defaultStartYear = [...searchYearsStringArray].pop();

    searchYearsStringArray.unshift(BUNYA_KENSAKU_DEFAULT_YEAR);
    this.selectableYears = searchYearsStringArray.map(year => {
      if (year === BUNYA_KENSAKU_DEFAULT_YEAR) {
        return { label: year, value: year };
      } else {
        const label = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(year) ? year : year + COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS;
        return { label, value: year };
      }
    });

    this.startYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
    this.endYearFormControl.setValue(BUNYA_KENSAKU_DEFAULT_YEAR);
  }

  private setUpDepartmentCategories() {
    this.defaultDepartmentCategory = DEFAULT_DEPARTMENT_CATEGORY;
    this.selectableDepartmentCategories = DataUtil.sortNumberIdentifiedData([
      ...this.staticCommonData.departmentCategories.filter(it => !NO_DISPLAY_DEPARTMENT_CATEGORY_IDS.includes(it.id)),
      this.defaultDepartmentCategory
    ]);
    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
  }
}
