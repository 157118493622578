import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { filter, take, map, shareReplay } from 'rxjs/operators';

import * as SearchActions from 'src/app/actions/search.actions';
import * as SearchSelectors from 'src/app/selectors/search.selectors';
import { RootState } from 'src/app/reducers';
import { SearchCondition, SearchProblemsCondition, SearchProblemsConditionWithTotalPage } from 'src/app/models/search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import {
  SubjectId,
  SEARCH_RESULTS_PER_PAGE,
  PAGINATION_WINDOW_SIZE,
  MAX_PRINTABLE_COUNT,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  PRINT_CONFIRM_DIALOG_WIDTH,
  SortType,
  SortTypeDisplayName,
  SCIENCE_IDS
} from 'src/app/resources/config';
import { Log } from 'src/app/utils/log';
import { navigate, setTitle, openWindow, setBrowserTitle, dispatchInfoMessage } from 'src/app/actions/core.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import {
  getStaticMathData,
  getStaticJapaneseHistoryData,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from 'src/app/selectors/static-data.selectors';
import {
  ReadableScienceProblem,
  ReadableEnglishProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguageProblem
} from 'src/app/models/problem';
import { ReadableCommonSearchCondition } from 'src/app/models/common-search-condition';
import { enter, fadeInOut } from 'src/app/resources/animations';
import { EnglishSearchCondition, ReadableEnglishSearchCondition } from 'src/app/models/english-search-condition';
import { HistorySearchCondition, ReadableHistorySearchCondition } from 'src/app/models/history-search-condition';
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { PrintConfirmDialogComponent, PrintConfirmDialogResultType } from '../print-confirm-dialog/print-confirm-dialog.component';
import { AppEvent } from 'src/app/models/event-log';
import { reportAppEvent } from 'src/app/actions/event-log.actions';
import {
  NationalLanguageSearchCondition,
  ReadableNationalLanguageSearchCondition
} from 'src/app/models/national-language-search-condition';
import { AddBookmarkData, Bookmark, BookmarkProblem, DeleteBookmarkData } from 'src/app/models/bookmark';
import * as BookmarkSelectors from 'src/app/selectors/bookmark.selectors';
import * as BookmarkActions from 'src/app/actions/bookmark.actions';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { User } from 'src/app/models/user';
import { addBookmark, deleteBookmark } from 'src/app/actions/bookmark.actions';

interface SelectableSortType {
  type: SortType;
  displayName: string;
}

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [enter, fadeInOut]
})
export class SearchResultComponent implements OnInit, OnDestroy {
  @ViewChild('pageTop') pageTop: ElementRef;

  conditionOpenState = false;

  private LOG_SOURCE = this.constructor.name;
  private title = '基本検索結果';
  private conditionInitializedSubject = new BehaviorSubject(false);
  private firstPageInitializedSubject = new BehaviorSubject(false);
  private initializedBookmarkSubject = new BehaviorSubject(false);

  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableMathProblems$: Observable<ReadableScienceProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readablePhysicsProblems$: Observable<ReadableScienceProblem[]>;
  readableChemistryProblems$: Observable<ReadableScienceProblem[]>;
  readableBiologyProblems$: Observable<ReadableScienceProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableGeographyProblems$: Observable<ReadableScienceProblem[]>;
  readablePoliticalEconomyProblems$: Observable<ReadableScienceProblem[]>;
  problemsSearching$: Observable<boolean>;
  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;
  user: User;

  ENGLISH_SUBJECT_ID: string;
  MATH_SUBJECT_ID: string;
  NATIONAL_LANGUAGE_SUBJECT_ID: string;
  PHYSICS_SUBJECT_ID: string;
  CHEMISTRY_SUBJECT_ID: string;
  BIOLOGY_SUBJECT_ID: string;
  JAPANESE_HISTORY_SUBJECT_ID: string;
  WORLD_HISTORY_SUBJECT_ID: string;
  GEOGRAPHY_SUBJECT_ID: string;
  POLITICAL_ECONOMY_SUBJECT_ID: string;

  searchCondition: SearchCondition<
    EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition
  >;
  readableSearchCondition$: Observable<ReadableCommonSearchCondition>;
  readableEnglishCondition$: Observable<ReadableEnglishSearchCondition>;
  readableNationalLanguageCondition$: Observable<ReadableNationalLanguageSearchCondition>;
  readableJapaneseHistoryCondition$: Observable<ReadableHistorySearchCondition>;
  readableWorldHistoryCondition$: Observable<ReadableHistorySearchCondition>;

  totalProblemCount: number;
  selectableSortTypes: SelectableSortType[];
  selectedSortType: SelectableSortType;
  firstPageInitialized$ = this.firstPageInitializedSubject.asObservable();
  conditionInitialized$ = this.conditionInitializedSubject.asObservable();
  initialized$: Observable<boolean>;
  initializedBookmark$ = this.initializedBookmarkSubject.asObservable();

  // 表示用
  englishSearchCondition: EnglishSearchCondition;
  mathSearchCondition: ScienceSearchCondition;
  nationalLanguageSearchCondition: NationalLanguageSearchCondition;
  physicsSearchCondition: ScienceSearchCondition;
  chemistrySearchCondition: ScienceSearchCondition;
  biologySearchCondition: ScienceSearchCondition;
  japaneseHistorySearchCondition: HistorySearchCondition;
  worldHistorySearchCondition: HistorySearchCondition;
  geographySearchCondition: ScienceSearchCondition;
  politicalEconomySearchCondition: ScienceSearchCondition;
  bookmark: Bookmark;
  bookmarkSubjectProblems: BookmarkProblem[];

  // pagination ----------
  currentPage = 1;
  totalPage: number;
  paginationPageSize: number;
  paginationWindowSize: number;

  signedInUser$: Observable<User>;

  constructor(
    private store: Store<RootState>,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.ENGLISH_SUBJECT_ID = SubjectId.ENGLISH;
    this.MATH_SUBJECT_ID = SubjectId.MATH;
    this.NATIONAL_LANGUAGE_SUBJECT_ID = SubjectId.NATIONAL_LANGUAGE;
    this.PHYSICS_SUBJECT_ID = SubjectId.PHYSICS;
    this.CHEMISTRY_SUBJECT_ID = SubjectId.CHEMISTRY;
    this.BIOLOGY_SUBJECT_ID = SubjectId.BIOLOGY;
    this.JAPANESE_HISTORY_SUBJECT_ID = SubjectId.JAPANESE_HISTORY;
    this.WORLD_HISTORY_SUBJECT_ID = SubjectId.WORLD_HISTORY;
    this.GEOGRAPHY_SUBJECT_ID = SubjectId.GEOGRAPHY;
    this.POLITICAL_ECONOMY_SUBJECT_ID = SubjectId.POLITICAL_ECONOMY;

    this.paginationPageSize = SEARCH_RESULTS_PER_PAGE;
    this.paginationWindowSize = PAGINATION_WINDOW_SIZE;
    this.setUpSelectableSortTypes();
  }

  @HostListener('window:popstate')
  onPopState() {
    Log.debug(this.LOG_SOURCE, 'onPopState');
    this.store.dispatch(SearchActions.setSearchCondition({ condition: { ...this.searchCondition } }));
  }

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.store.dispatch(SearchActions.initializeSearchCondition());
    this.setUps();
  }

  ngOnDestroy() {
    this.store.dispatch(SearchActions.initializeProblemsState());
    this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
    this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
    this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
  }

  selectPage(page: number) {
    Log.debug(this.LOG_SOURCE, `選択された page: ${page}`);
    this.scrollToTop();
    this.findProblems(page, this.selectedSortType.type);
    this.currentPage = page;
  }

  onChangeSortType() {
    this.selectPage(1);
  }

  showPreviousView() {
    if (!this.searchCondition || !this.selectedSortType || !this.totalPage) {
      this.location.back();
      return;
    }

    const condition: SearchProblemsConditionWithTotalPage<
      EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition
    > = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type,
      page: this.currentPage,
      totalPage: this.totalPage
    };
    const event: AppEvent = {
      ...condition,
      type: 'back-to-search-form',
      value: JSON.stringify(condition)
    };
    setTimeout(() => this.store.dispatch(reportAppEvent({ event })));
    this.location.back();
  }

  showPrint() {
    // 表示している件数が 300 件を超えていたら print-confirm-dialog 経由で print.component を表示
    // 300 件以下の場合は直接 print.component を表示
    if (this.totalProblemCount <= MAX_PRINTABLE_COUNT) {
      this.openPrintPage();
      return;
    }

    const dialog = this.dialog.open(PrintConfirmDialogComponent, {
      width: PRINT_CONFIRM_DIALOG_WIDTH,
      autoFocus: false,
      restoreFocus: false,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      disableClose: true
    });

    dialog.afterClosed().subscribe((result: PrintConfirmDialogResultType) => {
      if (result === 'OK') {
        this.openPrintPage();
      }
    });
  }

  showEnglishProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type
    };
    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams } }));
  }

  showScienceProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type
    };
    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams } }));
  }

  showNationalLanguageProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type
    };
    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams } }));
  }

  showJapaneseHistoryProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type
    };
    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams } }));
  }

  showWorldHistoryProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type
    };
    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams } }));
  }

  toggleConditionOpenState() {
    this.conditionOpenState = !this.conditionOpenState;
  }

  addBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを登録します');
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを登録しています`
      })
    );

    const request: AddBookmarkData = {
      userId: this.user.id,
      subjectId: this.searchCondition.subjectId,
      problemId
    };

    Log.debug(this.LOG_SOURCE, 'add bookmark', request);
    this.store.dispatch(addBookmark(request));

    this.store
      .select(BookmarkSelectors.getAddBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `add bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを登録しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `add bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
        this.setUpBookmarks();
      });
  }

  deleteBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを解除します');
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを解除しています`
      })
    );

    const request: DeleteBookmarkData = {
      userId: this.user.id,
      subjectId: this.searchCondition.subjectId,
      problemId
    };

    Log.debug(this.LOG_SOURCE, 'delete bookmark', request);
    this.store.dispatch(deleteBookmark(request));

    this.store
      .select(BookmarkSelectors.getDeleteBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `delete bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
        this.setUpBookmarks();
      });
  }

  private openPrintPage() {
    const condition: SearchProblemsCondition<any> = {
      ...this.searchCondition,
      sortType: this.selectedSortType.type
    };
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, `印刷画面用の query params: `, queryParams);

    this.store.dispatch(openWindow({ url: RoutingPathResolver.resolvePrint(), extras: { queryParams } }));
  }

  // set ups ----------------------------------------------------------------------------------------------

  private setUps() {
    this.problemsSearching$ = this.store.select(SearchSelectors.getProblemsSearching);
    this.staticMathData$ = this.store.select(getStaticMathData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPhysicsData$ = this.store.select(getStaticPhysicsData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticChemistryData$ = this.store.select(getStaticChemistryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticBiologyData$ = this.store.select(getStaticBiologyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticJapaneseHistoryData$ = this.store.select(getStaticJapaneseHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticWorldHistoryData$ = this.store.select(getStaticWorldHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticGeographyData$ = this.store.select(getStaticGeographyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPoliticalEconomyData$ = this.store.select(getStaticPoliticalEconomyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.setUpBookmarks();

    this.initializedBookmark$.pipe(take(2)).subscribe(initializedBookmark => {
      if (initializedBookmark) {
        this.setUpEnglishProblems();
        this.setUpMathProblems();
        this.setUpNationalLanguageProblems();
        this.setUpPhysicsProblems();
        this.setUpChemistryProblems();
        this.setUpBiologyProblems();
        this.setUpJapaneseHistoryProblems();
        this.setUpWorldHistoryProblems();
        this.setUpGeographyProblems();
        this.setUpPoliticalEconomyProblems();
        this.setUpLoading();
        this.findFirstPageProblems();
      }
    });
  }

  private setUpEnglishProblems() {
    this.readableEnglishProblems$ = this.store.select(SearchSelectors.getReadableEnglishProblems).pipe(shareReplay(1));
    this.readableEnglishProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpMathProblems() {
    this.readableMathProblems$ = this.store.select(SearchSelectors.getReadableMathProblems).pipe(shareReplay(1));
    this.readableMathProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpNationalLanguageProblems() {
    this.readableNationalLanguageProblems$ = this.store.select(SearchSelectors.getReadableNationalLanguageProblems).pipe(shareReplay(1));
    this.readableNationalLanguageProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpPhysicsProblems() {
    this.readablePhysicsProblems$ = this.store.select(SearchSelectors.getReadablePhysicsProblems).pipe(shareReplay(1));
    this.readablePhysicsProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpChemistryProblems() {
    this.readableChemistryProblems$ = this.store.select(SearchSelectors.getReadableChemistryProblems).pipe(shareReplay(1));
    this.readableChemistryProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpBiologyProblems() {
    this.readableBiologyProblems$ = this.store.select(SearchSelectors.getReadableBiologyProblems).pipe(shareReplay(1));
    this.readableBiologyProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpJapaneseHistoryProblems() {
    this.readableJapaneseHistoryProblems$ = this.store.select(SearchSelectors.getReadableJapaneseHistoryProblems).pipe(shareReplay(1));
    this.readableJapaneseHistoryProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpWorldHistoryProblems() {
    this.readableWorldHistoryProblems$ = this.store.select(SearchSelectors.getReadableWorldHistoryProblems).pipe(shareReplay(1));
    this.readableWorldHistoryProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpGeographyProblems() {
    this.readableGeographyProblems$ = this.store.select(SearchSelectors.getReadableGeographyProblems).pipe(shareReplay(1));
    this.readableGeographyProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpPoliticalEconomyProblems() {
    this.readablePoliticalEconomyProblems$ = this.store.select(SearchSelectors.getReadablePoliticalEconomyProblems).pipe(shareReplay(1));
    this.readablePoliticalEconomyProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(() => this.firstPageInitializedSubject.next(true));
  }

  private setUpTotalPage(totalProblemCount: number) {
    const totalPage = Math.ceil(totalProblemCount / SEARCH_RESULTS_PER_PAGE);
    this.totalPage = totalPage;
  }

  private setUpSelectableSortTypes() {
    const sortTypeDefault: SelectableSortType = { type: SortType.D_CODE, displayName: SortTypeDisplayName.D_CODE };
    this.selectableSortTypes = [
      sortTypeDefault,
      { type: SortType.UNIVERSITY_NAME, displayName: SortTypeDisplayName.UNIVERSITY_NAME },
      { type: SortType.YEAR_ASC, displayName: SortTypeDisplayName.YEAR_ASC },
      { type: SortType.YEAR_DESC, displayName: SortTypeDisplayName.YEAR_DESC },
      { type: SortType.LEVEL_ASC, displayName: SortTypeDisplayName.LEVEL_ASC },
      { type: SortType.LEVEL_DESC, displayName: SortTypeDisplayName.LEVEL_DESC }
    ];
    this.selectedSortType = sortTypeDefault;
  }

  private setUpLoading() {
    this.initialized$ = combineLatest([this.conditionInitialized$, this.firstPageInitialized$]).pipe(
      map(([conditionInitialized, firstPageInitialized]) => conditionInitialized && firstPageInitialized)
    );
  }

  private findFirstPageProblems() {
    const conditionParams = this.activatedRoute.snapshot.queryParams;
    Log.debug(this.LOG_SOURCE, `query params: `, conditionParams);
    const condition = QueryParamsMapper.decodeSearchConditionQueryParams(conditionParams);
    if (!condition) {
      Log.warn(this.LOG_SOURCE, `検索条件が設定されていないため前の画面に戻ります`);
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
      return;
    }

    this.store.dispatch(SearchActions.initializeProblemCountState());
    this.findProblemCount(condition);
    this.store
      .select(SearchSelectors.getMatchedProblemCount)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problemCount => {
        this.searchCondition = condition;
        this.totalProblemCount = problemCount;

        this.readableSearchCondition$ = this.store.pipe(select(SearchSelectors.getReadableSearchCondition(condition)));

        if (this.searchCondition.subjectId === SubjectId.ENGLISH) {
          const subCondition = (this.searchCondition as SearchCondition<EnglishSearchCondition>).subjectCondition;
          this.englishSearchCondition = subCondition ? { ...subCondition } : {};
          const subjectCondition = condition.subjectCondition as EnglishSearchCondition;
          this.readableEnglishCondition$ = this.store.pipe(select(SearchSelectors.getReadableEnglishCondition(subjectCondition || {})));
        }

        if (this.searchCondition.subjectId === SubjectId.MATH) {
          const subCondition = (this.searchCondition as SearchCondition<ScienceSearchCondition>).subjectCondition;
          this.mathSearchCondition = subCondition ? { ...subCondition } : {};
        }

        if (this.searchCondition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          const subCondition = (this.searchCondition as SearchCondition<NationalLanguageSearchCondition>).subjectCondition;
          this.nationalLanguageSearchCondition = subCondition ? { ...subCondition } : {};
          const subjectCondition = condition.subjectCondition as NationalLanguageSearchCondition;
          this.readableNationalLanguageCondition$ = this.store.pipe(
            select(SearchSelectors.getReadableNationalLanguageCondition(subjectCondition || {}))
          );
        }

        if (this.searchCondition.subjectId === SubjectId.PHYSICS) {
          const subCondition = (this.searchCondition as SearchCondition<ScienceSearchCondition>).subjectCondition;
          this.physicsSearchCondition = subCondition ? { ...subCondition } : {};
        }

        if (this.searchCondition.subjectId === SubjectId.CHEMISTRY) {
          const subCondition = (this.searchCondition as SearchCondition<ScienceSearchCondition>).subjectCondition;
          this.chemistrySearchCondition = subCondition ? { ...subCondition } : {};
        }

        if (this.searchCondition.subjectId === SubjectId.BIOLOGY) {
          const subCondition = (this.searchCondition as SearchCondition<ScienceSearchCondition>).subjectCondition;
          this.biologySearchCondition = subCondition ? { ...subCondition } : {};
        }

        if (this.searchCondition.subjectId === SubjectId.JAPANESE_HISTORY) {
          const subCondition = (this.searchCondition as SearchCondition<HistorySearchCondition>).subjectCondition;
          this.japaneseHistorySearchCondition = subCondition ? { ...subCondition } : {};
          this.readableJapaneseHistoryCondition$ = this.store.pipe(
            select(SearchSelectors.getReadableJapaneseHistoryCondition(subCondition || {}))
          );
        }

        if (this.searchCondition.subjectId === SubjectId.WORLD_HISTORY) {
          const subCondition = (this.searchCondition as SearchCondition<HistorySearchCondition>).subjectCondition;
          this.worldHistorySearchCondition = subCondition ? { ...subCondition } : {};
          this.readableWorldHistoryCondition$ = this.store.pipe(
            select(SearchSelectors.getReadableWorldHistoryCondition(subCondition || {}))
          );
        }

        if (this.searchCondition.subjectId === SubjectId.GEOGRAPHY) {
          const subCondition = (this.searchCondition as SearchCondition<ScienceSearchCondition>).subjectCondition;
          this.geographySearchCondition = subCondition ? { ...subCondition } : {};
        }

        if (this.searchCondition.subjectId === SubjectId.POLITICAL_ECONOMY) {
          const subCondition = (this.searchCondition as SearchCondition<ScienceSearchCondition>).subjectCondition;
          this.politicalEconomySearchCondition = subCondition ? { ...subCondition } : {};
        }

        this.conditionInitializedSubject.next(true);

        if (problemCount === 0) {
          Log.warn(this.LOG_SOURCE, `条件に該当する大問が 0 件です`);
          this.firstPageInitializedSubject.next(true);
          return;
        }

        this.setUpTotalPage(problemCount);
        this.selectPage(this.currentPage);
      });
  }

  private findProblems(page: number, sortType: SortType) {
    if (this.searchCondition.subjectId === SubjectId.ENGLISH) {
      this.findEnglishProblems(page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.MATH) {
      this.findScienceProblems('MATH', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
      this.findNationalLanguageProblems(page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.PHYSICS) {
      this.findScienceProblems('PHYSICS', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.CHEMISTRY) {
      this.findScienceProblems('CHEMISTRY', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.BIOLOGY) {
      this.findScienceProblems('BIOLOGY', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.JAPANESE_HISTORY) {
      this.findHistoryProblems('JAPANESE_HISTORY', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.WORLD_HISTORY) {
      this.findHistoryProblems('WORLD_HISTORY', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.GEOGRAPHY) {
      this.findScienceProblems('GEOGRAPHY', page, sortType);
      return;
    }
    if (this.searchCondition.subjectId === SubjectId.POLITICAL_ECONOMY) {
      this.findScienceProblems('POLITICAL_ECONOMY', page, sortType);
      return;
    }

    Log.error(this.LOG_SOURCE, 'subjectId が想定されていないものです: ', this.searchCondition.subjectId);
  }

  private findEnglishProblems(page: number, sortType: SortType) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の english の大問を取得します`);
    const currentCondition = this.searchCondition as SearchCondition<EnglishSearchCondition>;
    const condition: SearchProblemsCondition<EnglishSearchCondition> = {
      ...currentCondition,
      sortType,
      page
    };
    this.store.dispatch(SearchActions.findEnglishProblems({ condition }));
  }

  private findScienceProblems(
    subject: 'MATH' | 'PHYSICS' | 'CHEMISTRY' | 'BIOLOGY' | 'GEOGRAPHY' | 'POLITICAL_ECONOMY',
    page: number,
    sortType: SortType
  ) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の ${subject} の大問を取得します`);
    const currentCondition = this.searchCondition as SearchCondition<ScienceSearchCondition>;
    const condition: SearchProblemsCondition<ScienceSearchCondition> = {
      ...currentCondition,
      sortType,
      page
    };

    const action =
      subject === 'MATH'
        ? SearchActions.findMathProblems({ condition })
        : subject === 'PHYSICS'
        ? SearchActions.findPhysicsProblems({ condition })
        : subject === 'BIOLOGY'
        ? SearchActions.findBiologyProblems({ condition })
        : subject === 'GEOGRAPHY'
        ? SearchActions.findGeographyProblems({ condition })
        : subject === 'POLITICAL_ECONOMY'
        ? SearchActions.findPoliticalEconomyProblems({ condition })
        : SearchActions.findChemistryProblems({ condition });
    this.store.dispatch(action);
  }

  private findNationalLanguageProblems(page: number, sortType: SortType) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の national language の大問を取得します`);
    const currentCondition = this.searchCondition as SearchCondition<NationalLanguageSearchCondition>;
    const condition: SearchProblemsCondition<NationalLanguageSearchCondition> = {
      ...currentCondition,
      sortType,
      page
    };
    this.store.dispatch(SearchActions.findNationalLanguageProblems({ condition }));
  }

  private findHistoryProblems(subject: 'JAPANESE_HISTORY' | 'WORLD_HISTORY', page: number, sortType: SortType) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の ${subject} の問題を取得します`);
    const currentCondition = this.searchCondition as SearchCondition<HistorySearchCondition>;
    const condition: SearchProblemsCondition<HistorySearchCondition> = {
      ...currentCondition,
      sortType,
      page
    };
    const action =
      subject === 'JAPANESE_HISTORY'
        ? SearchActions.findJapaneseHistoryProblems({ condition })
        : SearchActions.findWorldHistoryProblems({ condition });
    this.store.dispatch(action);
  }

  private findProblemCount(condition: SearchCondition<EnglishSearchCondition | ScienceSearchCondition | HistorySearchCondition>) {
    if (condition.subjectId === SubjectId.ENGLISH) {
      const c = condition as SearchCondition<EnglishSearchCondition>;
      this.store.dispatch(SearchActions.findEnglishProblemCount({ condition: c, logging: false }));
      return;
    }
    if (SCIENCE_IDS.includes(condition.subjectId as SubjectId)) {
      const subjectId = condition.subjectId;
      const c = condition as SearchCondition<ScienceSearchCondition>;
      const action =
        subjectId === SubjectId.MATH
          ? SearchActions.findMathProblemCount({ condition: c, logging: false })
          : subjectId === SubjectId.PHYSICS
          ? SearchActions.findPhysicsProblemCount({ condition: c, logging: false })
          : subjectId === SubjectId.BIOLOGY
          ? SearchActions.findBiologyProblemCount({ condition: c, logging: false })
          : subjectId === SubjectId.GEOGRAPHY
          ? SearchActions.findGeographyProblemCount({ condition: c, logging: false })
          : subjectId === SubjectId.POLITICAL_ECONOMY
          ? SearchActions.findPoliticalEconomyProblemCount({ condition: c, logging: false })
          : SearchActions.findChemistryProblemCount({ condition: c, logging: false });
      this.store.dispatch(action);
      return;
    }
    if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
      const c = condition as SearchCondition<NationalLanguageSearchCondition>;
      this.store.dispatch(SearchActions.findNationalLanguageProblemCount({ condition: c, logging: false }));
      return;
    }
    if ([SubjectId.JAPANESE_HISTORY, SubjectId.WORLD_HISTORY].includes(condition.subjectId as SubjectId)) {
      const subjectId = condition.subjectId;
      const c = condition as SearchCondition<HistorySearchCondition>;
      const action =
        subjectId === SubjectId.JAPANESE_HISTORY
          ? SearchActions.findJapaneseHistoryProblemCount({ condition: c, logging: false })
          : SearchActions.findWorldHistoryProblemCount({ condition: c, logging: false });
      this.store.dispatch(action);
      return;
    }
    Log.error(this.LOG_SOURCE, `findProblemCount: 不明な subjectId が指定されています: `, condition);
  }

  private setUpBookmarks() {
    const conditionParams = this.activatedRoute.snapshot.queryParams;
    this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
    this.store
      .select(getSignedInUser)
      .pipe(filter<User>(it => it != null && it !== 'none'))
      .pipe(take(1))
      .subscribe(user => {
        this.user = user;
        this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
        this.store.dispatch(BookmarkActions.findBookmark({ userId: user.id }));
        this.store
          .select(BookmarkSelectors.getBookmark)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(bookmark => {
            this.bookmark = bookmark;
            this.bookmarkSubjectProblems = [];
            if (bookmark.problems !== undefined) {
              this.bookmarkSubjectProblems = this.bookmark.problems.filter(problem => problem.subjectId === conditionParams.subjectId);
            }
            this.store.dispatch(BookmarkActions.initializeFindBookmarkState());

            this.initializedBookmarkSubject.next(true);
          });
      });
  }

  private scrollToTop() {
    this.pageTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
