import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, take, shareReplay } from 'rxjs/operators';

import { RootState } from '../../../../reducers';
import { dispatchInfoMessage, navigate, setTitle } from 'src/app/actions/core.actions';
import * as CommonIdSearchActions from 'src/app/actions/common-id/common-id-search.actions';
import * as CommonIdUniversityBookmarkActions from 'src/app/actions/common-id/common-id-university-bookmark.actions';
import * as CommonIdFindUniversityActions from 'src/app/actions/common-id/common-id-find-university.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { getUniversities } from 'src/app/selectors/common-id/common-id-search.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import * as CommonIdUniversityBookmarkSelectors from 'src/app/selectors/common-id/common-id-university-bookmark.selectors';
import { getFindUniversityResult } from 'src/app/selectors/common-id/common-id-find-university.selectors';
import { getCommonIdVisitedPapers } from 'src/app/selectors/common-id/common-id-visited-paper.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';

import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

import { UniversityCondition, UniversitySearchType, UniversitySearchCondition } from 'src/app/models/university-search-condition';
import { StaticCommonData } from '../../../../models/static-common-data';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdFindUniversityPapersResponse } from 'src/app/models/common-id/common-id-find-university-papers-response';
import { UniversityBookmarkCondition } from 'src/app/models/common-id/common-id-bookmark-conditions';
import { Department } from 'src/app/models/find-university-papers-result';
import { UniversityBookmark } from 'src/app/models/common-id/common-id-bookmark';
import { CommonIdFindUniversityRequest } from 'src/app/models/common-id/common-id-find-university-request';
import { CurrentDateTime } from 'src/app/models/current-date-time';

import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { CommonIdSubjectUtil } from 'src/app/utils/common-id/common-id-subject-util';
import {
  COMMON_ID_PREFECTURES,
  COMMON_ID_FREE_YEARS,
  COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS,
  DESCRIPTION_PREMIUM_DIALOG_WITDH,
  COMMON_TEST_PREFECTURE_NAME,
  COMMON_TEST_UNIVERSITY_ID
} from 'src/app/resources/common-id-config';
import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';
import { DIALOG_ZERO_PADDING_PANEL_CLASS } from '../../../../resources/config';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { GoToPaperDetailParams, UniversityDetails, Year } from '../../../../models/search-univ-interfaces';

@Component({
  selector: 'app-common-id-search-univ-detail',
  templateUrl: './search-univ-detail.component.html',
  styleUrls: ['./search-univ-detail.component.scss']
})
export class CommonIdSearchUnivDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  private LOG_SOURCE = this.constructor.name;
  private title = '大学詳細';

  private initializedBookmarkSubject = new BehaviorSubject(false);
  private errorUnivIdSubject = new BehaviorSubject(null);

  isPremiumUser: boolean;

  currentDateTime$: Observable<CurrentDateTime>;

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;
  signedInUser$: Observable<CommonIdUser>;

  universities$: Observable<CommonIdFindUniversityPapersResponse[]>;
  searchResults: Department[];

  isPremiumIconShown: boolean;

  universityBookmark$: Observable<UniversityBookmark>;
  isBookmarked: boolean;
  initializedBookmark$ = this.initializedBookmarkSubject.asObservable();
  errorUnivId$ = this.errorUnivIdSubject.asObservable();

  years: Year[];
  selectedYear: string;
  defaultYear: string;

  universityId: string;
  universityDetails$: Observable<UniversityDetails>;

  commonIdVisitedPapers$: Observable<string[]>;
  commonIdVisitedPapers: string[];

  isLoadingFinished$: Observable<boolean>;

  yearFromQueryParams: string;

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));

    this.isLoadingFinished$ = of(false);
    this.setUpCurrentDateTime();
    this.setUpUser();
    this.setUpStaticCommonData();
    this.setUpCommonIdVisitedPapers();
    this.setUpUniversityBookmarks();
    this.setUpIsBookmarkedFlag();

    // クエリパラメータの大学IDと年度を取得
    this.universityId = this.activatedRoute.snapshot.paramMap.get('universityId');
    Log.debug(this.LOG_SOURCE, `大学詳細画面　クエリパラメータ 大学ID : `, this.universityId);
    this.yearFromQueryParams = this.activatedRoute.snapshot.queryParams.year;
    Log.debug(this.LOG_SOURCE, `大学詳細画面　クエリパラメータ 年度 : `, this.yearFromQueryParams);

    // 大学詳細と利用可能な年度を取得
    this.setUpUniversityAndYears();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.store.dispatch(CommonIdSearchActions.initializeUniversityPapersState());
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeDeleteUniversityBookmarkState());
    this.store.dispatch(CommonIdFindUniversityActions.initializeFindUniversityState());
  }

  addUniversityBookmark(universityId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入り大学を登録します ', universityId);
    this.errorUnivIdSubject.next(null);
    this.initializedBookmarkSubject.next(false);

    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入り大学を登録しています`
      })
    );

    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        const request: UniversityBookmarkCondition = {
          userId: signedInUser.id,
          universityId
        };

        Log.debug(this.LOG_SOURCE, 'お気に入り大学の登録 request: ', request);
        this.store.dispatch(CommonIdUniversityBookmarkActions.addUniversityBookmark({ condition: request }));
      });

    this.store
      .select(CommonIdUniversityBookmarkSelectors.getAddUniversityBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `お気に入り大学の登録 result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入り大学を登録しました`
            })
          );
          this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
          this.setUpIsBookmarkedFlag();
        } else {
          Log.warn(this.LOG_SOURCE, `お気に入り大学の登録 error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
          this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
          this.errorUnivIdSubject.next(universityId);
          this.initializedBookmarkSubject.next(true);
        }
      });
  }

  deleteUniversityBookmark(universityId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入り大学を解除します ', universityId);
    this.errorUnivIdSubject.next(null);
    this.initializedBookmarkSubject.next(false);

    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入り大学を解除しています`
      })
    );

    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        const request: UniversityBookmarkCondition = {
          userId: signedInUser.id,
          universityId
        };

        Log.debug(this.LOG_SOURCE, 'お気に入り大学の解除 request: ', request);
        this.store.dispatch(CommonIdUniversityBookmarkActions.deleteUniversityBookmark({ condition: request }));
      });

    this.store
      .select(CommonIdUniversityBookmarkSelectors.getDeleteUniversityBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `お気に入り大学の解除 result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入り大学を解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `お気に入り大学の解除 error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(CommonIdUniversityBookmarkActions.initializeDeleteUniversityBookmarkState());
        this.setUpIsBookmarkedFlag();
      });
  }

  onYearsChange(selectedYear: string) {
    // 年度の値を取得 + 王冠アイコンの表示有無フラグを設定
    this.isPremiumIconShown = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(selectedYear) ? false : true;
    this.selectedYear = selectedYear;

    this.initializeSearchResults();
    this.setUpUniversityDetails();
  }

  goToPaperDetail(event: GoToPaperDetailParams) {
    if (event.isPremiumIconShown) {
      const config: MatDialogConfig = {
        width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
        panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
        autoFocus: false,
        disableClose: true
      };
      this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
    } else {
      Log.debug(this.LOG_SOURCE, '問題詳細を開きます: ', event.paperId);

      const url = RoutingPathResolver.resolveCommonIdPaperDetail(event.paperId);
      const extras: NavigationExtras = {
        queryParams: UniversitySearchQueryParamsMapper.encodeDeptNameAndSpecialSubject(event.deptName, event.specialSubject)
      };

      this.store.dispatch(navigate({ url, extras }));
    }
  }

  private findUniversity(condition: UniversitySearchCondition<UniversityCondition>, page: number) {
    this.store.dispatch(CommonIdSearchActions.initializeUniversityPapersState());
    this.store.dispatch(CommonIdSearchActions.findUniversityPapersByUniversityIds({ condition, page }));

    this.universities$ = this.store.select(getUniversities).pipe(filter(it => it != null));

    this.universities$.pipe(take(1)).subscribe(universities => {
      // staticCommonDataとcommonIdVisitedPapersの取得
      this.staticCommonData$.subscribe(staticCommonData => {
        this.staticCommonData = staticCommonData;
      });

      this.commonIdVisitedPapers$.subscribe(commonIdVisitedPapers => {
        this.commonIdVisitedPapers = commonIdVisitedPapers;
      });

      // 大学検索結果に、教科名称、閲覧済みフラグ、アイコンの表示有無フラグを追加
      this.searchResults = universities[0].departments.map(department => {
        return {
          ...department,
          papers: department.papers.map(paper => {
            return {
              ...paper,
              paperIds: paper.paperIds.map(paperId => {
                const subjectId = paperId.paperId.substring(6, 8);
                const subjectName =
                  this.universityId === COMMON_TEST_UNIVERSITY_ID
                    ? CommonIdSubjectUtil.getSubjectNameForCommonTest(paperId.paperId, this.staticCommonData.subjects)
                    : SubjectUtil.getName(this.staticCommonData.subjects, subjectId);

                return {
                  id: paperId.paperId,
                  daimonIds: paperId.daimonIds,
                  subjectName,
                  isVisited:
                    this.commonIdVisitedPapers &&
                    this.commonIdVisitedPapers.find(commonIdVisitedPaper => commonIdVisitedPaper === paperId.paperId)
                      ? true
                      : false,
                  isPremiumIconShown: this.isPremiumIconShown
                };
              })
            };
          })
        };
      });
    });
  }

  private setUpUniversityAndYears() {
    this.store.dispatch(CommonIdFindUniversityActions.initializeFindUniversityState());
    const request: CommonIdFindUniversityRequest = {
      universityId: this.universityId
    };
    this.store.dispatch(CommonIdFindUniversityActions.findUniversity({ request }));
    this.store
      .select(getFindUniversityResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        if (result.success) {
          // 大学詳細を設定する
          this.universityDetails$ = combineLatest([this.staticCommonData$]).pipe(
            map(([staticCommonData]) => {
              const universityName = staticCommonData.universities.find(staticData => staticData.id === this.universityId).name;
              const universityType = result.response.universityType;
              const universityTypeName = staticCommonData.universityTypes.find(staticData => staticData.id === universityType).name;
              const prefecture = COMMON_ID_PREFECTURES.find(pref => pref.id === result.response.prefectureId);
              const prefectureName = prefecture ? prefecture.name : COMMON_TEST_PREFECTURE_NAME;

              const title = `${this.title} - ${universityName}`;
              this.store.dispatch(setCommonIdBrowserTitle({ subTitle: title }));
              setTimeout(() => this.store.dispatch(setTitle({ title })));

              return {
                universityName,
                universityType,
                universityTypeName,
                prefectureName
              };
            }),
            take(1)
          );

          if (result.response.years.length > 0) {
            // 年度を並び替え
            const yearsSorted = [...result.response.years];
            yearsSorted.sort();
            yearsSorted.reverse();

            combineLatest([this.currentDateTime$, this.signedInUser$])
              .pipe(take(1))
              .subscribe(([currentDateTime, signedInUser]) => {
                this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser);

                // 有料会員でない場合、該当の年度にプレミアム会員限定の表示を追加
                this.years = yearsSorted.map(year => {
                  const label =
                    this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(year) ? year : year + COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS;
                  return {
                    label,
                    value: year
                  };
                });

                // 存在しない年度の場合は無視する
                const yearValues = this.years.map(year => year.value);
                if (!yearValues.includes(this.yearFromQueryParams)) {
                  this.store.dispatch(
                    navigate({ url: RoutingPathResolver.resolveCommonIdSearchUnivDetail(this.universityId), extras: { replaceUrl: true } })
                  );
                  this.yearFromQueryParams = null;
                }

                // クエリパラメタに年度の情報がある際は、その年度を初期設定とし、ない場合は最新年度を初期設定とする
                this.defaultYear = this.years[0].value;
                if (this.yearFromQueryParams) {
                  this.selectedYear = this.yearFromQueryParams;
                } else {
                  this.selectedYear = this.defaultYear;
                }

                // プレミアムアイコンは、無料ユーザーで、無料プランに含まれない年度を選択した場合に表示する
                this.isPremiumIconShown = !this.isPremiumUser && !COMMON_ID_FREE_YEARS.includes(this.selectedYear);

                // ローディングアイコンを非表示にする
                this.isLoadingFinished$ = of(true);

                // 選択された年度の大学詳細を検索する
                this.setUpUniversityDetails();
              });
          } else {
            // 年度情報が空の場合は大学検索画面へリダイレクト
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv(), extras: { replaceUrl: true } }));
          }
        } else {
          Log.warn(
            this.LOG_SOURCE,
            `大学詳細の取得(commonIdFindUniversity) error: err.code: ${result.error ? result.error.code : 'none'}`,
            result.error
          );
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }
      });
  }

  private setUpUniversityDetails() {
    Log.debug(this.LOG_SOURCE, '選択された過去問の年度: ', this.selectedYear);
    const universityIds = [this.universityId];

    const condition: UniversitySearchCondition<UniversityCondition> = {
      type: UniversitySearchType.DETAIL,
      universityCondition: { universityIds },
      year: Number(this.selectedYear)
    };

    this.findUniversity(condition, 1);
  }

  private setUpCurrentDateTime() {
    this.store.dispatch(getCurrentDateTime());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null),
      shareReplay(1)
    );
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpCommonIdVisitedPapers() {
    this.commonIdVisitedPapers$ = this.store.select(getCommonIdVisitedPapers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private initializeSearchResults() {
    this.searchResults = [];
  }

  private setUpUniversityBookmarks() {
    this.universityBookmark$ = this.store.select(CommonIdUniversityBookmarkSelectors.getUniversityBookmark).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpIsBookmarkedFlag() {
    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());
        this.store.dispatch(CommonIdUniversityBookmarkActions.findUniversityBookmark({ userId: signedInUser.id }));
        this.universityBookmark$.subscribe(bookmark => {
          // お気に入り登録の有無フラグを更新
          this.isBookmarked =
            bookmark.universities && bookmark.universities.find(bookmarkUniversity => bookmarkUniversity.unvId === this.universityId)
              ? true
              : false;

          this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
          this.initializedBookmarkSubject.next(true);
        });
      });
  }
}
