import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';

import { Observable } from 'rxjs';

import { Department } from 'src/app/models/find-university-papers-result';
import { GoToPaperDetailParams, UniversityDetails, Year } from '../../../models/search-univ-interfaces';

@Component({
  selector: 'app-search-univ-detail-base',
  templateUrl: './search-univ-detail-base.component.html',
  styleUrls: ['./search-univ-detail-base.component.scss']
})
export class SearchUnivDetailBaseComponent implements OnInit {
  @Input() isBToC: boolean;
  @Input() universityDetails$: Observable<UniversityDetails>;
  @Input() searchResults: Department[];
  @Input() years: Year[];
  @Input() selectedYear: string;
  @Input() isPremiumIconShown: boolean;
  @Input() isBookmarked: boolean;
  @Input() universityId: string;
  @Input() buttonAbled$: Observable<boolean>;
  @Input() errorUnivId$: Observable<string>;

  @Output() yearsChangeClick = new EventEmitter<string>();
  @Output() goToPaperDetailClick = new EventEmitter<GoToPaperDetailParams>();
  @Output() addUniversityBookmarkClick = new EventEmitter<string>();
  @Output() deleteUniversityBookmarkClick = new EventEmitter<string>();

  constructor(private location: Location) {}

  yearFormControl = new FormControl();

  ngOnInit() {
    this.yearFormControl.setValue(this.selectedYear);
  }

  goBack() {
    this.location.back();
  }

  onYearsChange() {
    this.yearsChangeClick.emit(this.yearFormControl.value);
    this.selectedYear = this.yearFormControl.value;
  }
}
