<app-dialog-basic [dialogTitle]="dialogTitle" [isLoading]="accountEditing">
  <form [formGroup]="accountForm">
    <!-- 氏名 -->
    <div class="grid grid-cols-2 gap-2">
      <mat-form-field appearance="outline">
        <mat-label>姓</mat-label>
        <input matInput formControlName="familyName" />
        <mat-error *ngIf="accountForm.get('familyName').hasError('required')">入力してください</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>名</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error *ngIf="accountForm.get('firstName').hasError('required')">入力してください</mat-error>
      </mat-form-field>
    </div>

    <!-- ふりがな -->
    <div class="grid grid-cols-2 gap-2">
      <mat-form-field appearance="outline">
        <mat-label>せい</mat-label>
        <input matInput formControlName="familyNameKana" />
        <mat-error *ngIf="accountForm.get('familyNameKana').hasError('required')">入力してください</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>めい</mat-label>
        <input matInput formControlName="firstNameKana" />
        <mat-error *ngIf="accountForm.get('firstNameKana').hasError('required')">入力してください</mat-error>
      </mat-form-field>
    </div>

    <!-- 団体種別 -->
    <mat-form-field class="block" appearance="outline" *ngIf="signInUserIsAdmin">
      <mat-label>団体種別</mat-label>
      <mat-select formControlName="organization" (selectionChange)="onChangeOrganization()">
        <mat-option *ngFor="let organization of organizations" [value]="organization">{{ organization.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="accountForm.get('organization').hasError('required')">選択してください</mat-error>
    </mat-form-field>

    <!-- 都道府県（絞り込み） -->
    <mat-form-field class="block" appearance="outline" *ngIf="signInUserIsAdmin">
      <mat-label>都道府県（絞り込み）</mat-label>
      <mat-select formControlName="prefecture" (selectionChange)="onChangePrefecture()">
        <mat-option *ngFor="let prefecture of prefectures" [value]="prefecture.id">{{ prefecture.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- 団体名 -->
    <mat-form-field class="block" appearance="outline" *ngIf="signInUserIsAdmin">
      <mat-label>団体名</mat-label>
      <input type="text" matInput formControlName="school" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectSchool($event)">
        <mat-option *ngFor="let highSchool of filteredSchools$ | async" [value]="highSchool">
          {{ highSchool.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="accountForm.get('school').hasError('required')">入力してください</mat-error>
      <mat-error *ngIf="accountForm.get('school').hasError('isSchoolInMaster')">正しい高校名を入力してください</mat-error>
      <mat-error *ngIf="accountForm.get('school').hasError('isJukuInMaster')">正しい塾/予備校名を入力してください</mat-error>
    </mat-form-field>

    <div class="block mb-4 mx-2">
      <label class="block mb-2">権限</label>
      <mat-radio-group formControlName="authority">
        <ng-container *ngFor="let authority of authorities">
          <ng-container *ngIf="authority.id === userAuthorityObunshaId">
            <mat-radio-button class="mr-4" [value]="authority.id" *ngIf="signInUserIsAdmin" [disabled]="adminDisabled">{{
              authority.name
            }}</mat-radio-button>
          </ng-container>
          <ng-container *ngIf="authority.id === userAuthorityOrganizationMasterId">
            <mat-radio-button class="mr-4" [value]="authority.id" *ngIf="signInUserIsAdmin" [disabled]="organizationMasterDisabled">{{
              authority.name
            }}</mat-radio-button>
          </ng-container>
          <ng-container *ngIf="authority.id === userAuthorityOrganizationUserId">
            <mat-radio-button class="mr-4" [value]="authority.id">{{ authority.name }}</mat-radio-button>
          </ng-container>
        </ng-container>
      </mat-radio-group>
      <mat-error *ngIf="accountForm.get('authority').hasError('required')">選択してください</mat-error>
    </div>
  </form>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="accountEditing"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="accountEditing">キャンセル</button>
    <button mat-flat-button color="primary" (click)="editAccount()" [disabled]="accountEditing">更新</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
