import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';
import { dispatchAppError, dispatchInfoMessage } from 'src/app/actions/core.actions';
import { deletePlan, initializeDeletePlanState } from '../../../actions/plan.actions';
import { PLAN_NAMES } from '../../../resources/config';
import { getDeletePlanResult } from '../../../selectors/plan.selectors';
import { enter } from '../../../resources/animations';
import { DeletePlanData } from '../../../models/plan';

export interface DeletePlanDialogData {
  planId: string;
  recordId: number;
  school: string;
  subjectId: string;
}

@Component({
  selector: 'app-delete-plan-dialog',
  templateUrl: './delete-plan-dialog.component.html',
  styleUrls: ['./delete-plan-dialog.component.scss'],
  animations: [enter]
})
export class DeletePlanDialogComponent implements OnInit {
  disabled = false;
  planName: string;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<DeletePlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeletePlanDialogData
  ) {}

  ngOnInit() {
    this.planName = PLAN_NAMES[this.data.subjectId];
  }

  deletePlan() {
    if (!this.data || !this.data.planId || !this.data.recordId) {
      Log.warn(this.LOG_SOURCE, `data.planId または data.recordId が設定されていません: `, this.data);
      return;
    }

    this.disableForms();

    Log.debug(this.LOG_SOURCE, `プランを削除します`);
    this.store
      .select(getDeletePlanResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeDeletePlanState());
        if (result.success) {
          const message = 'プラン削除が完了しました';
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `プラン削除完了: `, result);
          this.dialogRef.close(true);
        } else {
          Log.error(this.LOG_SOURCE, `プラン削除でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
          this.dialogRef.close(false);
        }

        this.enableForms();
      });

    const plan: DeletePlanData = {
      id: this.data.planId,
      recordId: this.data.recordId,
      school: this.data.school
    };
    this.store.dispatch(deletePlan({ plan }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.planName} のプランを削除しています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
