import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import * as CurrentDateTimeActions from '../actions/current-date-time.actions';
import { CurrentDateTimeService } from '../services/current-date-time.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';

@Injectable()
export class CurrentDateTimeEffects {
  private LOG_SOURCE = this.constructor.name;

  getCurrentDateTime$ = createEffect(() => {
    const object = this.actions$.pipe(
      ofType(CurrentDateTimeActions.getCurrentDateTime),
      switchMap(() =>
        this.currentDateTimeService.getCurrentDateTime().pipe(
          map(currentDateTime => {
            return CurrentDateTimeActions.getCurrentDateTimeSuccess({ currentDateTime });
          }),
          catchError(e =>
            of(
              CurrentDateTimeActions.getCurrentDateTimeFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    );
    return object;
  });

  constructor(private actions$: Actions, private currentDateTimeService: CurrentDateTimeService) {}
}
