import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { enter } from '../../../resources/animations';
import { navigate, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { ActivatedRoute } from '@angular/router';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { User } from '../../../models/user';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { Plan } from '../../../models/plan';
import { getPlans } from '../../../selectors/plan.selectors';
import { CurrentDateTime } from '../../../models/current-date-time';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { getUsers } from '../../../selectors/user.selectors';
import { HighSchool, Subject } from '../../../models/common-data';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { getHighSchool } from '../../../selectors/static-data.selectors';
import { StaticDataUtil } from '../../../utils/static-data-util';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../../actions/current-date-time.actions';
import { findPlans, initializeFindPlansState } from '../../../actions/plan.actions';
import { findUsers, initializeFindUsers } from '../../../actions/user.actions';
import { Log } from '../../../utils/log';
import {
  ADD_ACCOUNT_DIALOG_HEIGHT,
  ADD_ACCOUNT_DIALOG_WIDTH,
  CHANGE_ORGANIZATION_AUTHORITY_DIALOG_WIDTH,
  DELETE_ACCOUNT_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  EDIT_ACCOUNT_DIALOG_ADMIN_HEIGHT,
  EDIT_ACCOUNT_DIALOG_HEIGHT,
  EDIT_ACCOUNT_DIALOG_WIDTH,
  ACCOUNT_DIALOG_TYPE_MEMBERS,
  PLAN_AVAILABLE_ORGANIZATION_IDS,
  PLAN_NAMES,
  PlanSubjectId,
  SCHOOL_ORGANIZATION_ID,
  JUKU_ORGANIZATION_ID
} from '../../../resources/config';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddAccountDialogComponent, AddAccountDialogData } from '../add-account-dialog/add-account-dialog.component';
import { EditAccountDialogComponent, EditAccountDialogData } from '../edit-account-dialog/edit-account-dialog.component';
import { DeleteAccountDialogComponent, DeleteAccountDialogData } from '../delete-account-dialog/delete-account-dialog.component';
import { PlanUtil, UserAssignmentPlan } from '../../../utils/plan-util';
import {
  ChangeOrganizationAuthorityDialogComponent,
  ChangeOrganizationAuthorityDialogData
} from '../change-organization-authority-dialog/change-organization-authority-dialog.component';
import { getJukus } from '../../../selectors/juku.selectors';
import { Juku } from '../../../models/juku';
import { findJukus, initializeFindJukusState } from '../../../actions/juku.actions';
import { JukuUtil } from 'src/app/utils/juku-util';

interface MemberData {
  user: User;
  planId: string | null;
  planSubjectId: string | null;
  planName: string | null;
}

interface Breadcrumb {
  key: string;
  label: string;
}

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  animations: [enter]
})
export class MembersComponent implements OnInit, OnDestroy {
  isAdmin: boolean;
  isOrganizationAdmin: boolean;
  signInUser: User;
  highSchools: HighSchool[];
  jukus: Juku[];
  schoolId: string;
  schoolName: string;
  organization: string;
  membersData$: Observable<MemberData[]>;
  highSchools$: Observable<HighSchool[]>;
  jukus$: Observable<Juku[]>;
  allPlanSubjectId: string = PlanSubjectId.ALL;
  displayedColumns: string[] = ['memberName', 'memberNameKana', 'email', 'memberType', 'assignedPlan', 'edit', 'delete'];
  breadcrumbs: Breadcrumb[] = [];

  private LOG_SOURCE = this.constructor.name;
  private title = '所属メンバー一覧';

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeFindPlansState());
    this.store.dispatch(initializeGetCurrentDateTime());
    this.store.dispatch(initializeFindUsers());
    this.store.dispatch(initializeFindJukusState());
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
      case 'admin/schools':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSchools() }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'admin/plans':
        this.store.dispatch(
          navigate({ url: RoutingPathResolver.resolvePlans(this.isAdmin ? this.organization : null, this.isAdmin ? this.schoolId : null) })
        );
        break;
    }
  }

  plans() {
    this.store.dispatch(
      navigate({ url: RoutingPathResolver.resolvePlans(this.isAdmin ? this.organization : null, this.isAdmin ? this.schoolId : null) })
    );
  }

  planAssign(planId) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlanAssign(planId) }));
  }

  addAccount() {
    const config: MatDialogConfig<AddAccountDialogData> = {
      height: ADD_ACCOUNT_DIALOG_HEIGHT,
      width: ADD_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: {
        signInUser: this.signInUser,
        highSchools: this.highSchools,
        jukus: this.jukus,
        defaultSchool: this.schoolName,
        defaultSchoolId: this.schoolId,
        defaultOrganization: this.organization,
        type: ACCOUNT_DIALOG_TYPE_MEMBERS
      },
      disableClose: true
    };
    this.dialog
      .open(AddAccountDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(initializeFindUsers());
          this.setUpSchools(this.organization, this.schoolId);
        }
      });
  }

  goToMembersImport() {
    this.store.dispatch(
      navigate({
        url: RoutingPathResolver.resolveMembersImport(this.isAdmin ? this.organization : null, this.isAdmin ? this.schoolId : null)
      })
    );
  }

  editAccount(user: User) {
    const config: MatDialogConfig<EditAccountDialogData> = {
      height: this.signInUser.isAdmin ? EDIT_ACCOUNT_DIALOG_ADMIN_HEIGHT : EDIT_ACCOUNT_DIALOG_HEIGHT,
      width: EDIT_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: {
        signInUser: this.signInUser,
        highSchools: this.highSchools,
        jukus: this.jukus,
        user: {
          id: user.id,
          familyName: user.familyName,
          firstName: user.firstName,
          familyNameKana: user.familyNameKana,
          firstNameKana: user.firstNameKana,
          organization: user.organization,
          schoolId: user.schoolId,
          school: user.school,
          isAdmin: user.isAdmin,
          isOrganizationAdmin: user.isOrganizationAdmin
        },
        type: ACCOUNT_DIALOG_TYPE_MEMBERS
      },
      disableClose: true
    };
    this.dialog
      .open(EditAccountDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(initializeFindUsers());
          this.setUpSchools(this.organization, this.schoolId);
        }
      });
  }

  removeAccount(user: User) {
    Log.debug(this.LOG_SOURCE, `次のアカウント削除します: `, user);
    const data: DeleteAccountDialogData = { userId: user.id, email: user.email, isSignedInUserAdmin: this.isAdmin ? true : false };

    const config: MatDialogConfig = {
      width: DELETE_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(DeleteAccountDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(initializeFindUsers());
          this.setUpSchools(this.organization, this.schoolId);
        }
      });
  }

  changeOrganizationAuthority(user: User) {
    const config: MatDialogConfig<ChangeOrganizationAuthorityDialogData> = {
      width: CHANGE_ORGANIZATION_AUTHORITY_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data: { user },
      disableClose: true
    };
    this.dialog
      .open(ChangeOrganizationAuthorityDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(initializeFindUsers());
          this.setUpSchools(this.organization, this.schoolId);
        }
      });
  }

  private setUp() {
    const organization: string = this.activatedRoute.snapshot.paramMap.get('organization') || '';
    const schoolId: string = this.activatedRoute.snapshot.paramMap.get('schoolId') || '';

    this.store
      .select(getSignedInUser)
      .pipe(filter<User>(it => it != null && it !== 'none'))
      .pipe(take(1))
      .subscribe(user => {
        this.isAdmin = user.isAdmin;
        this.isOrganizationAdmin = user.isOrganizationAdmin;
        this.signInUser = user;

        user.isAdmin && organization && schoolId
          ? this.setUpSchools(organization, schoolId)
          : user.isOrganizationAdmin
          ? this.setUpSchools(user.organization, user.schoolId)
          : this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
      });
  }

  private setUpSchools(organization: string, schoolId: string) {
    this.schoolId = schoolId;
    this.organization = organization;

    if (!this.organizationCheck()) {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
      return;
    }

    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const plans$: Observable<Plan[]> = this.store.select(getPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const currentDateTime$: Observable<CurrentDateTime> = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const users$: Observable<User[]> = this.store.select(getUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.highSchools$ = this.store.select(getHighSchool).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.jukus$ = this.store.select(getJukus).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.membersData$ = combineLatest([subjects$, plans$, currentDateTime$, users$, this.highSchools$, this.jukus$])
      .pipe(take(1))
      .pipe(
        map(([subjects, plans, currentDateTime, users, highSchools, jukus]) => {
          this.highSchools = highSchools;
          this.jukus = jukus;
          this.schoolName = StaticDataUtil.getHiSchoolName(highSchools, this.schoolId);
          if (this.organization === SCHOOL_ORGANIZATION_ID) {
            this.schoolName = StaticDataUtil.getHiSchoolName(highSchools, this.schoolId);
          } else if (this.organization === JUKU_ORGANIZATION_ID) {
            const juku: Juku = JukuUtil.getJukuByJukuCode(jukus, this.schoolId);
            if (juku !== undefined && juku !== null) {
              this.schoolName = JukuUtil.getDisplayJukuName(juku);
            }
          }

          if (this.schoolName === '') {
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
            return;
          }

          this.breadcrumbs = [];
          this.breadcrumbs.push({ key: 'admin', label: 'Admin機能' });

          if (this.organization === SCHOOL_ORGANIZATION_ID) {
            this.breadcrumbs.push({ key: 'admin/schools', label: 'アカウント管理 - 学校' });
          } else if (this.organization === JUKU_ORGANIZATION_ID) {
            this.breadcrumbs.push({ key: 'admin/jukus', label: 'アカウント管理 - 塾・予備校' });
          }

          this.breadcrumbs.push({ key: 'admin/plans', label: this.schoolName + ' - 所属メンバー一覧' });

          return users
            .map(user => this.setUpMemberData(user, currentDateTime, subjects, plans))
            .sort((a, b) => -a.user.createdAt.localeCompare(b.user.createdAt));
        }),
        shareReplay(1)
      );

    this.store.dispatch(getCurrentDateTime());
    this.store.dispatch(findJukus({}));
    this.store.dispatch(findPlans({ organization: this.organization, schoolId: this.schoolId }));
    this.store.dispatch(findUsers({ userSearchCondition: { organization: this.organization, schoolId: this.schoolId } }));
  }

  private setUpMemberData(user: User, currentDateTime: CurrentDateTime, subjects: Subject[], plans: Plan[]): MemberData {
    const userAssignmentPlan: UserAssignmentPlan | null = PlanUtil.getUserAssignmentPlan(user.id, plans, currentDateTime);

    return {
      user,
      planId: userAssignmentPlan ? userAssignmentPlan.plan.id : null,
      planSubjectId: userAssignmentPlan ? userAssignmentPlan.plan.subjectId : null,
      planName: userAssignmentPlan ? PLAN_NAMES[userAssignmentPlan.plan.subjectId] : null
    };
  }

  private organizationCheck(): boolean {
    return PLAN_AVAILABLE_ORGANIZATION_IDS.includes(this.organization);
  }
}
