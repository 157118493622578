import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { MAX_PRINTABLE_COUNT } from 'src/app/resources/config';

export type PrintConfirmDialogResultType = 'OK' | undefined;

@Component({
  selector: 'app-print-confirm-dialog',
  templateUrl: './print-confirm-dialog.component.html',
  styleUrls: ['./print-confirm-dialog.component.scss']
})
export class PrintConfirmDialogComponent {
  disabled = false;
  maxPrintableCount: number;

  constructor(private dialogRef: MatDialogRef<PrintConfirmDialogComponent, PrintConfirmDialogResultType>) {
    this.maxPrintableCount = MAX_PRINTABLE_COUNT;
  }

  confirm() {
    this.disableForms();
    // NOTE: OKボタンが押されたときは 'OK'を呼び出し元に渡す。
    this.dialogRef.close('OK');
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close();
  }

  private disableForms() {
    this.disabled = true;
  }
}
