import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { inOut } from 'src/app/resources/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CommonIdPaperSourceInformationDialogData {
  descriptions: string[];
}

@Component({
  selector: 'app-common-id-paper-source-information-dialog',
  templateUrl: './paper-source-information-dialog.component.html',
  styleUrls: ['./paper-source-information-dialog.component.scss'],
  animations: [inOut]
})
export class CommonIdPaperSourceInformationDialogComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  title: any;
  descriptions: any;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<CommonIdPaperSourceInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdPaperSourceInformationDialogData // @Inject(MAT_DIALOG_DATA) public title: string, // @Inject(MAT_DIALOG_DATA) public description: string, // @Inject(MAT_DIALOG_DATA) public examData: string, // @Inject(MAT_DIALOG_DATA) public nyushiName: string, // @Inject(MAT_DIALOG_DATA) public examTime: string
  ) {}

  // @Input() description: string;
  // @Input() examDate: string;
  // @Input() nyushiName: string;
  // @Input() examTime: string;
  // @Input() paper: ExamPaper;

  ngOnInit() {
    // console.log(this.data);
    // console.log(this.description);
  }

  ngOnDestroy() {}

  close() {
    this.dialogRef.close(false);
  }
}
