import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Log } from './app/utils/log';

Sentry.init({
  dsn: environment.sentryDsn,
  release: environment.release,
  // beforeSend(event, hint) {
  //   if (event.exception) {
  //     Sentry.showReportDialog({
  //       eventId: event.event_id,
  //       title: 'クラッシュレポートを報告',
  //       subtitle: 'エラーが発生してしまいました。お手数ですが操作方法など記載の上、',
  //       successMessage: 'ご連絡ありがとうございます。'
  //     });
  //   }
  //   return event;
  // },
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.redirectDomainConfig.to],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment.envName
});

if (environment.production) {
  enableProdMode();
}

if (location.host === environment.redirectDomainConfig.from) {
  const url = new URL(location.href);
  url.hostname = environment.redirectDomainConfig.to;
  location.href = url.toString();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => Log.error('main.ts', err));

Log.debug('main.ts', `current project-id: ${environment.firebase.projectId}`);
