import { Component, Inject, OnInit } from '@angular/core';
import { enter } from '../../../../resources/animations';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonIdGetStripeCheckoutUrlRequest } from '../../../../models/common-id/common-id-get-stripe-checkout-url-request';
import { Log } from '../../../../utils/log';
import { getStripeCheckoutUrl } from '../../../../selectors/common-id/common-id-get-stripe-checkout-url.selectors';
import { filter, take } from 'rxjs/operators';
import {
  commonIdGetStripeCheckoutUrl,
  initializeCommonIdGetStripeCheckoutUrlState
} from '../../../../actions/common-id/common-id-get-stripe-checkout-url.actions';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { openWindow } from '../../../../actions/core.actions';

@Component({
  selector: 'app-common-id-purchase-dialog',
  templateUrl: './purchase-dialog.component.html',
  styleUrls: ['./purchase-dialog.component.scss'],
  animations: [enter]
})
export class CommonIdPurchaseDialogComponent implements OnInit {
  disabled = true;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    @Inject(WINDOW_OBJECT) private window: Window,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdGetStripeCheckoutUrlRequest
  ) {}

  ngOnInit() {
    if (!this.data) {
      Log.warn(this.LOG_SOURCE, `dialog.data が設定されていません: `, this.data);
      return;
    }

    // 親コンポーネント側でGAイベントを送信中のため、念のため500ミリ秒待機してから処理を行う
    setTimeout(() => {
      this.store.dispatch(commonIdGetStripeCheckoutUrl({ condition: this.data }));
      this.store
        .select(getStripeCheckoutUrl)
        .pipe(
          filter(it => it != null),
          take(1)
        )
        .subscribe(result => {
          this.store.dispatch(initializeCommonIdGetStripeCheckoutUrlState());
          // Log.debug(this.LOG_SOURCE, 'get stripe checkout url', this.data, result);
          if (result && result.response.url) {
            this.store.dispatch(openWindow({ url: result.response.url, target: '_self' }));
          }
        });
    }, 1000);
  }
}
