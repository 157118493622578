import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { ScienceUnit } from 'src/app/models/science-data';
import { HistoryUnit } from 'src/app/models/history-data';
import { EnglishSearchCondition } from './../../../../models/english-search-condition';
import { StaticEnglishData } from './../../../../models/static-english-data';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { CommonSearchCondition } from 'src/app/models/common-search-condition';

import { Log } from 'src/app/utils/log';

import { CATEGORY_DELIMITER, NO_DISPLAY_MATH_CATEGORY_PREFIX, SubjectId } from 'src/app/resources/config';
import { GeneralError } from 'src/app/errors/general-error';
import { inOut } from 'src/app/resources/animations';

import { CommonIdSearchCategoryDialogSharedService } from 'src/app/services/common-id/common-id-search-category-dialog-shared.service';

export interface SelectSubjectCategoryDialogData {
  subjectId: string;
  staticEnglishData?: StaticEnglishData;
  staticScienceData?: StaticScienceData;
  staticHistoryData?: StaticHistoryData;
  staticNationalLanguageData?: StaticNationalLanguageData;

  englishSearchCondition?: EnglishSearchCondition;
  scienceSearchCondition?: ScienceSearchCondition;
  historySearchCondition?: HistorySearchCondition;
  nationalLanguageSearchCondition?: NationalLanguageSearchCondition;

  matchedProblemCount$: Observable<number>;
  problemCountSearching$: Observable<boolean>;
}

/** cancel で close のときは undefined */
export type SelectSubjectCategoryDialogResult =
  | ScienceSearchCondition
  | HistorySearchCondition
  | NationalLanguageSearchCondition
  | undefined;

interface UnitData extends ScienceUnit, HistoryUnit {
  checked: boolean;
  parentSubjectId: string;
}

interface FieldData {
  id: string;
  name: string;
  parentSubjectId: string;
  checked: boolean;
  indeterminate: boolean;
  units: UnitData[];
}

interface SubjectData {
  id: string;
  index: number;
  name: string;
  checked: boolean;
  indeterminate: boolean;
  fields: FieldData[];
}

@Component({
  selector: 'app-common-id-select-subject-category-dialog',
  templateUrl: './select-subject-category-dialog.component.html',
  styleUrls: ['./select-subject-category-dialog.component.scss'],
  animations: [inOut]
})
export class CommonIdSelectSubjectCategoryDialogComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;

  subjectDataArray: SubjectData[];
  subjectId: string;
  commonSearchCondition: CommonSearchCondition;

  matchedProblemCount$: Observable<number>;
  problemCountSearching$: Observable<boolean>;

  /** two-way binding */
  selectedTabIndex = 0;

  constructor(
    private dialogRef: MatDialogRef<CommonIdSelectSubjectCategoryDialogComponent, SelectSubjectCategoryDialogResult>,
    @Inject(MAT_DIALOG_DATA) private data: SelectSubjectCategoryDialogData,
    private commonIdSearchCategoryDialogSharedService: CommonIdSearchCategoryDialogSharedService
  ) {}

  ngOnInit() {
    this.setUpDialogData();
    this.setUpCheckboxes();
    this.setInitialValues();
  }

  onChangeSubject(subject: SubjectData) {
    const currentSubject = this.subjectDataArray.find(subjectData => subjectData.index === this.selectedTabIndex);
    if (!currentSubject) return;

    if (subject.checked) {
      currentSubject.fields.forEach(field => {
        field.indeterminate = false;
        field.checked = true;
        field.units.forEach(unit => (unit.checked = true));
      });
    } else {
      currentSubject.fields.forEach(field => {
        field.indeterminate = false;
        field.checked = false;
        field.units.forEach(unit => (unit.checked = false));
      });
    }

    this.emitCategory();
  }

  onChangeField(field: FieldData, subject: SubjectData) {
    const allChecked = subject.fields.filter(it => it.checked).length === subject.fields.length;
    const allUnchecked = subject.fields.filter(it => it.checked).length === 0;

    subject.indeterminate = !allChecked && !allUnchecked;
    if (allChecked) {
      subject.checked = true;
    } else {
      subject.checked = false;
    }

    if (field.checked) {
      field.units.forEach(unit => (unit.checked = true));
    } else {
      field.units.forEach(unit => (unit.checked = false));
    }

    this.emitCategory();
  }

  onChangeUnit(field: FieldData, subject: SubjectData) {
    const allChecked = field.units.filter(it => it.checked).length === field.units.length;
    const allUnchecked = field.units.filter(it => it.checked).length === 0;

    subject.indeterminate = !allChecked && !allUnchecked;
    field.indeterminate = !allChecked && !allUnchecked;
    if (allChecked) field.checked = true;
    if (allUnchecked) field.checked = false;

    this.emitCategory();
  }

  reset() {
    // チェックボックスのチェックを外す
    this.subjectDataArray.forEach(subject => {
      subject.checked = false;
      subject.indeterminate = false;
      subject.fields.forEach(field => {
        field.units.forEach(unit => {
          unit.checked = false;
        });
        field.checked = false;
        field.indeterminate = false;
      });
    });

    this.emitCategory();
  }

  close() {
    const condition = this.collectCondition();
    this.dialogRef.close(condition);
  }

  isSubjectDataType() {
    return this.subjectId === SubjectId.ENGLISH ||
      this.subjectId === SubjectId.NATIONAL_LANGUAGE ||
      this.subjectId === SubjectId.JAPANESE_HISTORY ||
      this.subjectId === SubjectId.WORLD_HISTORY
      ? true
      : null;
  }

  private setUpDialogData() {
    if (
      !this.data ||
      !this.data.subjectId ||
      (!this.data.staticEnglishData &&
        !this.data.staticScienceData &&
        !this.data.staticHistoryData &&
        !this.data.staticNationalLanguageData)
    ) {
      throw GeneralError.failedPrecondition(`必要なデータが設定されていません`);
    }

    this.subjectId = this.data.subjectId;
    this.matchedProblemCount$ = this.data.matchedProblemCount$;
    this.problemCountSearching$ = this.data.problemCountSearching$;
  }

  private setUpCheckboxes() {
    const filteredStaticData = this.getFilteredStaticData();

    this.subjectDataArray = filteredStaticData.subjects.map((subject, index) => {
      const fields = filteredStaticData.fields.filter(it => it.parentSubjectId === subject.id);

      const fieldsWithUnit: FieldData[] = fields.map(field => {
        const units = filteredStaticData.units.filter(it => it.parentFieldId === field.id);
        const fieldData: FieldData = {
          id: field.id,
          name: field.name,
          parentSubjectId: field.parentSubjectId,
          checked: false,
          indeterminate: false,
          units: units.map<UnitData>(unit => ({ ...unit, checked: false, parentSubjectId: subject.id }))
        };
        return fieldData;
      });

      const subjectData: SubjectData = {
        id: subject.id,
        index,
        name: subject.name,
        checked: false,
        indeterminate: false,
        fields: fieldsWithUnit
      };
      return subjectData;
    });
  }

  private setInitialValues() {
    const selected =
      this.data.englishSearchCondition ||
      this.data.scienceSearchCondition ||
      this.data.historySearchCondition ||
      this.data.nationalLanguageSearchCondition;
    if (!selected) {
      Log.debug(this.LOG_SOURCE, '何も選択されていないため初期値をセットしません');
      return;
    }

    if (selected.subjectIds) {
      selected.subjectIds.forEach(subjectId => this.selectSubject(subjectId));
    }
    if (selected.subjectAndFieldIds) {
      selected.subjectAndFieldIds.forEach(subjectAndFieldId => this.selectField(subjectAndFieldId));
    }
    if (selected.categories) {
      this.selectCategories(selected.categories);
    }
  }

  private selectSubject(subjectId: string) {
    const subject = this.subjectDataArray.find(it => it.id === subjectId);
    if (subject) {
      subject.checked = true;
      subject.fields.forEach(field => {
        field.checked = true;
        field.units.forEach(unit => (unit.checked = true));
      });
    }
  }

  private selectField(subjectAndFieldId: string) {
    const splitIds = subjectAndFieldId.split(CATEGORY_DELIMITER);
    if (splitIds.length !== 2) {
      Log.warn(this.LOG_SOURCE, `subjectAndFieldIds が不正な値です: ${subjectAndFieldId}`);
      return;
    }

    const [subjectId, fieldId] = [splitIds[0], splitIds[1]];
    const subject = this.subjectDataArray.find(it => it.id === subjectId);
    if (!subject) {
      Log.warn(this.LOG_SOURCE, `指定された ID の subject が見つかりませんでした. subjectId: ${subjectId}`);
      return;
    }
    const field = subject.fields.find(it => it.id === fieldId);
    if (!field) {
      Log.warn(this.LOG_SOURCE, `指定された ID の field が見つかりませんでした. fieldId: ${fieldId}`);
      return;
    }

    subject.indeterminate = true;
    field.checked = true;
    field.units.forEach(unit => (unit.checked = true));
  }

  private selectCategories(categories: string[]) {
    categories.forEach(category => {
      const splitIds = category.split(CATEGORY_DELIMITER);
      if (splitIds.length !== 3) {
        Log.warn(this.LOG_SOURCE, `subjectAndFieldIds が不正な値です: ${category}`);
        return;
      }

      const [subjectId, fieldId, unitId] = [splitIds[0], splitIds[1], splitIds[2]];
      const subject = this.subjectDataArray.find(it => it.id === subjectId);
      if (!subject) {
        Log.warn(this.LOG_SOURCE, `指定された ID の subject が見つかりませんでした. subjectId: ${subjectId}`);
        return;
      }
      const field = subject.fields.find(it => it.id === fieldId);
      if (!field) {
        Log.warn(this.LOG_SOURCE, `指定された ID の field が見つかりませんでした. fieldId: ${fieldId}`);
        return;
      }

      const unit = field.units.find(it => it.id === unitId);
      if (!unit) {
        Log.warn(this.LOG_SOURCE, `指定された ID の unit が見つかりませんでした. unitId: ${unitId}`);
        return;
      }

      subject.indeterminate = true;
      field.indeterminate = true;
      unit.checked = true;
    });
  }

  private getFilteredStaticData(): StaticScienceData | StaticHistoryData | StaticNationalLanguageData {
    if (this.subjectId === SubjectId.ENGLISH) {
      return {
        subjects: this.data.staticEnglishData.bunyaSubjects,
        fields: this.data.staticEnglishData.bunyaFields,
        units: this.data.staticEnglishData.bunyaUnits
      };
    }

    if (this.subjectId === SubjectId.MATH) {
      return {
        subjects: this.data.staticScienceData.subjects.filter(subject => !subject.id.startsWith(NO_DISPLAY_MATH_CATEGORY_PREFIX)),
        fields: this.data.staticScienceData.fields.filter(field => !field.id.startsWith(NO_DISPLAY_MATH_CATEGORY_PREFIX)),
        units: this.data.staticScienceData.units.filter(unit => !unit.id.startsWith(NO_DISPLAY_MATH_CATEGORY_PREFIX))
      };
    }

    if (this.subjectId === SubjectId.NATIONAL_LANGUAGE) {
      return {
        subjects: this.data.staticNationalLanguageData.subjects,
        fields: this.data.staticNationalLanguageData.fields,
        units: this.data.staticNationalLanguageData.units.filter(unit => unit.id.startsWith('1')) // 大問分野のみ表示
      };
    }

    if (
      this.subjectId === SubjectId.PHYSICS ||
      this.subjectId === SubjectId.CHEMISTRY ||
      this.subjectId === SubjectId.BIOLOGY ||
      this.subjectId === SubjectId.GEOGRAPHY ||
      this.subjectId === SubjectId.POLITICAL_ECONOMY
    ) {
      return {
        subjects: this.data.staticScienceData.subjects,
        fields: this.data.staticScienceData.fields,
        units: this.data.staticScienceData.units
      };
    }

    if (this.subjectId === SubjectId.JAPANESE_HISTORY || this.subjectId === SubjectId.WORLD_HISTORY) {
      return {
        subjects: this.data.staticHistoryData.subjects,
        fields: this.data.staticHistoryData.fields,
        units: this.data.staticHistoryData.units
      };
    }
  }

  private collectCondition(): ScienceSearchCondition | HistorySearchCondition | NationalLanguageSearchCondition {
    const allFieldSelectedSubjects = this.subjectDataArray.filter(subject => {
      const fieldAllChecked =
        subject.fields.filter(field => {
          const unitAllChecked = field.units.filter(unit => unit.checked).length === field.units.length;
          return unitAllChecked;
        }).length === subject.fields.length;
      return fieldAllChecked;
    });
    const subjectIds = allFieldSelectedSubjects.map(subject => subject.id);

    const selectedSubjectIgnoredFields = this.subjectDataArray
      .filter(subject => !subjectIds.includes(subject.id))
      .reduce<FieldData[]>((acc, subject) => [...acc, ...subject.fields], []);
    const allUnitSelectedFields = selectedSubjectIgnoredFields.filter(field => {
      const unitAllChecked = field.units.filter(unit => unit.checked).length === field.units.length;
      return unitAllChecked;
    });
    const allUnitSelectedFieldIds = allUnitSelectedFields.map(field => field.id);
    const subjectAndFieldIds = allUnitSelectedFields.map(field => `${field.parentSubjectId}${CATEGORY_DELIMITER}${field.id}`);

    const selectedUnits = selectedSubjectIgnoredFields
      .filter(field => !allUnitSelectedFieldIds.includes(field.id))
      .reduce<UnitData[]>((acc, field) => [...acc, ...field.units], [])
      .filter(unit => unit.checked);
    const categories = selectedUnits.map(
      unit => `${unit.parentSubjectId}${CATEGORY_DELIMITER}${unit.parentFieldId}${CATEGORY_DELIMITER}${unit.id}`
    );

    const condition: EnglishSearchCondition | ScienceSearchCondition | HistorySearchCondition | NationalLanguageSearchCondition = {
      subjectIds,
      subjectAndFieldIds,
      categories
    };
    Log.debug(this.LOG_SOURCE, '分野ダイアログ内で選択された条件: ', condition);

    return condition;
  }

  private emitCategory() {
    const condition = this.collectCondition();
    this.emitSearchCondition(condition);
  }

  private emitSearchCondition(
    subjectSearchCondition: EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition
  ) {
    // 最新の選択状況を、親コンポーネントに送る
    switch (this.subjectId) {
      case SubjectId.ENGLISH:
        Log.debug(this.LOG_SOURCE, '英語の分野の選択状況: ', subjectSearchCondition);
        this.commonIdSearchCategoryDialogSharedService.emitEnglishCategory(subjectSearchCondition);
        break;

      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        Log.debug(this.LOG_SOURCE, '理系科目または地理または政経の分野の選択状況: ', subjectSearchCondition);
        this.commonIdSearchCategoryDialogSharedService.emitScienceCategory(subjectSearchCondition);
        break;

      case SubjectId.NATIONAL_LANGUAGE:
        Log.debug(this.LOG_SOURCE, '国語の分野の選択状況: ', subjectSearchCondition);
        this.commonIdSearchCategoryDialogSharedService.emitNationalLanguageCategory(subjectSearchCondition);
        break;

      case SubjectId.JAPANESE_HISTORY:
      case SubjectId.WORLD_HISTORY:
        Log.debug(this.LOG_SOURCE, '歴史科目の分野の選択状況: ', subjectSearchCondition);
        this.commonIdSearchCategoryDialogSharedService.emitHistoryCategory(subjectSearchCondition);
        break;
    }
  }
}
